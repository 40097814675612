/* global _ */
require('./autocomplete.scss')
/** @ngInject */
function autoCompleteController ($scope, visionService) {
  const queryMethod = $scope.to.queryMethod
  $scope.uniqueValues = $scope.to.uniqueValues || false
  $scope.noCache = $scope.to.noCache || false
  $scope.showVision = $scope.to.showVision || false
  $scope.searchMinLength =
    typeof $scope.to.searchMinLength === 'number'
      ? $scope.to.searchMinLength
      : 1

  $scope.mapObject = $scope.to.mapObject || {
    id: 'id',
    name: 'name'
  }

  $scope.searchObject = {
    searchText: ''
  }

  $scope.localOptions = $scope.to.localOptions || null
  $scope.isOffline = !!$scope.to.localOptions
  if ($scope.to.onBlur) {
    $scope.onBlur = function onBlur () {
      $scope.to.onBlur($scope.model, $scope.options)
    }
  }
  const setSearchValue = function setSearchValue (value) {
    $scope.searchObject.searchText = value
  }

  const setValue = function setValue (value) {
    _.set($scope.model, $scope.options.key, value)
    if (typeof $scope.to.onChange === 'function') {
      $scope.to.onChange(value, $scope.options)
    }
  }

  $scope.setSearchAsValue = function setSearchAsValue () {
    setValue($scope.searchObject.searchText)
  }

  $scope.getItems = async function getItems (searchText) {
    if ($scope.isOffline) {
      const results = $scope.localOptions.filter(function (item) {
        return (
          item[$scope.mapObject.name]
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
        )
      })
      return results
    } else {
      const filterObject = JSON.parse(
        JSON.stringify($scope.to.baseFilterObject)
      )
      if (!filterObject.where) {
        filterObject.where = {}
      }
      filterObject.where[$scope.mapObject.name] = {
        like: searchText,
        options: 'i'
      }
      const results = await queryMethod({ filter: filterObject }).$promise
      if ($scope.uniqueValues) {
        const uniqueResults = _.uniqBy(results, $scope.mapObject.id)
        return uniqueResults
      }
      return results
    }
  }

  $scope.selectedItemChange = function selectedItemChange (item) {
    if (item) {
      if (typeof item === 'string') {
        setValue(item)
      } else {
        setValue(item[$scope.mapObject.id])
      }
    }
  }

  $scope.$watch('model', function (newValue, oldValue) {
    if (!_.isEqual(newValue, oldValue)) {
      const newVal = _.get(newValue, $scope.options.key)
      $scope.selectedItem = newVal
      $scope.searchObject.searchText = newVal
    }
  })

  const initSelect = async function initSelect () {
    const initialValue = _.get($scope.model, $scope.options.key)
    $scope.selectedItem = initialValue
    if ($scope.isOffline) {
      setSearchValue(initialValue)
    }
    if (initialValue && $scope.mapObject.id !== $scope.mapObject.name) {
      const filterObject = {
        ...JSON.parse(JSON.stringify($scope.to.baseFilterObject)),
        limit: 1
      }
      filterObject.where[$scope.mapObject.id] = initialValue
      const initialValueArray = await queryMethod({ filter: filterObject })
        .$promise
      if (Array.isArray(initialValueArray) && initialValueArray.length === 1) {
        const initialValueObject = initialValueArray[0]
        $scope.selectedItem = initialValueObject
        setSearchValue(initialValueObject[$scope.mapObject.name])
      }
    }
    if ($scope.to.onInit && typeof $scope.to.onInit === 'function') {
      $scope.to.onInit($scope.model, $scope.options)
    }
    $scope.$applyAsync()
  }

  const resetFieldMapping = function resetFieldMapping () {
    $scope.mapObject = $scope.to.mapObject || {
      id: 'id',
      name: 'name'
    }
  }

  $scope.openVisionDialog = async function openVisionDialog () {
    if (
      $scope.to.preVisionDialog &&
      typeof $scope.to.preVisionDialog === 'function'
    ) {
      $scope.to.preVisionDialog()
    }
    if (!$scope.to.disabled) {
      await visionService.openVisionDialog({
        callback: $scope.callback,
        returnWords: false,
        key: `${$scope.options.type}_${$scope.options.key}`
      })
    }
    if (
      $scope.to.postVisionDialog &&
      typeof $scope.to.postVisionDialog === 'function'
    ) {
      $scope.to.postVisionDialog()
    }
  }
  $scope.callback = selectedWord => {
    $scope.searchObject.searchText = selectedWord
    $scope.setSearchAsValue()
    if ($scope.to.onScan) {
      $scope.to.onScan($scope.model, $scope.options)
    }
  }

  $scope.$watch(
    'to.mapObject',
    (newValue, oldValue) => {
      if (_.isEqual(newValue, oldValue)) return
      resetFieldMapping()
    },
    true
  )

  initSelect()
}

module.exports = autoCompleteController
