'use strict'
/* global $ window */
require('./part-view.dialog.scss')

/** @ngInject */
function PartViewDialogController (
  $scope,
  ResolvedPart,
  mdPanelRef,
  ResolvedOptions,
  PartViewHelper
) {
  $scope.resolvedPart = ResolvedPart
  $scope.options = ResolvedOptions

  $scope.close = function close () {
    mdPanelRef.close()
  }
  $scope.showPart = function showPart (partNumber, partRev = null) {
    mdPanelRef.close()
    PartViewHelper.openPartViewDialog(partNumber, partRev, $scope.options)
  }
}

module.exports = {
  PartViewDialogController
}
