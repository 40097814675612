/* global _ */
/** @ngInject */
function variablesSelect () {
  return {
    restrict: 'E',
    template: require('./variables-select.html'),
    replace: true,
    scope: {
      key: '@',
      model: '=',
      isDisabled: '=',
      onChange: '&',
      variables: '&'
    },
    controller: [
      '$rootScope',
      '$scope',
      '$translate',
      ($rootScope, $scope, $translate) => {
        $scope.onSelectVariable = function (value) {
          let currentValue = _.get($scope.model, $scope.key, '')
          currentValue += `{{${value}}}`
          _.set($scope.model, $scope.key, currentValue)
          $scope.resetView()
          $scope.selected.variable = ''
          if ($scope.onChange) {
            $scope.onChange()
          }
        }
        $scope.variables = $scope.variables()
        let input

        $scope.resetView = function resetView () {
          $scope.options = _.cloneDeep($scope.variables)
          $scope.searchTerm = ''
        }
        $scope.search = $event => {
          input = $event.target
          if (input.value !== $scope.searchTerm) {
            $scope.searchTerm = input.value
            makeSearch(input.value)
          }
        }
        const makeSearch = function makeSearch (query) {
          if (!query || query === '') {
            $scope.resetView()
          } else {
            const searchRegex = new RegExp(query, 'i')
            $scope.options = _.cloneDeep($scope.variables).map(
              variablesGroup => {
                variablesGroup.variables = variablesGroup.variables.filter(
                  variable => {
                    return searchRegex.test(variable.title)
                  }
                )
                return variablesGroup
              }
            )
          }
          $scope.$applyAsync()
        }
        $scope.focusInput = function focusInput () {
          setTimeout(() => {
            const inputElement = document.querySelector(
              '.md-select-menu-container.md-active .selectboxWithSearch input'
            )
            if (inputElement) {
              inputElement.focus()
            }
          }, 300)
        }
        const init = async function init () {
          $scope.resetView()
          $scope.selected = {
            variable: '',
            savedVariables: $scope.options
          }
        }
        init()
      }
    ]
  }
}

module.exports = variablesSelect
