/* global _ */

/** @ngInject */
function FormValidationDialogController (
  mdPanelRef,
  $scope,
  $translate,
  errors
) {
  const ERROR_TYPES = {
    REQUIRED: 'required',
    PARSER_ERROR: 'parserError',
    PATTERN: 'pattern',
    MIN: 'min',
    MAX: 'max'
  }

  $scope.closeValidationDialog = function closeValidationDialog () {
    mdPanelRef && mdPanelRef.close()
  }

  const initDialog = function initDialog () {
    const errorsWithType = _.remove(errors, error => error.errorType)
    const errorsGroupedByType = _.groupBy(errorsWithType, 'errorType')
    const errorTypes = Object.keys(errorsGroupedByType)
    $scope.specialErrors = []
    for (let i = 0; i < errorTypes.length; i++) {
      const errorType = errorTypes[i]
      const errorsByType = errorsGroupedByType[errorType]
      switch (errorType) {
        case ERROR_TYPES.REQUIRED:
          {
            const errorByStock = _.groupBy(errorsByType, e =>
              JSON.stringify(e.params)
            )
            Object.keys(errorByStock).forEach(key => {
              const relevantErrors = errorByStock[key]
              const { params } = relevantErrors[0]
              $scope.specialErrors.push({
                message: $translate.instant(
                  'FORM.ERROR.FORM_ERROR_REQUIRED_FIELD_TITLE',
                  params
                ),
                fieldNames: errorsByType.map(e => e.fieldName)
              })
            })
          }
          break
        case ERROR_TYPES.PATTERN:
          {
            const errorByStock = _.groupBy(errorsByType, e =>
              JSON.stringify(e.params)
            )
            Object.keys(errorByStock).forEach(key => {
              const relevantErrors = errorByStock[key]
              const { params } = relevantErrors[0]
              $scope.specialErrors.push({
                message: $translate.instant(
                  'FORM.ERROR.FORM_ERROR_PATTERN_FIELD_TITLE',
                  params
                ),
                fieldNames: errorsByType.map(e => e.fieldName)
              })
            })
          }
          break
        case ERROR_TYPES.MAX:
          {
            const errorByStock = _.groupBy(errorsByType, e =>
              JSON.stringify(e.params)
            )
            Object.keys(errorByStock).forEach(key => {
              const relevantErrors = errorByStock[key]
              const { params } = relevantErrors[0]
              $scope.specialErrors.push({
                message: $translate.instant(
                  'FORM.ERROR.FORM_ERROR_MAX_FIELD_TITLE',
                  params
                ),
                fieldNames: errorsByType.map(e => e.fieldName)
              })
            })
          }
          break
        case ERROR_TYPES.MIN:
          {
            const errorByStock = _.groupBy(errorsByType, e =>
              JSON.stringify(e.params)
            )
            Object.keys(errorByStock).forEach(key => {
              const relevantErrors = errorByStock[key]
              const { params } = relevantErrors[0]
              $scope.specialErrors.push({
                message: $translate.instant(
                  'FORM.ERROR.FORM_ERROR_MIN_FIELD_TITLE',
                  params
                ),
                fieldNames: errorsByType.map(e => e.fieldName)
              })
            })
          }
          break
      }
    }
    $scope.errors = errors.map(e => $translate.instant(e.message, e.params))
  }

  initDialog()
}

module.exports = FormValidationDialogController
