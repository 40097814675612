/** @ngInject */
function resourceLink (ResourceUtils, $compile) {
  return {
    restrict: 'C',
    link: function (scope, element, attrs) {
      const resourceId = attrs.resourceId
      const type = attrs.type || 'original'
      ResourceUtils.getResource(resourceId).then(resource => {
        let reourceName = resource ? resource.name : ''
        if (
          type !== 'original' &&
          resource.optimalFiles &&
          resource.optimalFiles.length
        ) {
          const typedOptimalFile = resource.optimalFiles.find(
            r => r.name === type
          )
          if (typedOptimalFile) {
            reourceName = typedOptimalFile.fileName
          }
        }
        const originalLink = resource
          ? `api/containers/${resource.container}/download/${resource.name}`
          : ''
        const link = resource
          ? `api/containers/${resource.container}/download/${reourceName}`
          : ''
        if (resource.type.includes('image')) {
          element.append(
            $compile(`<picture>
                        <source srcset="${link}" media="(min-width: 100px)"/>
                          <img md-lightbox src="${originalLink}" style="cursor:pointer;">
                      </picture>`)(scope)
          )
        } else {
          element.append($compile('')(scope))
          element.append(
            $compile(
              `<a href="${link}" target="_blank"><img style="width: 3em;"src="assets/images/file-icons/512px/${resource.ext}.png"></a>`
            )(scope)
          )
        }
      })
    }
  }
}

module.exports = resourceLink
