/* global _ angular L */
const { getMapLink } = require('root/../common/services/field-helper.js')
/** @ngInject */
function gpsInputController (
  $scope,
  $window,
  $mdToast,
  $rootScope,
  $translate
) {
  $scope.loading = false
  $scope.hasValue = false
  let map = null
  let marker = null
  let attribution = null

  const updateLocationValue = function updateLocationValue (value) {
    _.set($scope.model, $scope.options.key, value)
    if (typeof $scope.to.onChange === 'function') {
      $scope.to.onChange(value, $scope.options)
    }
  }

  const removeMap = function removeMap () {
    if (map) {
      map.remove()
      map = null
      marker = null
      attribution = null
      const elm = document
        .getElementById($scope.id)
        .parentNode.querySelector('.gpsInputMap')
      elm.style.height = '2px'
    }
  }
  $scope.getLocation = function getLocation () {
    if (!$scope.to.disabled) {
      if (navigator.geolocation) {
        $scope.loading = true
        navigator.geolocation.getCurrentPosition(
          successOnLocation,
          errorOnLocation,
          {
            enableHighAccuracy: true
          }
        )
      } else {
        $mdToast.show(
          $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'body',
            theme: 'error-toast',
            hideDelay: 3000
          })
        )
        $mdToast.updateTextContent(
          $translate.instant('WF.ERRORS.LOCATION_UNAVAILABLE')
        )
      }
    }
  }
  const updateFieldView = function () {
    if (
      _.get($scope.model, $scope.options.key) &&
      typeof _.get($scope.model, $scope.options.key).latitude === 'number'
    ) {
      $scope.hasValue = true
    } else {
      $scope.hasValue = false
    }
    $scope.$applyAsync()

    setLocationString()
    setMap()
  }
  const setMap = function () {
    const location = _.get($scope.model, $scope.options.key)
    if ($scope.hasValue) {
      if (map === null) {
        const elm = document
          .getElementById($scope.id)
          .parentNode.querySelector('.gpsInputMap')
        elm.style.height = '150px'
        map = L.map(elm)
        angular.element($window).on('resize', () => {
          map.invalidateSize({ debounceMoveend: false })
        })
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: ''
        }).addTo(map)
        map.attributionControl.setPrefix(false)
      }
      map.setView([location.latitude, location.longitude], 18)
      map.invalidateSize({ debounceMoveend: false })

      if (attribution !== null) {
        map.attributionControl.removeAttribution(
          Object.keys(attribution._attributions)[0]
        )
      }
      attribution = map.attributionControl.addAttribution(
        `<a href="${getMapLink(
          location.latitude,
          location.longitude
        )}" target="_blank">Open in a new tab</a>`
      )

      if (marker !== null) {
        map.removeLayer(marker)
      }
      marker = L.marker([location.latitude, location.longitude], {
        icon: L.icon({
          iconSize: [25, 41],
          iconAnchor: [13, 41],
          iconUrl: 'images/marker-icon.png',
          shadowUrl: 'images/marker-shadow.png'
        })
      }).addTo(map)
    } else {
      removeMap()
    }
  }
  const setLocationString = function () {
    if ($scope.hasValue) {
      $scope.locationString =
        $scope.model[$scope.options.key].latitude +
        ', ' +
        $scope.model[$scope.options.key].longitude
    } else {
      $scope.locationString = '--'
    }
  }

  const errorOnLocation = function errorOnLocation (error) {
    $scope.loading = false
    $scope.$applyAsync()
    $mdToast.show(
      $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'body',
        theme: 'error-toast',
        hideDelay: 3000
      })
    )
    $mdToast.updateTextContent(
      $translate.instant('WF.ERRORS.ALLOW_ACCESS_LOCATION')
    )
    console.error(error)
  }

  const successOnLocation = function successOnLocation (position) {
    $scope.loading = false
    updateLocationValue({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      accuracy: position.coords.accuracy
    })
    updateFieldView()
  }
  $scope.init = function init () {
    updateFieldView()
  }

  $scope.clearLocation = function clearLocation () {
    if (!$scope.to.disabled) {
      updateLocationValue(null)
      updateFieldView()
    }
  }

  $scope.$watch('model', function (newValue, oldValue, theScope) {
    if (!_.isEqual(newValue, oldValue)) {
      updateFieldView()
    }
  })
}
module.exports = gpsInputController
