/* global alert */
/** @ngInject */
function newEditPostDialogController (
  $scope,
  locals,
  $mdDialog,
  getUrlFromObj,
  PermissionUtils
) {
  $scope.getUrlFromObj = getUrlFromObj
  $scope.PermissionUtils = PermissionUtils
  $scope.post = locals.post || {
    name: '',
    content: ''
  }

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.closeDialog = function () {
    $mdDialog.hide(null)
  }

  $scope.addPost = () => {
    if (!$scope.post.name) {
      alert('Need enter name')
      return
    }

    if (!$scope.post.content) {
      alert('Need enter content')
      return
    }
    $mdDialog.hide($scope.post)
  }
}

module.exports = newEditPostDialogController
