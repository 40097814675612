/* global _ */
const UUID = require('uuid')
require('./variants.dialog.scss')
/** @ngInject */
function VariantsSaveDialogController ($scope, $translate, $mdDialog, locals) {
  $scope.save = () => {
    $mdDialog.hide($scope.variantData)
  }

  $scope.cancel = () => $mdDialog.cancel()

  const initDialog = async function initDialog () {
    $scope.variantData = _.clone(
      locals.variantData || { variantId: UUID(), variantName: '' }
    )
    $scope.editMode = locals.editMode || false
    $scope.fields = [
      {
        key: 'variantName',
        type: 'input',
        className: 'flex',
        templateOptions: {
          required: true,
          label: $translate.instant('VARIANT.VARIANT_NAME'),
          onChange: function (value, options) {}
        }
      }
    ]
  }

  initDialog()
}
module.exports = VariantsSaveDialogController
