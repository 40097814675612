/* global _ */
require('./views.dialog.scss')
/** @ngInject */
function ViewsFilterDialogController (
  $scope,
  $mdDialog,
  $state,
  locals,
  PermissionUtils,
  KendoFilterHelper,
  IntercomService
) {
  IntercomService.trackEvent('view-filter-dialog')
  $scope.cancel = () => $mdDialog.cancel()

  $scope.saveFilters = function saveFilters () {
    $mdDialog.hide($scope.gridFilters.getExpression())
  }

  const expressionConverterFromDB = function expressionConverterFromDB (
    filters
  ) {
    const expression = {}
    expression.logic =
      filters.logic === 'or' || filters.logic === 'OR' ? 'or' : 'and'
    expression.filters = []
    if (filters.filters) {
      filters.filters.forEach(dbFilter => {
        const filter = _.cloneDeep(dbFilter)
        if (_.isArray(dbFilter.value) && dbFilter.value.length > 0) {
          filter.value = dbFilter.value[0]
          filter.value2 = dbFilter.value[1]
        }
        expression.filters.push(filter)
      })
    }
    return expression
  }

  const setupKendoFilter = function setupKendoFilter (filters = {}) {
    $scope.gridFilters.setup(
      {
        expressionPreview: false,
        applyButton: false,
        change: e => {
          const expression = e.expression
          $scope.gridFilters.setExpression(expression)
        },
        fields: $scope.columns
          .filter(c => {
            if (c.type === 'object') return true
            if (c.type === 'array' && !c.viewFilterType) return true
            if (_.isNil(c.filterable) || c.filterable !== false) return true
            return false
          })
          .map(column => {
            const type = column.viewFilterType || column.type || 'string'
            const filterObject = {
              name: column.field,
              type,
              input: column.values
                ? 'select'
                : column.type === 'array'
                ? 'array'
                : null,
              values: column.values || null,
              label: column.title
            }
            // if (column?.filterable?.cell) {
            //   if (column?.filterable?.cell?.operator) {
            //     filterObject.operators = {
            //       [type]: {
            //         [column?.filterable?.cell?.operator]: 'Zobi'
            //       }
            //     }
            //   }
            // }
            if (
              column?.filterable?.cell?.template &&
              typeof column.filterable.cell.template === 'function'
            ) {
              filterObject.template = column.filterable.cell.template
            }
            return filterObject
          }),
        expression: expressionConverterFromDB(filters)
      },
      $scope
    )
  }

  const initDialog = async function initDialog () {
    $scope.isEditor = PermissionUtils.isPermit('Workflow', 'create')
    $scope.state = locals.state || $state.current.name
    $scope.columns = locals.columns
    $scope.viewId = locals.viewId
    $scope.pageTitle = locals.title
    $scope.columnsById = _.keyBy(locals.columns, 'uniqueId')
    $scope.gridFilters = KendoFilterHelper.filterInstance()
    setupKendoFilter(locals.filters)
  }

  initDialog()
}
module.exports = ViewsFilterDialogController
