const FileTypes = {
  image: ['bmp', 'gif', 'jpg', 'png', 'jpeg', 'svg'],
  archive: ['zip', '7z', 'rar', 'tar', 'tar.gz'],
  pdf: ['pdf'],
  video: ['mp4', 'mkv'],
  storyline: ['zip']
}
FileTypes.media = [...FileTypes.image, ...FileTypes.video]

module.exports = FileTypes
