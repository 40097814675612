/* global */
require('./datePicker.scss')
/** @ngInject */
function datePickerController ($scope, DateTimeFormatService) {
  $scope.hasTime = $scope.options.type === 'dateTimePicker'
  const dateTimeFormats = DateTimeFormatService.getDateTimeFormatsForField()
  $scope.dateOpts = {
    enableTime: $scope.hasTime,
    placeholder: $scope.to.placeholder || null,
    dateFormat: $scope.hasTime
      ? `${dateTimeFormats.date} ${dateTimeFormats.time}`
      : dateTimeFormats.date,
    minuteIncrement: 1,
    time_24hr: !dateTimeFormats.time?.endsWith('K'),
    minDate: $scope.to.minDate || null,
    maxDate: $scope.to.maxDate || null,
    removeClearOption: $scope.to.removeClearOption || false,
    // defaultDate: $scope.model[$scope.options.key] || new Date(),
    onChange: function (selectedDates, dateStr, instance) {
      $scope.options.instance = instance
      $scope.$applyAsync(() => {
        const selectedDate = Array.isArray(selectedDates)
          ? selectedDates[0]
          : selectedDates
        // $scope.model[$scope.options.key] = selectedDate
        if ($scope.to.onChange) {
          $scope.to.onChange(selectedDate, $scope.options, $scope.model)
        }
      })
    }
  }
}

module.exports = datePickerController
