/* global _ */

/** @ngInject */
function PartUnitShowController (
  $scope,
  $rootScope,
  $translate,
  $timeout,
  $mdDialog,
  FormlyHelper,
  Unit,
  PermissionUtils,
  ResolvedUnit,
  ErrorHandlerService
) {
  $scope.PermissionUtils = PermissionUtils
  $scope.Unit = Unit

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  const editMode = ResolvedUnit !== null

  const staticUnitFields = [
    {
      key: 'name',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        required: true,
        label: $translate.instant('PartAssembly.UNITS.NAME'),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    {
      key: 'each',
      type: 'checkbox',
      className: 'layout-row',
      templateOptions: {
        label: $translate.instant('PartAssembly.UNITS.EACH'),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    }
  ]

  $scope.unitFields = FormlyHelper.buildFields(staticUnitFields, Unit)

  $scope.unit =
    ResolvedUnit === null
      ? {
          name: '',
          each: false
        }
      : ResolvedUnit

  $scope.save = async function save () {
    $rootScope.loadingProgress = true
    try {
      if (editMode) {
        await Unit.prototype$patchAttributes(
          { id: $scope.unit.id },
          _.omit($scope.unit, 'id')
        ).$promise
      } else {
        await Unit.create($scope.unit).$promise
      }
      $rootScope.loadingProgress = false
      $mdDialog.hide()
    } catch (err) {
      $rootScope.loadingProgress = false
      $timeout(
        () => {
          ErrorHandlerService.formlyErrorHandler(
            err,
            $scope.unitFields,
            $scope.unitForm
          )
        },
        0,
        false
      )
    }
  }
}

module.exports = PartUnitShowController
