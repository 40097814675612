'use strict'
/* global angular, _ */
const UUID = require('uuid')

require('./subForm.scss')
/** @ngInject */
function subForm (
  $scope,
  $timeout,
  $mdDialog,
  $translate,
  $state,
  $localStorage
) {
  $scope.subFormElementId = `subform_${+new Date()}`
  $scope.formOptions = {
    formState: $scope.formState
  }
  $scope.formlyFields = []
  $scope.copyFields = function copyFields (fields) {
    fields = angular.copy(fields)
    fields.forEach((field, idx) => {
      field.id = `${field.key}_${getRandomInt()}_${getRandomInt()}` // to avoid duplicate ids
      field.templateOptions.disabled = $scope.to.disabled
      field.templateOptions.staticLabel = $scope.to.staticLabel
      field.expressionProperties = field.expressionProperties || {}
      field.expressionProperties['templateOptions.disabled'] =
        'formState.disabled'
      if (field.type === 'upload') {
        field.templateOptions.workflowSessionItemId =
          $scope.to.workflowSessionItemId || null
        field.templateOptions.formDataId = $scope.to.formDataId || null
      }
      if ($scope.to.onChange && typeof $scope.to.onChange === 'function') {
        field.templateOptions.onChange = $scope.to.onChange
      }
    })
    return fields
  }
  const getLocalStorageKey = () =>
    `subform_layout_${$scope.to.overrideStateName || $state.current.name}_${
      $scope.options.key
    }`

  $scope.setLayout = function setLayout (layout) {
    $scope.to.layout = layout
    // Storing the layout in the localStorage
    $localStorage[getLocalStorageKey()] = layout
  }

  // Initial retrieval of layout data from localStorage during initialization
  const savedLayout = $localStorage[getLocalStorageKey()]

  if (savedLayout) {
    $scope.to.layout = savedLayout
  } else {
    // If the saved layout does not exist, determine the layout based on the width of the parent element
    $timeout(() => {
      const parentElement = document.getElementById($scope.subFormElementId)
      if (!parentElement) return
      const parentElementWidth = parentElement.offsetWidth
      const minFieldWith = 250
      const totalFieldWidth = minFieldWith * $scope.to.fields.length

      if (totalFieldWidth < parentElementWidth || parentElementWidth > 1000) {
        $scope.to.layout = 'row'
      } else {
        $scope.to.layout = 'column'
      }
    })
  }

  const getRandomInt = function getRandomInt (min = 0, max = 1000000) {
    return Math.floor(Math.random() * (max - min)) + min
  }

  $scope.addNew = function addNew () {
    const repeatSection = _.get($scope.model, $scope.options.key, [])
    let obj = {
      id: UUID()
    }
    if ($scope.to.addParameters) {
      obj = { ...obj, ...$scope.to.addParameters() }
    }
    repeatSection.push(obj)

    _.set($scope.model, $scope.options.key, repeatSection)
    if ($scope.to.onChange && typeof $scope.to.onChange === 'function') {
      $scope.to.onChange(null, $scope.options)
    }
  }
  $scope.removeField = function removeField (index) {
    const optionsArray = _.get($scope.model, $scope.options.key, [])
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title($translate.instant('COMMON.ARE_YOU_SURE'))
          .multiple(true)
          .clickOutsideToClose(false)
          .escapeToClose(false)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          $scope.formlyFields.splice(index, 1)
          optionsArray.splice(index, 1)
          if ($scope.to.onRemove) {
            $scope.to.onRemove()
          }
          if ($scope.to.onChange && typeof $scope.to.onChange === 'function') {
            $scope.to.onChange(null, $scope.options)
          }
        },
        function () {}
      )
  }
  $scope.kendoSorterOpts = {
    handler: '.handler',
    disabled: '.disabled'
  }
  $scope.endHandler = function endHandler (e) {
    e.preventDefault()
    console.log(`oldIndex ${e.oldIndex} newIndex ${e.newIndex}`)
    const itemMoved = angular.copy(
      _.get($scope.model, $scope.options.key)[e.oldIndex]
    )
    $scope.model[$scope.options.key].splice(e.oldIndex, 1)
    $scope.model[$scope.options.key].splice(e.newIndex, 0, itemMoved)
    if (typeof $scope.options.templateOptions.onSorted === 'function') {
      $scope.options.templateOptions.onSorted(
        $scope.model[$scope.options.key],
        e.oldIndex,
        e.newIndex
      )
    }
  }

  const initialValue = _.get($scope.model, $scope.options.key, [])

  initialValue.map(rowModel => {
    if (_.isNil(rowModel.id)) {
      rowModel.id = UUID()
    }
    return rowModel
  })

  _.set($scope.model, $scope.options.key, initialValue)

  $scope.modelValue =
    $scope.options.key.split('.').reduce((o, i) => o[i], $scope.model) || []

  $scope.fieldsOptions = { formState: { disabled: $scope.to.disabled } }
  $scope.$watch('to.disabled', function (newValue, oldValue, theScope) {
    $scope.fieldsOptions = { formState: { disabled: newValue } }
  })

  $scope.$watch(
    'modelValue',
    function (newValue, oldValue, theScope) {
      if (!_.isEqual(newValue, oldValue)) {
        _.set($scope.model, $scope.options.key, newValue)
      }
    },
    true
  )
  $scope.$watch(
    'model',
    function (newValue, oldValue, theScope) {
      if (!_.isEqual(newValue, oldValue)) {
        $scope.modelValue =
          $scope.options.key.split('.').reduce((o, i) => o[i], newValue) || []
      }
    },
    true
  )
}

module.exports = subForm
