/* global _ $ */
'use strict'

/** @ngInject */
function selectFieldWithSearch ($scope, $mdSelect) {
  let input
  $scope.optionsToDisplay = $scope.to.options
  $scope.searchTerm = ''
  $scope.search = $event => {
    input = $event.target
    $scope.searchTerm = input.value
    $scope.debouncedFn(input.value)
  }
  $scope.openAddFieldDialog = ev => {
    $mdSelect.hide()
    $scope.to.openDialog().then(
      function (data) {
        if (data) {
          $scope.clearSearchTerm()
          $scope.to.options.push({ name: data.title, id: data.id })
          $scope.model[$scope.options.key].push(data.id)
        }
      },
      function () {
        console.log('close')
      }
    )
  }
  $scope.$watch('to.options', function (newValue, oldValue, theScope) {
    $scope.optionsToDisplay = $scope.to.options
  })
  $scope.onSelect = () => {
    if (input) {
      input.value = ''
    }
    if (!$scope.to.multiple) {
      $scope.optionsToDisplay = $scope.to.options
    }
    if ($scope.to.onChange) {
      if (_.isArray($scope.options.formControl)) {
        $scope.options.formControl = $scope.options.formControl[0]
      }
      if ($scope.to.onChange) {
        $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
      }
    }
  }
  $scope.clearSearchTerm = () => {
    if (input) {
      $scope.searchTerm = input.value
      input.value = ''
      $scope.makeSearch(input.value)
    }
  }
  $scope.fixPosition = e => {
    setTimeout(() => {
      const { left, top } = $("[md-container-class='my-elem']")
        .parent()
        .offset()
      $('.my-elem').css('top', `${top - 45}px`)
      $('.my-elem').css('left', `${left - 15}px`)
    }, 200)
  }
  $scope.makeSearch = word => {
    if (!word) {
      $scope.optionsToDisplay = $scope.to.options
    } else {
      $scope.optionsToDisplay = $scope.to.options.filter(option =>
        option.name.toLowerCase().includes(word.toLowerCase())
      )
    }
  }
  $scope.debouncedFn = _.debounce($scope.makeSearch, 200)
}

module.exports = selectFieldWithSearch
