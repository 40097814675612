/* global _ angular */
require('./imageBasedForm.scss')
/** @ngInject */
function imageBasedForm ($scope, $mdDialog, FieldUtilsService) {
  const generateValueAsHTML = function generateValueAsHTML (value = []) {
    if (_.isEmpty(value)) return ''
    const fieldId = $scope.to.fieldObject ? $scope.to.fieldObject.id : null
    const fields = $scope.to.originalFields.concat($scope.to.fieldObject)
    return FieldUtilsService.getFieldHTMLValue(
      fields,
      fieldId,
      value,
      'column',
      true
    )
  }

  const onChange = function onChange (value) {
    _.set($scope.model, $scope.options.key, value)
    $scope.imageBasedFormAsHTML = generateValueAsHTML(value)
    if ($scope.to.onChange && typeof $scope.to.onChange === 'function') {
      $scope.to.onChange(value, $scope.options)
    }
  }

  $scope.openFormDialog = function openFormDialog () {
    let fieldIds = []
    const images = $scope.to.images
    if (
      $scope.to.preClickEvent &&
      typeof $scope.to.preClickEvent === 'function'
    ) {
      const object = $scope.to.preClickEvent()
      const { layers, shapes } = object
      fieldIds = object.fieldIds
      images[0].shapes = shapes
      images[0].layers = layers
    } else {
      fieldIds = $scope.to.fieldIds
    }
    const modelBeforeOpen = _.cloneDeep(
      _.get($scope.model, $scope.options.key, [])
    )
    $mdDialog
      .show({
        controller: require('./dialog/image-based-form.dialog.controller.js'),
        template: require('./dialog/image-based-form.dialog.template.html'),
        parent: angular.element(document.body),
        locals: {
          disabled: $scope.disabled,
          fieldIds: fieldIds,
          images: images,
          model: modelBeforeOpen
        },
        fullscreen: true,
        multiple: true,
        escapeToClose: false,
        clickOutsideToClose: false
      })
      .then(
        function (newModel) {
          if (newModel) {
            onChange(newModel)
          }
        },
        function () {}
      )
  }

  const initField = function initField () {
    $scope.resourceId = null
    $scope.disabled = $scope.to.disabled
    const initialValue = _.get($scope.model, $scope.options.key, [])
    $scope.imageBasedFormAsHTML = generateValueAsHTML(initialValue)
    if ($scope.to.images && $scope.to.images.length) {
      $scope.resourceId = $scope.to.images[0].resourceId
    }
  }

  initField()

  $scope.$watch('to.disabled', function (newValue, oldValue, theScope) {
    $scope.disabled = newValue || false
  })
}

module.exports = imageBasedForm
