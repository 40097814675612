/* global */
require('./card-grid.scss')
/** @ngInject */
function cardGrid () {
  return {
    restrict: 'E',
    template: require('./card-grid.html'),
    replace: true,
    scope: {
      items: '=',
      searchObject: '=?',
      hiddenObject: '=?'
    },
    controller: [
      '$scope',
      $scope => {
        $scope.shouldHideItem = function shouldHideItem (item) {
          return (
            ($scope.hiddenObject && $scope.hiddenObject[item.id]) ||
            ($scope.searchObject &&
              $scope.searchObject.search &&
              !new RegExp($scope.searchObject.search, 'i').test(item.name))
          )
        }
      }
    ]
  }
}

module.exports = cardGrid
