/* global angular _ */
/** @ngInject */
function ImageBasedFormDialogController ($scope, $mdDialog, htmlWork, locals) {
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.save = function save () {
    $mdDialog.hide($scope.model)
  }

  $scope.openInnerFormDialog = function openInnerFormDialog (area) {
    const { id, name } = area
    const modelIndex = $scope.model.findIndex(
      m => m.imageId === $scope.image.id && m.areaId === id
    )
    $mdDialog
      .show({
        controller: require('./area-form.dialog.controller.js'),
        template: require('./area-form.dialog.template.html'),
        parent: angular.element(document.body),
        locals: {
          disabled: $scope.disabled,
          areaName: name,
          fieldIds: $scope.fieldIds,
          model: modelIndex > -1 ? _.cloneDeep($scope.model[modelIndex]) : {}
        },
        fullscreen: true,
        multiple: true,
        escapeToClose: false,
        clickOutsideToClose: false
      })
      .then(
        function (values) {
          if (values) {
            const valueArray = []
            Object.keys(values).forEach(fieldId => {
              valueArray.push({
                fieldId: fieldId,
                value: values[fieldId]
              })
            })
            const data = {
              imageId: $scope.image.id,
              imageLabel: $scope.image.label || '',
              areaId: id,
              areaName: name,
              values: valueArray
            }
            $scope.hasValueByArea[id] = true
            if (modelIndex > -1) {
              $scope.model[modelIndex] = data
            } else {
              $scope.model.push(data)
            }
          } else {
            $scope.hasValueByArea[id] = false
          }
        },
        function () {}
      )
  }

  const initDialog = async function initDialog () {
    const { fieldIds, images, model, disabled } = locals
    $scope.disabled = disabled || false
    $scope.model = model
    $scope.mapFunctions = {
      onClickShape: $scope.openInnerFormDialog
    }
    $scope.hasValueByArea = {}
    $scope.fieldIds = fieldIds
    if (images && images.length) {
      $scope.image = images[0]
      const { shapes, layers, resourceId } = $scope.image
      $scope.resourceId = resourceId
      $scope.layers = layers.map(layer => ({
        ...layer,
        name: htmlWork.htmlEncode(layer.name)
      }))
      $scope.shapes = shapes
      $scope.layers.forEach(layer => {
        const { id } = layer
        if ($scope.model.find(m => m.areaId === id)) {
          $scope.hasValueByArea[id] = true
        } else {
          $scope.hasValueByArea[id] = false
        }
      })
    }
  }

  initDialog()
}

module.exports = ImageBasedFormDialogController
