/* global _ $ angular kendo */
/** @ngInject */
function stockView () {
  require('./stock-view.scss')
  return {
    template: require('./stock-view.html'),
    bindings: {
      data: '<',
      locations: '<',
      workflowTypes: '<',
      users: '<',
      workorderStatus: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function StockView (
        $rootScope,
        $scope,
        $state,
        $translate,
        $timeout,
        $window,
        Stock,
        Workorder,
        FormData,
        htmlWork,
        KendoGridHelper,
        ViewsService,
        PermissionUtils,
        WorkorderUtils,
        FormUtils,
        Form,
        DialogService,
        DateTimeFormatService,
        $mdToast
      ) {
        const vm = this

        const VALID_SKU_SERIALS_ACTIONS = [
          'SKU_CONVERSION',
          'ATTACH',
          'ITEM_ATTACH',
          'DETACH',
          'ITEM_DETACH',
          'SERVICE_CALL_ATTACH',
          'SERVICE_CALL_ITEM_ATTACH',
          'SERVICE_CALL_DETACH',
          'SERVICE_CALL_ITEM_DETACH'
        ]

        const COLOR_MAP = {
          MAXIMUM: '#92b1b6',
          MINIMUM: '#bfd1df',
          AVERAGE: '#ced2c2',
          CURRENT: '#f1c5ae'
        }

        const columnGraphDefaults = {
          chartArea: {
            margin: {
              top: 10,
              bottom: 10,
              left: 20,
              right: 20
            },
            height: 250,
            background: 'transparent'
          },
          legend: {
            position: 'bottom'
          },
          seriesDefaults: {
            type: 'column'
          },
          seriesColors: [
            COLOR_MAP.MAXIMUM,
            COLOR_MAP.AVERAGE,
            COLOR_MAP.CURRENT,
            COLOR_MAP.MINIMUM
          ],
          valueAxis: {
            labels: {
              format: `{0} ${$translate.instant('STOCK_PAGE.HR')}`
            },
            line: {
              visible: false
            },
            axisCrossingValue: 0
          },
          tooltip: {
            visible: true,
            format: '{0}%',
            template: '#= series.name #: #= value #'
          }
        }

        const columnGraphSeriesDefaults = {
          border: {
            width: 0
          },
          overlay: {
            gradient: 'none'
          }
        }

        const buildColumnGraphSettings = function buildColumnGraphSettings (
          maximum,
          minimum,
          average,
          current
        ) {
          return {
            ...columnGraphDefaults,
            series: [
              {
                ...columnGraphSeriesDefaults,
                name: $translate.instant('COMMON.MAXIMUM'),
                data: [maximum.toFixed(2)]
              },
              {
                ...columnGraphSeriesDefaults,
                name: $translate.instant('COMMON.AVERAGE'),
                data: [average.toFixed(2)]
              },
              {
                ...columnGraphSeriesDefaults,
                name: $translate.instant('COMMON.CURRENT'),
                data: [current.toFixed(2)]
              },
              {
                ...columnGraphSeriesDefaults,
                name: $translate.instant('COMMON.MINIMUM'),
                data: [minimum.toFixed(2)]
              }
            ]
          }
        }

        const modifyProductTree = function modifyProductTree (item) {
          const header = item.isSerial ? item.serial : item.workorderNumber
          const partName = item.partName !== '' ? item.partName : null
          if (!item.isSerial) {
            $scope.disabledObject[item.id] = true
          }
          item.template = partName
            ? `<div layout="column" layout-align="center start">
                <span class="stock-serial">${htmlWork.htmlEncode(header)}</span>
                <span style="font-size: 0.9em;">${htmlWork.htmlEncode(
                  partName
                )} (${htmlWork.htmlEncode(item.sku)})</span>
              </div>`
            : header
          item.expanded = true
          if (Array.isArray(item.items) && item.items.length > 0) {
            item.items.map(i => modifyProductTree(i))
          } else {
            item.items = []
          }
          return item
        }

        const initWorkOrderTable = async function initWorkOrderTable (stock) {
          const workorderStateName = `${$state.current.name}.stock-workorders`
          $scope.usersPerRow = {}
          $scope.assigneePerRow = {}
          const baseFilter = {
            order: ['priority DESC', 'expectedDate DESC', 'modified DESC'],
            where: { sku: stock.sku, serials: { inq: [stock.serial] } },
            fields: {
              kitItems: false,
              KITITEMS_SUBFORM: false,
              TRANSORDER_K_SUBFORM: false,
              orderLines: false
            },
            include: [
              {
                relation: 'stocks',
                scope: {
                  fields: { id: true, serial: true, kitStatus: true, end: true }
                }
              },
              {
                relation: 'sessions',
                scope: {
                  fields: { id: true, serials: true, end: true }
                }
              },
              {
                relation: 'groupModel',
                scope: {
                  fields: { id: true, name: true }
                }
              },
              {
                relation: 'workorderType',
                scope: {
                  fields: { id: true, name: true }
                }
              }
            ]
          }

          const { defaultTableColumns } =
            ViewsService.generateCustomFieldColumns(
              'Workorder',
              $scope.workorderColumns
            )

          const { columns, selectedViewId } = ViewsService.getTablesColumns(
            defaultTableColumns,
            workorderStateName
          )

          const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
            selectedViewId,
            _.cloneDeep(baseFilter),
            workorderStateName
          )

          const defaultTableSetup = {
            stateName: workorderStateName,
            ignoreParams: true,
            find: Workorder.find,
            count: Workorder.count,
            autoSize: false,
            pageSize: 5,
            cleanBaseFilter: baseFilter || {},
            baseFilter: newBaseFilter,
            selectedViewId,
            columns: defaultTableColumns,
            encodeTitles: true
          }

          const defaultTableToolbarSetup = {
            stateName: workorderStateName,
            columns: defaultTableColumns,
            currentColumnIds: columns.map(c => c.uniqueId),
            filters,
            selectedViewId,
            title: $scope.title
          }

          if ($scope.workordersKendoGrid) {
            $scope.workordersKendoGrid.setOptions(defaultTableSetup)
          } else {
            $scope.workordersKendoGrid = await KendoGridHelper.GridInstance(
              defaultTableSetup,
              $scope,
              columns
            )

            await $scope.workordersKendoGrid.isBound()

            $scope.workordersToolbar = await ViewsService.GridToolBarInstance(
              defaultTableToolbarSetup,
              $scope.workordersKendoGrid,
              $scope
            )
          }

          $scope.workorderTableInit = false

          $scope.$applyAsync()
        }

        $scope.deleteFormData = async function deleteFormData (
          formDataId,
          formId
        ) {
          const form = await Form.findOne({ filter: { where: { id: formId } } })
            .$promise
          const canView = FormUtils.isPermit('view', form)
          if (canView) {
            DialogService.deleteDialog(
              $translate.instant(
                'FORM.DELETE_MESSAGE',
                {},
                null,
                null,
                'sceParameters'
              )
            ).then(
              function () {
                FormData.destroyById({ id: formDataId })
                  .$promise.then(() => {
                    $scope.formsKendoGrid.reloadData()
                  })
                  .catch(e => console.error(e))
              },
              function () {}
            )
          } else {
            $mdToast.show(
              $mdToast.nextplus({
                position: $rootScope.toastLocation,
                parent: 'body',
                theme: 'error-toast',
                hideDelay: 3000
              })
            )
            $mdToast.updateTextContent(
              $translate.instant('FORM.ERROR.NO_DELETE_PERMISSIONS')
            )
          }
        }

        const initFormsTable = async function initFormsTable (stock) {
          const myFormsStateName = `${$state.current.name}.stock-forms`

          const baseFilter = {
            order: ['modified DESC'],
            where: {
              or: [
                { partSku: stock.sku, serial: stock.serial },
                { stockSku: stock.sku, stockSerial: stock.serial }
              ]
            }
          }
          const tableColumns = FormUtils.generateFormColumns(
            'multiForm',
            null,
            null,
            vm.users,
            [],
            $scope,
            'formsKendoGrid'
          )

          const { defaultTableColumns } =
            ViewsService.generateCustomFieldColumns('FormData', tableColumns)

          const { columns, selectedViewId } = ViewsService.getTablesColumns(
            defaultTableColumns,
            myFormsStateName
          )

          const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
            selectedViewId,
            _.cloneDeep(baseFilter),
            myFormsStateName
          )

          const defaultTableSetup = {
            stateName: myFormsStateName,
            ignoreParams: true,
            find: FormData.getSessionFormDataList,
            count: FormData.getSessionFormDataCount,
            pageSize: 5,
            autoSize: false,
            cleanBaseFilter: baseFilter || {},
            baseFilter: newBaseFilter,
            selectedViewId,
            columns: defaultTableColumns,
            encodeTitles: true
          }

          const defaultTableToolbarSetup = {
            stateName: myFormsStateName,
            columns: defaultTableColumns,
            currentColumnIds: columns.map(c => c.uniqueId),
            filters,
            selectedViewId,
            title: $scope.title
          }

          if ($scope.formsKendoGrid) {
            $scope.formsKendoGrid.setOptions(defaultTableSetup)
          } else {
            $scope.formsKendoGrid = await KendoGridHelper.GridInstance(
              defaultTableSetup,
              $scope,
              columns
            )

            await $scope.formsKendoGrid.isBound()

            $scope.formsToolbar = await ViewsService.GridToolBarInstance(
              defaultTableToolbarSetup,
              $scope.formsKendoGrid,
              $scope
            )
          }
          $scope.formTableInit = false

          $scope.$applyAsync()
        }

        const initHistoryTable = async function initHistoryTable (stock) {
          const historyStateName = `${$state.current.name}.stock-history`

          $scope.userPerRow = {}

          const tableColumns = [
            // DATE
            {
              uniqueId: 'ccdab7b8-c1c0-408b-b56d-047c31868c17',
              field: 'date',
              translateCode: 'WO.CHANGE_LOG.DATE',
              type: 'date',
              width: 'auto',
              filterable: true,
              template: data => {
                return data.date === null
                  ? '--'
                  : DateTimeFormatService.formatDateTime(data.date, 'dateTime')
              }
            },
            // TYPE
            {
              uniqueId: '7a775fdc-da0a-49ed-84ec-4e98d07ca383',
              field: 'type',
              translateCode: 'WO.CHANGE_LOG.CHANGE',
              filterable: false,
              sortable: false,
              width: 'auto',
              template: data => {
                return $translate.instant(`WO.CHANGE_LOG.${data.type}`)
              }
              // media: '(min-width: 768px)',
            },
            // USER
            {
              uniqueId: '84e6f6bc-1952-48ee-a620-c6ab86004a70',
              field: 'userId',
              translateCode: 'WO.CHANGE_LOG.USER',
              filterable: {
                mode: 'row',
                cell: {
                  showOperators: false,
                  template: function (args) {
                    args.element.kendoDropDownList({
                      filter: 'contains',
                      autoBind: false,
                      dataTextField: 'displayName',
                      dataValueField: 'id',
                      dataSource: new kendo.data.DataSource({
                        data: vm.users
                      }),
                      valuePrimitive: true
                    })
                  }
                }
              },
              sortable: false,
              width: 'auto',
              trustedTemplate: data => {
                if (!data.userId || !$scope.usersById[data.userId]) return '--'
                $scope.userPerRow[data.userId] =
                  $scope.usersById[data.userId].displayName
                return `<user-avatar
                          user-id="'${htmlWork.escapeHTMLQuotes(data.userId)}'"
                          display-name="userPerRow['${htmlWork.escapeHTMLQuotes(
                            data.userId
                          )}']"
                          show-name-inline="true"
                        ></user-avatar>`
              }
            },
            // OLD_VALUE
            {
              uniqueId: '9d5bdb70-5a4e-4d43-b28d-29711494ac90',
              field: 'oldValue',
              type: 'object',
              translateCode: 'WO.CHANGE_LOG.OLD_VALUE',
              filterable: false,
              sortable: false,
              width: 'auto',
              trustedTemplate: data => {
                if (!data.oldValue) return ''
                if (VALID_SKU_SERIALS_ACTIONS.includes(data.type)) {
                  let serial = ''
                  let serialName = 'SERIAL'
                  if (data.oldValue.serial) {
                    serial = data.oldValue.serial
                  } else if (data.oldValue.workorderNumber) {
                    serial = data.oldValue.workorderNumber
                    serialName = 'LOT'
                  } else {
                    serial = ''
                  }
                  return `<div layout="column" layout-align="center start">
                    <div layout="row" layout-align="start center">
                      <span translate="WO.SKU_CONVERSION.SKU"></span>:&nbsp;
                      <span><strong>${htmlWork.htmlEncode(
                        data.oldValue.sku
                      )}</strong></span>
                    </div>
                    <div layout="row" layout-align="start center">
                      <span translate="WO.SKU_CONVERSION.${serialName}"></span>:&nbsp;
                      <span><strong>${htmlWork.htmlEncode(
                        serial
                      )}</strong></span>
                    </div>
                  </div>`
                }
                return data.oldValue
              }
            },
            // NEW_VALUE
            {
              uniqueId: 'd0c3a905-f931-4adb-98bb-fac24e8807c7',
              field: 'newValue',
              type: 'object',
              translateCode: 'WO.CHANGE_LOG.NEW_VALUE',
              filterable: false,
              sortable: false,
              width: 'auto',
              trustedTemplate: data => {
                if (!data.newValue) return ''
                if (VALID_SKU_SERIALS_ACTIONS.includes(data.type)) {
                  let serial = ''
                  let serialName = 'SERIAL'
                  if (data.newValue.serial) {
                    serial = data.newValue.serial
                  } else if (data.newValue.workorderNumber) {
                    serial = data.newValue.workorderNumber
                    serialName = 'LOT'
                  } else {
                    serial = ''
                  }
                  return `<div layout="column" layout-align="center start">
                    <div layout="row" layout-align="start center">
                      <span translate="WO.SKU_CONVERSION.SKU"></span>:&nbsp;
                      <span><strong>${htmlWork.htmlEncode(
                        data.newValue.sku
                      )}</strong></span>
                    </div>
                    <div layout="row" layout-align="start center">
                      <span translate="WO.SKU_CONVERSION.${serialName}"></span>:&nbsp;
                      <span><strong>${htmlWork.htmlEncode(
                        serial
                      )}</strong></span>
                    </div>
                  </div>`
                }
                return data.newValue
              }
            },
            // METADATA
            {
              uniqueId: 'f568cd82-2eb6-47df-a59c-219621a385b9',
              field: 'metadata',
              type: 'object',
              translateCode: 'WO.CHANGE_LOG.MORE_INFO',
              filterable: false,
              sortable: false,
              width: 'auto',
              trustedTemplate: data => {
                if (!data.metadata) return ''
                let html = `<div layout="column" layout-align="center start">
                  <div layout="row" layout-align="start center">`
                Object.keys(data.metadata).forEach(key => {
                  if (['workorderNumber', 'serviceCall'].includes(key)) {
                    html += `<span translate="WO.CHANGE_LOG.METADATA.${key}"></span>:&nbsp;`
                    html += `<span><strong>${htmlWork.htmlEncode(
                      data.metadata[key]
                    )}</strong></span>`
                  }
                })
                html += `</div></div>`
                return html
              }
            }
          ]

          const defaultTableSetup = {
            stateName: historyStateName,
            ignoreParams: true,
            serverSide: false,
            data: _.reverse(stock.changeLog),
            autoSize: false,
            cleanBaseFilter: {},
            baseFilter: {},
            pageSize: 5,
            selectedViewId: 'systemDefault',
            columns: tableColumns
          }

          if ($scope.workordersKendoGrid) {
            $scope.historyKendoGrid.setOptions(defaultTableSetup)
          } else {
            $scope.historyKendoGrid = await KendoGridHelper.GridInstance(
              defaultTableSetup,
              $scope,
              tableColumns
            )
          }
        }

        const resizeCharts = function resizeCharts () {
          Object.values($scope.stockCharts).forEach(chart => {
            if (chart && typeof chart.redraw === 'function') {
              chart.redraw()
            }
          })
        }

        $scope.selectStock = async function selectStock ({ data }) {
          if (data.id !== $scope.stock.id) {
            $state.transitionTo(
              $state.current,
              // update url params
              { ...$state.params, id: data.id },
              {
                location: true,
                reload: false,
                notify: true,
                inherit: true
              }
            )
          }
        }

        const initCycleTimeChart = function initCycleTimeChart () {
          const { cycleTimeStats } = $scope.stockProductionDetails
          if (cycleTimeStats) {
            const { max, min, avg } = cycleTimeStats
            $scope.cycleTimeGraphSettings = $scope.netTimeGraphSettings =
              buildColumnGraphSettings(
                max,
                min,
                avg,
                $scope.stockProductionDetails.cycleTime
              )
          }
        }

        const initNetTimeChart = function initNetTimeChart () {
          const { netTimeStats } = $scope.stockProductionDetails
          if (netTimeStats) {
            const { max, min, avg } = netTimeStats
            $scope.netTimeGraphSettings = buildColumnGraphSettings(
              max,
              min,
              avg,
              $scope.stockProductionDetails.netTime
            )
          }
        }

        const initInvolvedUsersChart = function initInvolvedUsersChart () {
          $scope.usersGraphSettings = {
            chartArea: {
              margin: 0,
              height: 250,
              background: 'transparent'
            },
            legend: {
              position: 'bottom'
            },
            seriesDefaults: {
              labels: {
                template: "#= kendo.format('{0:P}', percentage) #",
                position: 'auto',
                visible: true,
                background: 'transparent'
              }
            },
            seriesColors: [
              COLOR_MAP.CURRENT,
              '#ecddd0',
              COLOR_MAP.AVERAGE,
              COLOR_MAP.MAXIMUM,
              '#35455d',
              COLOR_MAP.MINIMUM
            ],
            series: [
              {
                type: 'pie',
                data: $scope.stockProductionDetails.involvedUsers,
                overlay: {
                  gradient: 'none'
                }
              }
            ],
            tooltip: {
              visible: true,
              template: data => {
                const userName = htmlWork.htmlEncode(data.category)
                const percentage = (data.percentage * 100).toFixed(2)
                return `${userName} - ${percentage}%`
              }
            }
          }
        }

        const initStock = function initStock (data) {
          const {
            stock,
            mainStock,
            part,
            mainStockPart,
            stockProductionDetails
          } = data
          $scope.selectedObject = { [stock.id]: true }
          $scope.stockCharts = {}
          $scope.part = part
          $scope.stock = stock
          $scope.mainStock = mainStock
          $scope.mainStockPart = mainStockPart
          stockProductionDetails.involvedUsers =
            stockProductionDetails.involvedUsers.sort((a, b) => {
              return b.value - a.value
            })
          stockProductionDetails.involvedUsersCount =
            stockProductionDetails.involvedUsers.length
          if (stockProductionDetails.involvedUsers.length > 6) {
            const top5 = stockProductionDetails.involvedUsers.slice(0, 5)
            const others = stockProductionDetails.involvedUsers.slice(
              5,
              stockProductionDetails.involvedUsers.length
            )
            const otherSlice = {
              value: _.sumBy(others, 'value'),
              category: $translate.instant('STOCK_PAGE.OTHERS')
            }
            stockProductionDetails.involvedUsers = top5

            if (otherSlice.value > 0) {
              stockProductionDetails.involvedUsers.push(otherSlice)
            }
          }

          $scope.stockProductionDetails = stockProductionDetails

          initInvolvedUsersChart()
          initCycleTimeChart()
          initNetTimeChart()
          initWorkOrderTable(stock)
          initFormsTable(stock)
          initHistoryTable(stock)

          $timeout(() => {
            if ($('.cycle-time-graph')) {
              const cycleTimeGraphElement = $('.cycle-time-graph').kendoChart(
                $scope.cycleTimeGraphSettings
              )
              $scope.stockCharts.cycleTime =
                cycleTimeGraphElement.data('kendoChart')
            }
            if ($('.net-time-graph')) {
              const cycleTimeGraphElement = $('.net-time-graph').kendoChart(
                $scope.netTimeGraphSettings
              )
              $scope.stockCharts.netTime =
                cycleTimeGraphElement.data('kendoChart')
            }
            const element = $('.involved-users-graph').kendoChart(
              $scope.usersGraphSettings
            )
            $scope.stockCharts.users = element.data('kendoChart')
            resizeCharts()
          }, 100)
        }

        const initScreen = function initScreen (data) {
          const { productTree } = data
          $scope.disabledObject = {}
          initStock(data)
          $scope.productTree = [modifyProductTree(productTree)]
        }

        this.$onDestroy = function () {
          angular.element($window).unbind('resize', resizeCharts)
          if ($scope.stateWatcher) {
            $scope.stateWatcher()
          }
        }

        this.$onInit = function () {
          $scope.usersById = _.keyBy(vm.users, 'id')
          $scope.workorderColumns = WorkorderUtils.generateWorkorderColumns(
            $scope,
            vm.locations,
            vm.users,
            vm.workorderStatus,
            {
              workorderNumber: true,
              start: true,
              end: true,
              workorderTypeId: true,
              workflowName: true,
              id: true
            }
          )
          $scope.hasSKUConversionPermission = PermissionUtils.isPermit(
            'Stock',
            'convertStock'
          )
          $scope.hasServiceCallPermission = PermissionUtils.isPermit(
            'Stock',
            'serviceCall'
          )
          initScreen(vm.data)
          $scope.stateWatcher = $scope.$watchCollection(
            function () {
              return $state.params
            },
            async function (newParams, oldParams) {
              if (newParams.id !== oldParams.id) {
                $scope.loadingStockData = true
                $scope.selectedObject = { [newParams.id]: true }
                const stockData = await Stock.getDetails({
                  includeProductTree: false,
                  stockId: newParams.id
                }).$promise
                initStock(stockData)
                $scope.loadingStockData = false
                $scope.$applyAsync()
              }
            }
          )
          angular.element($window).bind('resize', resizeCharts)
        }
      }
  }
}

module.exports = stockView
