/* global _ */
/** @ngInject */
function partView () {
  require('./part-view.scss')
  return {
    template: require('./part-view.html'),
    bindings: {
      part: '<',
      showPart: '&'
    },
    controller:
      /**
       * @ngInject
       */
      function partViewController (
        $scope,
        FormlyHelper,
        PartAssembly,
        KendoGridHelper,
        htmlWork,
        $translate,
        PermissionUtils,
        Revision,
        ViewsService
      ) {
        $scope.showPartFn = function (partNumber, partRev = null) {
          $scope.showPart()(partNumber, partRev)
        }
        const getTableSetup = function (data, tableColumns, extraParams = {}) {
          return {
            stateName: 'part.view',
            ignoreParams: true,
            serverSide: false,
            data,
            autoSize: false,
            cleanBaseFilter: {},
            baseFilter: {},
            pageSize: 10,
            columns: tableColumns,
            selectedViewId: 'systemDefault',
            ...extraParams
          }
        }
        const getRevisionCFields = function () {
          const cfields = {}
          if ($scope.part.selectedRevision) {
            _.each($scope.part.selectedRevision, (value, key) => {
              if (key.startsWith('C_')) {
                cfields[key] = value
              }
            })
          }
          return cfields
        }
        this.$onInit = async function () {
          $scope.part = this.part ? this.part : null
          $scope.showPart = this.showPart
          $scope.PermissionUtils = PermissionUtils
          $scope.partFields = FormlyHelper.buildFields([], PartAssembly).map(
            field => {
              field.templateOptions.disabled = true
              return field
            }
          )
          if ($scope.part.managedByRevision && $scope.part.selectedRevision) {
            $scope.part = {
              ...$scope.part,
              ...getRevisionCFields()
            }
            $scope.partFields.push(
              ...FormlyHelper.buildFields([], Revision).map(field => {
                field.templateOptions.disabled = true
                return field
              })
            )
          }
          const subItemsTableColumns = [
            {
              uniqueId: '4829c84a-4046-4a00-9d45-d9563ee63d77',
              field: 'number',
              width: '150px',
              title: $translate.instant('PartAssembly.PART_NUMBER'),
              sortable: true,
              filterable: true,
              trustedTemplate: function (data) {
                return `<a style="cursor: pointer;" ng-click="showPartFn('${data.number}')" >${data.number}</a>`
              }
            },
            {
              uniqueId: 'd0c021e5-2255-46f5-8b5e-0453c1842936',
              field: 'quantity',
              width: '150px',
              title: $translate.instant('PartAssembly.QUANTITY'),
              sortable: true,
              filterable: true
            },
            {
              uniqueId: 'c0c021e5-2d55-46f5-8b5e-0453c1842936',
              field: 'infoOnly',
              type: 'boolean',
              width: '150px',
              title: $translate.instant('PartAssembly.INFO_ONLY'),
              sortable: true,
              filterable: true
            }
          ]
          const documentsTableColumns = [
            {
              uniqueId: 'b0c021e5-2d55-46f5-8b5e-0453c1842936',
              field: 'title',
              width: '150px',
              title: $translate.instant('PartAssembly.PART_VIEW.FILE'),
              sortable: true,
              filter: {
                type: 'input'
              },
              trustedTemplate: function (data) {
                if (data.url)
                  return `<a href="${htmlWork.htmlEncode(
                    data.url
                  )}" target="_blank">${htmlWork.htmlEncode(data.title)}</a>`
              }
            }
          ]
          if ($scope.part.nodes) {
            $scope.part.nodes = $scope.part.nodes.sort((a, b) => {
              return a.number.localeCompare(b.number)
            })
            const stateName = 'part.view.subItems'
            const { columns, selectedViewId } = ViewsService.getTablesColumns(
              subItemsTableColumns,
              stateName
            )
            const baseFilter = {
              order: 'number ASC'
            }
            const { newBaseFilter, filters } =
              ViewsService.getViewCustomFilters(
                selectedViewId,
                _.cloneDeep(baseFilter),
                stateName
              )
            const tableSetup = getTableSetup($scope.part.nodes, columns, {
              stateName,
              cleanBaseFilter: baseFilter,
              baseFilter: newBaseFilter,
              selectedViewId
            })
            const defaultTableToolbarSetup = {
              stateName,
              columns,
              currentColumnIds: columns.map(c => c.uniqueId),
              filters,
              autoSize: false,
              selectedViewId
            }
            $scope.subItemsKendoGrid = await KendoGridHelper.GridInstance(
              tableSetup,
              $scope,
              columns
            )
            $scope.subItemsToolbar = await ViewsService.GridToolBarInstance(
              defaultTableToolbarSetup,
              $scope.subItemsKendoGrid,
              $scope
            )
          }
          $scope.partDocumentsKendoGrid = await KendoGridHelper.GridInstance(
            getTableSetup($scope.part._documents, documentsTableColumns),
            $scope,
            documentsTableColumns
          )
          if ($scope.part.selectedRevision) {
            $scope.partRevDocumentsKendoGrid =
              await KendoGridHelper.GridInstance(
                getTableSetup(
                  $scope.part.selectedRevision._documents,
                  documentsTableColumns
                ),
                $scope,
                documentsTableColumns
              )
          }
        }
      }
  }
}

module.exports = partView
