/* global angular, alert, _ */

/** @ngInject */
function CatalogNewEditController (
  getUrlFromObj,
  $timeout,
  $scope,
  $rootScope,
  $translate,
  $stateParams,
  $state,
  ResolvedCatalog,
  Catalog,
  FormlyHelper,
  $mdDialog,
  ResolvedGroups,
  PermissionUtils,
  ResolvedCategories
) {
  $scope.getUrlFromObj = getUrlFromObj
  $scope.PermissionUtils = PermissionUtils
  const categoriesForSelect = ResolvedCategories.map(category => ({
    id: category.id,
    name: category.name,
    parent: category.parentId
  }))
  $scope.tagPlaceholder = $translate.instant('CATALOG.INPUT_TAG')
  let groupsForSelect = []
  if (
    $rootScope.currentUser &&
    $rootScope.currentUser.groups &&
    $rootScope.currentUser.groups.length > 0
  ) {
    groupsForSelect = ResolvedGroups.filter(
      group => $rootScope.currentUser.groups.indexOf(group.id) !== -1
    )
  } else {
    groupsForSelect = ResolvedGroups
  }
  $scope.loadTags = function (q) {
    return []
  }

  if (!ResolvedCatalog) {
    $scope.catalog = {
      groups:
        $rootScope.currentUser && $rootScope.currentUser.groups
          ? $rootScope.currentUser.groups
          : [],
      categories: [],
      options: {
        hotspot_height: 0,
        hotspot_wight: 0,
        hotspot_font_size: 0,
        hotspot_opacity: ''
      }
    }
    if ($stateParams && $stateParams.initialValues) {
      Object.keys($stateParams.initialValues).forEach(key => {
        $scope.catalog[key] = $stateParams.initialValues[key]
      })
    }
  } else {
    $scope.catalog = ResolvedCatalog
    if (!$scope.catalog.groups) $scope.catalog.groups = []
    if (!$scope.catalog.categories) $scope.catalog.categories = []
    if (!$scope.catalog.options) $scope.catalog.options = {}
    if (!$scope.catalog.options.hotspot_height) {
      $scope.catalog.options.hotspot_height = 0
    }
    if (!$scope.catalog.options.hotspot_wight) {
      $scope.catalog.options.hotspot_wight = 0
    }
    if (!$scope.catalog.options.hotspot_font_size) {
      $scope.catalog.options.hotspot_font_size = 0
    }
    if (!$scope.catalog.options.hotspot_opacity) {
      $scope.catalog.options.hotspot_opacity = ''
    }
    $scope.catalog.assembly = ResolvedCatalog.assembly
  }

  $scope.staticCatalogFields = [
    {
      key: 'name',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        focus: true,
        label: $translate.instant('CATALOG.NEW_EDIT_CATALOG_NAME'),
        placeholder: $translate.instant('CATALOG.NEW_EDIT_CATALOG_NAME'),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    {
      key: 'assembly',
      type: 'select_assembly',
      templateOptions: {
        label: $translate.instant('CATALOG.NEW_EDIT_ROOT_ASSEMBLY'),
        onClick: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    {
      key: 'groups',
      type: 'selectWithSearch',
      className: 'layout-column',
      templateOptions: {
        label: $translate.instant('CATALOG.GROUPS'),
        multiple: true,
        searchPlaceholder: 'Search...',
        options: groupsForSelect,
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    {
      type: 'chips',
      key: 'tags',
      templateOptions: {
        label: $translate.instant('CATALOG.TAGS'),
        placeholder: $translate.instant('CATALOG.TAGS'),
        secondaryPlaceholder: $translate.instant('CATALOG.TAGS'),
        onKeydown: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        },
        onClick: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    // {
    //   key: 'categories',
    //   type: 'selectWithSearch',
    //   className: 'layout-column',
    //   templateOptions: {
    //     label: $translate.instant('CATALOG.CATEGORIES'),
    //     multiple: true,
    //     searchPlaceholder: 'Search...',
    //     options: ResolvedCategories,
    //     onChange: function (value, options) {
    //       options.validation.errorExistsAndShouldBeVisible = null
    //       options.formControl.$setValidity('serverError', true)
    //     }
    //   }
    // },
    {
      key: 'categories',
      type: 'selectTree',
      className: 'layout-column',
      templateOptions: {
        label: $translate.instant('CATALOG.CATEGORIES'),
        options: categoriesForSelect,
        multiple: true,
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    {
      key: 'options.hotspot_height',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        label: $translate.instant('CATALOG.NEW_EDIT_HOTSPOT_SETTINGS_HEIGHT'),
        placeholder: $translate.instant(
          'CATALOG.NEW_EDIT_HOTSPOT_SETTINGS_HEIGHT'
        ),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    {
      key: 'options.hotspot_wight',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        label: $translate.instant('CATALOG.NEW_EDIT_HOTSPOT_SETTINGS_WIDTH'),
        placeholder: $translate.instant(
          'CATALOG.NEW_EDIT_HOTSPOT_SETTINGS_WIDTH'
        ),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    {
      key: 'options.hotspot_font_size',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        label: $translate.instant(
          'CATALOG.NEW_EDIT_HOTSPOT_SETTINGS_FONT_SIZE'
        ),
        placeholder: $translate.instant(
          'CATALOG.NEW_EDIT_HOTSPOT_SETTINGS_FONT_SIZE'
        ),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    },
    {
      key: 'options.hotspot_opacity',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        label: $translate.instant('CATALOG.NEW_EDIT_HOTSPOT_SETTINGS_OPACITY'),
        placeholder: $translate.instant(
          'CATALOG.NEW_EDIT_HOTSPOT_SETTINGS_OPACITY'
        ),
        onChange: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        }
      }
    }
  ]
  $scope.catalogFields = FormlyHelper.buildFields(
    $scope.staticCatalogFields,
    Catalog
  )

  $scope.cancel = () => window.history.back()

  $scope.groups = ResolvedGroups
  $scope.categories = ResolvedCategories

  const fieldsCorrect = () => {
    if ($scope.catalog.name === '') {
      alert('type name of catalog')
      return false
    }

    if (!$scope.catalog.partNumber || $scope.catalog.partNumber === '') {
      alert('select main assembly of catalog')
      return false
    }

    return true
  }

  $scope.saveCatalog = () => {
    if (!fieldsCorrect()) return

    if (!$scope.catalog.tags || $scope.catalog.tags.length === 0) {
      $scope.catalog.tags = []
    }

    if (!$scope.catalog.groups) $scope.catalog.groups = []
    if (!$scope.catalog.categories) $scope.catalog.categories = []
    if (!$scope.catalog.options.hotspot_height) {
      $scope.catalog.options.hotspot_height = 0
    }
    if (!$scope.catalog.options.hotspot_wight) {
      $scope.catalog.options.hotspot_wight = 0
    }
    if (!$scope.catalog.options.hotspot_font_size) {
      $scope.catalog.options.hotspot_font_size = 0
    }
    if (!$scope.catalog.options.hotspot_opacity) {
      $scope.catalog.options.hotspot_opacity = ''
    }
    const tmp = _.omit(_.clone($scope.catalog), ['assembly'])
    Catalog.prototype$patchAttributes({ id: $scope.catalog.id }, tmp)
      .$promise.then(() =>
        $state.go('app.catalog.show', { id: $scope.catalog.id })
      )
      .catch(e => console.error(e))
  }

  $scope.addCatalog = () => {
    if (!fieldsCorrect()) return

    if (!$scope.catalog.options.hotspot_height) {
      $scope.catalog.options.hotspot_height = 0
    }
    if (!$scope.catalog.options.hotspot_wight) {
      $scope.catalog.options.hotspot_wight = 0
    }

    const tmp = _.omit(_.clone($scope.catalog), ['assembly'])
    Catalog.create(tmp)
      .$promise.then(newCatalog => $state.go('app.catalog.list'))
      .catch(e => {
        console.log(e)
      })
  }

  $scope.deleteCatalog = event => {
    event.stopPropagation()
    event.preventDefault()
    const confirm = $mdDialog
      .confirm()
      .title(
        $translate.instant('CATALOG.REAL_DELETE', {
          catalogName: $scope.catalog.name
        })
      )
      .targetEvent()
      .clickOutsideToClose(true)
      .parent(angular.element(document.body))
      .ok($translate.instant('CATALOG.YES'))
      .cancel($translate.instant('CATALOG.CANCEL'))

    $mdDialog.show(confirm).then(
      function () {
        Catalog.destroyById({ id: $scope.catalog.id })
          .$promise.then(data =>
            $state.go('app.catalog.list', {}, { reload: true })
          )
          .catch(e => console.log(e))
      },
      function () {}
    )
  }

  $scope.headerOptions = {
    icon: 'icon-arrange-send-backward',
    template: require('app/templates/headers/simple.html'),
    title: $scope.catalog.id
      ? $translate.instant('CATALOG.NEW_EDIT_EDIT_CATALOG')
      : $translate.instant('CATALOG.NEW_EDIT_NEW_CATALOG'),
    fabButton: {}
  }
}

module.exports = { CatalogNewEditController }
