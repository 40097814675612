'use strict'
/* global angular */
/** @ngInject */
function inlineButtonController ($scope) {
  const onClick = $event => {
    if (typeof $scope.to.args === 'undefined') {
      $scope.to.args = []
    }
    if ($scope.to.includeModel) {
      $scope.to.args.push($scope.model)
    }
    if (angular.isString($scope.to.onClick)) {
      return $scope.$eval($scope.to.onClick, ...$scope.to.args)
    } else {
      return $scope.to.onClick(...$scope.to.args)
    }
  }
  $scope.onClick = onClick
}

module.exports = inlineButtonController
