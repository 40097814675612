/* global */
/** @ngInject */
function CatalogListLearning (
  $scope,
  Page,
  $translate,
  $state,
  PermissionUtils,
  ResolvedCategoryList,
  Items
) {
  const initScreen = function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.categories = ResolvedCategoryList
    $scope.obj = {
      search: ''
    }
    $scope.selectedCategories = {}
    $scope.page = {
      title: $translate.instant('CATALOG.LEARNING'),
      icon: 'icon-folder-outline'
    }
    Page.setTitleText($scope.page.title)
    $scope.items = Items.map(contentPackage => {
      return {
        id: contentPackage.id,
        name: contentPackage.name,
        metadata: '',
        image: contentPackage.image
          ? `./api/Resources/${contentPackage.image}/thumbnail/0`
          : null,
        link: $state.href('app.content-package.show', {
          id: contentPackage.id
        }),
        categories: contentPackage.categories,
        tags: contentPackage.tags
      }
    })
  }

  initScreen()

  $scope.headerOptions = {
    icon: $scope.page.icon,
    preTitleHtml: require('app/templates/headers/sidebar.html'),
    postTitleHtml: require('app/templates/headers/filter.html'),
    template: require('app/templates/headers/simple.html'),
    title: $scope.page.title,
    fabButton: {}
  }
}

module.exports = CatalogListLearning
