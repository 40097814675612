/* global _ */
require('./nps.dialog.scss')
/** @ngInject */
function NPSDialogController (
  $scope,
  $rootScope,
  $translate,
  $mdDialog,
  NPS,
  ResolvedNPS
) {
  if (_.isNil(ResolvedNPS)) {
    $mdDialog.cancel()
  }

  $scope.npsQuestion = $translate.instant(
    'COMMON.NPS_QUESTION',
    {},
    null,
    null,
    'sceParameters'
  )

  $scope.PAGE_NAMES = {
    NPS_SCORE: 'NPS_SCORE',
    NPS_DESCRIPTION: 'NPS_DESCRIPTION'
  }

  $scope.close = async function close () {
    const { id } = ResolvedNPS
    const updateObject = {
      opened: true,
      ignored: true,
      answerDate: new Date()
    }
    if ($scope.dialogData.selectedScore > 0) {
      updateObject.score = $scope.dialogData.selectedScore
      updateObject.ignored = false
    }
    await NPS.prototype$patchAttributes({ id: id }, updateObject).$promise
    $mdDialog.hide()
  }

  $scope.submit = async function submit () {
    if ($scope.dialogData.innerNavigation === $scope.PAGE_NAMES.NPS_SCORE) {
      $scope.dialogData.innerNavigation = $scope.PAGE_NAMES.NPS_DESCRIPTION
      if ($scope.dialogData.selectedScore >= 7) {
        $scope.secondText = $translate.instant('COMMON.NPS_ABOVE_SEVEN', {
          score: $scope.dialogData.selectedScore
        })
      } else {
        $scope.secondText = $translate.instant('COMMON.NPS_UNDER_SEVEN', {
          score: $scope.dialogData.selectedScore
        })
      }
    } else if (
      $scope.dialogData.innerNavigation === $scope.PAGE_NAMES.NPS_DESCRIPTION
    ) {
      const { id } = ResolvedNPS
      try {
        $rootScope.loadingProgress = true
        await NPS.prototype$patchAttributes(
          { id: id },
          {
            opened: true,
            ignored: false,
            answerDate: new Date(),
            score: $scope.dialogData.selectedScore,
            description: $scope.dialogData.description
          }
        ).$promise
      } catch (err) {
        $rootScope.showErrorToast(JSON.stringify(err))
      } finally {
        $rootScope.loadingProgress = false
        $mdDialog.hide()
      }
    }
  }

  $scope.selectScore = function selectScore (score) {
    $scope.dialogData.selectedScore = score
  }

  const initDialog = async function initDialog () {
    $scope.dialogData = {
      innerNavigation: $scope.PAGE_NAMES.NPS_SCORE,
      selectedScore: 0,
      description: ''
    }
    $scope.options = [
      { score: 1 },
      { score: 2 },
      { score: 3 },
      { score: 4 },
      { score: 5 },
      { score: 6 },
      { score: 7 },
      { score: 8 },
      { score: 9 },
      { score: 10 }
    ]
    $scope.emojis = ['😩', '🙁', '😶', '🙂', '😃']
  }

  initDialog()
}
module.exports = NPSDialogController
