/* global */
/** @ngInject */
function timelineItem ($scope) {
  $scope.fields = $scope.to.fields || []
  $scope.headerFields = $scope.to.headerFields || []
  $scope.isOpen = true
  $scope.editHeader = () => {
    if ($scope.to.editHeaderCB) {
      $scope.to.editHeaderCB($scope.model)
    }
  }
  $scope.removeItem = () => {
    if ($scope.to.removeItemCB) {
      $scope.to.removeItemCB($scope.model)
    }
  }
}

module.exports = timelineItem
