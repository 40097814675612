/** @ngInject */

function Page () {
  let self = {}
  self.prefix = 'Next Plus'
  self.title = ''
  self.textTitle = ''
  self.separator = '|'
  self.setTitleText = title => {
    self.textTitle = title
    self.updateTitle()
  }
  self.setTitlePrefix = prefix => {
    self.prefix = prefix
  }
  self.setTitleSeparator = separator => {
    self.separator = separator
  }
  self.updateTitle = () => {
    self.title = `${self.prefix} ${self.separator} ${self.textTitle}`
  }
  return self
}

module.exports = Page
