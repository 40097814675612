/* global rangy, angular $ */
/** @ngInject */

function TextAngularConfig ($provide) {
  // this demonstrates how to register a new tool and add it to the default toolbar
  $provide.decorator('taOptions', [
    'taRegisterTool',
    'taTranslations',
    'taSelection',
    'taToolFunctions',
    '$delegate',
    '$translate',
    '$window',
    '$rootScope',
    function (
      taRegisterTool,
      taTranslations,
      taSelection,
      taToolFunctions,
      taOptions,
      $translate,
      $window,
      $rootScope
    ) {
      const tmpimgOnSelectAction = (...param) => {
        $rootScope.$broadcast('imgSelected')
        taToolFunctions.imgOnSelectAction(...param)
      }

      taToolFunctions.aOnSelectAction = function (
        event,
        $element,
        editorScope
      ) {
        event.preventDefault()
        editorScope.displayElements.popover.css('width', 'auto')
        const container = editorScope.displayElements.popoverContainer
        container.empty()
        container.css('line-height', '28px')
        const link = angular.element(
          '<a href="' +
            $element.attr('href') +
            '" target="_blank"> ' +
            $translate.instant('TA.OPEN_LINK') +
            '</a>'
        )
        link.css({
          display: 'inline-block',
          'max-width': '300px',
          'word-wrap': 'break-word',
          'text-overflow': 'ellipsis',
          'vertical-align': 'middle'
        })
        container.append(link)
        const buttonGroup = angular.element('<div class="ta-group pull-right">')

        const unLinkButton = angular.element(
          '<button type="button" class="md-button" tabindex="-1" unselectable="on" title="' +
            taTranslations.editLink.unLinkButton.tooltip +
            '">' +
            $translate.instant('TA.UNLINK') +
            '</button>'
        )
        // directly before this click event is fired a digest is fired off whereby the reference to $element is orphaned off
        unLinkButton.on('click', function (event) {
          event.preventDefault()
          $element.replaceWith($element.contents())
          editorScope.updateTaBindtaTextElement()
          editorScope.hidePopover()
        })
        buttonGroup.append(unLinkButton)
        const targetToggle = angular.element(
          '<button type="button" class="md-button" tabindex="-1" unselectable="on">' +
            $translate.instant('TA.OPEN_IN_A_NEW_WINDOW') +
            '</button>'
        )
        if ($element.attr('target') && $element.attr('target') === '_blank') {
          targetToggle.addClass('active')
        } else {
          targetToggle.removeClass('active')
        }
        targetToggle.on('click', function (event) {
          event.preventDefault()
          $element.attr(
            'target',
            $element.attr('target') === '_blank' ? '' : '_blank'
          )
          targetToggle.toggleClass('active')
          editorScope.updateTaBindtaTextElement()
        })
        buttonGroup.append(targetToggle)
        container.append(buttonGroup)
        editorScope.showPopover($element)
      }

      taRegisterTool('linkToDocument', {
        iconclass: 'icon-link',
        tooltiptext: 'Link',
        action: function () {
          const selectedText = window.getSelection()
          if (selectedText.focusNode.href) return
          const savedSel = rangy.saveSelection()
          $rootScope.linkToDocument().then(data => {
            rangy.restoreSelection(savedSel)
            if (data) {
              this.$editor().wrapSelection('createLink', data, true)
            }
          })
        }
      })

      taRegisterTool('linkToAbsoluteDocument', {
        iconclass: 'icon-link',
        tooltiptext: 'Link',
        action: function () {
          const selectedText = window.getSelection()
          if (selectedText.focusNode.href) return
          const savedSel = rangy.saveSelection()
          $rootScope.linkToDocument(true).then(data => {
            rangy.restoreSelection(savedSel)
            if (data) {
              this.$editor().wrapSelection('insertHTML', data)
            }
          })
        }
      })

      taRegisterTool('uploadImage', {
        iconclass: 'icon-image-plus',
        tooltiptext: 'Images',
        action: function () {
          const savedSel = rangy.saveSelection()

          $rootScope.uploadImage().then(data => {
            console.log(data.resource.ext)
            rangy.restoreSelection(savedSel)
            let imageData
            if (data.resource.ext.toLowerCase() === 'pdf') {
              const pdfSource = data.url
              /* eslint-disable */
              imageData = `
              <img style="width: auto;   height: 400px; max-width: 100%;"
                class="custom-pdf"
                id="${data.resource.id}"
                src="assets/images/pdf.jpg"
                ta-insert-video="${pdfSource}" contenteditable="false" allowfullscreen="true"  frameborder="0">​`
              /* eslint-enable */
            } else {
              imageData = `<img id="${data.resource_id}" longdesc="${data.resource_id}" src='${data.url}'>`
            }
            this.$editor().wrapSelection('insertHTML', imageData)
          })
        },
        onElementSelect: {
          element: 'img',
          action: function (event, $element, editorScope) {
            tmpimgOnSelectAction(event, $element, editorScope)
          }
        }
      })
      taRegisterTool('insertIFrame', {
        iconclass: 'icon-group',
        tooltiptext: 'Custom Iframe',
        action: function () {
          const savedSel = rangy.saveSelection()

          $rootScope.insertIFrame().then(data => {
            rangy.restoreSelection(savedSel)
            /*eslint-disable */
            let iframeLink = `
            <img  style="width:100%;height:100%"
              class="custom-iframe"
              src="assets/images/iframe.jpg"
              ta-insert-video="${data}"
              contenteditable="false"
              allowfullscreen="true"
              frameborder="0">​`
            /* eslint-enable */
            this.$editor().wrapSelection('insertHTML', iframeLink)
          })
        },
        onElementSelect: {
          element: 'img',
          onlyWithAttrs: ['ta-insert-video'],
          action: function (event, $element, editorScope) {
            tmpimgOnSelectAction(event, $element, editorScope)
          }
        }
      })
      taRegisterTool('insertMetabase', {
        iconclass: 'icon-table',
        tooltiptext: 'Metabase',
        action: function () {
          const savedSel = rangy.saveSelection()
          $rootScope.insertMetabaseFrame().then(data => {
            if (data) {
              rangy.restoreSelection(savedSel)
              /*eslint-disable */
              let metabase = `
            <img style="height: 100%;width: 100%;max-width: 100%;"
              class="custom-metabase"
              src="assets/images/metabase.jpg"
              ta-insert-video='${data}'
              contenteditable="false"
              allowfullscreen="true"
              frameborder="0">​`
              /* eslint-enable */
              this.$editor().wrapSelection('insertHTML', metabase)
            }
          })
        },
        onElementSelect: {
          element: 'img',
          action: function (event, $element, editorScope) {
            tmpimgOnSelectAction(event, $element, editorScope)
          }
        }
      })
      taRegisterTool('insertStoryLine', {
        iconclass: 'icon-message-text-outline',
        tooltiptext: 'Storyline',
        action: function () {
          const savedSel = rangy.saveSelection()
          $rootScope.uploadStoryLine().then(data => {
            const storySource = `/public/${data.resource.folderName}/`
            rangy.restoreSelection(savedSel)
            /*eslint-disable */
            let story = `
            <img style="width: auto;   height: 400px; max-width: 100%;"
              class="custom-storyline"
              id="${data.resource.id}"
              src="assets/images/storyline.jpg"
              ta-insert-video="${storySource}"
              contenteditable="false"
              allowfullscreen="true"
              frameborder="0">​`
            /* eslint-enable */
            this.$editor().wrapSelection('insertHTML', story)
          })
        },
        onElementSelect: {
          element: 'img',
          action: function (event, $element, editorScope) {
            tmpimgOnSelectAction(event, $element, editorScope)
          }
        }
      })
      taRegisterTool('insertStreamVideo', {
        iconclass: 'icon-video',
        tooltiptext: 'Video',
        action: function () {
          const savedSel = rangy.saveSelection()
          $rootScope.uploadVideo().then(data => {
            const videoSource = data.url
            rangy.restoreSelection(savedSel)
            /*eslint-disable */
            let video = `
            <img  style="width: auto;   height: 400px; max-width: 100%;"
              class="custom-video"
              id="${data.resource.id}"
              src="assets/images/video.png"
              ta-insert-video="${videoSource}"
              contenteditable="false"
              allowfullscreen="true"
              frameborder="0">​`
            /* eslint-enable */
            this.$editor().wrapSelection('insertHTML', video)
          })
        },
        onElementSelect: {
          element: 'img',
          action: function (event, $element, editorScope) {
            tmpimgOnSelectAction(event, $element, editorScope)
          }
        }
      })
      taRegisterTool('fontColor', {
        display: `
          <button style="padding-bottom: 20px; overflow: visible"> 
            <style>
              .color-picker-wrapper .color-picker-panel .color-picker-grid-wrapper .color-picker-row .color-picker-grid{
                display: block;
              }
              .color-picker-wrapper .color-picker-panel.color-picker-panel-left{
                right: 0 !important;
              }
              .color-picker-wrapper .color-picker-input-wrapper {
                margin-top: 4px;
              }
              [dir="rtl"] .color-picker-wrapper .color-picker-panel.color-picker-panel-left{
                left: 0 !important;
              }
            </style>
              <div style="position: absolute;
                cursor: not-allowed;
                height: 100%;
                width: 100%;
                z-index: 9999;" ng-if="isDisabled()">
              </div>
              <color-picker ng-model="color" options="options"
                            api="api" event-api="eventApi">
              </color-picker>
          </button>`,
        activeState: function (e) {
          const me = this
          if (!e) {
            $(me.api.getElement())
              .find('.color-picker-swatch')
              .css('background-color', 'black')
            return false
          }

          let color = false

          e.each((index, el) => {
            if (el.tagName === 'FONT') {
              color = el.color
            }
          })
          if (color) {
            $(me.api.getElement())
              .find('.color-picker-swatch')
              .css('background-color', color)
          }
          return !!color
        },
        action: function (color) {
          const me = this
          const colorSet = $(me.api.getElement()).find('.color-set')
          if (!colorSet || colorSet.length === 0) {
            $(me.api.getElement()).find('.color-picker-grid-wrapper').append(`
              <div class="color-set">
                <div class="simple-color" style="background-color: #c00000" color="#c00000"></div>
                <div class="simple-color" style="background-color: #ff0000" color="#ff0000"></div>
                <div class="simple-color" style="background-color: #ffc000" color="#ffc000"></div>
                <div class="simple-color" style="background-color: #ffff00" color="#ffff00"></div>
                <div class="simple-color" style="background-color: #92d050" color="#92d050"></div>
                <div class="simple-color" style="background-color: #00b050" color="#00b050"></div>
                <div class="simple-color" style="background-color: #0070c0" color="#0070c0"></div>
                <div class="simple-color" style="background-color: #7030a0" color="#7030a0"></div>
                <div class="simple-color" style="background-color: #002060" color="#002060"></div>
              </div>`)

            $(me.api.getElement())
              .find('.simple-color')
              .click(function () {
                const color = $(this).attr('color')
                if (!me.$editor().wrapSelection) {
                  setTimeout(function () {
                    me.action(color)
                    $(me.api.getElement())
                      .find('.color-picker-swatch')
                      .css('background-color', color)
                  }, 100)
                } else {
                  me.api.close()
                  $rootScope.$apply()
                  $(me.api.getElement())
                    .find('.color-picker-swatch')
                    .css('background-color', color)
                  return me.$editor().wrapSelection('foreColor', color)
                }
                me.api.close()
                $rootScope.$apply()
              })
          }

          me.eventApi = {
            onChange: function (api, color, $event) {
              // console.log(color)
              if (!me.$editor().wrapSelection) {
                setTimeout(function () {
                  me.action(color)
                }, 100)
              } else {
                return me.$editor().wrapSelection('foreColor', color)
              }
            }
          }
        },
        options: {
          replacerClassName: 'icon icon-format-color',
          showButtons: false
        },
        color: '#000'
      })
      // $delegate is the taOptions we are decorating
      // here we override the default toolbars and classes specified in taOptions.
      taOptions.forceTextAngularSanitize = false // set false to allow the textAngular-sanitize provider to be replaced
      taOptions.keyMappings = [] // allow customizable keyMappings for specialized key boards or languages
      taOptions.taSelectableElements = ['a', 'img']
      // look at app.js line 622
      // taOptions.toolbar = [
      //   [
      //     'h1',
      //     'h2',
      //     'h3',
      //     'h4',
      //     'h5',
      //     'h6',
      //     'p',
      //     'pre',
      //     'quote',
      //     'bold',
      //     'italics',
      //     'underline',
      //     'strikeThrough',
      //     'fontColor',
      //     'ul',
      //     'ol',
      //     'redo',
      //     'undo',
      //     'clear'
      //   ],
      //   [
      //     'justifyLeft',
      //     'justifyCenter',
      //     'justifyRight',
      //     'justifyFull',
      //     'indent',
      //     'outdent',
      //     'html',
      //     'uploadImage',
      //     'linkToDocument',
      //     'insertLink',
      //     'insertIFrame',
      //     'insertStreamVideo',
      //     'insertStoryLine',
      //     'insertMetabase',
      //     'wordcount',
      //     'charcount'
      //     // 'insertImage',
      //     // 'insertVideo'
      //   ]
      // ]
      taOptions.classes = {
        focussed: 'focussed',
        toolbar: 'ta-toolbar',
        toolbarGroup: 'ta-group',
        toolbarButton: 'md-button',
        toolbarButtonActive: 'active',
        disabled: '',
        textEditor: 'form-control',
        htmlEditor: 'form-control'
      }
      return taOptions // whatever you return will be the taOptions
    }
  ])
  // this demonstrates changing the classes of the icons for the tools for font-awesome v3.x
  $provide.decorator('taTools', [
    '$delegate',
    function (taTools) {
      taTools.quote.iconclass = 'icon-format-quote-open'
      taTools.bold.iconclass = 'icon-format-bold'
      taTools.italics.iconclass = 'icon-format-italic'
      taTools.underline.iconclass = 'icon-format-underline'
      taTools.strikeThrough.iconclass = 'icon-format-strikethrough'
      taTools.ul.iconclass = 'icon-format-list-bulleted'
      taTools.ol.iconclass = 'icon-format-list-numbered'
      taTools.redo.iconclass = 'icon-redo'
      taTools.undo.iconclass = 'icon-undo'
      taTools.clear.iconclass = 'icon-close-circle-outline'
      taTools.justifyLeft.iconclass = 'icon-format-align-left'
      taTools.justifyCenter.iconclass = 'icon-format-align-center'
      taTools.justifyRight.iconclass = 'icon-format-align-right'
      taTools.justifyFull.iconclass = 'icon-format-align-justify'
      taTools.indent.iconclass = 'icon-format-indent-increase'
      taTools.outdent.iconclass = 'icon-format-indent-decrease'
      taTools.html.iconclass = 'icon-code-tags'
      taTools.insertImage.iconclass = 'icon-file-image-box-hiden'
      taTools.insertLink.iconclass = 'icon-link'
      taTools.fontColor.iconclass = 'icon-format-color'
      taTools.insertVideo.iconclass = 'icon-filmstrip'
      taTools.insertStoryLine.iconclass = 'icon-message-text-outline'
      taTools.insertMetabase.iconclass = 'icon-table'

      return taTools
    }
  ])
}

module.exports = TextAngularConfig
