/* global _ angular */

require('./repeatedList.scss')
/** @ngInject */
function repeatedList ($scope, $translate, DialogService) {
  $scope.label = $scope.to.label
  $scope.hideHeader = $scope.to.hideHeader || false
  $scope.hideEdit = $scope.to.hideEdit || false
  $scope.showRemovePrompt = $scope.to.showRemovePrompt || false
  $scope.removePromptText = $scope.to.removePromptText || false
  $scope.startLimit = _.isUndefined($scope.to.limit) ? 5 : $scope.to.limit
  $scope.limit = $scope.startLimit
  /* const handleInsert = !_.isUndefined($scope.to.handleInsert)
    ? $scope.to.handleInsert
    : false */
  $scope.formOptions = {
    formState: $scope.formState
  }

  $scope.showLess = function showLess () {
    $scope.limit = $scope.startLimit
  }

  $scope.loadMore = function loadMore () {
    $scope.limit += 10
  }

  $scope.addNew = function addNew () {
    const obj = {}
    /*
    Asaf 27/01/2022: We figure out that this piece of code never run before so i comment it out.
                      None of the repeatedList formly forms is setting handleInsert to true
    if (handleInsert) {
      const repeat = _.get($scope.model, $scope.options.key, [])
      repeat.push(obj)
      $scope.index = repeat.length - 1
      _.set($scope.model, $scope.options.key, repeat)
    } */
    if (!_.isUndefined($scope.options.templateOptions.onAdd)) {
      $scope.options.templateOptions.onAdd(obj, $scope.index)
    }
  }

  const removeFunction = function removeFunction (index) {
    const obj = _.cloneDeep(
      _.get($scope.model, [$scope.options.key], [])[index]
    )
    $scope.model[$scope.options.key].splice(index, 1)
    if (!_.isUndefined($scope.options.templateOptions.onRemove)) {
      $scope.options.templateOptions.onRemove(obj, index)
    }
  }

  $scope.removeField = function removeField (index) {
    if ($scope.showRemovePrompt) {
      DialogService.deleteDialog($scope.removePromptText).then(function () {
        removeFunction(index)
      })
    } else {
      removeFunction(index)
    }
  }
  $scope.editField = function editField (index) {
    if (!_.isUndefined($scope.options.templateOptions.onEdit)) {
      $scope.options.templateOptions.onEdit(
        $scope.model[$scope.options.key][index],
        index
      )
    }
  }
  $scope.kendoSorterOpts = {
    handler: '.handler'
  }

  $scope.endHandler = function endHandler (e) {
    e.preventDefault()
    const itemMoved = angular.copy($scope.model[$scope.options.key][e.oldIndex])
    $scope.model[$scope.options.key].splice(e.oldIndex, 1)
    $scope.model[$scope.options.key].splice(e.newIndex, 0, itemMoved)
    if (typeof $scope.options.templateOptions.onSorted === 'function') {
      $scope.options.templateOptions.onSorted(
        $scope.model[$scope.options.key],
        e.oldIndex,
        e.newIndex
      )
    }
  }
  $scope.onMove = function onMove (index, isMoveUp) {
    const currentInput = _.cloneDeep($scope.model[$scope.options.key][index])
    const moveIndex = isMoveUp ? index - 1 : index + 1
    $scope.model[$scope.options.key][index] = _.cloneDeep(
      $scope.model[$scope.options.key][moveIndex]
    )
    $scope.model[$scope.options.key][moveIndex] = currentInput
    if (!_.isUndefined($scope.options.templateOptions.onMove)) {
      $scope.options.templateOptions.onMove(index, isMoveUp)
    }
  }
  $scope.extraButtonFunction = async function extraButtonFunction (idx) {
    const model = _.get($scope.model, $scope.options.key)
    if (model[idx]) {
      if ($scope.to.extraButtonFunction) {
        await $scope.to.extraButtonFunction(model[idx], idx)
      }
    }
  }
}

module.exports = repeatedList
