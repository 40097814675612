/** @ngInject */

function textSizeSliderDirective ($document) {
  return {
    restrict: 'E',
    template: `<div class="text-size-slider">
        <md-slider-container>
      <md-slider min="{{min}}" max="{{max}}" ng-model="textSize">
      </md-slider>
      <md-input-container>
      </div>`,
    scope: {
      min: '@',
      max: '@',
      unit: '@',
      value: '@',
      step: '@'
    },
    link: function (scope, element, attr) {
      scope.textSize = parseInt(scope.value)
      scope.$watch('textSize', function (size) {
        size = parseInt(size)
        if (size === 0 || size === '0') {
          return
        }
        // $document[0].body.style.fontSize = size + scope.unit
      })
    }
  }
}

module.exports = textSizeSliderDirective
