/* global angular _ */
import SearchResultsShowController from './pages/show/show'

const MODULE_NAME = 'app.search'

angular
  .module(MODULE_NAME, ['ngSanitize'])
  .config(config)
  .controller('SearchResultsShowController', SearchResultsShowController)
  .filter('filterResults', function () {
    return function (items, curentFilter) {
      if (curentFilter === 1) return items

      return items.filter(x => x.source === curentFilter)
    }
  })

/** @ngInject */

function config ($stateProvider, msApiProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.search_results', {
      abstract: true,
      url: '/search'
    })
    .state('app.search_results.show', {
      url: '/results/?:type/?:query/?:isOCR',
      views: {
        'content@app': {
          template: require('./pages/show/show.html'),
          controller: 'SearchResultsShowController'
        }
      },
      bodyClass: 'search-results-show',
      resolve: {
        searchResults: ($stateParams, Document, $state) => {
          return new Promise((resolve, reject) => {
            Document.searchIn({
              obj: {
                where: parseInt($stateParams.type),
                query: $stateParams.query,
                isOCR: $stateParams.isOCR === 'true' ? true : false
              }
            }).$promise.then(results => {
              if (
                $stateParams.isOCR === 'true' &&
                results.result.length === 1 &&
                [7, 8, 9].includes(results.result[0].source)
              ) {
                reject(results)
                const item = results.result[0]
                let state = ''
                let params = {}
                switch (item.source) {
                  case 7:
                    if (item.id) {
                      if (item.workorderId) {
                        state = 'app.workorder.workflowSession'
                        params = {
                          id: item.workorderId,
                          sessionIds: [item.id]
                        }
                      } else if (item.status === 'WF.NEW') {
                        state = 'app.workflow.session.show'
                        params = {
                          recordId: item.recordId,
                          preview: false
                        }
                      } else {
                        state = 'app.workflow.session.show'
                        params = {
                          workflowSessionIds: [item.id],
                          preview: false
                        }
                      }
                    }
                    break
                  case 8:
                    if (item.id) {
                      state = 'app.workorder.show'
                      params = {
                        id: item.id
                      }
                    }
                    break
                }
                $state.go(state, params)
              } else {
                resolve(results)
              }
            })
          })
        },
        workflowList: (Workflow, $rootScope) =>
          new Promise((resolve, reject) => {
            const customFields = {
              id: true,
              name: true,
              categories: true
            }
            _.mapKeys(
              $rootScope.appSettings.modelsFields.Workflow.properties,
              (value, key) => {
                if (value.custom && value.filterable) {
                  const exits = _.find(customFields, { key: key })
                  if (!exits) {
                    customFields[key] = true
                  }
                }
              }
            )
            resolve(
              Workflow.find({ filter: { fields: customFields } }).$promise
            )
          }),
        contentPackageList: (ContentPackage, $rootScope) =>
          new Promise((resolve, reject) => {
            const customFields = {
              id: true,
              categories: true,
              name: true
            }
            _.mapKeys(
              $rootScope.appSettings.modelsFields.ContentPackage.properties,
              (value, key) => {
                if (value.custom && value.filterable) {
                  const exits = _.find(customFields, { key: key })
                  if (!exits) {
                    customFields[key] = true
                  }
                }
              }
            )
            resolve(
              ContentPackage.find({ filter: { fields: customFields } }).$promise
            )
          }),
        timelineList: (Timeline, $rootScope) =>
          new Promise((resolve, reject) => {
            const customFields = {
              id: true,
              categories: true,
              name: true
            }
            _.mapKeys(
              $rootScope.appSettings.modelsFields.Timeline.properties,
              (value, key) => {
                if (value.custom && value.filterable) {
                  const exits = _.find(customFields, { key: key })
                  if (!exits) {
                    customFields[key] = true
                  }
                }
              }
            )
            resolve(
              Timeline.find({ filter: { fields: customFields } }).$promise
            )
          }),
        ResolvedCategories: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          )
      }
    })

  // Translation
  // $translatePartialLoaderProvider.addPart('app/main/apps/search_results');
}

export default MODULE_NAME
