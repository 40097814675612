/** @ngInject */
function CatalogListController(
  $scope,
  $rootScope,
  $translate,
  Page,
  PermissionUtils,
  workflow,
  training,
  timeline,
  $state,
  workflowFlatus
) {
  $scope.workflowFlatus = workflowFlatus
  $scope.trainings = training
  $scope.timelines = timeline
  $scope.workflows = workflow
  $scope.PermissionUtils = PermissionUtils
  $scope.page = {
    title: $translate.instant('CATALOG.HOME')
  }
  Page.setTitleText($scope.page.title)

  $rootScope.title = $scope.page.title

  $scope.search = ''

  $scope.loadMoreTodo = () => {
    $state.go('app.reports.content-package-views-list')
  }
}

module.exports = CatalogListController
