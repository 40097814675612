/* global _ */
/** @ngInject */
function selectRealwear ($scope) {
  $scope.optionsToDisplay = $scope.to.options
  $scope.onSelect = () => {
    if ($scope.to.onChange) {
      if (_.isArray($scope.options.formControl)) {
        $scope.options.formControl = $scope.options.formControl[0]
      }
      if ($scope.to.onChange) {
        $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
      }
    }
  }
}

module.exports = selectRealwear
