/* global angular $  */
require('./pdf.dialog.scss')
/** @ngInject */
function PdfDialogController ($scope, mdPanelRef, $compile, url) {
  $scope.cancel = function cancel () {
    mdPanelRef.close()
  }

  const initDialog = async function initDialog () {
    console.log(url)
    const compiledElm = $compile(
      angular.element(
        `<div><img src="assets/images/pdf.jpg" class="custom-pdf" ta-insert-video="${url}" /></div>`
      )
    )($scope)[0]
    const compiledHtml = $(compiledElm).html()
    await mdPanelRef._$q

    $(
      angular.element(mdPanelRef.panelEl[0]).find('md-dialog-content')[0]
    ).append(compiledHtml)
  }

  initDialog()
}
module.exports = PdfDialogController
