/* global _ */

/** @ngInject */
function conditionsWizard () {
  require('./conditions-wizard.scss')
  return {
    template: require('./conditions-wizard.html'),
    bindings: {
      fields: '=',
      categories: '=',
      model: '=',
      extras: '='
    },
    controller:
      /**
       * @ngInject
       */
      function conditionsWizard (
        $scope,
        $translate,
        WizardConditionsService,
        $timeout,
        DateTimeFormatService
      ) {
        const operatorsTemp = WizardConditionsService.operators
        let operators = {}

        const allOperators = WizardConditionsService.allOperators
        const translateMap = {
          WHEN: $translate.instant('CONDITIONS_WIZARD.WHEN'),
          FIELD: $translate.instant('CONDITIONS_WIZARD.FIELD'),
          FULL_AUTOCOMPLETE: $translate.instant(
            'CONDITIONS_WIZARD.FULL_AUTOCOMPLETE'
          ),
          AFTER_SELECT_FIELD_AUTO_COMPLETE: $translate.instant(
            'CONDITIONS_WIZARD.AFTER_SELECT_FIELD_AUTO_COMPLETE'
          ),
          AFTER_SELECT_OPERATOR_AUTO_COMPLETE: allOperators.reduce(
            (obj, op) => {
              obj[op] = $translate.instant(
                `CONDITIONS_WIZARD.AFTER_SELECT_OPERATOR_AUTO_COMPLETE.${op}`
              )
              return obj
            },
            {}
          )
        }
        const validTypesByType = {
          string: ['string', 'select'],
          select: ['string', 'select'],
          number: ['number'],
          date: ['date', 'dateTime', 'today'],
          dateTime: ['dateTime', 'date', 'today'],
          today: ['date', 'dateTime'],
          checkbox: ['boolean', 'checkbox'],
          boolean: ['checkbox', 'boolean'],
          kitArray: ['kitArray'],
          tinymce: ['tinymce', 'string', 'select', 'number']
        }

        $scope.unrecognizedTitleResolver = field => {
          if (field.includes('variable_')) {
            return $translate.instant('CONDITIONS_WIZARD.UNRECOGNIZED_VARIABLE')
          }
          return $translate.instant('CONDITIONS_WIZARD.UNRECOGNIZED_FIELD')
        }

        $scope.unrecognizedVariableTitle = $scope.isNullOrUndefined = value => {
          return _.isNil(value)
        }
        $scope.isArray = value => _.isArray(value)

        $scope.valueFormatter = (idx, value) => {
          const currentCondition = $scope.conditions[idx]
          if (currentCondition) {
            const valueIsField = $scope.fields.find(f => f.id === value)
            if (valueIsField) {
              return valueIsField.title
            }
            const field = $scope.fields.find(
              f => f.id === currentCondition.field
            )
            if (field && field.type === 'date') {
              return DateTimeFormatService.formatDateTime(value, 'date')
            } else if (
              field &&
              (field.type === 'dateTime' || field.type === 'today')
            ) {
              return DateTimeFormatService.formatDateTime(value, 'dateTime')
            } else if (field && field.type === 'kitArray') {
              return value.join(', ')
            } else if (field && field.type === 'checkbox') {
              if (typeof value === 'boolean') {
                if (value) {
                  return $translate.instant('CONDITIONS_WIZARD.CHECKED')
                } else {
                  return $translate.instant('CONDITIONS_WIZARD.NOT_CHECKED')
                }
              } else {
                return value
              }
            } else if (
              field &&
              field.type === 'number' &&
              (currentCondition.operator === 'between' ||
                currentCondition.operator === 'not_between') &&
              _.isArray(currentCondition.value) &&
              currentCondition.value.length === 2
            ) {
              const valueAIsField = $scope.fields.find(
                f => f.id === currentCondition.value[0]
              )
              const valueA = valueAIsField
                ? valueAIsField.title
                : currentCondition.value[0]

              const valueBIsField = $scope.fields.find(
                f => f.id === currentCondition.value[1]
              )
              const valueB = valueBIsField
                ? valueBIsField.title
                : currentCondition.value[1]
              return ` ${valueA} ${$translate.instant(
                'CONDITIONS_WIZARD.TO'
              )} ${valueB}`
            } else if (
              ['changes_to', 'changes_from'].includes(
                currentCondition.operator
              ) &&
              _.isArray(currentCondition.value) &&
              currentCondition.value.length === 2
            ) {
              const valueAIsField = $scope.fields.find(
                f => f.id === currentCondition.value[0]
              )
              const valueA = valueAIsField
                ? valueAIsField.title
                : currentCondition.value[0]

              return ` ${valueA}`
            }
          }
          return value
        }

        $scope.checkValidation = () => {
          for (let i = 0; i < $scope.conditions.length; i++) {
            const condition = $scope.conditions[i]
            if (
              $scope.isNullOrUndefined(condition.value) ||
              $scope.isNullOrUndefined(condition.operator) ||
              $scope.isNullOrUndefined(condition.field)
            ) {
              return true
            }
          }
          return false
        }

        const initFieldsSection = function initFieldsSection () {
          $scope.optionsAreFields = true
          $scope.optionsAreOperators = false
          $scope.optionsAreValues = false
          $scope.inputOption = false
          $scope.currentOptions = {}
          $scope.categories = []
          $scope.fieldCategories = []
          Object.keys($scope.fieldsByCategory).forEach(categoryId => {
            const categoryName =
              $scope.fieldsByCategory[categoryId] &&
              $scope.fieldsByCategory[categoryId].categoryName
                ? $scope.fieldsByCategory[categoryId].categoryName
                : ''
            const fields =
              $scope.fieldsByCategory[categoryId] &&
              $scope.fieldsByCategory[categoryId].fields
                ? $scope.fieldsByCategory[categoryId].fields
                : []
            $scope.currentOptions[categoryId] = fields.filter(
              field => !field.invalid
            )
            $scope.categories.push({ id: categoryId, name: categoryName })
          })
          if ($scope.$ctrl.extras.columnCondition) {
            $scope.categories = $scope.categories.filter(
              c => c.id === 'columns'
            )
          }
          $scope.originalCurrentOptions = _.cloneDeep($scope.currentOptions)
          $scope.originalCategories = _.cloneDeep($scope.categories)
          scrollToNode()
        }

        const initOperatorsSection = function initOperatorsSection (field) {
          $scope.optionsAreFields = false
          $scope.optionsAreOperators = true
          $scope.optionsAreValues = false
          let selectedOperators = []
          $scope.conditions[$scope.currentIndex].type = field.type
          switch (field.type) {
            case 'number':
              $scope.conditions[$scope.currentIndex].type = 'double'
              selectedOperators = operators.number
              break
            case 'date':
            case 'dateTime':
            case 'today':
              selectedOperators = operators.date
              break
            case 'checkbox':
            case 'boolean':
              selectedOperators = operators.boolean
              break
            case 'kitArray':
              selectedOperators = operators.kitArray
              break
            case 'tinymce':
              selectedOperators = operators.tinymce
              break
            default:
              selectedOperators = operators.string
          }
          $scope.currentOptions = selectedOperators.map(op => {
            return {
              id: op,
              name: $translate.instant(`CONDITIONS_WIZARD.OPERATORS.${op}`)
            }
          })
          $scope.originalCurrentOptions = _.cloneDeep($scope.currentOptions)
        }

        const initValueSection = function initValueSection (currentCondition) {
          const field = $scope.fields.find(f => f.id === currentCondition.field)
          $scope.currentOptions = []
          $scope.optionsAreFields = false
          $scope.optionsAreOperators = false
          $scope.optionsAreValues = true
          $scope.inputOption = false
          const fieldObject = {
            key: 'value',
            type: 'input',
            ngModelAttrs: {
              rawValue: { bound: 'raw-value', attribute: 'raw-value' }
            },
            className: 'flex-50 margin-5',
            templateOptions: {
              label: $translate.instant('CONDITIONS_WIZARD.VALUE'),
              rawValue: true,
              onChange: function (value, options) {
                const currentCondition = $scope.conditions[$scope.currentIndex]
                currentCondition.valueText = value
                currentCondition.value = value
                if (
                  currentCondition.operator === 'between' ||
                  currentCondition.operator === 'not_between'
                ) {
                  if (
                    !$scope.isNullOrUndefined(currentCondition.value2) &&
                    !$scope.isNullOrUndefined(value)
                  ) {
                    currentCondition.autoCompleteString = null
                  } else {
                    currentCondition.autoCompleteString =
                      translateMap.AFTER_SELECT_OPERATOR_AUTO_COMPLETE[
                        currentCondition.operator
                      ]
                  }
                } else if (
                  ['changes_to', 'changes_from'].includes(
                    currentCondition.operator
                  )
                ) {
                  currentCondition.autoCompleteString = null
                } else {
                  if (!$scope.isNullOrUndefined(value) || value !== '') {
                    currentCondition.autoCompleteString = null
                  } else {
                    currentCondition.autoCompleteString =
                      translateMap.AFTER_SELECT_OPERATOR_AUTO_COMPLETE[
                        currentCondition.operator
                      ]
                  }
                }
              }
            },
            hideExpression: function ($viewValue, $modelValue, scope) {
              let value = $modelValue
              if (scope.model.value) {
                value = scope.model.value
              }
              return value && $scope.fieldsById[value]
            }
          }
          switch (field.type) {
            case 'string':
            case 'number':
              $scope.inputOption = true
              fieldObject.type = 'input'
              fieldObject.templateOptions.type =
                field.type === 'string' ? 'text' : 'number'
              if (field.type === 'number') {
                delete fieldObject.templateOptions.rawValue
              }
              if (
                currentCondition.operator === 'between' ||
                currentCondition.operator === 'not_between'
              ) {
                fieldObject.templateOptions.label = $translate.instant(
                  'CONDITIONS_WIZARD.FROM'
                )
              }
              break
            case 'date':
            case 'dateTime':
            case 'today':
              $scope.inputOption = true
              fieldObject.type =
                field.type === 'date' ? 'datePicker' : 'dateTimePicker'
              fieldObject.defaultValue = new Date()
              break
            case 'checkbox':
            case 'boolean':
              $scope.currentOptions = [true, false]
              break
            case 'select':
              $scope.inputOption = false
              $scope.currentOptions = field.values
              break
            case 'kitArray':
              $scope.inputOption = true
              fieldObject.type = 'customChips'
              break
            default:
              $scope.inputOption = true
              fieldObject.type = 'input'
          }
          $scope.valueField = [
            {
              className: 'display-flex layout-row',
              fieldGroup: [
                fieldObject,
                {
                  key: 'value2',
                  type: 'input',
                  className: 'flex-50 margin-5',
                  ngModelAttrs: {
                    rawValue: { bound: 'raw-value', attribute: 'raw-value' }
                  },
                  templateOptions: {
                    type: 'number',
                    rawValue: true,
                    label: $translate.instant('CONDITIONS_WIZARD.TO'),
                    onChange: function (value, options) {
                      const currentCondition =
                        $scope.conditions[$scope.currentIndex]
                      if (
                        !$scope.isNullOrUndefined(currentCondition.value) &&
                        !$scope.isNullOrUndefined(value)
                      ) {
                        if (
                          _.isArray(currentCondition.value) &&
                          currentCondition.value.length === 2
                        ) {
                          currentCondition.value[1] = value
                        }
                        currentCondition.autoCompleteString = null
                      } else {
                        currentCondition.autoCompleteString =
                          translateMap.AFTER_SELECT_OPERATOR_AUTO_COMPLETE[
                            currentCondition.operator
                          ]
                      }
                    }
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return (
                      $scope.conditions[$scope.currentIndex].operator !==
                        'between' &&
                      $scope.conditions[$scope.currentIndex].operator !==
                        'not_between'
                    )
                  }
                }
              ]
            }
          ]
          if (
            ['is_null', 'is_not_null', 'changes'].includes(
              currentCondition.operator
            )
          ) {
            $scope.optionsAreValues = false
            currentCondition.autoCompleteString = null
          }

          // INIT VARIABLES / FIELDS WITH SAME TYPE
          $scope.valueCategories = []
          $scope.currentValueOptions = {}
          const currentField = $scope.fields.find(
            f => f.id === $scope.conditions[$scope.currentIndex].field
          )
          Object.keys($scope.fieldsByCategory).forEach(categoryId => {
            if (
              categoryId !== 'variables' ||
              (categoryId === 'variables' &&
                !['changes_to', 'changes_from'].includes(
                  currentCondition.operator
                ))
            ) {
              const categoryName =
                $scope.fieldsByCategory[categoryId] &&
                $scope.fieldsByCategory[categoryId].categoryName
                  ? $scope.fieldsByCategory[categoryId].categoryName
                  : ''
              const fields =
                $scope.fieldsByCategory[categoryId] &&
                $scope.fieldsByCategory[categoryId].fields
                  ? $scope.fieldsByCategory[categoryId].fields
                  : []
              const validTypes = validTypesByType[currentField.type]
              const filteredFields = fields.filter(
                field =>
                  validTypes.includes(field.type) &&
                  field.id !== currentField.id
              )
              if (filteredFields.length) {
                $scope.currentValueOptions[categoryId] = filteredFields.filter(
                  field => !field.invalid
                )
                $scope.valueCategories.push({
                  id: categoryId,
                  name: categoryName
                })
              }
            }
          })
          if ($scope.$ctrl.extras.columnCondition) {
            $scope.valueCategories = $scope.valueCategories.filter(
              c => c.id !== 'columns'
            )
          }
          $scope.originalValueCategories = _.cloneDeep($scope.valueCategories)
          $scope.originalCurrentValueOptions = _.cloneDeep(
            $scope.currentValueOptions
          )
          scrollToNode()
        }

        const initFirstCondition = function initFirstCondition () {
          $scope.optionsAreFields = false
          $scope.optionsAreOperators = false
          $scope.optionsAreValues = false
          $scope.conditions.push({
            autoCompleteString: translateMap.FULL_AUTOCOMPLETE
          })
          $scope.addRemoveClass.push(false)
          initFieldsSection()
        }

        $scope.addNewCondition = logic => {
          $scope.logic = logic
          $scope.conditions.push({
            autoCompleteString: translateMap.FULL_AUTOCOMPLETE
          })
          initFieldsSection()
          $scope.currentIndex = $scope.conditions.length - 1
          $scope.addRemoveClass.push(false)
        }

        /** **FIELDS BUTTONS ****/
        $scope.onFieldSelect = field => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          currentCondition.field = field.id
          currentCondition.autoCompleteString =
            translateMap.AFTER_SELECT_FIELD_AUTO_COMPLETE
          currentCondition.fieldHover = false
          $scope.clearSearchTerm()
          initOperatorsSection(field)
        }

        $scope.onFieldHover = field => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          if ($scope.isNullOrUndefined(currentCondition.field)) {
            currentCondition.fieldHover = true
            $scope.lastField = currentCondition.field
            $scope.lastAutoCompleteString = currentCondition.autoCompleteString
            currentCondition.field = field.id
            currentCondition.autoCompleteString =
              translateMap.AFTER_SELECT_FIELD_AUTO_COMPLETE
          }
        }

        $scope.onFieldLeave = () => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          if (currentCondition.fieldHover) {
            currentCondition.fieldHover = false
            currentCondition.field = $scope.lastField
            currentCondition.autoCompleteString = $scope.lastAutoCompleteString
          }
        }

        /** **OPERATORS BUTTONS ****/
        $scope.onOperatorSelect = operator => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          currentCondition.operator = operator.id
          currentCondition.autoCompleteString =
            translateMap.AFTER_SELECT_OPERATOR_AUTO_COMPLETE[
              currentCondition.operator
            ]
          currentCondition.operatorHover = false
          $scope.clearSearchTerm()
          initValueSection(currentCondition)
        }

        $scope.onOperatorHover = operator => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          if ($scope.isNullOrUndefined(currentCondition.operator)) {
            currentCondition.operatorHover = true
            $scope.lastField = currentCondition.operator
            $scope.lastAutoCompleteString = currentCondition.autoCompleteString
            currentCondition.operator = operator.id
            if (operator.name.includes('null')) {
              currentCondition.autoCompleteString = null
            } else {
              currentCondition.autoCompleteString =
                translateMap.AFTER_SELECT_OPERATOR_AUTO_COMPLETE[
                  currentCondition.operator
                ]
            }
          }
        }

        $scope.onOperatorLeave = () => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          if (currentCondition.operatorHover) {
            currentCondition.operatorHover = false
            currentCondition.operator = $scope.lastField
            currentCondition.autoCompleteString = $scope.lastAutoCompleteString
          }
        }

        /** **VALUES BUTTONS****/
        $scope.onValueSelect = (value, isField = false) => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          if (
            isField &&
            (currentCondition.operator === 'between' ||
              currentCondition.operator === 'not_between') &&
            ((!$scope.isNullOrUndefined(currentCondition.value) &&
              !currentCondition.valueHover) ||
              (currentCondition.valueHover &&
                !$scope.isNullOrUndefined($scope.lastField))) &&
            !_.isArray(currentCondition.value)
          ) {
            currentCondition.value = [currentCondition.value, value]
          } else {
            currentCondition.value = value
          }
          delete currentCondition.value2
          if (
            currentCondition.operator === 'between' ||
            currentCondition.operator === 'not_between'
          ) {
            if (
              !$scope.isNullOrUndefined(currentCondition.value) &&
              _.isArray(currentCondition.value) &&
              currentCondition.value.length === 2
            ) {
              currentCondition.autoCompleteString = null
            } else {
              currentCondition.autoCompleteString =
                translateMap.AFTER_SELECT_OPERATOR_AUTO_COMPLETE[
                  currentCondition.operator
                ]
            }
          }
          currentCondition.valueHover = false
          currentCondition.valueText = currentCondition.value
          $scope.clearSearchTerm()
        }

        $scope.onValueHover = value => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          if ($scope.isNullOrUndefined(currentCondition.value)) {
            currentCondition.valueHover = true
            $scope.lastField = currentCondition.valueText
            $scope.lastAutoCompleteString = currentCondition.autoCompleteString
            currentCondition.valueText = value
            currentCondition.autoCompleteString = null
          }
        }

        $scope.onValueLeave = value => {
          const currentCondition = $scope.conditions[$scope.currentIndex]
          if (currentCondition.valueHover) {
            currentCondition.valueHover = false
            currentCondition.valueText = $scope.lastField
            currentCondition.autoCompleteString = $scope.lastAutoCompleteString
          }
        }

        /** **REMOVE BUTTON****/
        $scope.onRemove = index => {
          if (index === $scope.conditions.length - 1) {
            $scope.optionsAreFields = false
            $scope.optionsAreOperators = false
            $scope.optionsAreValues = false
          }
          $scope.addRemoveClass.splice(index, 1)
          $scope.conditions.splice(index, 1)
          $scope.inputOption = false
          if ($scope.conditions.length === 0) {
            initFirstCondition()
          }
          $scope.currentIndex = $scope.conditions.length - 1
        }

        $scope.onRemoveHover = (index, boolean) => {
          $scope.addRemoveClass[index] = boolean
        }

        const loadConditions = function loadConditions (
          fields,
          categories = [],
          model = {}
        ) {
          $scope.conditions = []
          $scope.fields = fields
          $scope.model = model
          if ($scope.model && $scope.model.rules?.length > 0) {
            $scope.model.rules = $scope.model.rules.map(rule => {
              const newRule = rule
              newRule.valueText =
                ['changes_to', 'changes_from', 'changes'].includes(
                  rule.operator
                ) && Array.isArray(rule.value)
                  ? rule.value[0]
                  : rule.value
              return newRule
            })
          }
          $scope.categoriesNames = categories.reduce((obj, category) => {
            obj[category.id] = category.name
            return obj
          }, {})
          const duplicatedTitles = _.map(
            fields.filter((e, idx, arr) => {
              return (
                _.findLastIndex(arr, elem => elem.title === e.title) !== idx
              )
            }),
            'title'
          )
          $scope.fieldsTitleMap = fields.reduce((obj, field) => {
            obj[field.id] = {
              title: field.title,
              category: field.category,
              invalid: field.invalid || false,
              ...(duplicatedTitles.includes(field.title)
                ? { isDuplicated: true }
                : {})
            }
            return obj
          }, {})
          $scope.fieldsByCategory = []
          for (let i = 0; i < $scope.fields.length; i++) {
            const field = $scope.fields[i]
            if (!$scope.isNullOrUndefined(field.category)) {
              field.categoryName = $scope.categoriesNames[field.category]
            }
            $scope.fieldsByCategory.push(field)
          }
          const fieldsByCategory = _.groupBy(
            $scope.fieldsByCategory,
            'category'
          )
          $scope.fieldsByCategory = {}
          Object.keys(fieldsByCategory).forEach(category => {
            const fields = fieldsByCategory[category]
            const categoryName = fields[0].categoryName
            $scope.fieldsByCategory[category] = {
              categoryName,
              fields
            }
          })
          // $scope.fieldsByCategory = _.groupBy(
          //   $scope.fieldsByCategory,
          //   'categoryName'
          // )
          const { rules } = model
          $scope.logic = model.condition
          if (rules) {
            if (rules.length === 0) {
              initFirstCondition()
            }
            for (let c = 0; c < rules.length; c++) {
              const condition = JSON.parse(JSON.stringify(rules[c]))
              condition.valueHover = false
              condition.operatorHover = false
              condition.fieldHover = false
              condition.autoCompleteString = null
              if ($scope.isNullOrUndefined(condition.field)) {
                condition.autoCompleteString = translateMap.FULL_AUTOCOMPLETE
              } else if ($scope.isNullOrUndefined(condition.operator)) {
                condition.autoCompleteString =
                  translateMap.AFTER_SELECT_FIELD_AUTO_COMPLETE
              } else if (
                $scope.isNullOrUndefined(condition.value) &&
                !condition.operator.includes('null')
              ) {
                condition.autoCompleteString =
                  translateMap.AFTER_SELECT_OPERATOR_AUTO_COMPLETE[
                    condition.operator.id
                  ]
              }
              $scope.conditions.push(condition)
              $scope.addRemoveClass.push(false)
            }
          } else if (!rules) {
            initFirstCondition()
          }
          $scope.currentIndex = $scope.conditions.length - 1

          $scope.$watch(
            'conditions',
            function (current, old) {
              if (current && current !== old) {
                $scope.model.rules = current.map(condition => {
                  const newCondition = _.omit(condition, [
                    'valueHover',
                    'operatorHover',
                    'fieldHover',
                    'autoCompleteString'
                  ])
                  newCondition.id = newCondition.field
                  if (
                    !$scope.isNullOrUndefined(newCondition.value2) &&
                    (!_.isArray(newCondition.value) ||
                      newCondition.value.length === 1)
                  ) {
                    newCondition.value = [
                      !isNaN(newCondition.value) &&
                      !isNaN(parseFloat(newCondition.value))
                        ? parseFloat(newCondition.value)
                        : newCondition.value,
                      !isNaN(newCondition.value2) &&
                      !isNaN(parseFloat(newCondition.value2))
                        ? parseFloat(newCondition.value2)
                        : newCondition.value2
                    ]
                    delete newCondition.value2
                  }
                  return newCondition
                })
                $scope.model.rules = _.compact(
                  $scope.model.rules.map(rule => {
                    if ($scope.isNullOrUndefined(rule.field)) {
                      return null
                    }
                    return rule
                  })
                )
                $scope.$ctrl.extras.canSave =
                  ($scope.isNullOrUndefined(
                    current[$scope.currentIndex].autoCompleteString
                  ) &&
                    !current[$scope.currentIndex].operatorHover &&
                    !current[$scope.currentIndex].valueHover) ||
                  $scope.isNullOrUndefined(current[0].field) ||
                  (current[0].field && current[0].fieldHover)
              }
            },
            true
          )

          $scope.$watch('logic', function (current, old) {
            if (current && current !== old) {
              $scope.model.condition = current
            }
          })
        }

        this.$onInit = function () {
          console.log('Init conditions wizard component')
          const that = this
          $scope.optionsAreFields = false
          $scope.optionsAreOperators = false
          $scope.optionsAreValues = false
          $scope.inputOption = false
          $scope.fieldsById = _.keyBy(that.fields, 'id')
          $scope.sourceNodeId = $scope.$ctrl.extras.sourceNodeId || null
          $scope.searchTerm = ''
          if (that.extras?.validOperators) {
            Object.keys(operatorsTemp).forEach(type => {
              operators[type] = []
              operatorsTemp[type].forEach(op => {
                if (that.extras?.validOperators.includes(op)) {
                  operators[type].push(op)
                }
              })
            })
          } else {
            operators = operatorsTemp
          }
          $scope.operatorsNames = _.uniq(
            _.flatten(Object.values(operators))
          ).reduce((obj, op) => {
            obj[op] = $translate.instant(`CONDITIONS_WIZARD.OPERATORS.${op}`)
            return obj
          }, {})
          $scope.addRemoveClass = []
          $scope.logic = 'AND'

          $scope.$ctrl.extras.save = () => {
            $scope.model.rules.forEach(rule => {
              delete rule.valueText
              if (
                ['changes_to', 'changes_from', 'changes'].includes(
                  rule.operator
                )
              ) {
                rule.value = [rule.value, `FIELD_ID_${rule.field}`]
              }
            })

            $scope.$ctrl.extras.saveCallback($scope.conditions)
          }
          loadConditions(that.fields, that.categories, that.model)
        }
        const scrollToNode = function scrollToNode () {
          $timeout(() => {
            if ($scope.sourceNodeId) {
              const target = document.getElementById($scope.sourceNodeId)
              if (target) {
                target.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              }
            }
          })
        }
        $scope.search = function search ($event) {
          const input = $event.target
          if (input.value !== $scope.searchTerm) {
            $scope.searchTerm = input.value
            $scope.makeSearch()
          }
        }
        $scope.makeSearch = function makeSearch () {
          if (!$scope.searchTerm || $scope.searchTerm === '') {
            resetFilter()
          } else {
            const search = $scope.searchTerm.toLowerCase()
            if ($scope.optionsAreOperators) {
              $scope.currentOptions = $scope.originalCurrentOptions.filter(
                option => {
                  return option.name.toLowerCase().includes(search)
                }
              )
            } else {
              if ($scope.optionsAreFields) {
                const categories = []
                $scope.originalCategories.forEach(category => {
                  const fields = _.cloneDeep(
                    $scope.originalCurrentOptions[category.id]
                  )
                  if (category.name.toLowerCase().includes(search)) {
                    categories.push(category)
                    $scope.currentOptions[category.id] = fields
                  } else {
                    $scope.currentOptions[category.id] = fields.filter(
                      field => {
                        return field.title.toLowerCase().includes(search)
                      }
                    )
                    if ($scope.currentOptions[category.id].length > 0) {
                      categories.push(category)
                    }
                  }
                })
                $scope.categories = categories
              } else if ($scope.optionsAreValues) {
                const categories = []
                $scope.originalValueCategories.forEach(category => {
                  const fields = _.cloneDeep(
                    $scope.originalCurrentValueOptions[category.id]
                  )
                  if (category.name.toLowerCase().includes(search)) {
                    categories.push(category)
                    $scope.currentValueOptions[category.id] = fields
                  } else {
                    $scope.currentValueOptions[category.id] = fields.filter(
                      field => {
                        return field.title.toLowerCase().includes(search)
                      }
                    )
                    if ($scope.currentValueOptions[category.id].length > 0) {
                      categories.push(category)
                    }
                  }
                })
                $scope.valueCategories = categories
              }
            }
          }
        }
        $scope.clearSearchTerm = function clearSearchTerm () {
          $scope.searchTerm = ''
          resetFilter()
        }
        const resetFilter = function resetFilter () {
          if ($scope.optionsAreOperators || $scope.optionsAreFields) {
            $scope.currentOptions = _.cloneDeep($scope.originalCurrentOptions)
            if ($scope.optionsAreFields) {
              $scope.categories = _.cloneDeep($scope.originalCategories)
            }
          }
          if ($scope.optionsAreValues) {
            $scope.currentValueOptions = _.cloneDeep(
              $scope.originalCurrentValueOptions
            )
            $scope.valueCategories = _.cloneDeep($scope.originalValueCategories)
          }
        }
      }
  }
}

module.exports = conditionsWizard
