/* global */

/** @ngInject */
function CatalogListController (
  $scope,
  Page,
  $translate,
  $state,
  PermissionUtils,
  ResolvedCategoryList,
  Items
) {
  const initScreen = function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.categories = ResolvedCategoryList
    $scope.obj = {
      search: ''
    }
    $scope.selectedCategories = {}
    $scope.page = {
      title: $translate.instant('CATALOG.TRAINING'),
      icon: 'icon-folder-outline'
    }
    Page.setTitleText($scope.page.title)
    $scope.items = Items.map(timeline => {
      if (timeline.type === 'ContentPackage') {
        return {
          id: timeline.id,
          name: timeline.name,
          metadata: '',
          image: timeline.image
            ? `./api/Resources/${timeline.image}/thumbnail/0`
            : null,
          link: $state.href('app.content-package.show', {
            id: timeline.id
          }),
          categories: timeline.categories
        }
      }
      return {
        id: timeline.id,
        name: timeline.name,
        metadata: '',
        image: timeline.ResourceId
          ? `./api/Resources/${timeline.ResourceId}/thumbnail/0`
          : null,
        link: $state.href('app.timeline.display', {
          id: timeline.id
        }),
        categories: timeline.categories
      }
    })
  }

  initScreen()

  $scope.headerOptions = {
    icon: $scope.page.icon,
    preTitleHtml: require('app/templates/headers/sidebar.html'),
    postTitleHtml: require('app/templates/headers/filter.html'),
    template: require('app/templates/headers/simple.html'),
    title: $scope.page.title,
    fabButton: {}
  }
}

module.exports = CatalogListController
