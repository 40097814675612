/* global */
/** @ngInject */
function collorPaletteController ($scope) {
  if (!$scope.options.templateOptions.colorPaletteOptions.palette) {
    $scope.options.templateOptions.colorPaletteOptions.palette = [
      '#E9DCCC',
      '#B1B5B8',
      '#F09574',
      '#FCA293',
      '#FFCD8A',
      '#FFE390',
      '#D9FAE9',
      '#8DB6C4',
      '#889FD3',
      '#E8E4FC',
      '#F5EDE6',
      '#DCDCDE',
      '#FCDDCE',
      '#EDC9C7',
      '#FFEFCF',
      '#FDF1C1',
      '#EFF8F2',
      '#D8EBF2',
      '#E6F1FC',
      '#DCDBED'
    ]
  }
}

module.exports = collorPaletteController
