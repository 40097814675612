/* global angular */
import AddEditFormulaController from './edit'

/** @ngInject */

function inputFormula () {
  return {
    restrict: 'E',
    require: 'ngModel',
    template: `
      <md-input-container class="md-icon-float md-icon-right md-block">
        <label>{{title}}</label>
        <input ng-click="editFormula()" type="text" ng-model="modelValue" readonly>
      </md-input-container>`,
    replace: true,
    scope: {
      modelValue: '=ngModel',
      errorValue: '=error',
      title: '@title',
      options: '=options',
      placeholder: '@placeholder',
      type: '@?'
    },
    controller: [
      '$scope',
      '$mdDialog',
      ($scope, $mdDialog) => {
        $scope.deleteFormula = () => {}

        $scope.editFormula = () => {
          $mdDialog
            .show({
              controller: AddEditFormulaController,
              template: require('./edit.html'),
              parent: angular.element(document.body),
              targetEvent: '',
              locals: {
                value: $scope.modelValue,
                options: $scope.options,
                type: $scope.type
              },
              resolve: {},
              multiple: true,
              clickOutsideToClose: false,
              escapeToClose: false
            })
            .then(
              function (response) {
                if (response || response === '') {
                  $scope.modelValue = response
                }
              },
              function () {}
            )
        }
      }
    ]
  }
}

module.exports = inputFormula
