/* global _ Audio barcodeDetectorPolyfill */
import { BrowserStream } from './browser-stream'
require('./vision.dialog.scss')
/** @ngInject */
function VisionDialogController (
  $scope,
  $rootScope,
  $element,
  $mdDialog,
  $window,
  $timeout,
  locals,
  visionService
) {
  if (!('BarcodeDetector' in $window)) {
    if (!barcodeDetectorPolyfill) {
      $scope.error = 'Barcode detection is not supported in this browser'
      $scope.userAgent = navigator.userAgent
    } else {
      $window.BarcodeDetector = barcodeDetectorPolyfill.BarcodeDetectorPolyfill
    }
  }
  $scope.isLandscape = $window.matchMedia('(orientation: landscape)').matches

  const onOrientationChange = function (event) {
    $scope.isLandscape = event.target.screen.orientation.angle % 180 !== 0
  }

  $window.addEventListener('orientationchange', onOrientationChange)

  $scope.$on('$destroy', () => {
    $window.removeEventListener('orientationchange', onOrientationChange)
  })

  const callback = locals.callback
    ? locals.callback
    : word => {
        console.log('call back !!!!', word)
      }
  const returnWords = locals.returnWords || false
  const key = locals.key
  // const codeReader = new $window.BrowserMultiFormatReader()
  const browserStream = new BrowserStream()
  let canvas = document.querySelector('#canvas')
  let video = document.querySelector('#video')
  let defaultDevice

  /**
   * Get all video devices
   */
  const getEnumerateDevices = function getEnumerateDevices () {
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => {
          const videoInputDevices = devices.filter(
            device => device.kind === 'videoinput'
          )
          console.log('videoInputDevices', JSON.stringify(videoInputDevices))
          if (
            _.isUndefined(videoInputDevices) ||
            videoInputDevices.length === 0
          ) {
            $scope.optionsModel.noDevice = true
          } else {
            defaultDevice = videoInputDevices[0].deviceId
            videoInputDevices.forEach((device, index) => {
              const option = {}
              option.id = device.deviceId
              option.name =
                device.label || `Camera ${index + 1}` || device.deviceId
              $scope.cameraOptions.push(option)
            })
            resolve()
          }
        })
        .catch(err => {
          console.error(err)
          $scope.optionsModel.noDevice = true
          reject(err)
        })
    })
  }
  /**
   * Start video stream
   */
  const startStream = function startStream () {
    console.log('startStream function')
    if (navigator.mediaDevices.getUserMedia) {
      video = document.querySelector('#video')
      let selectedDeviceId = defaultDevice
      console.log('defaultDevice', defaultDevice)
      if (
        !_.isUndefined($scope.selectedDeviceId) &&
        $scope.selectedDeviceId !== null
      ) {
        const deviceExists = $scope.cameraOptions.find(
          op => op.id === $scope.selectedDeviceId
        )
        if (deviceExists) {
          console.log('device found')
          selectedDeviceId = $scope.selectedDeviceId
        } else {
          console.log('device not found')
          $scope.selectedDeviceId = defaultDevice
        }
      }
      browserStream.startStreamProcess(selectedDeviceId, video)
    }
  }
  /**
   * Stop video stream
   */
  const stopStream = function stopStream () {
    browserStream.stopStreams()
  }
  /**
   * Capture image from stream and stop the stream
   */
  $scope.captureImage = function captureImage () {
    if (!canvas) {
      canvas = document.querySelector('#canvas')
    }
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    const ctx = canvas.getContext('2d')
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
    $scope.imageCaptured = true
    stopStream()
  }
  /**
   * Restart video stream
   */
  $scope.retry = function retry () {
    $scope.imageCaptured = false
    $timeout(
      () => {
        startStream()
      },
      0,
      false
    )
  }
  /**
   * Send image for processing according to current option
   */
  $scope.sendImageForProcessing = function sendImageForProcessing () {
    switch ($scope.visionOption) {
      case 'ocr':
        {
          const dataURI = canvas.toDataURL('image/jpeg')
          visionService.sendOCRImageAndroidEvent(dataURI, callback, returnWords)
          $scope.cancel()
        }
        break
      default:
    }
  }
  /**
   * Close dialog without return any value
   */
  $scope.cancel = function cancel () {
    try {
      stopStream()
    } catch (err) {
      console.error(err)
    }
    $mdDialog.cancel()
  }

  /**
   * Initialization function for barcode reader
   * @param {string} deviceId
   */
  const zxingInit = async function zxingInit (deviceId) {
    const video = document.getElementById('video')
    let selectedDeviceId = defaultDevice
    if (!_.isUndefined(deviceId)) {
      const deviceExists = $scope.cameraOptions.find(op => op.id === deviceId)
      if (deviceExists) {
        selectedDeviceId = deviceId
      } else {
        $scope.selectedDeviceId = defaultDevice
      }
    }
    // Set the selected device id to the source of the video
    $scope.selectedDeviceId = selectedDeviceId
    // const formats = await $window.BarcodeDetector.getSupportedFormats()
    browserStream.startStreamProcess(selectedDeviceId, video)
    const detector = new $window.BarcodeDetector()
    while (!$scope.optionsModel.value && $scope.$$destroyed !== true) {
      try {
        const codes = await detector.detect(video)
        if (codes && codes.length > 0) {
          $scope.optionsModel.value = codes[0].rawValue
          const audio = new Audio('assets/audio/beep.mp3')
          audio.play()
          stopStream()
          setTimeout(() => {
            $mdDialog.hide({
              mode: 'barcode',
              value: $scope.optionsModel.value
            })
          }, 1 * 1000)
        } else {
          await new Promise(resolve => setTimeout(resolve, 30))
        }
      } catch (err) {
        console.error(err)
        await new Promise(resolve => setTimeout(resolve, 30))
      }
    }
  }

  const videoDimensions = function videoDimensions (video) {
    // Ratio of the video's intrinsic dimensions
    const videoRatio = video.videoWidth / video.videoHeight
    // The width and height of the video element
    let width = video.offsetWidth
    let height = video.offsetHeight
    // The ratio of the element's width to its height
    const elementRatio = width / height
    // If the video element is short and wide
    if (elementRatio > videoRatio) width = height * videoRatio
    // It must be tall and thin, or exactly equal to the original ratio
    else height = width / videoRatio
    return {
      width,
      height
    }
  }
  /**
   * Render barcode SVG on video stream
   */
  $scope.renderBestScanPosition = function renderBestScanPosition () {
    const vid = document.getElementById('video')
    const videoSize = videoDimensions(vid)
    if (!_.isUndefined(vid.videoWidth) && !_.isUndefined(vid.videoHeight)) {
      const xMidPoint = vid.offsetWidth / 2
      const yMidPoint = vid.offsetHeight / 2
      const barcodeWidth = 0.8 * videoSize.width
      const barcodeHeight = _.clamp(barcodeWidth / 3, 0, 0.8 * videoSize.height)
      const qrWidth = 0.4 * videoSize.width
      const qrHeight = qrWidth
      const left = xMidPoint - barcodeWidth / 2
      const right = xMidPoint + barcodeWidth / 2
      const top = yMidPoint - barcodeHeight / 2
      const bottom = yMidPoint + barcodeHeight / 2
      const leftQR = xMidPoint - qrWidth / 2
      const rightQR = xMidPoint + qrWidth / 2
      const topQR = yMidPoint - qrHeight / 2
      const bottomQR = yMidPoint + qrHeight / 2
      return `<svg
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
              x='0'
              y='0'
              width='${vid.offsetWidth}'
              height='${vid.offsetHeight}'
              viewBox='0, 0, ${vid.offsetWidth}, ${vid.offsetHeight}'
            >
              <g>
                <path
                  d=' M ${left},${top}
                      L ${left},${bottom}
                      L ${right},${bottom}
                      L ${right},${top}
                      L ${left},${top} z'
                  fill='#0066ff'
                  opacity='0.35'
                  stroke-width='1'
                  stroke-opacity='0.1'
                  class="opacity"
                />
                <path
                  d='M ${left},${yMidPoint} L ${right},${yMidPoint}'
                  fill-opacity='0'
                  stroke='#ed553b'
                  stroke-width='3'
                  class='guide-line'
                />
              </g>
              <g>
                <path
                  d=' M ${leftQR},${topQR}
                      L ${leftQR},${top}
                      L ${rightQR},${top}
                      L ${rightQR},${topQR}
                      L ${leftQR},${topQR} z'
                  fill='#0066ff'
                  opacity='0.35'
                  stroke-width='0'
                  stroke-opacity='0.1'
                  class="opacity"
                />
              </g>
              <g>
                <path
                  d=' M ${leftQR},${bottom}
                      L ${leftQR},${bottomQR}
                      L ${rightQR},${bottomQR}
                      L ${rightQR},${bottom}
                      L ${leftQR},${bottom} z'
                  fill='#0066ff'
                  opacity='0.35'
                  stroke-width='0'
                  stroke-opacity='0.1'
                  class="opacity"
                />
              </g>
            </svg>`
    } else {
      return '<div></div>'
    }
  }
  /**
   * Switch camera according to user select
   * @param {string} deviceId
   * @param {number} idx
   */
  $scope.cameraSelect = (deviceId, idx) => {
    $scope.selectedDeviceId = deviceId
    $scope.selectedDeviceIndex = idx
    $scope.imageCaptured = false
    $window.localStorage.removeItem('vision-camera-position')
    $window.localStorage.setItem('vision-camera-position', JSON.stringify(idx))
    if ($scope.visionOption === 'barcode') {
      zxingInit(deviceId)
    } else {
      stopStream()
      startStream()
    }
  }
  /**
   * Switch camera mode
   * @param {string} mode
   */
  $scope.switchMode = mode => {
    $scope.visionOption = mode
    $scope.imageCaptured = false
    if (mode === 'ocr') {
      try {
        stopStream()
      } catch (err) {
        console.error(err)
      } finally {
        startStream()
      }
    } else if (mode === 'barcode') {
      zxingInit($scope.selectedDeviceId)
    }
    $window.localStorage.removeItem(`vision-option-${key}`)
    $window.localStorage.setItem(`vision-option-${key}`, JSON.stringify(mode))
  }
  /**
   * Initialization function for vision dialog
   */
  const initVisionDialog = async function initVisionDialog () {
    console.log('initVisionDialog')
    $element[0].style.zIndex = 90
    $scope.visionOption = $rootScope.hasOcr
      ? JSON.parse($window.localStorage.getItem(`vision-option-${key}`)) ||
        'barcode'
      : 'barcode'
    $scope.optionsModel = { value: null, noDevice: false }
    $scope.cameraOptions = []
    $scope.selectedDeviceIndex =
      JSON.parse($window.localStorage.getItem('vision-camera-position')) || 0
    $scope.imageCaptured = false
    try {
      await getEnumerateDevices()
      if (
        _.isUndefined($scope.cameraOptions[$scope.selectedDeviceIndex]) ||
        _.isUndefined($scope.cameraOptions[$scope.selectedDeviceIndex].id)
      ) {
        console.log('device NOT found from local storage')
        $scope.selectedDeviceIndex = 0
        $scope.selectedDeviceId = $scope.cameraOptions[0].id
      } else {
        console.log('device found from local storage')
        $scope.selectedDeviceId =
          $scope.cameraOptions[$scope.selectedDeviceIndex].id
      }
      if ($scope.visionOption === 'barcode') {
        zxingInit($scope.selectedDeviceId)
      } else {
        startStream()
      }
    } catch (err) {
      console.error(err)
    }
    $timeout(() => {
      $scope.videoRendered = true
    }, 1000)
  }

  initVisionDialog()
}
module.exports = VisionDialogController
