/* global angular $ */
/** @ngInject */

function customMetabase ($compile, Rule) {
  return {
    restrict: 'C',
    link: function (scope, element, attrs) {
      const id = +new Date()
      const iframe = angular.element(
        '<iframe id="' + id + '" frameborder="0" allowtransparency></iframe>'
      )
      const attributes = element.prop('attributes')
      angular.forEach(attributes, function (attr) {
        iframe.attr(attr.name, attr.value)
      })
      iframe.attr('src', '')
      element.replaceWith(iframe)

      Rule.getMetabaseSignedIframe({
        data: JSON.parse(iframe.attr('ta-insert-video'))
      }).$promise.then(res => {
        $('#' + id).attr('src', res.result)
      })
    }
  }
}

module.exports = customMetabase
