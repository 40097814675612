/* global _ */
require('./tree-category-grid.scss')
/** @ngInject */
function treeCardGrid () {
  return {
    restrict: 'E',
    template: require('./tree-category-grid.html'),
    replace: true,
    scope: {
      categories: '=',
      items: '=',
      searchObject: '=?'
    },
    controller: [
      '$scope',
      '$stateParams',
      'KendoTreeViewHelper',
      ($scope, $stateParams, KendoTreeViewHelper) => {
        const getItemCatagories = function getItemCatagories (
          neededCategories
        ) {
          const allCategoryWithParents = []
          const getParents = categoryId => {
            allCategoryWithParents.push(categoryId)
            const category = $scope.categoryById[categoryId]
            if (category && category.parentId) getParents(category.parentId)
          }
          neededCategories.map(categoryId => getParents(categoryId))
          return _.uniq(allCategoryWithParents).map(
            categoryId => $scope.categoryById[categoryId]
          )
        }

        const getItemsWithCategory = function getItemsWithCategory () {
          $scope.hiddenObject = {}
          if (
            !Object.values($scope.selectedObject).every(
              value => value === false
            )
          ) {
            $scope.items.forEach(item => {
              if (
                item.categories.length === 0 ||
                item.categories.every(
                  categoryId => !$scope.selectedObject[categoryId]
                )
              ) {
                $scope.hiddenObject[item.id] = true
              }
            })
          }
        }

        const getAllRelatedIds = function getAllRelatedIds (category) {
          const ids = []
          if (Array.isArray(category.items) && category.items.length > 0) {
            category.items.forEach(innerCategory => {
              ids.push(innerCategory.id)
              ids.push(...getAllRelatedIds(innerCategory))
            })
          }
          return ids
        }

        $scope.selectCategory = function selectCategory ({ data }) {
          const value = !$scope.selectedObject[data.id]
          const ids = [data.id].concat(getAllRelatedIds(data))
          ids.forEach(categoryId => {
            $scope.selectedObject[categoryId] = value
          })
          getItemsWithCategory()
          $scope.$applyAsync()
        }

        const initComponent = function initComponent () {
          $scope.hiddenObject = {}
          $scope.categoryById = _.keyBy($scope.categories, 'id')
          $scope.selectedObject = {}
          const itemsCategories = []
          for (let i = 0; i < $scope.items.length; i++) {
            const item = $scope.items[i]
            itemsCategories.push(...item.categories)
          }

          const relevantCategories = getItemCatagories(itemsCategories)

          relevantCategories.forEach(category => {
            $scope.selectedObject[category.id] = false
          })

          $scope.categoryData = KendoTreeViewHelper.convertDBToTree(
            relevantCategories
          )

          if ($stateParams.selectedCategories) {
            const selectedCategories = JSON.parse(
              $stateParams.selectedCategories
            )
            selectedCategories.forEach(categoryId => {
              $scope.selectCategory({ data: { id: categoryId, items: [] } })
            })
          }
        }

        initComponent()
      }
    ]
  }
}

module.exports = treeCardGrid
