/* global _ */
/** @ngInject */
function ErrorInDataDialogController (
  locals,
  $scope,
  $translate,
  $mdDialog,
  title,
  wrongRecords
) {
  $scope.linesWithErrors = _.uniq(_.map(wrongRecords, 'recordNum')).sort(
    (a, b) => a - b
  )
  $scope.recordErrors = []
  $scope.title = title
  $scope.linesWithErrors.map(num => {
    const errorsForLine = wrongRecords.filter(record => record.recordNum === num)
    $scope.recordErrors.push({
      num: num,
      errors: errorsForLine
    })
  })

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.closeDialog = function () {
    $mdDialog.hide('')
  }
}

module.exports = ErrorInDataDialogController
