/* eslint-disable */
/** @ngInject */
function PartImageCacheController(
  $scope,
  $translate,
  htmlWork,
  PartLeafleat,
  ResolvedPart,
  getUrlFromObj,
  $location
) {
  $scope.getUrlFromObj = getUrlFromObj
  $scope.selectedPart = ResolvedPart.assembly
  $('ms-theme-options').hide()
  $scope.partStyle = {
    printed: {
      fillColor: '#ffff00',
      color: '#000',
      opacity: 0.5,
      fillOpacity: 0.1,
      weight: 1
    }
  }
  $scope.assemblyStyle = _.merge(_.cloneDeep($scope.partStyle), {
    printed: { fillColor: '#2E62B1' }
  })

  $scope.getMarkerZoom = () => {
    let widthProp = $('.leaflet-image-layer').css('width')
    if (widthProp) {
      let currentImageWidthInLeafleat = $('.leaflet-image-layer')
        .css('width')
        .replace('px', '')
        .trim()
      return currentImageWidthInLeafleat / $scope.lastBuild.img.width
    }
    return 1
  }

  $scope.fixMarkerSize = () => {
    let widthProp = $('.leaflet-image-layer').css('width')
    if (widthProp) {
      let markerZoom = $scope.getMarkerZoom()
      $('.minimap-marker').animate({ zoom: markerZoom })
    }
  }

  $scope.setMarkerOptions = () => {
    let newLabelHeight = $scope.selectedPart.options.hotspot_height || 'auto'
    let newLabelWidth = $scope.selectedPart.options.hotspot_wight || 'auto'
    let newLabelFontSize = $scope.selectedPart.options.hotspot_font_size || 14
    let newLabelOpacity =
      $scope.selectedPart.options.hotspot_opacity === 0
        ? 1
        : $scope.selectedPart.options.hotspot_opacity || 1

    $('.minimap-marker').css('height', newLabelHeight)
    $('.minimap-marker').css('width', newLabelWidth)
    $('.minimap-marker').css('fontSize', newLabelFontSize)
    $('.minimap-marker').parent().css('opacity', newLabelOpacity)
    $scope.fixMarkerSize()
  }

  $scope.getStyle = (number, type) => {
    let subpart = _.find($scope.selectedPart.nodes, { number: number })
    return subpart && subpart.is_assembly
      ? $scope.assemblyStyle['printed']
      : $scope.partStyle['printed']
  }

  $scope.getNameOfMarker = subPart => {
    let node = _.find($scope.selectedPart.nodes, { number: subPart.number })
    return node && node.label ? htmlWork.htmlEncode(node.label) : '-'
  }

  $scope.createDisplayLayers = () => {
    $scope.overlayMaps = {}
    let subpartLayerIds = _.uniq(_.map($scope.selectedPart.nodes, 'number'))
    subpartLayerIds.map((subpartLayerId, i) => {
      let layer = new L.featureGroup()
      let subPart = _.find($scope.selectedPart.nodes, {
        number: subpartLayerId
      })
      $scope.overlayMaps[
        $translate.instant('PartAssembly.LAYER') + (i + 1) + ': ' + subPart.name
      ] = layer
      let shapesInLayer = $scope.selectedPart.shapes.filter(
        shape => subpartLayerId === shape.number
      )
      shapesInLayer.map(sh => {
        if (sh.type === 'circle') {
          PartLeafleat.createCircle(
            sh.number,
            sh.latlngs,
            sh.radius,
            layer,
            $scope.getStyle(sh.number, 'default'),
            []
          )
        }
        if (sh.type === 'marker') {
          let id = (
            sh.number +
            '_' +
            sh.latlngs[0] +
            '_' +
            sh.latlngs[1]
          ).toString()
          let markerClass = 'catalog-marker '
          markerClass += subPart.is_assembly ? 'blue' : 'yellow'

          let markerData = {
            id: id,
            icon: markerClass,
            name: $scope.getNameOfMarker(subPart)
          }

          PartLeafleat.createMarker(
            sh.number,
            sh.latlngs,
            markerData,
            layer,
            $scope.getStyle(sh.number, 'default'),
            []
          )
        }
        if (sh.type === 'rectangle') {
          PartLeafleat.createReactangle(
            sh.number,
            sh.latlngs,
            layer,
            $scope.getStyle(sh.number, 'default'),
            []
          )
        }
        if (sh.type === 'polygon') {
          PartLeafleat.createPolygon(
            sh.number,
            sh.latlngs,
            layer,
            $scope.getStyle(sh.number, 'default'),
            []
          )
        }
      })
    })
    _.values($scope.overlayMaps).map(l => $scope.map.addLayer(l))
    return { baseMaps: $scope.baseMaps, overlayMaps: $scope.overlayMaps }
  }

  $scope.selectedPart.image_url = $scope.getUrlFromObj(
    $scope.selectedPart.image_object
  )

  if ($scope.selectedPart.image_url) {
    let img = new Image()
    img.src = $scope.selectedPart.image_url
    img.onload = () => {
      $scope.lastBuild = { img }
      let opt = {
        img: img,
        minimap: false,
        mapContainer: 'map',
        zoomControl: false
      }

      $('#map').height($location.$$search.h)
      $('#map').width($location.$$search.w)
      PartLeafleat.buildMap(opt)
        .then(({ map, overlay }) => {
          $scope.map = map
          $scope.overlay = overlay
        })
        .then(() => $scope.createDisplayLayers())
        .then(() =>
          setTimeout(() => {
            $scope.setMarkerOptions()
            $('#map').addClass('map-ready-to-screen')
          }, 2000)
        )
    }
  }
}

module.exports = {
  PartImageCacheController
}
