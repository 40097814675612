/* global angular $ _ */

import ErrorInDataDialogController from '../parts-import/error-in-data-dialog'

/** @ngInject */
function PartsBulkChangeController (
  $scope,
  $rootScope,
  $translate,
  PartAssembly,
  PermissionUtils,
  $mdDialog,
  Page
) {
  $scope.PermissionUtils = PermissionUtils
  Page.setTitleText($translate.instant('PartAssembly.BULK_CHANGE'))
  $scope.headerOptions = {
    icon: 'icon-import',
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('PartAssembly.BULK_CHANGE'),
    fabButton: {}
  }

  const AL = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]
  const partCustomFields = []
  $scope.buildAdditionalFields = () => {
    const columns = []
    _.mapKeys(
      $rootScope.appSettings.modelsFields.PartAssembly.properties,
      (value, key) => {
        if (value.custom) {
          partCustomFields.push(value.key)
          columns.push({
            value: $translate.instant('PartAssembly.' + value.key),
            background: 'rgb(167,214,255)',
            textAlign: 'center',
            field: value.key,
            color: 'rgb(0,62,117)',
            width: 150
          })
        }
      }
    )
    return columns
  }

  $scope.staticHeader = [
    {
      value: $translate.instant('PartAssembly.PART_NUMBER'),
      background: 'rgb(167,214,255)',
      textAlign: 'center',
      field: 'number',
      color: 'rgb(0,62,117)',
      width: 150
    },
    {
      value: $translate.instant('PartAssembly.PART_NAME'),
      background: 'rgb(167,214,255)',
      textAlign: 'center',
      field: 'name',
      color: 'rgb(0,62,117)',
      width: 150
    }
  ]

  $scope.additionalHeader = $scope.buildAdditionalFields()
  $scope.allHeader = $scope.staticHeader.concat($scope.additionalHeader)

  $scope.spreadsheetOptions = {
    columns: $scope.allHeader.length,
    rows: 10000,
    toolbar: false,
    sheetsbar: false,
    sheets: [
      {
        name: 'Import',
        rows: [
          {
            height: 25,
            cells: $scope.allHeader
          }
        ],
        columns: $scope.allHeader.map(header => ({ width: header.width }))
      }
    ]
  }

  $scope.getData = () => {
    const spreadsheet = $scope.spreadsheet
    const data = spreadsheet.toJSON()
    return data.sheets[0].rows.map(row => {
      const res = {}
      row.cells.forEach(cell => {
        const field = $scope.allHeader[cell.index].field
        res[field] = cell.value
      })
      return res
    })
  }

  $scope.showDialog = status => {
    const confirm = $mdDialog
      .confirm()
      .title($translate.instant('PartAssembly.IMPORT_FINISHED'))
      .targetEvent()
      .clickOutsideToClose(true)
      .parent(angular.element(document.body))
      .ok($translate.instant('PartAssembly.OK'))
    $mdDialog.show(confirm)
  }

  const checkValid = async function checkValid (data) {
    const errors = []
    data.forEach((record, num, arr) => {
      const indexInKendo = num + 2
      if (!record.number) {
        errors.push({
          recordNum: indexInKendo,
          message: $translate.instant('PartAssembly.IMPORT_ERRORS.PART_NUMBER')
        })
      }
      if (arr.filter(item => item.number === record.number).length > 1) {
        errors.push({
          recordNum: indexInKendo,
          message: $translate.instant(
            'PartAssembly.UPDATE_ERRORS.SAME_NUMBER_IN_UPDATE_EXCEL'
          )
        })
      }
    })
    return errors
  }

  $scope.updateParts = async () => {
    let partToUpdate = _.cloneDeep(_.tail($scope.getData()))
    const fieldList = _.map($scope.allHeader, 'field')
    partToUpdate = partToUpdate.filter(d =>
      _.some(fieldList, field => d[field])
    )
    const worngLines = await checkValid(partToUpdate)
    if (worngLines.length) {
      $mdDialog
        .show({
          controller: ErrorInDataDialogController,
          controllerAs: 'vm',
          template: require('../parts-import/error-in-data-dialog.html'),
          parent: angular.element(document.body),
          targetEvent: '',
          locals: {
            title: $translate.instant('PartAssembly.DIALOG_UPDATE_ERROR'),
            wrongRecords: worngLines
          },
          clickOutsideToClose: false
        })
        .then(
          function () {},
          function () {}
        )
    } else {
      partToUpdate = partToUpdate.map(part => {
        const returnObj = {
          number: part.number,
          name: part.name || ''
        }
        partCustomFields.forEach(key => {
          returnObj[key] = part[key] || null
        })
        return returnObj
      })
      try {
        $rootScope.loadingProgress = true
        const res = await PartAssembly.bulkChangeParts({
          data: partToUpdate
        }).$promise
        if (res && res.length > 0) {
          $mdDialog
            .show({
              controller: ErrorInDataDialogController,
              controllerAs: 'vm',
              template: require('../parts-import/error-in-data-dialog.html'),
              parent: angular.element(document.body),
              targetEvent: '',
              locals: {
                title: $translate.instant('PartAssembly.DIALOG_UPDATE_ERROR'),
                wrongRecords: _.compact(
                  res.map(part => {
                    const idx = partToUpdate.findIndex(
                      dataParts => dataParts.number === part.number
                    )
                    if (idx !== -1) {
                      return {
                        recordNum: idx + 2,
                        message: $translate.instant(
                          'PartAssembly.UPDATE_ERRORS.PART_NUMBER_DOSENT_EXISTS'
                        )
                      }
                    }
                    return null
                  })
                )
              },
              clickOutsideToClose: false
            })
            .then(
              function () {},
              function () {}
            )
        } else {
          // all parts updates
          const confirm = $mdDialog
            .confirm()
            .title($translate.instant('PartAssembly.BULK_CHANGE_FINISHED'))
            .targetEvent()
            .clickOutsideToClose(true)
            .parent(angular.element(document.body))
            .ok($translate.instant('PartAssembly.OK'))
          $mdDialog.show(confirm)
        }
        $rootScope.loadingProgress = false
      } catch (e) {
        console.log(e)
        $mdDialog.show(
          $mdDialog
            .alert()
            .parent(angular.element(document.body))
            .clickOutsideToClose(false)
            .title(
              $translate.instant(
                'PartAssembly.UPDATE_ERRORS.SERVER_ERROR_TITLE'
              )
            )
            .textContent(
              $translate.instant(
                'PartAssembly.UPDATE_ERRORS.SERVER_ERROR_MESSAGE'
              )
            )
            .ok($translate.instant('PartAssembly.OK'))
        )
        $rootScope.loadingProgress = false
      }
    }
  }
  $(document).ready(() => {
    $('#spreadsheet').kendoSpreadsheet($scope.spreadsheetOptions)
    $scope.spreadsheet = $('#spreadsheet').data('kendoSpreadsheet')
    const ran = 'A1:' + AL[$scope.allHeader.length - 1] + '1'
    const range = $scope.spreadsheet.activeSheet().range(ran)
    range.enable(false)
    let formatRan = 'A2:C9999'
    let formatRange = $scope.spreadsheet.activeSheet().range(formatRan)
    formatRange.format('@')
    formatRan = 'E2:' + AL[$scope.allHeader.length - 1] + '9999'
    formatRange = $scope.spreadsheet.activeSheet().range(formatRan)
    formatRange.format('@')
  })
}

module.exports = {
  PartsBulkChangeController
}
