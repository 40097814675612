/* global */
'use strict'
require('./quantityRow.scss')

/** @ngInject */
function quantityRow ($scope) {
  $scope.plus = () => {
    $scope.model[$scope.options.key]++
  }
  $scope.minus = () => {
    $scope.model[$scope.options.key]--
    if ($scope.model[$scope.options.key] < 0) {
      $scope.model[$scope.options.key] = 0
    }
  }
}

module.exports = quantityRow
