module.exports = /** @ngInject */ function config (
  $stateProvider,
  msNavigationServiceProvider
) {
  // State
  $stateProvider
    .state('app.parts', {
      abstract: true,
      url: '/parts'
    })
    .state('app.parts.list', {
      url: '/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'PartsListController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'catalog catalog-list'
    })
    .state('app.parts.new', {
      url: '/new?type',

      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.splitter" */ '@progress/kendo-ui/js/kendo.splitter.min'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      views: {
        'content@app': {
          template: require('./pages/parts-new-edit/parts-new-edit.html'),
          controller: 'PartsNewEditController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedPart: () => null,
        PartsIdsToBeDisabled: (PartAssembly, $stateParams) => {
          return []
        }
      }
    })
    .state('app.parts.import', {
      url: '/import',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.spreadsheet" */ '@progress/kendo-ui/js/kendo.spreadsheet.min.js'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      resolve: {
        ResolvedUnits: Unit =>
          Unit.find({ filter: { fields: { id: true, name: true } } }).$promise
      },
      views: {
        'content@app': {
          template: require('./pages/parts-import/parts-import.html'),
          controller: 'PartsImportController'
        }
      },
      bodyClass: 'catalog catalog-list'
    })
    .state('app.parts.bulk-change', {
      url: '/bulk-change',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.spreadsheet" */ '@progress/kendo-ui/js/kendo.spreadsheet.min.js'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      views: {
        'content@app': {
          template: require('./pages/parts-bulk-change/parts-bulk-change.html'),
          controller: 'PartsBulkChangeController'
        }
      },
      bodyClass: 'catalog catalog-list'
    })
    .state('app.parts.edit', {
      url: '/edit/:number',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.splitter" */ '@progress/kendo-ui/js/kendo.splitter.min'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      views: {
        'content@app': {
          template: require('./pages/parts-new-edit/parts-new-edit.html'),
          controller: 'PartsNewEditController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedPart: (PartAssembly, $stateParams) => {
          return PartAssembly.getWithSubParts({
            number: $stateParams.number,
            deep: 1
          }).$promise.then(data => {
            const part = data.result
            const { alternatives, convertible, combinationParts } = part
            const partNumbers = [
              ...new Set(
                (alternatives || [])
                  .concat(combinationParts || [])
                  .concat(convertible || [])
              )
            ]
            if (partNumbers.length > 0) {
              return PartAssembly.find({
                filter: {
                  where: {
                    number: { inq: partNumbers }
                  },
                  fields: ['id', 'name', 'number', 'recordId', 'picture']
                }
              }).$promise.then(parts => {
                if (Array.isArray(alternatives) && alternatives.length > 0) {
                  part.alternatives = parts.filter(part =>
                    alternatives.includes(part.number)
                  )
                } else {
                  part.alternatives = []
                }
                if (
                  Array.isArray(combinationParts) &&
                  combinationParts.length > 0
                ) {
                  part.combinationParts = parts.filter(part =>
                    combinationParts.includes(part.number)
                  )
                } else {
                  part.combinationParts = []
                }
                if (Array.isArray(convertible) && convertible.length > 0) {
                  part.convertible = parts.filter(part =>
                    convertible.includes(part.number)
                  )
                } else {
                  part.convertible = []
                }
                return part
              })
            }
            return part
          })
        }
      }
    })
    .state('app.part-cache', {
      url: '/part-cache/:number',
      views: {
        'main@': {
          template: require('app/modules/core/layouts/content-only.html'),
          controller: 'MainController as vm'
        },
        'content@app.part-cache': {
          template: require('./pages/part-view-cache/part-view-cache.html'),
          controller: 'PartImageCacheController as vm'
        }
      },
      resolve: {
        ResolvedPart: (PartAssembly, $stateParams) => {
          console.log($stateParams)
          return PartAssembly.getWithSubParts({
            number: $stateParams.number,
            deep: 1,
            withResources: true,
            flatten: false,
            id: $stateParams.id
          }).$promise.then(data => ({ assembly: data.result }))
        }
      }
    })
    .state('app.parts.stock', {
      url: '/stock-management/:number/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'StockManagementController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'part stock-management',
      resolve: {
        ResolvedPart: (PartAssembly, $stateParams) => {
          return new Promise(async resolve => {
            const partObject = await PartAssembly.getWithSubParts({
              number: $stateParams.number,
              deep: 1
            }).$promise
            const part = partObject.result
            if (part.isCombinated) {
              const parts = await PartAssembly.find({
                filter: {
                  where: {
                    number: { inq: part.combinationParts }
                  },
                  fields: { recordId: true, number: true, name: true }
                }
              }).$promise
              part.combinationParts = parts
            }
            resolve(part)
          })
        }
      }
    })
    .state('app.parts.units', {
      url: '/units/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('app/modules/components/tables/kendo-grid/grid.html'),
          controller: 'PartUnitsController',
          controllerAs: 'vm'
        }
      },
      bodyClass: 'part-units'
    })
    .state('app.parts.view', {
      url: '/view/:number',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.splitter" */ '@progress/kendo-ui/js/kendo.splitter.min'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      views: {
        'content@app': {
          template: require('./pages/parts-view/part-view.template.html'),
          controller: 'PartViewController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedPart: (PartAssembly, $stateParams, FormlyHelper) => {
          const cfields = FormlyHelper.getCustomFields(PartAssembly).reduce(
            (acc, field) => {
              acc[field.key] = true
              return acc
            },
            {}
          )
          return PartAssembly.findOne({
            filter: {
              where: {
                number: $stateParams.number
              },
              fields: {
                id: true,
                name: true,
                number: true,
                _documents: true,
                picture: true,
                ...cfields
              },
              include: ['documents']
            }
          }).$promise
        }
      }
    })
  // Translation
  // $translatePartialLoaderProvider.addPart('app/main/apps/parts');

  // Navigation
  msNavigationServiceProvider.saveItem('apps.modeling.parts', {
    title: 'PartAssembly.Parts',
    icon: 'icon-codepen',
    weight: 10,
    acls: [{ model: 'PartAssembly', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.parts.all', {
    title: 'PartAssembly.Parts',
    state: 'app.parts.list',
    icon: 'icon-codepen',
    weight: 1,
    acls: [{ model: 'PartAssembly', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.parts.units', {
    title: 'PartAssembly.UNITS.TITLE',
    state: 'app.parts.units',
    icon: 'icon-tape-measure',
    weight: 2,
    acls: [{ model: 'Unit', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.parts.import', {
    title: 'PartAssembly.Import',
    state: 'app.parts.import',
    icon: 'icon-import',
    weight: 3,
    acls: [{ model: 'PartAssembly', method: 'create' }]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.parts.bulk-change', {
    title: 'PartAssembly.BULK_CHANGE',
    state: 'app.parts.bulk-change',
    icon: 'icon-import',
    hidden: true,
    weight: 4,
    acls: [{ model: 'PartAssembly', method: 'patchAttributes' }]
  })
}
