/** @ngInject */
function userAvatar () {
  require('./user-avatar.scss')
  return {
    template: require('./user-avatar.html'),
    bindings: {
      userId: '<',
      displayName: '<',
      maxWidth: '<',
      showNameInline: '<',
      hideTooltip: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function UserAvatar ($element) {
        this.loading = true
        this.$onInit = function () {
          const maxWidth = (this.maxWidth = this.maxWidth
            ? this.maxWidth
            : '40')
          const userAvatarDiv = $element[0].querySelector('.avatar-wrapper')
          userAvatarDiv.style.width = `${maxWidth}px`
          userAvatarDiv.style.height = `${maxWidth}px`
          userAvatarDiv.style.minWidth = `${maxWidth}px`
          userAvatarDiv.style.marginInlineStart = `${(maxWidth / 3) * -1}px`

          const imgElm = document.createElement('img')
          const imageFinishLoaded = () => {
            this.loading = false
            // const imgTag = $element[0].querySelector('img')
            // imgTag.style.backgronudSize = `auto ${maxWidth}px`
            // imgTag.style.width = `${maxWidth}px`
            // imgTag.style.height = `${maxWidth}px`
          }
          imgElm.onload = () => {
            this.image = true
            imageFinishLoaded()
          }
          imgElm.onerror = () => {
            this.image = false
            imageFinishLoaded()
          }
          this.imgSrc = imgElm.src = `./api/UserModels/getAvatar?userId=${this.userId}`
        }
      }
  }
}

module.exports = userAvatar
