/** @ngInject */

function EmbeddedForumDirective (Post) {
  return {
    restrict: 'EA',
    scope: {
      where: '=',
      openPosition: '@openPosition'
    },
    link: async function (scope, element, attrs) {
      scope.createForum(element)
    },
    /** @ngInject */
    controller: ($scope, $mdPanel, $compile) => {
      $scope.$watch('where', function () {
        $scope.update()
      })
      $scope.postCount = 0
      $scope.openForum = ev => {
        var position = $mdPanel
          .newPanelPosition()
          .relativeTo('.embedded-forum-fab-button')
          .addPanelPosition(
            $mdPanel.xPosition.ALIGN_END,
            $mdPanel.yPosition.ABOVE
          )
        $mdPanel.open({
          /** @ngInject */
          controller: ($scope, where, update, mdPanelRef) => {
            $scope.where = where
            $scope.update = update
            $scope.close = () => {
              mdPanelRef.close()
            }
          },
          hasBackdrop: true,
          position: position,
          trapFocus: true,
          zIndex: 79,
          clickOutsideToClose: true,
          escapeToClose: true,
          focusOnOpen: true,
          template: require('./embedded-forum-dialog.html'),
          targetEvent: ev,
          locals: {
            where: $scope.where,
            update: $scope.update
          }
        })
      }
      $scope.update = () => {
        console.log('Update post count')
        return new Promise(async (resolve, reject) => {
          try {
            let response = await Post.count({ where: $scope.where }).$promise
            $scope.postCount = response.count
            resolve($scope.postCount)
          } catch (err) {
            reject(err)
          }
        })
      }
      $scope.createForum = async element => {
        let html = `
<md-button
ng-click="openForum($event)"
aria-label="menu"
class="md-fab md-warn embedded-forum-fab-button"
>
<span style="color:white; font-size: 16px" class="icon icon-comment-processing"></span>
<span style="font-size: 16px;">{{postCount}}</span>
</md-icon>
</md-button>
`
        element.replaceWith($compile(html)($scope))
      }
    }
  }
}

module.exports = EmbeddedForumDirective
