/* global _ angular */
'use strict'

require('./phoneInput.scss')
/** @ngInject */
function phoneInput ($scope, $timeout) {
  $scope.label = $scope.to.label
  $scope.isRequired = $scope.to.required
  const valueFromModel = $scope.model[$scope.options.key]
  $scope.countryCodes = require('./phoneCodes.json')

  $scope.changeValue = function changeValue () {
    if (!$scope.phoneModel.phone || !$scope.phoneModel.code) {
      $scope.model[$scope.options.key] = null
    } else {
      $scope.model[$scope.options.key] =
        $scope.phoneModel.code + '_' + $scope.phoneModel.phone
    }
  }

  $scope.refresh = function refresh () {
    return $timeout(function () {
      $scope.$broadcast('$md-resize')
    }, 100)
  }

  $scope.getFlagHtml = function getFlagHtml (code) {
    if (code) {
      const country = _.find($scope.countryCodes, { dial_code: code })
      const countryFlag = `assets/images/flags/${country.code.toLowerCase()}.png`
      return `<img class="country-flag-value" src="${countryFlag}"/> <span class="country-dial-code">${country.dial_code}</span>`
    }
  }

  $scope.searchTerm = ''

  $scope.countryToDisplay = angular.copy($scope.countryCodes)

  const checkFilter = function checkFilter () {
    if (!$scope.searchTerm) {
      return angular.copy($scope.countryCodes)
    } else {
      return angular
        .copy($scope.countryCodes)
        .filter(
          country =>
            country.name
              .toLowerCase()
              .includes($scope.searchTerm.toLowerCase()) ||
            country.dial_code
              .toLowerCase()
              .includes($scope.searchTerm.toLowerCase())
        )
    }
  }

  $scope.$watch('searchTerm', function (newValue, oldValue) {
    $scope.countryToDisplay = checkFilter()
  })

  $scope.phoneModel = {
    code:
      valueFromModel && valueFromModel !== ''
        ? valueFromModel.split('_')[0]
        : '+972',
    phone:
      valueFromModel && valueFromModel !== ''
        ? parseInt(valueFromModel.split('_')[1])
        : ''
  }
}

module.exports = phoneInput
