/* global angular _ $ Image L */
import { move } from 'app/helper'
const UUID = require('uuid')
const MapHelper = require('./map-helper.js')
/** @ngInject */
function mapEditor () {
  require('./map-editor.scss')
  return {
    template: require('./map-editor.html'),
    bindings: {
      resourceId: '<',
      handleInsert: '<',
      shapes: '<',
      options: '<',
      layers: '<',
      hasValueByLayer: '<',
      idProperty: '<',
      shapeOptions: '<',
      functions: '<',
      viewMode: '<',
      isDisabled: '<?'
    },
    controller:
      /**
       * @ngInject
       */
      function mapEditor (
        $rootScope,
        $scope,
        $translate,
        $compile,
        $mdPanel,
        $mdDialog,
        ResourceUtils
      ) {
        const miniMapOptions = {
          zoomLevelFixed: 0,
          class: 'minimapclass',
          type: 'minimap',
          position: 'bottomright'
        }

        const shapeStyle = {
          DEFAULT: {
            fillColor: '#FFFF00',
            color: 'black',
            opacity: 0.7,
            fillOpacity: 0.5,
            weight: 1
          },
          SELECTED: {
            fillColor: '#2E62B1',
            color: 'white',
            opacity: 0.7,
            fillOpacity: 0.6,
            weight: 1
          },
          HAS_VALUE: {
            fillColor: '#f28b4c',
            color: 'black',
            opacity: 0.7,
            fillOpacity: 0.5,
            weight: 1
          }
        }

        const onClickShape = function onClickShape (ev) {
          const layerId = ev.target.layerId
          const layer = $scope.layers.find(l => l.id === layerId)
          if (
            !$scope.viewMode ||
            ($scope.viewMode &&
              (!$scope.selectedLayer || $scope.selectedLayer.id !== layerId))
          ) {
            selectLayer(layer)
          }
          if (
            $scope.viewMode &&
            $scope.functions &&
            typeof $scope.functions.onClickShape === 'function'
          ) {
            $scope.functions.onClickShape(layer)
          }
        }

        $scope.onClickShapeFromTable = function (layerId, ev) {
          ev.stopPropagation()
          ev.preventDefault()
          if (!$scope.viewMode) return
          const layer = $scope.layers.find(l => l.id === layerId)
          if (!$scope.selectedLayer || $scope.selectedLayer.id !== layerId) {
            selectLayer(layer)
          }
          if (
            $scope.viewMode &&
            $scope.functions &&
            typeof $scope.functions.onClickShape === 'function'
          ) {
            $scope.functions.onClickShape(layer)
          }
        }

        const onContextMenuShape = function onContextMenuShape (ev) {
          if ($scope.viewMode) {
            return
          }
          const position = $mdPanel
            .newPanelPosition()
            .absolute()
            .top(ev.originalEvent.clientY + 'px')
            .left(ev.originalEvent.clientX + 'px')
          const layerId = ev.target.layerId
          const layer = $scope.layers.find(l => l.id === layerId)
          const config = {
            attachTo: angular.element(document.body),
            controller: require('./context-menu/map-editor-context-menu.js'),
            template: require('./context-menu/map-editor-context-menu.html'),
            panelClass: 'demo-menu-example',
            position: position,
            locals: {
              ev: ev,
              layerId: layerId,
              layer,
              selectLayer: selectLayer,
              unselectLayer: unselectLayer,
              enableEditMode: $scope.enableEditMode,
              selectedLayer: $scope.selectedLayer
            },
            multiple: true,
            openFrom: ev,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: false,
            zIndex: 100
          }
          if (!$scope.editMode) {
            $mdPanel.open(config)
          }
        }

        const getShapeStyleByLayerId = function getShapeStyleByLayerId (
          layerId
        ) {
          let currentShapeStyle = shapeStyle.DEFAULT
          if ($scope.viewMode && $scope.hasValueByLayer[layerId]) {
            currentShapeStyle = shapeStyle.HAS_VALUE
          }
          return currentShapeStyle
        }

        const createShape = function createShape (shape, layer) {
          const events = [
            { name: 'click', fn: onClickShape },
            { name: 'contextmenu', fn: onContextMenuShape }
          ]
          const currentShapeStyle = getShapeStyleByLayerId(layer.id)
          if (shape && shape.type) {
            switch (shape.type) {
              case 'circle':
                return $scope.mapInstance.createGenericCircle(
                  shape.layerId,
                  shape.latlngs,
                  shape.radius,
                  currentShapeStyle,
                  events
                )
              case 'marker': {
                const markerData = {
                  id: `${shape.layerId}_${shape.latlngs[0]}_${shape.latlngs[1]}`,
                  icon: 'catalog-marker blue',
                  name: layer.name
                }
                const inlineStyle = getInlineStyle()
                const markerShape = $scope.mapInstance.createGenericMarker(
                  shape.layerId,
                  shape.latlngs,
                  markerData,
                  events,
                  inlineStyle
                )
                return markerShape
              }

              case 'rectangle':
                return $scope.mapInstance.createGenericReactangle(
                  shape.layerId,
                  shape.latlngs,
                  currentShapeStyle,
                  events
                )
              case 'polygon':
                return $scope.mapInstance.createGenericPolygon(
                  shape.layerId,
                  shape.latlngs,
                  currentShapeStyle,
                  events
                )
            }
          }
        }

        const getMarkerZoom = function getMarkerZoom () {
          const widthProp = $('.leaflet-image-layer').css('width')
          if (widthProp) {
            const currentImageWidthInLeafleat = $('.leaflet-image-layer')
              .css('width')
              .replace('px', '')
              .trim()
            return currentImageWidthInLeafleat / $scope.imageObject.width
          }
          return 1
        }

        const getInlineStyle = function getInlineStyle () {
          let result = ''
          if (!$scope.options) $scope.options = {}
          const newLabelHeight = $scope.options.hotspot_height
            ? $scope.options.hotspot_height + 'px'
            : 'auto'
          const newLabelWidth = $scope.options.hotspot_wight
            ? $scope.options.hotspot_wight + 'px'
            : 'auto'
          const newLabelFontSize = $scope.options.hotspot_font_size || '14px'
          const newLabelZoom = getMarkerZoom()

          result = result + `height:${newLabelHeight};`
          result = result + `width:${newLabelWidth};`
          result = result + `font-size:${newLabelFontSize}px;`
          result = result + `zoom:${newLabelZoom};`

          return result
        }

        const fixMarkerSize = function fixMarkerSize () {
          const widthProp = $('.leaflet-image-layer').css('width')
          if (widthProp) {
            const markerZoom = getMarkerZoom()
            $('.map-panel .minimap-marker').css({ zoom: markerZoom })
          }
        }

        const setMarkerOptions = function setMarkerOptions () {
          if (!$scope.options) $scope.options = {}

          const newLabelHeight = $scope.options.hotspot_height
            ? $scope.options.hotspot_height + 'px'
            : 'auto'
          const newLabelWidth = $scope.options.hotspot_wight
            ? $scope.options.hotspot_wight + 'px'
            : 'auto'
          const newLabelFontSize = $scope.options.hotspot_font_size || '16px'
          const newLabelOpacity =
            $scope.options.hotspot_opacity === 0
              ? 1
              : $scope.options.hotspot_opacity || 1

          $('.map-panel .minimap-marker').css('height', newLabelHeight)
          $('.map-panel .minimap-marker').css('width', newLabelWidth)
          $('.map-panel .minimap-marker').css('fontSize', newLabelFontSize)
          $('.map-panel .minimap-marker')
            .parent()
            .css('opacity', newLabelOpacity)
          fixMarkerSize()
        }

        const createCustomControl = function createCustomControl () {
          $scope.customControlFn = L.Control.extend({
            options: {
              position: 'topleft'
            },
            onAdd: function (map) {
              const container = L.DomUtil.create(
                'div',
                'leaflet-bar leaflet-control leaflet-control-custom'
              )
              container.style.backgroundColor = 'white'
              container.innerHTML = ` <div ng-click="disableEditMode()" style="display: inline-block; border-right: 1px solid gainsboro" class="icon-save-btn">
                                        <i style="font-size: 18px;" class="icon icon-content-save"></i>
                                      </div>
                                      <div ng-click="disableEditMode('cancel')" style="display: inline-block;" class="icon-save-btn">
                                        <i style="font-size: 18px;" class="icon icon-cancel"></i>
                                      </div>`
              container.style.backgroundSize = '30px 30px'
              container.style.width = '68px'
              container.style.height = '30px'
              container.style.position = 'absolute'
              container.style.left = '50px'
              container.style.top = '0px'
              const h = $compile(container)($scope)
              return h[0]
            }
          })
          $scope.customControl = new $scope.customControlFn() //eslint-disable-line
          $scope.map.addControl($scope.customControl)
        }

        const removeCustomControl = function removeCustomControl () {
          $scope.map.removeControl($scope.customControl)
        }

        const createShapeObject = function createShapeObject (template, layer) {
          if (!template || !template.type) return null
          switch (template.type) {
            case 'marker':
            case 'circle':
              {
                const { lat, lng } = $scope.mapInstance.convertToImageCoords({
                  blat: layer._latlng.lat,
                  blng: layer._latlng.lng
                })
                template.latlngs = [lat, lng]
              }
              break
            case 'rectangle':
            case 'polygon':
              {
                const latlngs = layer._latlngs.map(lll => {
                  return lll.map(ll => {
                    const {
                      lat,
                      lng
                    } = $scope.mapInstance.convertToImageCoords({
                      blat: ll.lat,
                      blng: ll.lng
                    })
                    return { lat, lng }
                  })
                })
                template.latlngs = latlngs
              }
              break
          }
          if (template.type === 'circle') {
            template.radius = layer._mRadius
          }
          return template
        }

        const addMapEvents = function addMapEvents () {
          $scope.map.on('overlayadd', e => {
            setMarkerOptions()
          })
          $scope.map.on('overlayremove', e => {
            setMarkerOptions()
          })
          $scope.map.on('zoomend', function (e) {
            setMarkerOptions()
          })
          $scope.map.on(L.Draw.Event.CREATED, function (e) {
            const type = e.layerType
            const layer = e.layer
            const layerId = $scope.selectedLayer.id
            layer.layerId = layerId
            layer.tmp = true
            layer.layerType = e.layerType
            const newShapeTemplate = {
              id: UUID(),
              type: type,
              tmp: true,
              layerId: layerId
            }
            const newShape = createShapeObject(newShapeTemplate, layer)
            $scope.shapes.push(newShape)
            if ($scope.drawnLayers) {
              $scope.drawnLayers.addLayer(layer)
            }
          })
          $scope.map.on(L.Draw.Event.EDITSTART, function (e) {
            console.log('EDITSTART')
            removeCustomControl()
          })
          $scope.map.on(L.Draw.Event.EDITSTOP, function (e) {
            console.log('EDITSTOP')
            createCustomControl()
          })
          $scope.map.on(L.Draw.Event.DELETESTART, function (e) {
            removeCustomControl()
          })
          $scope.map.on(L.Draw.Event.DELETESTOP, function (e) {
            createCustomControl()
          })
          $(window).on('resize', function () {
            resizeMapContainer()
          })
        }

        const createDisplayLayers = function createDisplayLayers () {
          $scope.overlayMaps = {}
          $scope.layerMap = {}
          for (let i = 0; i < $scope.layers.length; i++) {
            const layerObject = $scope.layers[i]
            const layerId = layerObject[$scope.idProperty]
            const layerIndex = i + 1
            const layer = new L.featureGroup() //eslint-disable-line
            layer.layerId = layerId
            $scope.layerMap[layerObject.id] = layer
            $scope.overlayMaps[
              `${$translate.instant('MAP_EDITOR.LAYER')} ${layerIndex}: ${
                layerObject.name
              }`
            ] = layer
            const shapesInLayer = $scope.shapes.filter(
              shape => layerId === shape.layerId
            )
            for (let j = 0; j < shapesInLayer.length; j++) {
              const shape = shapesInLayer[j]
              const createdShape = createShape(shape, layerObject)
              if (shape.id) {
                createdShape.id = shape.id
              } else {
                createdShape.id = UUID()
              }
              createdShape.addTo(layer)
            }
          }
          _.values($scope.overlayMaps).map(l => $scope.map.addLayer(l))
        }

        const selectLayer = function selectLayer (layer) {
          if ($scope.editMode) {
            return
          }
          if ($scope.selectedLayer && $scope.selectedLayer.id === layer.id) {
            unselectLayer()
            return
          }
          unselectLayer()
          $scope.selectedLayer = layer

          $scope.map.eachLayer(l => {
            if (l.layerId === layer.id) {
              if (l.layerType === 'marker') {
                $(`[layer-id="${l.layerId}"]`).addClass('selected')
              } else {
                l.setStyle(shapeStyle.SELECTED)
              }
            }
          })
          $scope.$applyAsync()
        }

        const unselectLayer = function unselectLayer () {
          $scope.selectedLayer = null
          if ($scope.editMode === false) {
            $scope.map.eachLayer(l => {
              if (l._leaflet_id !== $scope.overlay._leaflet_id && l.layerType) {
                if (l.layerType === 'marker') {
                  if (l.layerId) {
                    $(`[layer-id="${l.layerId}"]`).removeClass('selected')
                  }
                } else {
                  const style = getShapeStyleByLayerId(l.layerId)
                  l.setStyle(style)
                }
              }
            })
            $scope.$applyAsync()
          }
        }

        const removeAllLayers = function removeAllLayers () {
          $scope.map.eachLayer(l => {
            if (l._leaflet_id !== $scope.overlay._leaflet_id) {
              $scope.map.removeLayer(l)
            }
          })
        }

        const creteDrawLayer = function creteDrawLayer (layer) {
          if ($scope.editLayer) {
            $scope.map.removeLayer($scope.editLayer)
          }
          $scope.editLayer = new L.featureGroup() //eslint-disable-line
          for (let i = 0; i < $scope.shapes.length; i++) {
            const shape = $scope.shapes[i]
            if (shape.layerId === layer.id) {
              const createdShape = createShape(shape, layer)
              if (shape.id) {
                createdShape.id = shape.id
              } else {
                createdShape.id = UUID()
              }
              createdShape.addTo($scope.editLayer)
            }
          }
          $scope.drawnLayers = $scope.editLayer
          $scope.map.addLayer($scope.drawnLayers)
        }

        const addOtherLayers = function addOtherLayers (newOverlay) {
          const layers = _.values(newOverlay)
          layers.map(layer => $scope.map.addLayer(layer))
        }

        const createDrawControl = function createDrawControl (layer) {
          const markerClass = 'catalog-marker blue'
          const inlineStyle = getInlineStyle()
          const options = {
            position: 'topright',
            draw: {
              polyline: false,
              polygon: {
                shapeOptions: shapeStyle.DEFAULT
              },
              circle: {
                shapeOptions: shapeStyle.DEFAULT
              },
              rectangle: {
                shapeOptions: shapeStyle.DEFAULT
              },
              circlemarker: false,
              marker: {
                icon: L.divIcon({
                  iconSize: ['fit-content', 'fit-content'],
                  type: 'dom',
                  html: `<div layer-id="${layer.id}" style='${inlineStyle}' class="minimap-marker ${markerClass}">${layer.name}</div>`
                })
              }
            },
            edit: {
              featureGroup: $scope.drawnLayers
            }
          }
          if ($scope.shapeOptions && $scope.shapeOptions.length) {
            const optionNames = Object.keys(options.draw)
            optionNames.forEach(optionName => {
              if (
                options.draw[optionName] &&
                !$scope.shapeOptions.includes(optionName)
              ) {
                options.draw[optionName] = false
              }
            })
          }
          $scope.drawControl = new L.Control.Draw(options)
          $scope.map.addControl($scope.drawControl)
        }

        $scope.deleteLayer = async function deleteLayer (layerId, ev) {
          ev.stopPropagation()
          ev.preventDefault()
          $mdDialog
            .show(
              $mdDialog
                .confirm()
                .title(
                  $translate.instant(
                    'MAP_EDITOR.LAYERS_TABLE.DELETE_LAYER_MESSAGE'
                  )
                )
                .multiple(true)
                .clickOutsideToClose(true)
                .parent(angular.element(document.body))
                .ok($translate.instant('BUTTONS.DELETE'))
                .cancel($translate.instant('BUTTONS.CANCEL'))
            )
            .then(
              function () {
                if (
                  $scope.selectedLayer &&
                  $scope.selectedLayer.id === layerId
                ) {
                  unselectLayer()
                }
                if ($scope.layerMap[layerId]) {
                  _.remove($scope.shapes, { layerId: layerId })
                  const layer = $scope.layerMap[layerId]
                  Object.keys($scope.overlayMaps).forEach(key => {
                    if ($scope.overlayMaps[key].layerId === layerId) {
                      delete $scope.overlayMaps[key]
                    }
                  })
                  $scope.layerControl.removeLayer(layer)
                  $scope.map.removeLayer(layer)
                  delete $scope.layerMap[layerId]
                  $scope.$applyAsync()
                }
                _.remove($scope.layers, { id: layerId })
              },
              function () {}
            )
        }

        $scope.disableEditMode = async function disableEditMode (isCancel) {
          removeAllLayers()
          if (isCancel) {
            _.remove($scope.shapes, { tmp: true })
          } else {
            _.remove($scope.shapes, {
              layerId: $scope.selectedLayer.id
            })
            $scope.drawnLayers.eachLayer(layer => {
              const shapeTemplate = {
                id: layer.id,
                type: layer.layerType,
                layerId: layer.layerId
              }
              const shape = createShapeObject(shapeTemplate, layer)
              $scope.shapes.push(shape)
            })
          }

          $scope.shapes = $scope.shapes.map(shape => _.omit(shape, 'tmp'))
          $scope.editMode = false
          if (
            $scope.functions &&
            typeof $scope.functions.onFinishEditLayer === 'function'
          ) {
            $scope.functions.onFinishEditLayer()
          }
          unselectLayer()
          removeAllLayers()
          createDisplayLayers()

          $scope.map.removeControl($scope.layerControl)

          $scope.layerControl = await $scope.mapInstance.createLayerControl(
            $scope.baseMaps,
            $scope.overlayMaps
          )

          $scope.map.removeControl($scope.drawControl)
          removeCustomControl()
          setMarkerOptions()
        }

        $scope.enableEditMode = async function enableEditMode (layerId, type) {
          if ($scope.editMode) {
            return
          }
          if (
            $scope.selectedLayer &&
            $scope.selectedLayer.id === layerId &&
            type !== 'fromTable'
          ) {
            return
          }
          unselectLayer()
          const layer = $scope.layers.find(layer => layer.id === layerId)
          if (layer) {
            removeAllLayers()
            selectLayer(layer)
          }
          $scope.editMode = true
          if (
            $scope.functions &&
            typeof $scope.functions.onStartEditLayer === 'function'
          ) {
            $scope.functions.onStartEditLayer()
          }
          $scope.map.removeControl($scope.layerControl)
          const newOverlay = {}
          _.mapKeys($scope.overlayMaps, (value, key) => {
            if (value.layerId !== layerId) {
              newOverlay[key] = value
            }
          })
          $scope.layerControl = await $scope.mapInstance.createLayerControl(
            $scope.baseMaps,
            newOverlay
          )
          creteDrawLayer(layer)
          addOtherLayers(newOverlay)
          createDrawControl(layer)
          createCustomControl()
          if (type === 'fromMap') {
            document.querySelector('.leaflet-draw-edit-edit').click()
          }
        }

        $scope.showLayer = function showLayer (layerId) {
          const layer = $scope.layers.find(l => l.id === layerId)
          selectLayer(layer)
        }

        $scope.addNewLayer = function addNewLayer () {
          if (!$scope.handleInsert) return
          const layerId = UUID()
          $scope.layers.push({
            id: layerId,
            name: ''
          })
        }

        const prepareMapForSave = function prepareMapForSave () {
          const obj = {
            resourceId: $scope.resourceId
          }
          obj.shapes = $scope.shapes.map(shape => ({
            id: shape.id || UUID(),
            layerId: shape.layerId,
            type: shape.type,
            latlngs: shape.latlngs,
            radius: shape.radius || null
          }))
          obj.layers = _.cloneDeep($scope.layers)
          return obj
        }

        const setFunctionHelper = function setFunctionHelper () {
          $scope.functions.getMap = prepareMapForSave
        }

        const initEditor = async function initEditor () {
          $rootScope.loadingProgress = true
          if (_.isNil($scope.resourceId)) return
          const resourceLink = await ResourceUtils.getLink($scope.resourceId)
          if (!resourceLink) return
          const img = new Image()
          img.src = resourceLink
          img.onload = async () => {
            $scope.imageObject = img
            console.log('Map editor image loaded')
            const opt = {
              img: img,
              minimap: false,
              mapContainer: $scope.mapId,
              miniMapOptions: miniMapOptions,
              miniMapContainer: 'mini-map-container'
            }
            if ($scope.mapInstance) {
              $scope.mapInstance.destroyMap()
            }
            $scope.mapInstance = await MapHelper().MapInstance(opt, $scope)
            const { map, overlay } = await $scope.mapInstance.buildMap(
              $scope.mapId
            )
            $scope.map = map
            $scope.overlay = overlay
            createDisplayLayers()
            const control = await $scope.mapInstance.createLayerControl(
              $scope.baseMaps,
              $scope.overlayMaps
            )
            $scope.layerControl = control
            $scope.map.whenReady(addMapEvents)
            setMarkerOptions()
            $rootScope.loadingProgress = false
            $scope.$applyAsync()
          }
          img.onerror = () => {
            $rootScope.loadingProgress = false
            $scope.$applyAsync()
          }
        }

        const debounceInit = _.debounce(initEditor, 200)

        const resizeMapContainer = _.debounce(() => {
          const w = $scope.viewMode
            ? $('.map-content').width() - 4
            : $('.map-container').width() - 4
          const h = $scope.viewMode
            ? $('.map-content').height() - 4
            : $('.map-container').height() - 4
          $scope.mapInstance.resizeLeafLeat(w, h)
        }, 500)

        this.$onChanges = async changeObj => {
          if (changeObj) {
            if (!_.isUndefined(changeObj.resourceId)) {
              $scope.resourceId = changeObj.resourceId.currentValue
            }
            if (
              !_.isUndefined(changeObj.layers) &&
              changeObj.layers.currentValue
            ) {
              $scope.layers = changeObj.layers.currentValue
            }
            if (
              !_.isUndefined(changeObj.shapes) &&
              changeObj.shapes.currentValue
            ) {
              $scope.shapes = changeObj.shapes.currentValue
            }
            if (
              !_.isUndefined(changeObj.idProperty) &&
              changeObj.idProperty.currentValue
            ) {
              $scope.idProperty = changeObj.idProperty.currentValue
            }
            if (
              !_.isUndefined(changeObj.options) &&
              changeObj.options.currentValue
            ) {
              $scope.options = changeObj.options.currentValue
            }
            if (
              !_.isUndefined(changeObj.hasValueByLayer) &&
              changeObj.hasValueByLayer.currentValue
            ) {
              $scope.hasValueByLayer = changeObj.hasValueByLayer.currentValue
            }
            if (
              !_.isUndefined(changeObj.handleInsert) &&
              changeObj.handleInsert.currentValue
            ) {
              $scope.handleInsert = changeObj.handleInsert.currentValue
            }
            debounceInit()
          }
        }

        this.$onInit = async function () {
          const that = this
          $scope.mapId = UUID()
          $scope.resourceId = that.resourceId
          $scope.mapDisabled = that.isDisabled || false
          $scope.handleInsert = that.handleInsert || false
          $scope.viewMode = that.viewMode || false
          $scope.layers = that.layers || []
          $scope.hasValueByLayer = that.hasValueByLayer || {}
          $scope.idProperty = that.idProperty || 'id'
          $scope.shapes = that.shapes || []
          $scope.options = that.options || {
            hotspot_height: 60,
            hotspot_wight: 60,
            hotspot_font_size: 25,
            hotspot_opacity: ''
          }
          $scope.shapeOptions = that.shapeOptions || [
            'polyline',
            'polygon',
            'circle',
            'rectangle',
            'circlemarker',
            'marker'
          ]
          $scope.endHandler = function endHandler (e) {
            e.preventDefault()
            console.log(`oldIndex ${e.oldIndex} newIndex ${e.newIndex}`)
            $scope.layers = move($scope.layers, e.oldIndex, e.newIndex)
          }
          $scope.sortableOptions = {
            filter: 'tbody tr',
            handler: '.handler',
            ignore: 'input,button',
            cursor: 'move',
            disabled: '.disabled-area',
            placeholder: function (element) {
              return element
                .clone()
                .removeAttr('uid')
                .addClass('k-state-hover')
                .css('opacity', 0.4)
                .css('background-color', '#dcdcdc')
            },
            container: 'tbody',
            change: function (e) {}
          }
          $scope.drawnLayers = null
          $scope.editMode = false
          $scope.functions = that.functions || {}
          setFunctionHelper()
        }
      }
  }
}

module.exports = mapEditor
