/* global _ */
import { mapOrder } from 'app/helper'
/** @ngInject */
function areaFormDialogController (
  $scope,
  $rootScope,
  $mdDialog,
  FormlyHelper,
  MultiTranslateService,
  Field,
  locals
) {
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.save = function save () {
    const fieldIds = locals.fieldIds.concat(
      locals.fieldIds.map(fId => `${fId}_lookup`)
    )
    const values = _.pick($scope.areaModel, fieldIds)
    $mdDialog.hide(_.isEmpty(values) ? null : values)
  }

  const initDialog = async function initDialog () {
    const { fieldIds, model, areaName, disabled } = locals
    $scope.disabled = disabled || false
    $scope.areaName = areaName
    $scope.areaModel = {}
    if (model && model.values) {
      model.values.forEach(valueObject => {
        $scope.areaModel[valueObject.fieldId] = valueObject.value
      })
    }
    let fields = await Field.find({
      filter: {
        where: { id: { inq: fieldIds } },
        deleted: true,
        include: ['resource']
      }
    }).$promise
    fields = fields.map(field =>
      MultiTranslateService.getForView(Field, $rootScope.currentLang, field)
    )
    $scope.fields = []
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i]
      const formlyField = await FormlyHelper.convertToFormlyObject(field)
      formlyField.templateOptions.disabled = $scope.disabled
      $scope.fields.push(formlyField)
    }
    $scope.fields = mapOrder($scope.fields, fieldIds, 'key')
    $scope.$applyAsync()
  }

  initDialog()
}

module.exports = areaFormDialogController
