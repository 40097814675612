/* global _ */
const UUID = require('uuid')

/** @ngInject */
function SearchResultsShowController (
  $scope,
  Document,
  workflowList,
  contentPackageList,
  timelineList,
  $rootScope,
  $translate,
  $state,
  EventService,
  searchResults,
  $stateParams,
  ResourceUtils,
  ResolvedCategories
) {
  const FILTERS = {
    EVERYWHERE: 1,
    DOCUMENTS: 2,
    CATALOGS: 3,
    WORKFLOWS: 4,
    TIMELINES: 5,
    RESOURCES: 6,
    UNITS: 7,
    WORKORDERS: 8,
    PARTS: 9
  }

  const models = _.keys($rootScope.appSettings.modelsFields)
  $scope.ResourceUtils = ResourceUtils
  $scope.query = $stateParams.query
  $scope.ResolvedCategories = ResolvedCategories
  $scope.customFields = []
  $scope.selectedCustomFields = {}
  EventService.saveEvent({
    type: 'search',
    query: $scope.query
  })
  models.forEach(modelName => {
    _.mapKeys(
      $rootScope.appSettings.modelsFields[modelName].properties,
      (value, key) => {
        if (value.custom && value.filterable) {
          const exits = _.find($scope.customFields, { key: key })
          if (!exits) {
            $scope.customFields.push({ key, options: value.options })
          }
        }
      }
    )
  })

  const customFieldNameList = $scope.customFields.map(
    customField => customField.key
  )

  const postSearch = function postSearch (searchResults) {
    const res = []
    for (let index = 0; index < searchResults.length; index++) {
      let item = JSON.parse(JSON.stringify(searchResults[index]))
      let selectedPartNumber
      let selectedSubPartNumber
      let catalog
      switch (item.source) {
        case FILTERS.DOCUMENTS:
          if (item.recordId) {
            item.link = $state.href('app.content-package.show', {
              id: item.packId,
              recordId: item.recordId
            })
          } else {
            item.link = $state.href('app.content-package.show', {
              id: item.packId
            })
          }
          item = addContentPackageCustomFields(item)
          item.title = item.name
          item.pathToDisplay = item.path
          break
        case FILTERS.CATALOGS:
          item.title = item.obj.name
          if (item.obj.number) {
            item.title = item.title + ' / ' + item.obj.number
          }

          if (item.obj && !_.isUndefined(item.obj.is_assembly)) {
            item.is_assembly = item.obj.is_assembly
          }

          catalog = item.relatedCatalog

          if (catalog && item) {
            item.categories = catalog.categories
            if (item.pathName.length === 0) {
              item.pathToDisplay = catalog.name
            } else {
              item.pathToDisplay =
                catalog.name + ' > ' + item.pathName.join(' > ')
            }
            item = addCatalogCustomFields(item, catalog)

            if (item.is_assembly === false || !item.is_assembly) {
              selectedPartNumber = _.take(item.path, item.path.length - 1).join(
                '_'
              )
              selectedSubPartNumber = _.takeRight(item.path)
            } else {
              selectedPartNumber = item.path.join('_')
              selectedSubPartNumber = ''
            }

            item.link = $state.href('app.catalog.show', {
              id: catalog._id,
              selectedPartNumber: selectedPartNumber,
              selectedSubPartNumber: selectedSubPartNumber
            })
          } else {
            continue
          }
          break
        case FILTERS.WORKFLOWS:
          if (item.id) {
            item = addWorkflowCustomFields(item)
            item.link = $state.href('app.workflow.show-details', {
              recordId: item.recordId,
              tabName: 'sessions'
            })
            item.title = item.name
          }
          break
        case FILTERS.TIMELINES:
          if (item.id) {
            item = addTimelineCustomFields(item)
            item.title = item.name
            item.link = $state.href('app.timeline.display', {
              id: item.id
            })
          }
          break
        case FILTERS.RESOURCES:
          item.title = item.name
          item.target = '_blank'
          item.link = ResourceUtils.createDownloadLink({
            container: item.container,
            name: item.fileName
          })
          item.pathToDisplay =
            $translate.instant('SEARCH.ROOT') + ' > ' + item.pathToDisplay
          break
        case FILTERS.UNITS:
          if (item.id) {
            if (item.status === 'WF.NEW') {
              item.link = $state.href('app.workflow.session.show', {
                recordId: item.recordId,
                preview: false
              })
            } else {
              item.link = $state.href('app.workflow.session.show', {
                workflowSessionIds: [item.id],
                preview: false
              })
            }
            item.customTitleDetails = {
              title: `${$translate.instant('WF.SERIALS')}: ${item.serials}`,
              subTitle1: `${$translate.instant('WF.sku')}: ${item.partSku}`,
              subTitle2: `${$translate.instant('WO.workorderNumber')}: ${
                item.workorderNumber
              }`,
              subTitle3: `${$translate.instant(
                'WF.STATUS'
              )}: ${$translate.instant(item.status)}`
            }
          }
          break
        case FILTERS.WORKORDERS:
          if (item.id) {
            item.link = $state.href('app.workorder.show', {
              id: item.id
            })
            item.title = item.workorderNumber
            item.customTitleDetails = {
              title: `${$translate.instant('WO.workorderNumber')}: ${
                item.workorderNumber
              }`,
              subTitle1: `${$translate.instant('WF.sku')}: ${item.sku}`,
              subTitle2: `${$translate.instant('WO.workorderName')}: ${
                item.name
              }`,
              subTitle3: `${$translate.instant(
                'WF.STATUS'
              )}: ${$translate.instant(item.status)}`
            }
          }
          break
      }
      item.uniqueId = `${item.source}_${index}_${
        item.id || item.recordId || UUID()
      }`
      res.push(item)
    }
    return res
  }

  const addCatalogCustomFields = function addCatalogCustomFields (
    item,
    catalog
  ) {
    customFieldNameList.forEach(fieldName => {
      if (catalog[fieldName]) {
        item[fieldName] = catalog[fieldName]
      }
    })
    return item
  }

  const addContentPackageCustomFields = function addContentPackageCustomFields (
    item
  ) {
    const pack = $scope.contentPackageList.find(cp => cp.id === item.packId)
    customFieldNameList.forEach(fieldName => {
      if (pack[fieldName]) {
        item[fieldName] = pack[fieldName]
      }
    })
    return item
  }

  const addTimelineCustomFields = function addTimelineCustomFields (item) {
    const pack = $scope.timelineList.find(timeline => timeline.id === item.id)
    customFieldNameList.forEach(fieldName => {
      if (pack[fieldName]) {
        item[fieldName] = pack[fieldName]
      }
    })
    return item
  }

  const addWorkflowCustomFields = function addWorkflowCustomFields (item) {
    if (item.id) {
      const workflow = $scope.workflowList.find(wf => wf.id === item.id)
      if (workflow) {
        customFieldNameList.forEach(fieldName => {
          if (workflow[fieldName]) {
            item[fieldName] = workflow[fieldName]
          }
        })
      }
      return item
    }
  }

  $scope.makeSearch = async function makeSearch (item) {
    if (
      $stateParams.type &&
      parseInt($stateParams.type) === FILTERS.EVERYWHERE
    ) {
      const results =
        parseInt(item.id) === FILTERS.EVERYWHERE
          ? searchResults.result
          : searchResults.result.filter(i => i.source === parseInt(item.id))
      $scope.results = postSearch(results)
      return false
    }
    $rootScope.loadingProgress = true
    try {
      const results = await Document.searchIn({
        obj: {
          where: parseInt(item.id),
          query: $stateParams.query
        }
      }).$promise
      $scope.results = postSearch(results.result)
    } catch (err) {
      console.error(err)
    } finally {
      $rootScope.loadingProgress = false
      $scope.$applyAsync()
    }
  }

  $scope.getResultCount = function getResultCount () {
    return searchResults.length
  }

  const initScreen = function initScreen () {
    $scope.filters = [
      {
        id: FILTERS.EVERYWHERE,
        name: $translate.instant('SEARCH.EVERYWHERE')
      },
      {
        id: FILTERS.DOCUMENTS,
        name: $translate.instant('SEARCH.DOCUMENTS')
      },
      { id: FILTERS.CATALOGS, name: $translate.instant('SEARCH.CATALOGS') },
      {
        id: FILTERS.WORKFLOWS,
        name: $translate.instant('SEARCH.WORKFLOWS')
      },
      { id: FILTERS.TIMELINES, name: $translate.instant('SEARCH.TIMELINES') },
      { id: FILTERS.UNITS, name: $translate.instant('SEARCH.UNITS') },
      { id: FILTERS.WORKORDERS, name: $translate.instant('SEARCH.WORKORDERS') }
    ]
    if ($rootScope.appSettings.ALLOW_SEARCH_IN_FILES) {
      $scope.filters.push({
        id: FILTERS.RESOURCES,
        name: $translate.instant('SEARCH.RESOURCES')
      })
    }
    $scope.activeFilterId = $scope.filters.findIndex(
      f => f.id === parseInt($stateParams.type)
    )
    $scope.contentPackageList = contentPackageList
    $scope.workflowList = workflowList
    $scope.timelineList = timelineList
    $scope.results = postSearch(searchResults.result)
  }

  initScreen()
}

module.exports = SearchResultsShowController
