'use strict'
/* global _ */
/** @ngInject */
function wizardGroupController ($scope) {
  $scope.msWizard = {
    selectedIndex: $scope.to.selectedIndex
  }
  $scope.wizardForms = []
  $scope.finishWizard = () => {
    if ($scope.to.callback) {
      $scope.to.callback(_.get($scope.model, $scope.options.key))
    }
  }
  $scope.nextStep = async msWizard => {
    try {
      const idx = msWizard.selectedIndex
      const data = _.get($scope.model, $scope.options.key)
      if ($scope.to.nextStepFunction) {
        await $scope.to.nextStepFunction(idx, data)
      }
      msWizard.nextStep()
      $scope.$applyAsync()
    } catch (err) {
      console.error(err)
    }
  }
  $scope.checkInvalid = index => {
    if ($scope.wizardForms.length > 0) {
      for (let i = 0; i < index; i++) {
        if ($scope.wizardForms[i].$invalid) return true
      }
      return false
    }
  }
}

module.exports = wizardGroupController
