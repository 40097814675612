/* global _ */

/** @ngInject */
function formApproval () {
  require('./form-approval.scss')
  return {
    template: require('./form-approval.html'),
    bindings: {
      formData: '<',
      users: '<',
      approvalWorkflowDeclaration: '<',
      approvalWorkflow: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function formApproval ($scope, UserUtils) {
        const initApprovalSection = async function initApprovalSection (
          formData,
          approvalWorkflow = {},
          approvalWorkflowDeclaration = {}
        ) {
          if (_.isEmpty(approvalWorkflow)) return
          const { levels } = approvalWorkflow
          $scope.levels = []
          for (let l = 0; l < levels.length; l++) {
            const formDataLevel = levels[l]
            const level = approvalWorkflowDeclaration.levels.find(
              level => level.id === formDataLevel.id
            )
            const { approvedByAll, userIds } = level
            const currentLevel =
              formDataLevel && !formDataLevel.end && !!formDataLevel.start
            const status = formDataLevel.status
            const normalizedUserIds = await UserUtils.normalizeUserPicker(
              userIds,
              formData.ownerId,
              { assignee: formData.assignee }
            )
            $scope.levels.push({
              currentLevel,
              name: level.name,
              status,
              approvedByAll,
              userIds: normalizedUserIds,
              approvedBy: formDataLevel.approvedBy
            })
          }
        }

        this.$onChanges = function (changeObj) {
          initApprovalSection(
            this.formData,
            this.approvalWorkflow,
            this.approvalWorkflowDeclaration
          )
        }
      }
  }
}

module.exports = formApproval
