/* global */

/** @ngInject */
function PartViewHelper (PanelHelper) {
  const openPartViewDialog = function openPartViewDialog (
    partNumber,
    partRev = null,
    options = {}
  ) {
    return PanelHelper.openPanel({
      resolve: {
        ResolvedOptions: () => {
          return options
        },
        ResolvedPart: /** @ngInject */ (
          PartAssembly,
          FormlyHelper,
          $mdToast,
          $rootScope,
          $translate
        ) => {
          return new Promise(async (resolve, reject) => {
            const cfields = FormlyHelper.getCustomFields(PartAssembly).reduce(
              (acc, field) => {
                acc[field.key] = true
                return acc
              },
              {}
            )
            PartAssembly.findOne({
              filter: {
                where: {
                  number: partNumber
                },
                fields: {
                  id: true,
                  name: true,
                  number: true,
                  _documents: true,
                  picture: true,
                  revisions: true,
                  managedByRevision: true,
                  nodes: true,
                  ...cfields
                },
                include: ['documents', 'revisionItems']
              }
            })
              .$promise.then(res => {
                const part = res
                if (partRev) {
                  part.selectedRevision = part.revisionItems.find(
                    rev => rev.revision === partRev
                  )
                } else {
                  part.revisions = part.revisions.filter(rev => rev.active)
                  part.revisionItems = part.revisions.filter(rev => rev.active)
                }
                resolve(part)
              })
              .catch(err => {
                console.error(err)
                $mdToast.show(
                  $mdToast.nextplus({
                    position: $rootScope.toastLocation,
                    parent: 'body',
                    theme: 'error-toast',
                    hideDelay: 3000
                  })
                )
                let message = err.data.error.message
                if (err.data.error.statusCode === 404)
                  message = $translate.instant(
                    'PartAssembly.ERRORS.PART_NUMBER_NOT_EXIST',
                    {
                      partNumber
                    }
                  )
                $mdToast.updateTextContent(message)
                reject(err)
              })
          })
        }
      },
      controller: 'PartViewDialogController',
      template: require('./dialog/part-view.dialog.template.html'),
      fullscreen: false,
      escapeToClose: true,
      clickOutsideToClose: true,
      ...options
    })
  }
  return {
    openPartViewDialog
  }
}

module.exports = PartViewHelper
