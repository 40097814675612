/* global */
require('./reset.dialog.scss')
/** @ngInject */
function ResetDialogController ($scope, $mdDialog, $translate, locals) {
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.reset = function () {
    $mdDialog.hide({ extraFieldsModel: $scope.extraFieldsModel })
  }

  const initDialog = async function initDialog () {
    $scope.textMessage = locals.text || ''
    $scope.confirmText = locals.confirmText || null
    if (locals.extraFieldsObj) {
      $scope.extraFieldsFormly = locals.extraFieldsObj.extraFieldsFormly || []
      $scope.extraFieldsModel = locals.extraFieldsObj.extraFieldsModel || {}
    }
    $scope.model = {
      confirmationText: ''
    }
  }

  initDialog()
}
module.exports = ResetDialogController
