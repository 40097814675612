/* global _ */
'use strict'
const LookUpSelectHelper = require('app/formly/helpers/lookup-select-helper.class.js')

require('./lookupSelect.scss')
/** @ngInject */
function lookupSelect ($scope, $timeout, $mdSelect, visionService, Table) {
  const fieldId = $scope.to.lookupField
  const tableId = $scope.to.tableId

  let input
  $scope.searchTerm = ''

  const selectHelper = new LookUpSelectHelper(
    Table,
    $scope.model,
    $scope.options.key,
    tableId,
    fieldId
  )

  const runSearch = function runSearch (word) {
    if (input) {
      input.value = word
    }
    $scope.searchTerm = word
    $scope.debouncedFn(word, true)
  }

  $scope.ocr = (words, eventObject) => {
    const res = selectHelper.ocrCallback(words, eventObject, selectedWord => {
      runSearch(selectedWord)
    })
    if (res === true) {
      $scope.onSelect()
    } else {
      runSearch(res)
    }
  }

  $scope.openOcr = $event => {
    if ($event.target.parentNode.tagName === 'MD-SELECT-HEADER') {
      input = $event.target.parentNode.querySelector('input')
    } else {
      input = $event.target.parentNode
        .querySelector('md-select')
        .querySelector('input')
      $event.target.parentNode.querySelector('md-select').click()
    }
    visionService.sendOCRAndroidEvent(
      $scope.ocr,
      visionService.androidWithNewVersion
    )
  }

  $scope.openVisionDialog = async function openVisionDialog ($event) {
    if (
      $scope.to.preVisionDialog &&
      typeof $scope.to.preVisionDialog === 'function'
    ) {
      $scope.to.preVisionDialog()
    }
    if (!$scope.to.disabled) {
      if ($event.target.parentNode.tagName === 'MD-SELECT-HEADER') {
        input = $event.target.parentNode.querySelector('input')
      } else {
        $event.target.parentNode.querySelector('md-select').click()
        $timeout(() => {
          input = $event.target.parentNode
            .querySelector('md-select')
            .querySelector('input')
        }, 20)
      }
      await visionService.openVisionDialog({
        callback: $scope.ocr,
        returnWords: visionService.androidWithNewVersion,
        key: `${$scope.options.type}_${$scope.options.key}`
      })
    }
    if (
      $scope.to.postVisionDialog &&
      typeof $scope.to.postVisionDialog === 'function'
    ) {
      $scope.to.postVisionDialog()
    }
  }

  $scope.search = $event => {
    input = $event.target
    if (input.value !== $scope.searchTerm) {
      $scope.searchTerm = input.value
      $scope.debouncedFn(input.value)
    }
  }

  $scope.onSelect = function onSelect () {
    const value = _.get($scope.model, $scope.options.key)
    $mdSelect.hide()
    if (input) {
      input.value = ''
    }
    const selectedOption = $scope.optionsToDisplay.find(
      option => option.id === value
    )
    const data =
      selectedOption && selectedOption.data ? selectedOption.data : null
    if (data) {
      _.set($scope.model, `${$scope.options.key}_lookup`, data)
    }
    if ($scope.to.onChange) {
      if (_.isArray($scope.options.formControl)) {
        $scope.options.formControl = $scope.options.formControl[0]
      }
      if ($scope.to.onChange) {
        $scope.to.onChange(value, $scope.options)
      }
    }
    $scope.$applyAsync()
  }

  $scope.clearSearchTerm = function clearSearchTerm () {
    const value = _.get($scope.model, $scope.options.key)
    if (value) {
      const selectedOption = $scope.optionsToDisplay.find(
        option => option.id === value
      )
      $scope.selectedOption = selectedOption
    } else {
      $scope.selectedOption = null
    }
    if (input) {
      input.value = ''
    }
    $scope.searchTerm = ''
    $scope.makeSearch('')
    $scope.noMoreResults = false
  }

  $scope.mdSelectOnOpen = function mdSelectOnOpen () {
    $scope.$broadcast('$md-resize')
    return $timeout(function () {
      $scope.$broadcast('$md-resize')
    }, 100)
  }

  $scope.makeSearch = async function makeSearch (
    query,
    imFeelingLuckey = false
  ) {
    $scope.loadingMore = $scope.noMoreResults = false
    $scope.initLoading = true
    $scope.$applyAsync()
    $scope.optionsToDisplay = []
    const res = await selectHelper.makeSearch(query, imFeelingLuckey)
    if (res === true) {
      $scope.onSelect()
    } else {
      if (
        !_.isNil($scope.selectedOption) &&
        !res.find(opt => opt.id === $scope.selectedOption.id)
      ) {
        $scope.optionsToDisplay = [$scope.selectedOption, ...res]
      } else {
        $scope.optionsToDisplay = res
      }
    }
    $scope.initLoading = false
    $scope.$applyAsync()
  }

  const loadMoreDebounce = _.debounce(
    async () => {
      $scope.loadingMore = true
      const moreOptions = await selectHelper.loadMore()
      if (moreOptions.length === 0) {
        $scope.noMoreResults = true
      } else {
        $scope.noMoreResults = false
      }
      $scope.optionsToDisplay.push(...moreOptions)
      $scope.loadingMore = false
      $scope.$applyAsync()
    },
    500,
    { leading: true }
  )

  $scope.loadMore = async function loadMore () {
    if ($scope.loadingMore || $scope.noMoreResults) return
    loadMoreDebounce()
  }

  const focusInput = function focusInput () {
    $timeout(
      () => {
        const inputElement = document.querySelector(
          '.md-select-menu-container.md-active .selectboxWithSearch input'
        )
        if (inputElement) {
          inputElement.focus()
        }
      },
      200,
      false
    )
  }

  $scope.fillOptions = async function fillOptions () {
    focusInput()
    if ($scope.initLoading) return
    if (!selectHelper.alreadyInit()) {
      await selectHelper.getFirstOptions()
    }
    $scope.optionsToDisplay = selectHelper.getOptionsToDisplay()
    if (
      !_.isNil($scope.selectedOption) &&
      !$scope.optionsToDisplay.find(opt => opt.id === $scope.selectedOption.id)
    ) {
      $scope.optionsToDisplay = [
        $scope.selectedOption,
        ...$scope.optionsToDisplay
      ]
    }
  }

  const initSelect = async function initSelect () {
    $scope.initLoading = true
    if (!tableId || !fieldId) {
      console.log(
        '%c There are no related table or field ',
        'background: #f44336; color: #ffffff;',
        tableId,
        fieldId
      )
      return
    }
    await selectHelper.initOptions()
    $scope.optionsToDisplay = selectHelper.getOptionsToDisplay()
    const initialValue = _.get($scope.model, $scope.options.key)
    if (initialValue) {
      const selectedOption = $scope.optionsToDisplay.find(
        option => option.id === initialValue
      )
      $scope.selectedOption = selectedOption
    }
    $scope.initLoading = false
    $scope.$applyAsync()
  }

  initSelect()

  $scope.debouncedFn = _.debounce($scope.makeSearch, 200, { leading: false })
}

module.exports = lookupSelect
