/* global angular $ _ kendo */
import ConflictResolverDialogController from './conflict-resolver-dialog'
import ErrorInDataDialogController from './error-in-data-dialog'

/** @ngInject */
function PartsImportController (
  $scope,
  $rootScope,
  $translate,
  $mdDialog,
  PartAssembly,
  PermissionUtils,
  ResolvedUnits,
  Page
) {
  const buildAdditionalFields = function buildAdditionalFields () {
    const columns = []
    _.mapKeys(
      $rootScope.appSettings.modelsFields.PartAssembly.properties,
      (value, key) => {
        if (value.custom) {
          columns.push({
            value: $translate.instant('PartAssembly.' + value.key),
            field: value.key,
            width: 100
          })
        }
      }
    )
    return columns
  }

  const extractDataFromKendoSpreadsheet =
    function extractDataFromKendoSpreadsheet () {
      const spreadsheet = $scope.spreadsheet
      const data = spreadsheet.toJSON()
      return data.sheets[0].rows.map(row => {
        const res = {}
        row.cells.forEach(cell => {
          const field = $scope.kendoSpreadSheetHeaders[cell.index].field
          res[field] = cell.value
        })
        return res
      })
    }

  const removeLevel = function removeLevel (data) {
    delete data.level
    if (data.children) {
      data.children = data.children.map(x => {
        x = removeLevel(x)
        return x
      })
    }
    return data
  }

  const checkData = function checkData (data = []) {
    const errors = []
    const pushError = function pushError (recordNum, message) {
      errors.push({
        recordNum,
        message
      })
    }
    let currentLevel
    let availableLevelDeep
    if (data.length > 0 && data[0] && !_.isUndefined(data[0].level)) {
      currentLevel = parseInt(data[0].level)
      availableLevelDeep = currentLevel + 1
    }
    if (data.length === 0) {
      pushError(1, $translate.instant('PartAssembly.IMPORT_ERRORS.NO_DATA'))
    }
    for (let i = 0; i < data.length; i++) {
      const record = data[i]
      const indexInKendo = i + 2
      if (!record.level || record.level === '0') {
        pushError(
          indexInKendo,
          $translate.instant('PartAssembly.IMPORT_ERRORS.LEVEL_NOT_EXIST')
        )
        continue
      }
      record.level = parseInt(record.level)
      if (!record.name || record.name === '') {
        pushError(
          indexInKendo,
          $translate.instant('PartAssembly.IMPORT_ERRORS.PART_NAME')
        )
      }
      if (!record.number || record.number === '') {
        pushError(
          indexInKendo,
          $translate.instant('PartAssembly.IMPORT_ERRORS.PART_NUMBER')
        )
      }
      if (!record.unitName || record.unitName === '') {
        pushError(
          indexInKendo,
          $translate.instant('PartAssembly.IMPORT_ERRORS.UNIT')
        )
      }
      if (record.isSerial === 'yes' && record.freeStock === 'yes') {
        pushError(
          indexInKendo,
          $translate.instant(
            'PartAssembly.IMPORT_ERRORS.IS_SERIAL_AND_FREE_STOCK'
          )
        )
      }
      if (
        !record.quantity ||
        record.quantity === '' ||
        isNaN(parseInt(record.quantity)) ||
        parseInt(record.quantity) < 0
      ) {
        pushError(
          indexInKendo,
          $translate.instant('PartAssembly.IMPORT_ERRORS.QUANTITY')
        )
      }
      if (record.level > availableLevelDeep) {
        pushError(
          indexInKendo,
          $translate.instant('PartAssembly.IMPORT_ERRORS.LEVEL_STRUCTURE')
        )
      } else {
        currentLevel = parseInt(record.level)
        availableLevelDeep = currentLevel + 1
      }
    }
    return errors
  }

  const checkStructure = function checkStructure (data, originalData) {
    const numbersToCheck = {}
    const errorPartNumbers = []
    const PROPERTIES_TO_VERIFY = [
      'name',
      'isSerial',
      'unitName',
      'serialNumberRequiredAtStart',
      'freeStock',
      'picture',
      'children',
      'autoCreate'
    ]
    data.forEach((record, i) => {
      const samePartsInImport = data.filter(o => o.number === record.number)
      if (samePartsInImport.length > 1) {
        if (!numbersToCheck[record.number]) {
          numbersToCheck[record.number] = []
        }
        numbersToCheck[record.number].push(i)
      }
    })
    const partNumbers = Object.keys(numbersToCheck)
    for (let j = 0; j < partNumbers.length; j++) {
      const partNumber = partNumbers[j]
      const value = numbersToCheck[partNumber]
      const elements = []
      value.map(elementPosition =>
        elements.push(removeLevel(_.cloneDeep(data[elementPosition])))
      )
      if (
        elements.some(partDeclaration => {
          const tmp = _.pick(elements[0], PROPERTIES_TO_VERIFY)
          const tmp2 = _.pick(partDeclaration, PROPERTIES_TO_VERIFY)
          tmp.name = tmp.name ? tmp.name.toLowerCase() : ''
          tmp2.name = tmp2.name ? tmp2.name.toLowerCase() : ''
          return !_.isEqual(tmp, tmp2)
        })
      ) {
        errorPartNumbers.push(partNumber)
      }
    }
    const errors = []

    errorPartNumbers.forEach(errorPartNumber => {
      const error = {
        number: errorPartNumber,
        line: []
      }
      originalData.forEach((record, i) => {
        if (record.number === errorPartNumber) {
          error.line.push(i + 2)
        }
      })
      errors.push(error)
    })
    return errors.map(err => {
      return {
        recordNum: err.line.join(', '),
        message: 'Parts with number `' + err.number + '` are different'
      }
    })
  }

  const getTreeAndFlattenParts = function getTreeAndFlattenParts (data, levels) {
    let partList = []
    const makeTree = function makeTree (top, sub) {
      let i = 0
      let mode = 'searching'
      let elementsToDelete = []
      const elementsToReplace = []
      const obj = {}

      while (i < data.length) {
        if (i > 0) {
          Object.keys(data[i]).forEach(key => {
            if (typeof data[i][key] === 'string') {
              data[i][key] = data[i][key].trim()
            }
          })
          data[i].isSerial =
            data[i].isSerial === 'yes' || data[i].isSerial === true
          data[i].freeStock =
            data[i].freeStock === 'yes' || data[i].freeStock === true
          data[i].serialNumberRequiredAtStart =
            data[i].serialNumberRequiredAtStart === 'yes' ||
            data[i].serialNumberRequiredAtStart === true
          switch (data[i].autoCreate) {
            case $translate.instant('PartAssembly.AUTO_CREATE.ACTIVE'):
            case 'ACTIVE':
              data[i].autoCreate = 'ACTIVE'
              break
            case $translate.instant('PartAssembly.AUTO_CREATE.NOT_ACTIVE'):
            case 'NOT_ACTIVE':
              data[i].autoCreate = 'NOT_ACTIVE'
              break
            case $translate.instant(
              'PartAssembly.AUTO_CREATE.REQUIRE_APPROVAL'
            ):
            case 'REQUIRE_APPROVAL':
              data[i].autoCreate = 'REQUIRE_APPROVAL'
              break
            default:
              data[i].autoCreate = 'NOT_ACTIVE'
          }
        }
        if (mode === 'searching') {
          if (data[i].level === top) {
            mode = 'collecting'
            obj.index = i
            obj.data = data[i]
            obj.data.children = []
          }
        } else if (mode === 'collecting') {
          if (data[i].level === sub) {
            elementsToDelete.push(i)
            obj.data.children.push(data[i])
            partList.push(data[i])
          } else {
            mode = 'searching'
            elementsToReplace.push(obj)
            i--
          }
        }
        i++
      }

      elementsToReplace.forEach(element => {
        data[element.index] = element.data
      })
      elementsToDelete = elementsToDelete.sort((a, b) => b - a)
      elementsToDelete.map(index => data.splice(index, 1))
    }
    for (let i = levels.length - 1; i >= 0; i--) {
      if (i - 1 >= 0) {
        makeTree(levels[i - 1], levels[i])
      }
    }

    const partAssembly = data[0]
    partList.push(partAssembly)
    partList = partList.map(part => {
      if (!part.children) {
        part.children = []
      }
      return part
    })
    return {
      partAssembly,
      partList
    }
  }

  const createImportList = function createImportList (data) {
    data.unshift({
      number: 'system',
      name: 'system',
      description: 'system',
      count: 1,
      level: 0
    })
    const uniqueLevels = new Set(data.map(o => o.level))
    const levels = [...uniqueLevels].sort((a, b) => a - b)
    const { partList } = getTreeAndFlattenParts(data, levels)
    return { partList, levels }
  }

  const showSuccessDialog = function showSuccessDialog () {
    const confirm = $mdDialog
      .confirm()
      .title($translate.instant('PartAssembly.IMPORT_FINISHED'))
      .targetEvent()
      .clickOutsideToClose(true)
      .parent(angular.element(document.body))
      .ok($translate.instant('PartAssembly.OK'))
    $mdDialog.show(confirm)
  }

  const disableHeader = function disableHeader () {
    const rangeString = 'A1:Z1 '
    const range = $scope.spreadsheet.activeSheet().range(rangeString)
    range.enable(false)
  }

  const formatStringCells = function formatStringCells (from, to) {
    const rangeString = `${from}:${to}`
    const range = $scope.spreadsheet.activeSheet().range(rangeString)
    range.format('@')
  }

  $scope.importParts = async function importParts () {
    const partsData = extractDataFromKendoSpreadsheet()
    // REMOVE HEADER LINE
    partsData.shift()
    const nonEmptyRows = partsData.filter(part => part.number)
    let manipulatedRows = nonEmptyRows
    if (!$scope.includeProductTree) {
      manipulatedRows = nonEmptyRows.map(record => {
        record.level = 1
        record.quantity = 1
        return record
      })
    }
    let validationErrors = checkData(manipulatedRows)
    let partList, levels, res
    if (validationErrors.length === 0) {
      res = createImportList(JSON.parse(JSON.stringify(manipulatedRows)))
      partList = res.partList
      levels = res.levels
      validationErrors = checkStructure(partList, manipulatedRows)
    }
    if (validationErrors.length > 0) {
      // display dialog with errors
      $mdDialog.show({
        controller: ErrorInDataDialogController,
        controllerAs: 'vm',
        template: require('./error-in-data-dialog.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {
          title: $translate.instant('PartAssembly.DIALOG_IMPORT_ERROR'),
          wrongRecords: validationErrors
        },
        clickOutsideToClose: false
      })
    } else {
      let partToImport = _.cloneDeep(partList)
      partToImport.pop()
      levels.shift()
      const uniquePartNumbers = [
        ...new Set(partToImport.map(part => part.number))
      ]
      const conflicts = await PartAssembly.findParts({
        filter: {
          where: {
            number: { inq: uniquePartNumbers }
          },
          fields: { id: true, number: true, name: true, recordId: true }
        }
      }).$promise
      if (Array.isArray(conflicts) && conflicts.length > 0) {
        // show dialog for resolve conflicts
        $mdDialog
          .show({
            controller: ConflictResolverDialogController,
            controllerAs: 'vm',
            template: require('./conflict-resolver-dialog.html'),
            parent: angular.element(document.body),
            targetEvent: '',
            locals: {
              conflicts,
              partToImport: _.uniqBy(partToImport, 'number')
            },
            multiple: true,
            fullscreen: true,
            escapeToClose: false,
            clickOutsideToClose: false
          })
          .then(
            async function (userResolve) {
              if (userResolve) {
                // fix partToImport

                const newUserData = _.cloneDeep(manipulatedRows)
                const newUserDataForInsert = newUserData

                res = createImportList(_.cloneDeep(newUserDataForInsert))
                partList = res.partList
                levels = res.levels

                partToImport = _.cloneDeep(partList)
                partToImport.pop()
                levels.shift()

                if (partToImport.length > 0) {
                  partToImport = partToImport.map(part => {
                    const conflictPart = _.find(conflicts, {
                      number: part.number
                    })
                    if (conflictPart) {
                      part.recordId = conflictPart.recordId
                    }
                    if (
                      userResolve[part.number] &&
                      !userResolve[part.number].fromImport
                    ) {
                      part.fromDB = true
                    }
                    return part
                  })

                  // if user select DB then ignore part or assembly (and all it child)
                  // if user select current list for part make update part as new revision
                  // if user select current list for assembly
                  // need check that all child recursively exist if not then create
                  // then make update assembly as new revision
                  $rootScope.loadingProgress = true
                  await PartAssembly.bulkImportParts({
                    data: partToImport,
                    includeProductTree: $scope.includeProductTree
                  }).$promise
                }
                showSuccessDialog()
                $rootScope.loadingProgress = false
              }
            },
            function () {}
          )
      } else {
        try {
          $rootScope.loadingProgress = true
          await PartAssembly.bulkImportParts({
            data: partToImport,
            includeProductTree: $scope.includeProductTree
          }).$promise
          showSuccessDialog()
          $rootScope.loadingProgress = false
        } catch (e) {
          $rootScope.loadingProgress = false
        }
      }
    }
  }

  $scope.includeProductTreeChanged = function includeProductTreeChanged () {
    const sheet = $scope.spreadsheet.activeSheet()
    const levelColumnIndex = $scope.kendoSpreadSheetHeaders.findIndex(
      header => header.field === 'level'
    )
    const quantityColumnIndex = $scope.kendoSpreadSheetHeaders.findIndex(
      header => header.field === 'quantity'
    )
    if (!$scope.includeProductTree) {
      sheet.hideColumn(levelColumnIndex)
      sheet.hideColumn(quantityColumnIndex)
    } else {
      sheet.unhideColumn(levelColumnIndex)
      sheet.unhideColumn(quantityColumnIndex)
    }
  }
  const initScreen = function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('PartAssembly.IMPORT')
    $scope.hasCreatePermission = PermissionUtils.isPermit(
      'PartAssembly',
      'create'
    )
    Page.setTitleText($scope.title)
    $scope.includeProductTree = true

    const unitList = JSON.stringify(
      ResolvedUnits.map(unit => unit.name).join()
    ).replace(/^"|"$/g, '')
    const autoCreateOptions = [
      $translate.instant('PartAssembly.AUTO_CREATE.ACTIVE'),
      $translate.instant('PartAssembly.AUTO_CREATE.NOT_ACTIVE'),
      $translate.instant('PartAssembly.AUTO_CREATE.REQUIRE_APPROVAL')
    ]

    const AL = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z'
    ]

    const headerCellDesignProperties = {
      background: 'rgb(167,214,255)',
      textAlign: 'center',
      color: 'rgb(0,62,117)',
      wrap: true,
      verticalAlign: 'center'
    }

    const staticHeader = [
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.PART_NUMBER'),
        field: 'number',
        width: 150,
        height: 60
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.PART_NAME'),
        field: 'name',
        width: 150
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.REVISION'),
        field: 'currentRevision',
        width: 70
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.UNIT'),
        field: 'unitName',
        width: 100
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.AUTO_CREATION'),
        field: 'autoCreate',
        width: 100
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.QUANTITY'),
        field: 'quantity',
        width: 70
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.LEVEL'),
        field: 'level',
        width: 70
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.IS_SERIAL'),
        field: 'isSerial',
        width: 70
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant(
          'PartAssembly.SERIAL_NUMBER_REQUIRED_AT_START'
        ),
        field: 'serialNumberRequiredAtStart',
        width: 100
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.FREE_STOCK'),
        field: 'freeStock',
        width: 70
      },
      {
        ...headerCellDesignProperties,
        value: $translate.instant('PartAssembly.PICTURE'),
        field: 'picture',
        width: 100
      }
    ]

    const additionalHeader = buildAdditionalFields().map(f => ({
      ...headerCellDesignProperties,
      ...f
    }))

    $scope.kendoSpreadSheetHeaders = [...staticHeader, ...additionalHeader]

    $scope.spreadsheetOptions = {
      columns: $scope.kendoSpreadSheetHeaders.length,
      rows: 10000,
      toolbar: false,
      sheetsbar: false,
      sheets: [
        {
          name: 'Import',
          rows: [
            {
              height: 50,
              cells: $scope.kendoSpreadSheetHeaders
            }
          ],
          columns: $scope.kendoSpreadSheetHeaders
        }
      ]
    }

    kendo.spreadsheet.registerEditor('resource', function () {
      let context
      function open () {
        return $rootScope
          .uploadImage('.upload-part-picture .filePickerButton')
          .then(res => {
            if (res) {
              context.callback(res.resource_id)
            } else {
              context.callback('')
            }
            $scope.$applyAsync()
          })
      }
      return {
        edit: function (options) {
          context = options
          open()
        },
        icon: 'k-i-upload'
      }
    })

    $(document).ready(() => {
      $('#spreadsheet').kendoSpreadsheet($scope.spreadsheetOptions)
      $scope.spreadsheet = $('#spreadsheet').data('kendoSpreadsheet')
      disableHeader()
      formatStringCells('A2', 'D9999')
      formatStringCells(
        'G2',
        `${AL[$scope.kendoSpreadSheetHeaders.length - 1]}9999`
      )
      $scope.spreadsheet
        .activeSheet()
        .range('D2:D' + $scope.spreadsheetOptions.rows)
        .validation({
          dataType: 'list',
          showButton: true,
          comparerType: 'list',
          from: `"${unitList}"`,
          allowNulls: true,
          type: 'reject'
        })
      $scope.spreadsheet
        .activeSheet()
        .range('E2:E' + $scope.spreadsheetOptions.rows)
        .validation({
          dataType: 'list',
          showButton: true,
          comparerType: 'list',
          from: `"${autoCreateOptions}"`,
          allowNulls: true,
          type: 'reject'
        })
      $scope.spreadsheet
        .activeSheet()
        .range('H2:H' + $scope.spreadsheetOptions.rows)
        .validation({
          dataType: 'list',
          showButton: true,
          comparerType: 'list',
          from: '"yes,no"',
          allowNulls: true,
          type: 'reject'
        })
      $scope.spreadsheet
        .activeSheet()
        .range('I2:I' + $scope.spreadsheetOptions.rows)
        .validation({
          dataType: 'list',
          showButton: true,
          comparerType: 'list',
          from: '"yes,no"',
          allowNulls: true,
          type: 'reject'
        })
      $scope.spreadsheet
        .activeSheet()
        .range('J2:J' + $scope.spreadsheetOptions.rows)
        .validation({
          dataType: 'list',
          showButton: true,
          comparerType: 'list',
          from: '"yes,no"',
          allowNulls: true,
          type: 'reject'
        })
      $scope.spreadsheet
        .activeSheet()
        .range('K2:K' + $scope.spreadsheetOptions.rows)
        .editor('resource')
    })
  }
  $scope.$on('$destroy', function () {
    $('#spreadsheet').remove()
    $scope.spreadsheet.destroy()
  })
  initScreen()

  $scope.headerOptions = {
    icon: 'icon-import',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = {
  PartsImportController
}
