/* global angular _ */
'use strict'

/** @ngInject */
function selectIconController ($scope, $timeout, $mdSelect) {
  $scope.refresh = function () {
    return $timeout(function () {
      $scope.$broadcast('$md-resize')
    }, 100)
  }

  $scope.getIconHtml = () => {
    const icon = _.get($scope.model, $scope.options.key, null)
    if (icon) {
      const ic = icon.slice(5, icon.length)
      return `
        <span class="icon ${icon}"></span>
        ${ic}
        `
    }
    return '<span></span>'
  }

  $scope.icons = [
    {
      name: '',
      value: ''
    }
  ].concat(
    $scope.to.Icons.map(x => ({
      name: x,
      value: 'icon-' + x
    }))
  )
  $scope.searchTerm = ''
  $scope.iconsToDisplay = angular.copy($scope.icons)

  $scope.onSelect = () => {
    $mdSelect.hide()
    $scope.searchTerm = ''
    $scope.optionsToDisplay = angular.copy($scope.icons)
    $scope.$applyAsync()
    if ($scope.to.onChange) {
      if (_.isArray($scope.options.formControl)) {
        $scope.options.formControl = $scope.options.formControl[0]
      }
      $scope.to.onChange(
        $scope.model[$scope.options.key],
        $scope.options,
        $scope.model
      )
    }
  }

  const checkFilter = () => {
    if (!$scope.searchTerm) {
      $scope.iconsToDisplay = angular.copy($scope.icons)
    } else {
      $scope.iconsToDisplay = angular
        .copy($scope.icons)
        .filter(icon => icon.name.includes($scope.searchTerm))
    }
    $scope.$applyAsync()
  }

  const debouncedFn = _.debounce(checkFilter, 200)

  $scope.searchDeboune = ev => {
    console.log('serach icon')
    const input = ev.target
    $scope.searchTerm = input.value
    debouncedFn($scope.searchTerm)
  }
}

module.exports = selectIconController
