/* global angular _ */

/** @ngInject */
function NewEditPackageController (
  $scope,
  getUrlFromObj,
  $rootScope,
  $translate,
  $timeout,
  $state,
  $stateParams,
  FormlyHelper,
  ContentPackage,
  $mdDialog,
  PermissionUtils,
  DialogService,
  ResolvedContentPackage,
  ResolvedGroups,
  ResolvedCategories,
  ErrorHandlerService
) {
  $scope.ContentPackage = ContentPackage
  const categoriesForSelect = ResolvedCategories.map(category => ({
    id: category.id,
    name: category.name,
    parent: category.parentId
  }))
  let groupsForSelect = []
  if (
    $rootScope.currentUser &&
    $rootScope.currentUser.groups &&
    $rootScope.currentUser.groups.length > 0
  ) {
    groupsForSelect = ResolvedGroups.filter(
      group => $rootScope.currentUser.groups.indexOf(group.id) !== -1
    )
  } else {
    groupsForSelect = ResolvedGroups
  }

  const onChange = function (value, options) {
    options.validation.errorExistsAndShouldBeVisible = null
    options?.formControl?.$setValidity('serverError', true)
  }

  const staticPackageFields = [
    {
      key: 'name',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        focus: true,
        required: true,
        label: $translate.instant('CONTENTPACKAGE.PACKAGE_NAME'),
        placeholder: $translate.instant('CONTENTPACKAGE.PACKAGE_NAME'),
        onChange
      }
    },
    {
      key: 'image',
      type: 'upload',
      templateOptions: {
        filetype: 'image',
        mode: 'full',
        keyIs: 'id',
        object: 'image_object',
        onClick: onChange
      }
    },
    {
      key: 'tags',
      type: 'chips',
      templateOptions: {
        placeholder: $translate.instant('CONTENTPACKAGE.TAGS'),
        secondaryPlaceholder: $translate.instant('CONTENTPACKAGE.TAGS'),
        onKeydown: function (value, options) {
          options.validation.errorExistsAndShouldBeVisible = null
          options.formControl.$setValidity('serverError', true)
        },
        onClick: onChange
      }
    },
    {
      key: 'groups',
      type: 'selectWithSearch',
      className: 'layout-column',
      templateOptions: {
        multiple: true,
        searchPlaceholder: 'Search...',
        label: $translate.instant('CONTENTPACKAGE.GROUPS'),
        options: groupsForSelect,
        onChange
      }
    },
    {
      key: 'categories',
      type: 'selectTree',
      className: 'layout-column',
      templateOptions: {
        label: $translate.instant('CONTENTPACKAGE.CATEGORIES'),
        options: categoriesForSelect,
        multiple: true,
        onChange
      }
    },
    {
      key: 'template',
      type: 'selectWithSearch',
      className: 'layout-row',
      templateOptions: {
        searchPlaceholder: 'Search...',
        label: $translate.instant('CONTENTPACKAGE.TEMPLATE'),
        options: $rootScope.appSettings.availableContentPackageTemplate.map(
          template => ({ name: template, id: template })
        ),
        onChange
      }
    },
    {
      key: 'have_index',
      type: 'checkbox',
      hide: true,
      className: 'layout-row',
      templateOptions: {
        label: $translate.instant('CONTENTPACKAGE.POPULATE_TOC'),
        options: [
          { name: $translate.instant('CONTENTPACKAGE.YES'), id: true },
          { name: $translate.instant('CONTENTPACKAGE.NO'), id: false }
        ],
        onChange
      },
      hideExpression: function ($viewValue, $modelValue, scope) {
        return scope.model.template !== 'Feed'
      }
    }
  ]
  $scope.packageFields = FormlyHelper.buildFields(
    staticPackageFields,
    ContentPackage
  )
  $scope.PermissionUtils = PermissionUtils
  const isNew = !ResolvedContentPackage
  $scope.package = !ResolvedContentPackage
    ? {
        name: '',
        tags: [],
        image: '',
        groups:
          $rootScope.currentUser && $rootScope.currentUser.groups
            ? $rootScope.currentUser.groups
            : [],
        template: 'Default',
        have_index: false,
        categories: []
      }
    : ResolvedContentPackage

  if (!ResolvedContentPackage && $stateParams && $stateParams.initialValues) {
    Object.keys($stateParams.initialValues).forEach(key => {
      $scope.package[key] = $stateParams.initialValues[key]
    })
  }

  if (!$scope.package.template) $scope.package.template = 'Default'

  $scope.duplicateContentPackage = ev => {
    const confirm = $mdDialog
      .prompt()
      .title($translate.instant('CONTENTPACKAGE.DUPLICATE_CONTENT_PACKAGE'))
      .placeholder($translate.instant('CONTENTPACKAGE.PACKAGE_NAME'))
      .initialValue('')
      .targetEvent(ev)
      .multiple(true)
      .required(true)
      .ok($translate.instant('CONTENTPACKAGE.OK'))
      .cancel($translate.instant('CONTENTPACKAGE.CANCEL'))

    $mdDialog.show(confirm).then(
      function (result) {
        if (result) {
          ContentPackage.duplicateContentPackage({
            name: result,
            contentPackageId: $scope.package.id
          })
            .$promise.then(res => {
              $state.go('app.content-package.show', { id: res.result })
            })
            .catch(console.log)
        }
      },
      function () {}
    )
  }

  $scope.getUrlFromObj = getUrlFromObj

  $scope.tagPlaceholder = $translate.instant('CONTENTPACKAGE.INPUT_TAG')
  $scope.loadTags = function (q) {
    return []
  }

  $scope.cancel = () => window.history.back()

  const fieldsCorrect = () => {
    if (!$scope.package.tags || $scope.package.tags.length === 0) {
      $scope.package.tags = []
    }

    if (!$scope.package.groups) {
      $scope.package.groups = []
    }

    if (!$scope.package.categories) {
      $scope.package.categories = []
    }
  }
  const prepareForSave = function prepareForSave () {
    fieldsCorrect()
    const copy = angular.copy($scope.package)
    return _.omit(copy, ['documents', 'image_object'])
  }

  $scope.savePackage = () => {
    const tmp = prepareForSave()
    ContentPackage.prototype$patchAttributes({ id: tmp.id }, tmp)
      .$promise.then(data => $state.go('app.content-package.list'))
      .catch(res => {
        $scope.translate($rootScope.appSettings.defaultContentLanguage)
        $timeout(
          () => {
            ErrorHandlerService.formlyErrorHandler(
              res,
              $scope.packageFields,
              $scope.contentPackageForm
            )
          },
          0,
          false
        )
      })
  }

  $scope.addPackage = () => {
    const tmp = prepareForSave()
    ContentPackage.create(tmp)
      .$promise.then(res => {
        $state.go('app.content-package.show', {
          contentType: 'released',
          id: res.id
        })
      })
      .catch(ex => {
        $scope.translate($rootScope.appSettings.defaultContentLanguage)
        $timeout(
          () => {
            ErrorHandlerService.formlyErrorHandler(
              ex,
              $scope.packageFields,
              $scope.contentPackageForm
            )
          },
          0,
          false
        )
      })
  }
  $scope.deletePackage = function deletePackage (event) {
    event.stopPropagation()
    event.preventDefault()
    DialogService.deleteDialog(
      $translate.instant('CONTENTPACKAGE.REAL_DELETE_PACK') +
        ` ${$scope.package.name}?`,
      'delete'
    ).then(
      function () {
        ContentPackage.destroyById({ id: $scope.package.id })
          .$promise.then(() =>
            $state.go('app.content-package.list', {}, { reload: true })
          )
          .catch(e => console.log(e))
      },
      function () {}
    )
  }
  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title:
      !$scope.package || !$scope.package.id
        ? $translate.instant('CONTENTPACKAGE.NEW_PACKAGE')
        : $translate.instant('CONTENTPACKAGE.EDIT_PACKAGE'),
    fabButton: {},
    menuHideExpression: () => isNew,
    menu: [
      {
        content: `<md-button ng-click="duplicateContentPackage($event)" class="md-default-theme md-ink-ripple" data-testid="duplicate-content-package">
                    <md-icon md-font-icon="icon-content-copy"></md-icon>
                    <span>
                      ${$translate.instant(
                        'CONTENTPACKAGE.DUPLICATE_CONTENT_PACKAGE'
                      )}
                    </span>
                  </md-button>`,
        hideExpression: () =>
          isNew || !PermissionUtils.isPermit('ContentPackage', 'create')
      },
      {
        content: `<md-button ng-click="deletePackage($event)" class="md-default-theme md-ink-ripple" data-testid="delete-content-package">
                    <md-icon md-font-icon="icon-delete"></md-icon>
                      <span>${$translate.instant(
                        'CONTENTPACKAGE.DELETE'
                      )}</span>
                  </md-button>`,
        hideExpression: () =>
          isNew || !PermissionUtils.isPermit('ContentPackage', 'deleteById')
      }
    ]
  }
}
module.exports = NewEditPackageController
