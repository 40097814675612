'use strict'
/* global $ window */

/** @ngInject */
function PartsNewEditController (
  $scope,
  $rootScope,
  $translate,
  $state,
  PermissionUtils,
  ResolvedPart,
  PartAssembly,
  DialogService
) {
  $scope.resolvedPart = ResolvedPart

  const cannotDelete =
    $scope.resolvedPart === null ||
    !PermissionUtils.isPermit('PartAssembly', 'deletePartAssembly')

  $scope.functionHelper = {
    afterSaveHandler: function () {
      $state.go('app.parts.list')
    }
  }

  $scope.deletePart = function deletePart (event) {
    event.stopPropagation()
    event.preventDefault()
    DialogService.deleteDialog(
      $translate.instant(
        'PartAssembly.REAL_DELETE',
        {},
        null,
        null,
        'sceParameters'
      ),
      'delete'
    ).then(
      function () {
        $rootScope.loadingProgress = true
        PartAssembly.deletePartAssembly({
          number: $scope.resolvedPart.number
        })
          .$promise.then(({ deleted }) => {
            $rootScope.loadingProgress = false
            if (deleted === true) {
              $state.go('app.parts.list')
            } else {
              $rootScope.showErrorToast('NP-8100')
            }
          })
          .catch(e => {
            $rootScope.loadingProgress = false
            console.error(e)
            $rootScope.showErrorToast($translate.instant(e?.data?.error?.code))
          })
      },
      function () {}
    )
  }

  $scope.cancel = function cancel () {
    $state.go('app.parts.list')
  }

  $rootScope.$on('msNavigation::toggle', () => {
    $(window).trigger('resize')
  })

  $scope.headerOptions = {
    icon: 'icon-codepen',
    template: require('app/templates/headers/simple.html'),
    title: $scope.resolvedPart
      ? $translate.instant('PartAssembly.EDIT_PART')
      : $translate.instant('PartAssembly.NEW_PART'),
    fabButton: {},
    menuHideExpression: () => cannotDelete,
    menu: [
      {
        content: `<md-button ng-click="deletePart($event)" class="md-default-theme md-ink-ripple" data-testid="delete-part">
                    <md-icon md-font-icon="icon-delete"></md-icon>
                      <span translate="BUTTONS.DELETE"></span>
                  </md-button>`,
        hideExpression: () => cannotDelete
      }
    ]
  }
}

module.exports = {
  PartsNewEditController
}
