require('./edit.scss')
/* global _ alert CodeMirror */
/** @ngInject */
function AddEditFormulaController (
  $scope,
  $translate,
  locals,
  $timeout,
  EvalService,
  $mdDialog
) {
  $scope.modelValue = locals.value || ''
  if ($scope.modelValue !== '') {
    // eslint-disable-next-line
    const regex = /(variables|customFields)\["(.*?)\"]/gm
    let m
    let formula = _.clone($scope.modelValue)
    while ((m = regex.exec(formula))) {
      const field = m[2]
      // eslint-disable-next-line
      const escapedField = field.replace(/"/g, '\\"')
      formula = formula.replace(field, escapedField)
    }
    $scope.modelValue = formula
  }

  const options = _.cloneDeep(locals.options)
  if (locals.type) {
    if (locals.type === 'string') {
      options.customFields = locals.options.customFields.filter(
        customField => customField.type !== 'resource'
      )
    } else if (locals.type !== 'string' && locals.type !== 'text') {
      options.customFields = locals.options.customFields.filter(
        customField =>
          customField.type === locals.type ||
          (customField.type === 'date' && locals.type === 'number')
      )
      options.variables = locals.options.variables.filter(
        variable =>
          variable.type === locals.type ||
          (locals.type === 'number' && variable.type === 'date')
      )
    }

    options.variables.push({
      name: $translate.instant('COMMON.CURRENT_DATE'),
      value: new Date(),
      type: 'date'
    })
  }

  $scope.displayedOptions = options || {}

  $scope.selectElement = function selectElement (type, value) {
    insertTextAtCursor(
      $scope.editorJS,
      `${type}["${value.name.replace(/"/g, '\\"')}"]`
    )
  }

  $scope.filterList = function filterList () {
    if ($scope.filter) {
      const obj = {}
      _.mapKeys(options, (values, header) => {
        values.forEach(value => {
          const regex = new RegExp($scope.filter, 'i')
          if (value.name.match(regex)) {
            if (!obj[header]) {
              obj[header] = []
            }
            obj[header].push(value)
          }
        })
      })
      $scope.displayedOptions = obj
    } else {
      $scope.displayedOptions = options
    }
  }

  const insertTextAtCursor = function insertTextAtCursor (editor, text) {
    const doc = editor.getDoc()
    const cursor = doc.getCursor()
    doc.replaceRange(text, cursor)
  }
  import(/* webpackChunkName: "CodeMirror" */ 'app/chunks/CodeMirror').then(
    mod => {
      $scope.editorJS = CodeMirror.fromTextArea(
        document.getElementById('code-js-formula'),
        {
          value: $scope.modelValue,
          lineNumbers: true,
          indentUnit: 2,
          lineWrapping: true,
          lintOnChange: false,
          mode: 'javascript',
          gutters: ['CodeMirror-lint-markers'],
          lint: false
        }
      )

      $scope.editorJS.setSize('100%', '100%')
      setTimeout(() => {
        $scope.editorJS.getDoc().setValue($scope.modelValue || '')
        $scope.editorJS.refresh()
      }, 10)

      $scope.editorJS.on('change', function (instance) {
        const newValue = instance.getValue()
        if (newValue !== $scope.modelValue) {
          $scope.modelValue = newValue
        }
      })
    }
  )

  $scope.close = function () {
    $mdDialog.hide()
  }
  $scope.save = function () {
    // eslint-disable-next-line
    const regex = /(variables|customFields)\["(.*?)\"]/gm
    let m
    let formula = _.clone($scope.modelValue)
    while ((m = regex.exec(formula))) {
      const field = m[2]
      // eslint-disable-next-line
      const escapedField = field.replace('\\"', '"')
      formula = formula.replace(field, escapedField)
    }
    $scope.modelValue = formula
    const result = EvalService.checkExpression($scope.modelValue, {
      ...locals.options,
      session: {
        getChildSerialByPartNumber: function (partNumber) {
          return partNumber
        }
      }
    })
    if (result) {
      if (result.error && result.error === 'uomNotValid') {
        alert(
          `'${result.uom}' is not a valid time unit. valid time units are:  'milliseconds','seconds','minutes','hours','days','months','years'`
        )
      } else {
        $mdDialog.hide($scope.modelValue)
      }
    } else {
      alert('error in formula syntax')
    }
  }
}

module.exports = AddEditFormulaController
