/* global */
require('./variants.dialog.scss')
/** @ngInject */
function VariantsLoadDialogController ($scope, $translate, $mdDialog, locals) {
  $scope.select = () => {
    $mdDialog.hide($scope.model.variant)
  }

  $scope.cancel = () => $mdDialog.cancel()

  const initDialog = async function initDialog () {
    $scope.page = locals.page
    $scope.model = {
      method: false,
      variant: null
    }
  }

  initDialog()
}
module.exports = VariantsLoadDialogController
