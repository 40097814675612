import { FullScreenViewer } from 'iv-viewer'
const debug = require('debug')('nextplus:lightbox')
/** @ngInject */
function mdLightbox () {
  return {
    restrict: 'A',
    scope: {
      mdLightbox: '=mdLightbox'
    },
    link: function ($scope, elem, attrs) {
      elem.addClass('image-click')

      elem.on('click', function () {
        if ($scope.mdLightbox === false) {
          debug('mdLightbox expression is false, ignoring the click event')
          return
        }
        const viewer = new FullScreenViewer({})
        if (elem.attr('lightbox-src')) {
          viewer.show(elem.attr('lightbox-src'))
        } else if (elem.attr('src')) {
          viewer.show(elem.attr('src'))
        }
      })
    }
  }
}

module.exports = mdLightbox
