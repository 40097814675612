/* global _ */
export const blacklistNPTColumns = [
  '_id',
  'deletedAt',
  'createdAt',
  'modified',
  'workflowSessionItemId',
  'formId'
]
export const getPdfViewerUrl = function getPdfViewerUrl (baseUrl, pdfUrl) {
  return `${baseUrl}/pdfViewer/index.html?pdfUrl=${encodeURIComponent(pdfUrl)}`
}
export const move = function move (array, moveIndex, toIndex) {
  /* #move - Moves an array item from one position in an array to another.
      Note: This is a pure function so a new array will be returned, instead
      of altering the array argument.
      Arguments:
      1. array     (String) : Array in which to move an item.         (required)
      2. moveIndex (Object) : The index of the item to move.          (required)
      3. toIndex   (Object) : The index to move item at moveIndex to. (required)
    */
  const item = array[moveIndex]
  const length = array.length
  const diff = moveIndex - toIndex

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, moveIndex),
      ...array.slice(moveIndex + 1, length)
    ]
  } else if (diff < 0) {
    // move right
    const targetIndex = toIndex + 1
    return [
      ...array.slice(0, moveIndex),
      ...array.slice(moveIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, length)
    ]
  }
  return array
}

export const mapOrder = function mapOrder (array, order, key) {
  array.sort(function (a, b) {
    const A = a[key]
    const B = b[key]
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

export const paddingNumber = function paddingNumber (num, size) {
  num = num.toString()
  while (num.length < size) num = '0' + num
  return num
}

export const isNullOrUndefined = function isNullOrUndefined (value) {
  return _.isUndefined(value) || value === null
}

export const removeUnnecessaryColumns = function removeUnnecessaryColumns (
  columns
) {
  return _.cloneDeep(
    columns.filter(column => !blacklistNPTColumns.includes(column.id))
  )
}

export const isISODate = function isISODate (value) {
  if (new Date(value) !== 'Invalid Date' && !isNaN(new Date(value))) {
    if (value === new Date(value).toISOString()) {
      return true
    }
  }
  return false
}

export const isDate = function isDate (value) {
  switch (typeof value) {
    case 'number':
      return false
    case 'string':
      return isISODate(value)
    case 'object':
      if (value instanceof Date) {
        return !isNaN(value.getTime())
      }
      return false
    default:
      return false
  }
}

const operatorsConverter = {
  '==': 'equal',
  '!=': 'not_equal',
  '<': 'less',
  '<=': 'less_or_equal',
  '>': 'greater',
  '>=': 'greater_or_equal',
  changes: 'changes',
  changes_to: 'changes_to',
  changes_from: 'changes_from',
  in: 'includes',
  equal: '==',
  not_equal: '!=',
  less: '<',
  less_or_equal: '<=',
  greater: '>',
  greater_or_equal: '>=',
  after: '>',
  before: '<',
  includes: 'includes'
}

const dateoperatorsConverter = {
  '==': 'equal',
  '!=': 'not_equal',
  '<': 'before',
  '>': 'after',
  after: '>',
  before: '<'
}

export const convertOldTriggers = function convertOldTriggers (
  trigger,
  fieldsAndVariables
) {
  if (!trigger.conditions) return []
  trigger.conditions = trigger.conditions.map(condition => {
    condition.values = condition.values.map(conditionValue => {
      const hasOldConditions = conditionValue.variable
      if (!isNullOrUndefined(hasOldConditions)) {
        const { variable, operation, value } = conditionValue
        const variableFromList = fieldsAndVariables.find(
          fv => fv.id === `variable_${variable}`
        )
        if (variableFromList) {
          const newValue =
            variableFromList.type === 'date' && value ? new Date(value) : value
          return {
            id: variableFromList.id,
            field: variableFromList.id,
            value: newValue,
            operator:
              variableFromList.type && variableFromList.type === 'date'
                ? dateoperatorsConverter[operation]
                : operatorsConverter[operation],
            type: variableFromList.type || null,
            input: variableFromList.input || null
          }
        } else {
          const type = isISODate(value)
            ? 'date'
            : _.isNumber(value)
            ? 'double'
            : 'string'
          const operator =
            type === 'date'
              ? dateoperatorsConverter[operation]
              : operatorsConverter[operation]
          const newValue = type === 'date' && value ? new Date(value) : value
          return {
            id: `variable_${variable}`,
            field: `variable_${variable}`,
            value: newValue,
            operator,
            type,
            input: null
          }
        }
      }
      return conditionValue
    })
    return condition
  })
  return trigger
}

export const processArray = async function processArray (array, func) {
  for (const item of array) {
    await func(item)
  }
}

export const isNilOrEmpty = function isNilOrEmpty (value) {
  return _.isNil(value) || _.isEmpty(value)
}

export const isUUID = function isUUID (value) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    value
  )
}

export const sleep = function sleep (ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export const splashScreenGone = function splashScreenGone () {
  return new Promise(async (resolve, reject) => {
    let tries = 0
    while (document.querySelector('ms-splash-screen') && tries < 1000) {
      tries++
      await sleep(50)
    }
    if (!document.querySelector('ms-splash-screen')) return resolve()
    return reject(new Error(`splash screen still exstis after ${tries} tries`))
  })
}

export const stringToSlug = function stringToSlug (str) {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-zA-Z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str
}

export const downloadXLSX = async function downloadXLSX (
  headers,
  data,
  filename = 'output'
) {
  const XLSX = await import(/* webpackChunkName: "xlsx" */ 'xlsx')
  // Create header row from headers array
  const headerRow = headers.map(h => h.label)

  // Create data rows from data array
  const dataRows = data.map(row => {
    return headers.map(h => row[h.value])
  })

  // Combine header and data rows
  const output = [headerRow].concat(dataRows)

  // Generate workbook
  const ws = XLSX.utils.aoa_to_sheet(output)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  // Generate binary string
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

  function s2ab (s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  // Create blob and URL
  const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
  const blobURL = window.URL.createObjectURL(blob)

  // Create temporary download link and click it
  const tempLink = document.createElement('a')
  tempLink.href = blobURL
  tempLink.setAttribute('download', filename + '.xlsx')
  tempLink.click()
}
