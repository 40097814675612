/** @ngInject */
function CatalogListController (
  $scope,
  $rootScope,
  $translate,
  Page,
  PermissionUtils,
  Resolved
) {
  $scope.items = Resolved
  $scope.PermissionUtils = PermissionUtils
  $scope.page = {
    title: $translate.instant('CATALOG.HOME')
  }
  Page.setTitleText($scope.page.title)

  $rootScope.title = $scope.page.title
}

module.exports = CatalogListController
