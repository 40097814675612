/** @ngInject */
function nextBox () {
  require('./next-box.scss')
  return {
    template: require('./next-box.html'),
    bindings: {
      image: '<',
      name: '<',
      metadata: '<',
      mainIcon: '<',
      mainIconClass: '<',
      icon: '<',
      smallImage: '<',
      link: '<',
      imageFallback: '<',
      onClick: '<',
      editLink: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function NextBox ($compile, $scope, $element) {
        this.$onInit = function () {
          if (!this.image && this.imageFallback !== false && !this.mainIcon) {
            this.image = './assets/placeholder.png'
          }
          if (!this.image && !this.mainIcon) {
            $element[0].classList.add('no-image')
          }
        }
      }
  }
}

module.exports = nextBox
