/* global moment */

/** @ngInject */
function formHistory () {
  require('./form-history.scss')
  return {
    template: require('./form-history.html'),
    bindings: {
      changeLog: '<',
      users: '<',
      fields: '<',
      formFields: '<',
      units: '<',
      autoCreateOptions: '<',
      bulkProductionOptions: '<',
      linkedForms: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function formHistory (
        $scope,
        $translate,
        FieldUtilsService,
        htmlWork,
        DateTimeFormatService,
        $compile
      ) {
        const that = this

        const manipulateLinkedForms = function manipulateLinkedForms (
          linkedForms
        ) {
          let html = '<div layout="row" >'
          linkedForms.forEach((id, index) => {
            if ($scope.linkedFormsById[id]) {
              html += `<a class="form-link" ui-sref="app.form.display({id:'${id}'})" target="_blank" >#${$scope.linkedFormsById[id].number}</a>`
              if (index !== linkedForms.length - 1) {
                html += ', '
              }
            }
          })
          html += '</div>'
          return $compile(html)($scope)[0].outerHTML
        }
        const manipulateLog = function manipulateLog (log) {
          if (
            /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i.test(
              log.fieldId
            )
          ) {
            // custom field
            const relevantField = that.formFields.find(
              ({ id }) => id === log.fieldId
            )
            const field = that.fields.find(
              ({ id }) => id === relevantField.fieldId
            )
            log.fieldName = field ? field.title : ''
            log.oldValue =
              FieldUtilsService.getFieldHTMLValue(
                that.fields,
                field.id,
                log.oldValue,
                that.users,
                'column',
                false
              ) || 'None'
            log.newValue =
              FieldUtilsService.getFieldHTMLValue(
                that.fields,
                field.id,
                log.newValue,
                that.users,
                'column',
                false
              ) || 'None'
          } else {
            // meta field
            if (log.type) {
              log.fieldName = $translate.instant(
                `WF.CHANGE_LOGS_FIELDS.${log.type}`
              )
            } else {
              log.fieldName = $translate.instant(
                `FORM.CHANGE_LOGS.FIELDS.${log.fieldId}`
              )
            }
            if (log.fieldId === 'status') {
              log.oldValue = log.oldValue
                ? $translate.instant(log.oldValue)
                : 'None'
              log.newValue = $translate.instant(log.newValue)
            } else if (
              log.fieldId === 'closedBy' ||
              log.fieldId === 'ownerId'
            ) {
              log.oldValue = log.oldValue
                ? htmlWork.htmlEncode(that.users[log.oldValue])
                : 'None'
              log.newValue = log.newValue
                ? htmlWork.htmlEncode(that.users[log.newValue])
                : 'None'
            } else if (log.fieldId === 'assignee') {
              log.oldValue =
                log.oldValue && log.oldValue.length
                  ? log.oldValue
                      .map(id => htmlWork.htmlEncode(that.users[id]))
                      .join(',\n ')
                  : 'None'
              log.newValue =
                log.newValue && log.newValue.length
                  ? log.newValue
                      .map(id => htmlWork.htmlEncode(that.users[id]))
                      .join(',\n ')
                  : 'None'
            } else if (log.type === 'managerApprovalId') {
              log.oldValue = log.oldValue
                ? htmlWork.htmlEncode(that.users[log.oldValue])
                : 'None'
              log.newValue = log.newValue
                ? htmlWork.htmlEncode(that.users[log.newValue])
                : 'None'
            } else if (log.type === 'unitId') {
              log.oldValue = log.oldValue
                ? htmlWork.htmlEncode($scope.unitsById[log.oldValue])
                : 'None'
              log.newValue = log.newValue
                ? htmlWork.htmlEncode($scope.unitsById[log.newValue])
                : 'None'
            } else if (log.type === 'autoCreate') {
              log.oldValue = log.oldValue
                ? htmlWork.htmlEncode(
                    $scope.autoCreateOptionsById[log.oldValue]
                  )
                : 'None'
              log.newValue = log.newValue
                ? htmlWork.htmlEncode(
                    $scope.autoCreateOptionsById[log.newValue]
                  )
                : 'None'
            } else if (log.type === 'bulkProduction') {
              log.oldValue = log.oldValue
                ? htmlWork.htmlEncode(
                    $scope.bulkProductionOptionsById[log.oldValue]
                  )
                : 'None'
              log.newValue = log.newValue
                ? htmlWork.htmlEncode(
                    $scope.bulkProductionOptionsById[log.newValue]
                  )
                : 'None'
            } else if (
              log.fieldId === 'linkedTo' ||
              log.fieldId === 'linkedFrom'
            ) {
              log.oldValue =
                log.oldValue?.length > 0
                  ? manipulateLinkedForms(log.oldValue)
                  : 'None'
              log.newValue =
                log.newValue?.length > 0
                  ? manipulateLinkedForms(log.newValue)
                  : 'None'
            }
          }
          if (log.oldValue === null) {
            log.oldValue = 'None'
          }
          return log
        }
        this.$onChanges = function (changes) {
          if (changes.changeLog) {
            const changeLog = []
            const that = this
            $scope.formFields = that.formFields
            $scope.fields = that.fields
            $scope.usersById = that.users
            $scope.unitsById = that.units
            $scope.linkedFormsById = that.linkedForms
            $scope.autoCreateOptionsById = that.autoCreateOptions
            $scope.bulkProductionOptionsById = that.bulkProductionOptions
            let currentUser
            let currentDate

            for (let i = 0; i < that.changeLog.length; i++) {
              const element = that.changeLog[i]
              const elementDate = new Date(moment(element.date).format())
              if (
                currentDate &&
                currentUser &&
                elementDate.getTime() === currentDate.getTime() &&
                element.userId === currentUser
              ) {
                // same user with same modification date
                const lastChangeLog = changeLog[changeLog.length - 1]
                lastChangeLog.changes.push(manipulateLog(element))
              } else {
                changeLog.push({
                  date: DateTimeFormatService.formatDateTime(
                    elementDate,
                    'dateTime'
                  ),
                  user: $translate.instant(
                    'FORM.CHANGE_LOGS.CHANGES_BY',
                    {
                      user:
                        element.userId === 'trigger'
                          ? 'Trigger'
                          : that.users[element.userId]
                    },
                    undefined,
                    undefined,
                    'escapeParameters'
                  ),
                  changes: [manipulateLog(element)]
                })
              }
              currentUser = element.userId
              currentDate = elementDate
            }

            $scope.changeLog = changeLog
          }
        }
        this.$onInit = function () {}
      }
  }
}

module.exports = formHistory
