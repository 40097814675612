'use strict'
/* global _ $ angular */
import DocumentHistoryReleaseController from './release-dialog'

/** @ngInject */
function DocumentNewEditController (
  $scope,
  $rootScope,
  $mdDialog,
  $mdToast,
  $translate,
  $state,
  $window,
  PermissionUtils,
  UserModel,
  Document,
  ResolvedDocument,
  htmlWork,
  $timeout,
  FormlyHelper,
  ErrorHandlerService,
  MultiTranslateService,
  DateTimeFormatService
) {
  $scope.PermissionUtils = PermissionUtils
  $scope.Document = Document

  $scope.$on('imgSelected', () => {
    setTimeout(() => {
      $('.fa-trash-o').addClass('icon').addClass('icon-delete')
      $('.fa-align-left').addClass('icon').addClass('icon-format-align-left')
      $('.fa-align-justify')
        .addClass('icon')
        .addClass('icon-format-align-justify')
      $('.fa-align-right').addClass('icon').addClass('icon-format-align-right')
    }, 200)
  })

  $scope.setContent = function setContent () {
    const translatedDoc = MultiTranslateService.getForView(
      Document,
      $rootScope.currentLang,
      angular.copy($scope.document)
    )
    $scope.previewContent = htmlWork.textAngularSanitize(translatedDoc.content)
    $scope.previewTitle = translatedDoc.name
  }

  $scope.removeContent = function removeContent () {
    $scope.previewContent = ''
  }

  $scope.tabSelected = function tabSelected (num) {
    $scope.selectedTab = num
    switch (num) {
      case 1:
        $scope.setContent()
        break
      case 2:
        $scope.removeContent()
        break
      case 3:
        $scope.removeContent()
        $scope.getDocumentHistory()
        break
    }
  }

  $(document).ready(() => {
    $('button:has(i):has(.icon-filmstrip)').hide()
  })

  const errorHandler = function errorHandler (res) {
    $scope.translate($rootScope.appSettings.defaultContentLanguage)
    $timeout(
      () => {
        ErrorHandlerService.formlyErrorHandler(
          res,
          $scope.locationFields,
          $scope.locationForm
        )
      },
      0,
      false
    )
  }

  $scope.addDocument = async function addDocument (action) {
    $scope.document.plainContent = ''
    if (!$scope.document.name || $scope.document.name === '') {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'document.body',
          theme: 'error-toast',
          hideDelay: 3500
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('CONTENTPACKAGE.ERRORS.DOCUMENT_NAME_IS_REQUIRED')
      )
      return
    }
    if (action && action.publish) {
      $mdDialog
        .show({
          controller: DocumentHistoryReleaseController,
          template: require('./release-dialog.html'),
          parent: angular.element(document.body),
          targetEvent: '',
          locals: {},
          clickOutsideToClose: true
        })
        .then(
          async function (answer) {
            $rootScope.loadingProgress = true
            let releaseData = {}
            if (answer) {
              releaseData = {
                releaseName: answer.releaseName,
                releaseMessage: answer.releaseMessage,
                releaseType: answer.releaseType
              }
            }
            try {
              await Document.addDocument({
                contentPackageId: $scope.state.params.id,
                document: $scope.document,
                releaseData
              }).$promise
              $rootScope.loadingProgress = false
              $state.go('app.content-package.show', {
                id: $scope.state.params.id
              })
            } catch (err) {
              errorHandler(err)
              $rootScope.loadingProgress = false
            } finally {
              $rootScope.loadingProgress = false
            }
          },
          function () {
            $rootScope.loadingProgress = false
          }
        )
    } else {
      // simple add without published
      try {
        await Document.addDocument({
          contentPackageId: $scope.state.params.id,
          document: $scope.document,
          releaseData: false
        })
        $rootScope.loadingProgress = false
        $state.go('app.content-package.show', { id: $scope.state.params.id })
      } catch (err) {
        errorHandler(err)
        $rootScope.loadingProgress = false
      } finally {
        $rootScope.loadingProgress = false
      }
    }
  }

  $scope.saveDocument = async function saveDocument (action) {
    $scope.document.plainContent = ''
    if (!$scope.document.name || $scope.document.name === '') {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'document.body',
          theme: 'error-toast',
          hideDelay: 3500
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('CONTENTPACKAGE.ERRORS.DOCUMENT_NAME_IS_REQUIRED')
      )
      return
    }
    const document = _.pick($scope.document, [
      'content',
      'icon',
      'plainContent',
      'name',
      'recordId',
      'translations',
      'fallbackStatus'
    ])

    if (action && action.publish) {
      $mdDialog
        .show({
          controller: DocumentHistoryReleaseController,
          template: require('./release-dialog.html'),
          parent: angular.element(document.body),
          targetEvent: '',
          locals: {},
          clickOutsideToClose: true
        })
        .then(
          async function (answer) {
            $rootScope.loadingProgress = true
            let releaseData = {
              id: $scope.document.id,
              recordId: $scope.document.recordId
            }
            if (answer) {
              releaseData = {
                id: $scope.document.id,
                recordId: $scope.document.recordId,
                releaseName: answer.releaseName,
                releaseMessage: answer.releaseMessage,
                releaseType: answer.releaseType
              }
            }
            if ($scope.document.releaseStatus === 'unreleased') {
              document.id = $scope.document.id
              document.latestVersion = true
            }
            try {
              await Document.saveDocument({
                contentPackageId: $scope.state.params.id,
                document,
                documentId: $scope.document.id,
                releaseData,
                releaseStatus: $scope.document.releaseStatus
              }).$promise
              $rootScope.loadingProgress = false
              $state.go('app.content-package.show', {
                id: $scope.state.params.id
              })
            } catch (err) {
              ErrorHandlerService.formlyErrorHandler(
                err,
                $scope.documentFields,
                $scope.documentForm
              )
            } finally {
              $rootScope.loadingProgress = false
            }
          },
          function () {}
        )
    } else {
      if ($scope.document.releaseStatus === 'unreleased') {
        // current record NOT released and user click save WITHOUT publish
        // update current document based on document id set it to last

        // current record NOT released and user click save AND publish
        // update current document to released version with last mark
        document.id = $scope.document.id
        document.latestVersion = true
      }
      // ELSE
      // current record released and user click save WITHOUT publish
      // create new not published record

      // current record released and user click save AND publish
      // create new published version
      $rootScope.loadingProgress = true
      try {
        await Document.saveDocument({
          contentPackageId: $scope.state.params.id,
          document,
          documentId: $scope.document.id,
          releaseData: false,
          releaseStatus: $scope.document.releaseStatus
        }).$promise
        $rootScope.loadingProgress = false
        $state.go('app.content-package.show', { id: $scope.state.params.id })
      } catch (err) {
        ErrorHandlerService.formlyErrorHandler(
          err,
          $scope.documentFields,
          $scope.documentForm
        )
      } finally {
        $rootScope.loadingProgress = false
      }
    }
  }

  $scope.getDocumentHistory = async function getDocumentHistory () {
    if ($scope.releases.length === 0) {
      $rootScope.shadowEnable = true
      try {
        let historyRevisions = await Document.find({
          filter: { where: { recordId: $state.params.documentId } }
        }).$promise
        const userIds = new Set(
          historyRevisions.map(historyRevision => historyRevision.UserId)
        )
        const users = await UserModel.find({
          filter: {
            where: {
              id: { inq: [...userIds] }
            },
            fields: { id: true, displayName: true }
          }
        }).$promise.then(data => data)

        historyRevisions = historyRevisions.sort(
          (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)
        )
        historyRevisions.map(historyRevision => {
          historyRevision.content = htmlWork.textAngularSanitize(
            historyRevision.content
          )
          return historyRevision
        })
        $scope.releases = historyRevisions.map(release => {
          if (release.releaseDate) {
            release.releaseDate = DateTimeFormatService.formatDateTime(
              release.releaseDate,
              'dateTime'
            )
          }
          release.created = DateTimeFormatService.formatDateTime(
            release.created,
            'dateTime'
          )
          return release
        })
        $scope.release = $scope.releases[0]

        $scope.users = users

        $scope.userNameById = users.reduce((obj, user) => {
          obj[user.id] = user.displayName
          return obj
        }, {})

        $rootScope.shadowEnable = false
      } catch (err) {
        console.error('GET_DOCUMENT_HISTORY_ERROR', err)
        $rootScope.shadowEnable = false
      }
    }
  }

  $scope.revert = async function revert () {
    if ($scope.release.releaseStatus !== 'released') {
      $mdDialog
        .show({
          controller: DocumentHistoryReleaseController,
          template: require('./release-dialog.html'),
          parent: angular.element(document.body),
          targetEvent: '',
          locals: {},
          clickOutsideToClose: true
        })
        .then(
          async function (answer) {
            $rootScope.loadingProgress = true
            try {
              await Document.revertVersion({
                data: {
                  id: $scope.release.id,
                  recordId: $scope.release.recordId,
                  releaseName: answer?.releaseName ? answer.releaseName : null,
                  releaseMessage: answer?.releaseMessage
                    ? answer.releaseMessage
                    : null,
                  releaseType: answer?.releaseType ? answer.releaseType : null
                }
              }).$promise
              $state.go('app.content-package.show', {
                id: $scope.state.params.id
              })
            } catch (err) {
              console.error(err)
            } finally {
              $rootScope.loadingProgress = false
            }
          },
          function () {
            $rootScope.loadingProgress = false
          }
        )
    } else {
      $rootScope.loadingProgress = true
      try {
        await Document.revertVersion({
          data: {
            id: $scope.release.id,
            recordId: $scope.release.recordId,
            releaseName: $scope.release?.releaseName
              ? $scope.release.releaseName
              : null,
            releaseMessage: $scope.release?.releaseMessage
              ? $scope.release.releaseMessage
              : null,
            releaseType: $scope.release?.releaseType
              ? $scope.release.releaseType
              : null
          }
        }).$promise
      } catch (err) {
        $rootScope.loadingProgress = false
        $state.go('app.content-package.show', { id: $scope.state.params.id })
      } finally {
        $rootScope.loadingProgress = false
        $state.go('app.content-package.show', { id: $scope.state.params.id })
      }
    }
  }

  $scope.selectRevision = function selectRevision (rev) {
    $scope.release = rev
  }

  $scope.cancel = function cancel () {
    $state.go('app.content-package.show', { id: $state.params.id })
  }

  $scope.deleteDocument = function deleteDocument (event) {
    event.stopPropagation()
    event.preventDefault()
    $mdDialog
      .show(
        $mdDialog
          .confirm()
          .title(
            $translate.instant('CONTENTPACKAGE.REAL_DELETE') +
              ` ${$scope.document.name}?`
          )
          .targetEvent()
          .clickOutsideToClose(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('BUTTONS.DELETE'))
          .cancel($translate.instant('BUTTONS.CANCEL'))
      )
      .then(
        function () {
          Document.deleteDocument({
            document: $scope.document,
            contentPackageId: $state.params.id
          })
            .$promise.then(res => {
              if (res && res.result && res.result.error) {
                $rootScope.showErrorToast(res.result.msg)
              } else {
                $state.go(
                  'app.content-package.show',
                  { id: $state.params.id },
                  { reload: true }
                )
              }
            })
            .catch(e => {
              $rootScope.showErrorToast('NP-8100')
              console.error(e)
            })
        },
        function () {}
      )
  }

  const checkIfDocumentIsValid = function checkIfDocumentIsValid (html) {
    const textareaElement = document.querySelector(
      '.formly-field-inputTextAngular text-angular[data-testid="content"] textarea'
    )
    return (
      html !== '' &&
      textareaElement &&
      textareaElement.value !== null &&
      textareaElement.value !== undefined &&
      textareaElement.value !== ''
    )
  }

  const initScreen = function initScreen () {
    $scope.releases = []
    $scope.release = {}
    $scope.users = []
    $scope.userNameById = {}
    $scope.state = $state
    $scope.selectedTab = 2
    $scope.document = ResolvedDocument
      ? {
          id: ResolvedDocument.id,
          content: ResolvedDocument.content,
          plainContent: ResolvedDocument.plainContent,
          name: ResolvedDocument.name,
          icon: ResolvedDocument.icon,
          recordId: ResolvedDocument.recordId,
          releaseStatus: ResolvedDocument.releaseStatus,
          releaseName: ResolvedDocument.releaseName || '',
          releaseMessage: ResolvedDocument.releaseMessage || '',
          releasePlainMessage: ResolvedDocument.releasePlainMessage || '',
          releaseType: ResolvedDocument.releaseType || '',
          releaseDate: ResolvedDocument.releaseDate || null,
          translations: ResolvedDocument.translations || {}
        }
      : {
          content: '',
          plainContent: '',
          name: '',
          icon: ''
        }

    $scope.backDocument = angular.copy($scope.document)

    $scope.release = {
      releaseName: '',
      releaseMessage: '',
      releaseType: 'minor'
    }

    $scope.staticDocumentFields = [
      {
        className: 'display-flex',
        fieldGroup: [
          {
            key: 'name',
            type: 'input',
            className: 'flex-8 layout-row',
            templateOptions: {
              type: 'text',
              focus: true,
              required: true,
              label: $translate.instant('CONTENTPACKAGE.PACKAGE_NAME'),
              placeholder: $translate.instant('CONTENTPACKAGE.PACKAGE_NAME'),
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                if (options.formControl) {
                  options.formControl.$setValidity('serverError', true)
                }
              }
            }
          },
          {
            key: 'icon',
            type: 'selectIcon',
            className: 'flex-2 layout-row',
            templateOptions: {
              label: 'Icon',
              Icons: $window.Icons,
              onChange: function (value, options) {
                options.validation.errorExistsAndShouldBeVisible = null
                if (_.isArray(options.formControl)) {
                  options.formControl.forEach(formControl => {
                    formControl.$setValidity('serverError', true)
                  })
                } else {
                  options.formControl.$setValidity('serverError', true)
                }
              }
            }
          }
        ]
      },
      {
        key: 'content',
        type: 'inputTextAngular',
        templateOptions: {
          onKeydown: function (value, options) {
            options.validation.errorExistsAndShouldBeVisible = null
            if (options.formControl) {
              options.formControl.$setValidity('serverError', true)
            }
          }
        }
      }
    ]

    $scope.documentFields = FormlyHelper.buildFields(
      $scope.staticDocumentFields,
      Document
    )

    if (ResolvedDocument) {
      $timeout(() => {
        const isValid = checkIfDocumentIsValid(ResolvedDocument.content)
        if (!isValid) {
          $scope.document.content = htmlWork.textAngularSanitize(
            $scope.document.content
          )
        }
      }, 500)
    }
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title: !$scope.state.params.documentId
      ? $translate.instant('CONTENTPACKAGE.CREATE_NEW_DOCUMENT')
      : $translate.instant('CONTENTPACKAGE.EDIT_DOCUMENT'),
    fabButton: {}
  }
}

module.exports = DocumentNewEditController
