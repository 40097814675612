/** @ngInject */
function PartPriorityPullDialog (
  $scope,
  $translate,
  $mdDialog,
  $rootScope,
  $mdToast,
  PartAssembly,
  $state
) {
  $scope.inProgress = false
  $scope.formFields = [
    {
      key: 'sku',
      type: 'input',
      className: 'layout-row',
      templateOptions: {
        type: 'text',
        required: true,
        label: $translate.instant('WO.sku'),
        onChange: function (value, options) {}
      }
    }
  ]

  $scope.cancel = function () {
    $mdDialog.cancel()
  }
  $scope.part = {
    sku: ''
  }
  const openSuccessToast = function openSuccessToast (text) {
    $mdToast.show(
      $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: 'document.body',
        theme: 'success-toast',
        hideDelay: 1500
      })
    )
    $mdToast.updateTextContent(text)
  }

  $scope.save = async function save () {
    $scope.inProgress = true
    try {
      openSuccessToast(
        $translate.instant('PRIORITY.SETTINGS.PART_SUCCESSFULLY_SAVED')
      )
      const res = await PartAssembly.fetchPartFromPriority({
        sku: $scope.part.sku
      }).$promise
      if (res.success === true) {
        $state.go('app.parts.edit', { number: $scope.part.sku })
      } else {
        $mdToast.show(
          $mdToast.nextplus({
            position: $rootScope.toastLocation,
            parent: 'document.body',
            theme: 'error-toast',
            hideDelay: 1000 * 15
          })
        )
        $mdToast.updateTextContent(res.msg)
      }
    } catch (ex) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: 'document.body',
          theme: 'error-toast',
          hideDelay: 1000 * 15
        })
      )
      $mdToast.updateTextContent(JSON.stringify(ex))
      console.error(ex)
    } finally {
      $scope.inProgress = false
    }
  }
}

module.exports = PartPriorityPullDialog
