/* global _ */
/** @ngInject */
function combinationPartsTableDialogController (
  $scope,
  $rootScope,
  $state,
  $mdDialog,
  getUrlFromObj,
  locals,
  PartAssembly,
  KendoGridHelper,
  ViewsService,
  PartsIdsToBeDisabled,
  htmlWork
) {
  const disabledPartNumbers = PartsIdsToBeDisabled.result.concat(locals.number)

  $scope.selectParts = async function selectParts () {
    if (!$scope.selectedParts || $scope.selectedParts === 0) return []
    try {
      $rootScope.loadingProgress = true

      const res = await PartAssembly.find({
        filter: {
          where: {
            number: { inq: $scope.selectedParts }
          },
          fields: { id: true, name: true, recordId: true, number: true }
        }
      }).$promise
      $mdDialog.hide(res)
    } catch (err) {
      console.error('select part error', err)
    } finally {
      $rootScope.loadingProgress = false
    }
  }

  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.exists = function (number) {
    return $scope.selectedParts.indexOf(number) > -1
  }

  $scope.toggle = function (number) {
    const idx = $scope.selectedParts.indexOf(number)
    if (idx > -1) {
      $scope.selectedParts.splice(idx, 1)
    } else {
      $scope.selectedParts.push(number)
    }
  }

  const initScreen = async function initScreen () {
    $scope.selectedParts = locals.selectedParts || []
    const tableColumns = [
      // IMAGE
      {
        uniqueId: '9d7dfc26-f0b8-4a1e-a3bc-fe116c0623ea',
        field: 'picture_object',
        translateCode: 'PartAssembly.picture_object',
        type: 'object',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          if (!data.picture_object) return ''
          const url = getUrlFromObj(data.picture_object)
          if (!url || url === '') return ''
          return `<img width="60" height="60" md-lightbox lazy-load="${htmlWork.escapeHTMLQuotes(
            url
          )}" class="part-list-item-image" src="">`
        }
      },
      // NAME
      {
        uniqueId: '5cc5595a-8229-464e-800e-a961bd8badce',
        field: 'name',
        translateCode: 'PartAssembly.name'
        // media: '(min-width: 768px)',
      },
      // NUMBER
      {
        uniqueId: '65ce1d31-83f0-4ad6-9e38-d48db161aa49',
        field: 'number',
        translateCode: 'PartAssembly.number'
        // media: '(min-width: 768px)',
      },
      // DESCRIPTION
      {
        uniqueId: '2edef869-27bf-4296-b22d-f8cb68bb517a',
        field: 'description',
        translateCode: 'PartAssembly.description'
        // media: '(min-width: 768px)',
      },
      // ACTIONS
      {
        uniqueId: '71811184-f510-4a2e-9e86-2c983e8abeb0',
        field: 'id',
        translateCode: 'WF.ACTIONS',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          return `<div layout="row">
                    <md-checkbox    ng-disabled="PartsIdsToBeDisabled.includes('${htmlWork.escapeHTMLQuotes(
                      data.number
                    )}')" 
                                    style="margin-bottom: 0px;" ng-checked="exists('${htmlWork.escapeHTMLQuotes(
                                      data.number
                                    )}')" 
                                    data-testid="check-${htmlWork.escapeHTMLQuotes(
                                      data.number
                                    )}" ng-click="toggle('${htmlWork.escapeHTMLQuotes(
            data.number
          )}')">
                    </md-checkbox>
                  </div>`
        }
      }
    ]

    const basicFields = {
      id: true,
      recordId: true,
      name: true,
      number: true,
      description: true,
      picture: true,
      image: true,
      created: true,
      modified: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('PartAssembly', tableColumns)

    const baseFilter = {
      where: {
        number: { nin: disabledPartNumbers },
        isSerial: false,
        isCombinated: false
      },
      order: 'modified DESC',
      include: ['picture_object'],
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const defaultTableSetup = {
      stateName: `${$state.current.name}.combined-dialog`,
      ignoreParams: true,
      // model
      find: PartAssembly.find,
      count: PartAssembly.count,
      baseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    $scope.downloadFunction = () => {}

    $scope.combinedPartsKendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.combinedPartsKendoGrid.isBound()
  }

  initScreen()
}

module.exports = combinationPartsTableDialogController
