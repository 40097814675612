/* global _ */
/** @ngInject */
function SelectSubpartDialogController (
  getUrlFromObj,
  locals,
  $scope,
  PartAssembly,
  $translate,
  $state,
  $mdDialog,
  KendoGridHelper,
  ViewsService,
  PartsIdsToBeDisabled,
  htmlWork
) {
  $scope.alternative = locals.alternative

  $scope.selectedSubPartNumbers = locals.selectedSubPartNumbers || []

  PartsIdsToBeDisabled = PartsIdsToBeDisabled.result

  $scope.PartsIdsToBeDisabled = PartsIdsToBeDisabled.concat(locals.number)

  $scope.selectSubpart = function selectSubpart (number) {
    $mdDialog.hide(number)
  }

  $scope.checkboxChange = function checkboxChange (number) {
    const idx = $scope.selectedSubPartNumbers.findIndex(rId => rId === number)
    if (idx > -1) {
      $scope.selectedSubPartNumbers.splice(idx, 1)
    } else {
      $scope.selectedSubPartNumbers.push(number)
    }
  }

  $scope.exists = function exists (number) {
    return (
      $scope.selectedSubPartNumbers.findIndex(
        partNumber => partNumber === number
      ) > -1
    )
  }

  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.closeDialog = function closeDialog () {
    $mdDialog.hide($scope.selectedSubPartNumbers)
  }

  const initScreen = async function initScreen () {
    switch (locals.type) {
      case 'subpart':
        $scope.title = $translate.instant('PartAssembly.DIALOG_ADD.TITLE_SUB')
        break
      case 'convertible':
        $scope.title = $translate.instant(
          'PartAssembly.DIALOG_ADD.TITLE_CONVERTIBLE'
        )
        break
      case 'tool':
        $scope.title = $translate.instant('PartAssembly.DIALOG_ADD.TITLE_TOOL')
        break
      case 'alternative':
        $scope.title = $translate.instant(
          'PartAssembly.DIALOG_ADD.TITLE_ALTERNATIVE'
        )
        break
      default:
        $scope.title = ''
    }

    const tableColumns = [
      // IMAGE
      {
        uniqueId: '8cf1eca2-7c27-491a-84b7-0c4fecd325bb',
        field: 'picture_object',
        translateCode: 'PartAssembly.picture_object',
        type: 'object',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          if (!data.picture_object) return ''
          const url = getUrlFromObj(data.picture_object)
          if (!url || url === '') return ''
          return `<img width="60" height="60" md-lightbox lazy-load="${htmlWork.escapeHTMLQuotes(
            url
          )}" on-error-src="assets/images/no-image-icon.png" class="part-list-item-image" src="">`
        }
      },
      // NAME
      {
        uniqueId: 'f9ffa470-619b-4785-8d4f-e79383786781',
        field: 'name',
        translateCode: 'PartAssembly.name'
        // media: '(min-width: 768px)',
      },
      // NUMBER
      {
        uniqueId: '61d31175-b707-4510-a420-533685390487',
        field: 'number',
        translateCode: 'PartAssembly.number'
        // media: '(min-width: 768px)',
      },
      // DESCRIPTION
      {
        uniqueId: '44e5d4fb-0113-4638-8803-722a28a21279',
        field: 'description',
        translateCode: 'PartAssembly.description'
        // media: '(min-width: 768px)',
      },
      // ACTIONS
      {
        uniqueId: '876dbb79-d8aa-4fc2-8259-2567905a74d9',
        field: 'recordId',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                      <md-checkbox 
                        ng-disabled="PartsIdsToBeDisabled.includes('${htmlWork.escapeHTMLQuotes(
                          data.number
                        )}')"
                        ng-checked="exists('${htmlWork.escapeHTMLQuotes(
                          data.number
                        )}')" 
                        ng-click="checkboxChange('${htmlWork.escapeHTMLQuotes(
                          data.number
                        )}')"
                        style="margin: 0;">
                      </md-checkbox>
                  </div>`
        }
      }
    ]

    const basicFields = {
      id: true,
      recordId: true,
      name: true,
      number: true,
      description: true,
      picture: true,
      image: true,
      created: true,
      modified: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('PartAssembly', tableColumns)

    const baseFilter = {
      where: {},
      include: ['picture_object'],
      fields: _.merge(basicFields, customFieldsDisplay)
    }
    if (locals.type === 'convertible') {
      baseFilter.where.isPhantom = false
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const defaultTableSetup = {
      stateName: `${$state.current.name}.dialog`,
      ignoreParams: true,
      // model
      find: PartAssembly.find,
      count: PartAssembly.count,
      cleanBaseFilter: baseFilter,
      baseFilter: baseFilter,
      selectedViewId: selectedViewId,
      columns: defaultTableColumns
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.$applyAsync()
  }

  initScreen()
}

module.exports = SelectSubpartDialogController
