/* global _ */
/** @ngInject */
import Languages from 'app/constants/languages'
import modelsHandling from 'root/../common/services/translations/model-special-handling'
function translateBar () {
  return {
    restrict: 'E',
    template: require('./translate-bar.html'),
    replace: true,
    scope: {
      contentProperty: '=',
      formlyModel: '=',
      formlyFields: '=',
      editLanguage: '=',
      onChange: '&',
      setDisabled: '=',
      translationEvent: '=?',
      overwriteEvent: '&?'
    },
    /* @ngInject */
    controller: (
      $element,
      $rootScope,
      $scope,
      MultiTranslateService,
      AIService,
      $window,
      $compile
    ) => {
      const model = $scope.contentProperty.model
      const relationModels = []
      const parentNode = $element[0].parentNode
      $scope.isCompleted = {}
      $scope.Languages = Languages
      $scope.contentTranslations = $rootScope.appSettings.contentTranslations
      $scope.availableLanguages =
        $rootScope.appSettings.availableContentLanguages
      $scope.defaultLanguage = $rootScope.appSettings.defaultContentLanguage
      $scope.editLanguage = $rootScope.appSettings.defaultContentLanguage
      // $rootScope.currentUser.lang !== ''
      //   ? $rootScope.currentUser.lang
      //  : $rootScope.appSettings.defaultContentLanguage

      // NP-783 edit workflow without switch language every node
      if (model.name === 'Workflow' || model.name === 'Node') {
        const workflowLanguage = $window.localStorage.getItem(
          'workflow-edit-language'
        )
        if (workflowLanguage) {
          $scope.editLanguage = workflowLanguage
        }
      }
      /**
       * Set selected tab as default language
       */
      const languageObject = {}
      $scope.availableLanguages.forEach((lang, index) => {
        languageObject[lang] = index
      })
      $scope.selectedIndex = languageObject[$scope.editLanguage]
      let currentLang = $scope.editLanguage

      /**
       * Remove mark as completed in relation models
       */
      Object.keys(modelsHandling).forEach(model => {
        if (!_.isUndefined(modelsHandling[model].Relationships)) {
          const relationships = modelsHandling[model].Relationships
          Object.keys(relationships).forEach(relation => {
            relationModels.push(relation)
          })
        }
      })
      $scope.showMarkAsCompleted = !relationModels.includes(model.name)
      /**
       * Disable mark as completed when 'translationCompleted' property is true
       */
      if ($scope.formlyModel) {
        Object.keys(Languages).forEach(lang => {
          $scope.isCompleted[lang] = _.isUndefined(
            $scope.formlyModel.translations
          )
            ? false
            : _.isUndefined($scope.formlyModel.translations[lang])
            ? false
            : _.isUndefined(
                $scope.formlyModel.translations[lang].translationCompleted
              )
            ? false
            : $scope.formlyModel.translations[lang].translationCompleted
        })
      }
      $scope.overwriteContent = function overwriteContent () {
        MultiTranslateService.overwriteWithDefault(
          model,
          $scope.editLanguage,
          $scope.formlyModel
        )
        if ($scope.overwriteEvent) {
          $scope.overwriteEvent()
        }
      }
      const setLoading = function setLoading (isLoading) {
        if (isLoading) {
          const overlay = document.createElement('div')
          overlay.className = 'translation-bar-overlay'
          overlay.innerHTML = `<md-progress-circular md-mode="indeterminate"></md-progress-circular>`
          // Make overlay cover all parent
          overlay.style.width = parentNode.offsetWidth + 'px'
          overlay.style.height = parentNode.offsetHeight + 'px'
          // Set overlay position
          overlay.style.top = parentNode.offsetTop + 'px'
          overlay.style.left = parentNode.offsetLeft + 'px'
          parentNode.appendChild(overlay)
          $compile(overlay)($scope)
        } else {
          // Remove overlay element if exists
          const overlay = parentNode.querySelector('.translation-bar-overlay')
          if (overlay) {
            overlay.remove()
          }
        }
      }
      function extractJson (inputString) {
        // Using regular expression to match JSON structure within backticks
        const regex = /```json\s+({[\s\S]*?})\s+```/
        const match = inputString.match(regex)

        // If a match is found, parse it as JSON
        if (match && match[1]) {
          try {
            const jsonObject = JSON.parse(match[1])
            return jsonObject
          } catch (error) {
            console.error('Error parsing JSON:', error)
            return null
          }
        } else {
          console.log('No JSON found in the input string.')
          return null
        }
      }

      $scope.machineTranslate = async function machineTranslate () {
        setLoading(true)
        const currentLangCopy = String(currentLang)
        const settings = AIService.getPromptSettings('translate')
        const prompt = settings.plainPrompt
          .replace(
            'DEFAULT_CONTENT_LANGAGUGE',
            $rootScope.appSettings.defaultContentLanguage
          )
          .replace('TARGET_CONTENT_LANGAGUGE', currentLangCopy)
        const translateKeys = Object.keys(
          $scope.formlyModel.translations[currentLangCopy]
        )
        const originalContents = _.pick($scope.formlyModel, translateKeys)
        const completion = new AIService.Completion(
          [
            {
              role: 'system',
              content: prompt
            }
          ],
          { max_tokens: settings.maxTokens, temperature: settings.temperature }
        )
        const originalContentsString =
          '```json\n' + JSON.stringify(originalContents, null, 2) + '\n```'

        completion
          .send(originalContentsString)
          .then(res => {
            console.log(`Translation Result:`, res)
            $scope.formlyModel.translations[currentLangCopy] = extractJson(res)
          })
          .finally(() => {
            setLoading(false)
          })
      }
      $scope.markAsComplete = async function markAsComplete () {
        const res = await MultiTranslateService.markAsComplete(
          $scope.editLanguage,
          $scope.formlyModel,
          $scope.contentProperty
        )
        $scope.formlyModel = res
        $scope.isCompleted[$scope.editLanguage] = true
      }
      $scope.markAsIncomplete = async function markAsIncomplete () {
        const res = await MultiTranslateService.markAsIncomplete(
          $scope.formlyModel,
          $scope.contentProperty
        )
        $scope.formlyModel = res
        Object.keys(Languages).forEach(lang => {
          $scope.isCompleted[lang] = false
        })
      }
      $scope.onTranslate = function onTranslate (language) {
        currentLang = language
        if (model.name === 'Workflow' || model.name === 'Node') {
          $window.localStorage.setItem('workflow-edit-language', language)
        }
        $scope.selectedIndex = languageObject[language]
        const { data, formly } = MultiTranslateService.getForEdit(
          model,
          $scope.editLanguage,
          language,
          $scope.formlyModel,
          $scope.formlyFields,
          $scope.setDisabled
        )
        $scope.formlyModel = data
        $scope.formlyFields = formly
        $scope.editLanguage = language
        if ($scope.onChange && $scope.contentTranslations) {
          $scope.onChange({
            language,
            defaultLanguage: $scope.defaultLanguage
          })
        }
      }
      $scope.translationEvent = $scope.onTranslate
      if ($scope.editLanguage !== $scope.defaultLanguage) {
        $scope.onTranslate($scope.editLanguage)
      }
    }
  }
}

module.exports = translateBar
