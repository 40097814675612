/* global event _ alert  moment angular */
/** @ngInject */

function PostDirective () {
  return {
    restrict: 'EA',
    scope: {
      where: '=',
      afterDeleteFn: '='
    },
    link: async function (scope, element, attrs) {
      scope.getPost(element)
    },
    /** @ngInject */
    controller: (
      $scope,
      $rootScope,
      Post,
      Comment,
      $mdDialog,
      VoteMapping,
      $translate,
      getUrlFromObj,
      PermissionUtils,
      $compile,
      $location,
      Page,
      $anchorScroll
    ) => {
      $scope.getUrlFromObj = getUrlFromObj
      $scope.PermissionUtils = PermissionUtils

      if ($location.$$hash) {
        setTimeout(() => {
          if ($location.hash() !== $location.$$hash) {
            $location.hash($location.$$hash)
          } else {
            $anchorScroll()
          }
        }, 500)
      }

      $scope.voteIs = (element, value) => {
        let index = _.findIndex(element.votes, {
          UserId: $rootScope.currentUser.id
        })
        if (index === -1) return false
        return element.votes[index].value === value
      }

      $scope.vote = (element, value, commentId = null) => {
        event.cancelBubble = true
        event.preventDefault()
        event.stopPropagation()
        let index = _.findIndex(element.votes, {
          UserId: $rootScope.currentUser.id
        })

        if (index === -1) {
          VoteMapping.create({
            commentId: commentId,
            postId: $scope.post.id,
            value: value
          }).$promise.then(data => {
            element.votes = []
            element.score = 0
            element.votes.push(data)
            element.score = element.score + value
          })
        } else {
          if (element.votes[index].value === value) {
            alert($translate.instant('FORUM.ERRORS.TRY_TO_VOTE_TWICE'))
          } else if (element.votes[index].value !== value) {
            VoteMapping.prototype$patchAttributes(
              { id: element.votes[index].id },
              { value: value }
            ).$promise.then(() => {
              element.votes[index].value = value
              element.score = element.score + 2 * value
            })
          }
        }
      }

      $scope.createCommentTree = () => {
        $scope.post.comments = $scope.post.comments.map(com => {
          com.userName = com.user
            ? com.user.firstName + ' ' + com.user.lastName
            : 'unknown'
          com.createdAgo = moment(com.created).fromNow()
          com.nodes = []
          return com
        })

        let rootComments = $scope.post.comments
          .filter(comment => comment.parentId === null)
          .sort((b, a) => moment(b.created) - moment(a.created))

        let addChild = comment => {
          let children = $scope.post.comments.filter(
            x => x.parentId === comment.id
          )
          comment.nodes = children.sort(
            (b, a) => moment(b.created) - moment(a.created)
          )
          comment.nodes.map(x => addChild(x))
          return comment
        }
        rootComments = rootComments.map(comment => addChild(comment))
        return rootComments
      }

      $scope.showAddCommentEditor = node => {
        $scope.obj.comment = ''
        $scope.commentMode = 'add'
        $scope.closeAllEditors()
        $scope.commentEditorList[node.id] = true
      }

      $scope.closeAllEditors = () => {
        $scope.obj.comment = ''
        $scope.commentEditorList = $scope.commentEditorList.map(x => false)
      }

      $scope.addPostComment = node =>
        Comment.create({
          postId: $scope.post.id,
          parentId: node.id,
          content: $scope.obj.comment
        }).$promise.then(data => {
          $scope.obj.comment = ''
          data.user = $rootScope.currentUser
          $scope.post.comments.push(data)
          $scope.data = $scope.createCommentTree()
          $scope.closeAllEditors()
        })

      $scope.showEditCommentEditor = node => {
        $scope.closeAllEditors()
        $scope.commentMode = 'edit'
        $scope.obj.comment = node.content
        $scope.commentEditorList[node.id] = true
      }

      $scope.savePostComment = node => {
        Comment.prototype$patchAttributes(
          { id: node.id },
          { content: $scope.obj.comment }
        ).$promise.then(data => {
          let index = _.findIndex($scope.post.comments, { id: node.id })
          $scope.post.comments[index].content = _.clone($scope.obj.comment)
          $scope.data = $scope.createCommentTree()
          $scope.obj.comment = ''
          $scope.closeAllEditors()
        })
      }

      $scope.deletePost = post => {
        let confirm = $mdDialog
          .confirm()
          .title($translate.instant('FORUM.REAL_DELETE_FORUM'))
          .targetEvent()
          .clickOutsideToClose(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('FORUM.YES'))
          .cancel($translate.instant('FORUM.CANCEL'))

        $mdDialog.show(confirm).then(
          function () {
            Post.deleteById({ id: post.id })
              .$promise.then(() => {
                if ($scope.afterDeleteFn) $scope.afterDeleteFn(post.id)
              })
              .catch(e => console.log(e))
          },
          function () {}
        )
      }

      $scope.deleteComment = node => {
        let confirm = $mdDialog
          .confirm()
          .title($translate.instant('FORUM.REAL_DELETE'))
          .targetEvent()
          .clickOutsideToClose(true)
          .parent(angular.element(document.body))
          .ok($translate.instant('FORUM.YES'))
          .cancel($translate.instant('FORUM.CANCEL'))

        $mdDialog.show(confirm).then(
          function () {
            Comment.deleteCommentById({ id: node.id, postId: $scope.post.id })
              .$promise.then(
                data =>
                  Comment.find({
                    filter: {
                      where: { postId: $scope.post.id },
                      include: ['user']
                    }
                  }).$promise
              )
              .then(comments => {
                $scope.post.comments = comments
                $scope.data = $scope.createCommentTree()
              })
              .catch(e => console.log(e))
          },
          function () {}
        )
      }

      $scope.toggle = x => x.toggle()

      $scope.getPost = async element => {
        $scope.post = await Post.getPost({ where: $scope.where }).$promise
        Page.setTitleText($scope.post.name)

        $scope.post.userName = $scope.post.user
          ? $scope.post.user.firstName + ' ' + $scope.post.user.lastName
          : 'unknown'
        $scope.post.createdAgo = moment($scope.post.created).fromNow()

        $scope.data = $scope.createCommentTree()

        $scope.commentEditorList = []
        $scope.commentMode = 'none'
        $scope.obj = {
          comment: ''
        }

        let html = require('./post.html')

        if (!$scope.post || !$scope.post.id) {
          element.replaceWith('<div>Post not found</div>')
          return
        }

        element.replaceWith($compile(html)($scope))
      }
    }
  }
}

module.exports = PostDirective
