/* global CodeMirror */
/** @ngInject */

function variable (templates) {
  return {
    restrict: 'E',
    require: 'ngModel',
    templateUrl: function ($elem, $attr) {
      return templates[$attr.type]
    },
    replace: true,
    scope: {
      modelValue: '=ngModel',
      errorValue: '=error',
      title: '@title',
      style: '@style',
      type: '@type',
      variableId: '@varid',
      placeholder: '@placeholder'
    },
    controller: [
      '$scope',
      '$timeout',
      ($scope, $timeout) => {
        if ($scope.type === 'date') {
          $scope.modelValue = new Date($scope.modelValue)
        }

        if ($scope.type === 'json') {
          import(
            /* webpackChunkName: "CodeMirror" */ '../chunks/CodeMirror.js'
          ).then(mod => {
            $scope.editor_json = CodeMirror.fromTextArea(
              document.getElementById('code-json-' + $scope.variableId),
              {
                value: '',
                lineNumbers: true,
                indentUnit: 2,
                autoRefresh: true,
                lineWrapping: true,
                lintOnChange: false,
                mode: 'application/json',
                gutters: ['CodeMirror-lint-markers'],
                lint: true
              }
            )

            $scope.editor_json.setSize('100%', 50)
            $scope.editor_json.getDoc().setValue($scope.modelValue || '{}')
            $scope.editor_json.refresh()

            try {
              $scope.errorValue = false
              window.jsonlint.parse($scope.modelValue)
            } catch (e) {
              $scope.errorValue = true
            }

            $scope.editor_json.on('change', function (instance) {
              const newValue = instance.getValue()
              try {
                $scope.errorValue = false
                JSON.parse(newValue)
              } catch (e) {
                $scope.errorValue = true
              }

              if (newValue !== $scope.modelValue) {
                $scope.modelValue = newValue
                $scope.$apply()
              }
            })
          })
        }
      }
    ]
  }
}

module.exports = variable
