/* global */
/** @ngInject */
function WiringListController (
  $scope,
  $translate,
  ResolvedWirings,
  ResolvedCategoryList,
  PermissionUtils,
  $state,
  Page
) {
  const initScreen = function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.categories = ResolvedCategoryList
    const canEdit = PermissionUtils.isPermit('Wiringlist', 'patchAttributes')
    $scope.obj = {
      search: ''
    }
    $scope.selectedCategories = {}
    $scope.page = {
      title: $translate.instant('Wiring.LIST_TITLE'),
      icon: 'icon-cable-data'
    }
    Page.setTitleText($scope.page.title)
    $scope.items = ResolvedWirings.map(wire => {
      return {
        id: wire.id,
        name: wire.name,
        metadata: '',
        image: wire.resource_id
          ? `./api/Resources/${wire.resource_id}/thumbnail/0`
          : null,
        link: $state.href('app.wiring-list.show', {
          id: wire.id
        }),
        editLink: canEdit
          ? $state.href('app.wiring-list.edit', {
            id: wire.id
          })
          : null,
        categories: wire.categories
      }
    })
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-cable-data',
    preTitleHtml: require('app/templates/headers/sidebar.html'),
    postTitleHtml: require('app/templates/headers/filter.html'),
    template: require('app/templates/headers/simple.html'),
    title: $scope.page.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      icon: 'icon-plus',
      state: 'app.wiring-list.new',
      showExpression: () =>
        $scope.PermissionUtils.isPermit('Wiringlist', 'create')
    }
  }
}

module.exports = WiringListController
