/* global _ angular */
import { mapOrder, move } from 'app/helper'
const ViewsFilterDialogController = require('./views.filter.dialog.controller.js')
require('./views.dialog.scss')
/** @ngInject */
function ViewsDialogController (
  $scope,
  $rootScope,
  $mdDialog,
  $state,
  $mdToast,
  $translate,
  $window,
  locals,
  TableSetup,
  UserModel,
  KendoFilterHelper,
  ResolvedTableSetup,
  PermissionUtils,
  htmlWork,
  IntercomService
) {
  IntercomService.trackEvent('view-dialog')
  $scope.isNotLocked = function isNotLocked (column) {
    return !column.locked
  }

  $scope.cancel = () => $mdDialog.cancel()

  $scope.openFilters = function openFilters () {
    $mdDialog
      .show({
        controller: ViewsFilterDialogController,
        template: require('./views.filter.dialog.html'),
        locals: {
          state: $scope.state,
          columns: $scope.columns,
          viewId: $scope.viewId,
          title: $scope.pageTitle,
          filters: $scope.dialogModel.filters
        },
        parent: angular.element(document.body),
        multiple: true,
        fullscreen: true,
        clickOutsideToClose: false,
        escapeToClose: false
      })
      .then(
        function (filterObject) {
          if (filterObject) {
            $scope.filters = filterObject
            $scope.dialogModel.filters = filterObject
            $scope.filtersString = KendoFilterHelper.createPreviewHtml(
              $scope.filters,
              $scope.filterColumnsById
            )
          }
        },
        function () {}
      )
  }

  $scope.confirm = async function confirm () {
    $rootScope.loadingProgress = true
    $window.sessionStorage.removeItem(`custom-table-view-${$scope.state}`)
    const filters = $scope.dialogModel.filters || {}
    let columns = $scope.columns.filter(column =>
      $scope.columnsOrder.find(c => c.id === column.uniqueId)
    )
    if (columns.length === 0) {
      $rootScope.loadingProgress = false
      $rootScope.showErrorToast('VIEWS.NO_COLUMNS_SELECTED', false)
      return
    }
    const columnIds = $scope.columnsOrder.map(c => c.id)
    columns = mapOrder(columns, columnIds, 'uniqueId')
    if ($scope.dialogModel.save) {
      const mdToast = $mdToast.nextplus({
        position: $rootScope.toastLocation,
        parent: '.views-dialog',
        theme: 'error-toast',
        hideDelay: 2500
      })
      if (!$scope.dialogModel.name || $scope.dialogModel.name === '') {
        $mdToast.show(mdToast)
        $mdToast.updateTextContent(
          $translate.instant('VIEWS.VIEW_MUST_HAVE_NAME')
        )
        $rootScope.loadingProgress = false
        return
      }
      let tableSetup = null
      try {
        if ($scope.editMode) {
          tableSetup = await TableSetup.prototype$patchAttributes(
            { id: ResolvedTableSetup.id },
            {
              name: $scope.dialogModel.name,
              default: $scope.dialogModel.default,
              public: $scope.dialogModel.public,
              filters: filters,
              columns: columnIds
            }
          ).$promise
        } else {
          tableSetup = await TableSetup.create({
            state: $scope.state,
            name: $scope.dialogModel.name,
            default: $scope.dialogModel.default,
            public: $scope.dialogModel.public,
            filters: filters,
            columns: columnIds
          }).$promise
        }
      } catch (err) {
        console.log(err)
        $mdToast.show(mdToast)
        $mdToast.updateTextContent($translate.instant('VIEWS.VIEW_NOT_SAVED'))
      }
      if (!$rootScope.appSettings.tableSetups) {
        $rootScope.appSettings.tableSetups = {}
      }
      if (!$rootScope.appSettings.tableSetups[$scope.state]) {
        $rootScope.appSettings.tableSetups[$scope.state] = []
      }
      const idx = $rootScope.appSettings.tableSetups[$scope.state].findIndex(
        tSetup => tSetup.id === tableSetup.id
      )
      if (idx === -1) {
        $rootScope.appSettings.tableSetups[$scope.state].push(tableSetup)
      } else {
        $rootScope.appSettings.tableSetups[$scope.state][idx] = tableSetup
      }
      if (tableSetup.default) {
        // change local default
        $rootScope.appSettings.tableSetups[$scope.state] =
          $rootScope.appSettings.tableSetups[$scope.state].map(setup => {
            if (setup.default && setup.id !== tableSetup.id)
              setup.default = false
            return setup
          })
      }
      $scope.viewId = tableSetup.id
      const userViews = $rootScope.currentUser.views || {}
      const userStateName = $scope.state.replace(/\./g, '_') // eslint-disabled-line
      userViews[userStateName] = tableSetup.id
      try {
        await UserModel.prototype$patchAttributes(
          { id: $rootScope.currentUser.id },
          { views: userViews }
        ).$promise
        $rootScope.currentUser.views = userViews
      } catch (err) {
        console.log(err)
      }
    } else {
      // SAVE IN SESSION STORAGE
      $window.sessionStorage.setItem(
        `custom-table-view-${$scope.state.replace(/\./g, '_')}`,
        JSON.stringify({ columnIds, filters })
      )
    }
    $rootScope.loadingProgress = false
    $mdDialog.hide({
      columns: columns,
      newViewId: $scope.viewId,
      filters: filters
    })
  }

  $scope.removeColumn = function removeColumn (columnId) {
    const idx = _.findIndex($scope.columnsOrder, { id: columnId })
    if (idx > -1) {
      $scope.columnsOrder.splice(idx, 1)
      $scope.dialogModel[columnId] = false
    }
  }

  $scope.onChange = function onChange (columnId) {
    const idx = _.findIndex($scope.columnsOrder, { id: columnId })
    if ($scope.dialogModel[columnId]) {
      if (idx === -1) {
        const column = $scope.columnsById[columnId]
        $scope.columnsOrder.push({
          id: column.uniqueId,
          name: column.title
        })
        $scope.$applyAsync()
      }
    } else {
      if (idx !== -1) {
        $scope.columnsOrder.splice(idx, 1)
      }
    }
  }

  $scope.onPublicChange = function onPublicChange () {
    if (!$scope.dialogModel.public) {
      $scope.dialogModel.default = false
    }
  }

  $scope.onSaveChange = function onSaveChange () {
    if (!$scope.dialogModel.save) {
      $scope.dialogModel.public = false
      $scope.dialogModel.default = false
    }
  }

  $scope.endHandler = function endHandler (e) {
    e.preventDefault()
    const lockedColumns = $scope.columnsOrder.filter(c => c.locked)
    $scope.columnsOrder = [
      ...lockedColumns,
      ...move(
        $scope.columnsOrder.filter(c => !c.locked),
        e.oldIndex,
        e.newIndex
      )
    ]
  }

  const initDialog = async function initDialog () {
    $scope.isEditor = PermissionUtils.isPermit('Workflow', 'create')
    $scope.state = locals.state || $state.current.name
    const initColumns = _.cloneDeep(locals.columns).map(column => {
      column.locked = !!column.locked
      column.title = htmlWork.htmlEncode(column.title)
      return column
    })
    $scope.columns = initColumns
    $scope.filterColumnsById = _.keyBy(
      $scope.columns.map(column => {
        return {
          name: column.field,
          type: column.viewFilterType || column.type || 'string',
          input: column.values
            ? 'select'
            : column.type === 'array'
            ? 'array'
            : null,
          values: column.values || null,
          label: column.title
        }
      }),
      'name'
    )
    $scope.viewId = locals.viewId
    $scope.filters = locals.filters
    $scope.columnsById = _.keyBy(initColumns, 'uniqueId')
    $scope.pageTitle = locals.title
    $scope.kendoSorterOpts = {
      handler: '.handler',
      disabled: '.locked-column'
    }
    if (ResolvedTableSetup) {
      // edit
      $scope.editMode = true
      $scope.dialogModel = {
        name: ResolvedTableSetup.name,
        save: true,
        default: ResolvedTableSetup.default,
        filters: ResolvedTableSetup.filters || {},
        public: ResolvedTableSetup.public
      }
      $scope.filters = ResolvedTableSetup.filters || {}
      let columns = initColumns
      if (ResolvedTableSetup.columns && ResolvedTableSetup.columns.length) {
        columns = columns.filter(c =>
          ResolvedTableSetup.columns.includes(c.uniqueId)
        )
        columns = mapOrder(columns, ResolvedTableSetup.columns, 'uniqueId')
      }
      $scope.lockedColumns = columns
        .filter(c => c.locked)
        .map(column => ({
          id: column.uniqueId,
          name: column.title,
          locked: column.locked
        }))
      $scope.columnsOrder = columns.map(column => ({
        id: column.uniqueId,
        name: column.title,
        locked: column.locked || false
      }))
      const columnsById = _.keyBy(columns, 'uniqueId')
      initColumns.forEach(column => {
        if (columnsById[column.uniqueId]) {
          $scope.dialogModel[column.uniqueId] = true
        } else {
          $scope.dialogModel[column.uniqueId] = false
        }
      })
    } else {
      // new
      $scope.editMode = false
      $scope.dialogModel = {
        name: null,
        filters: locals.filters || {},
        save: false,
        default: false,
        public: false
      }
      let columns = initColumns
      if (locals.columnIds && locals.columnIds.length) {
        columns = columns.filter(c => locals.columnIds.includes(c.uniqueId))
        columns = mapOrder(columns, locals.columnIds, 'uniqueId')
      }
      $scope.lockedColumns = columns
        .filter(c => c.locked)
        .map(column => ({
          id: column.uniqueId,
          name: column.title,
          locked: column.locked
        }))
      $scope.columnsOrder = columns.map(column => ({
        id: column.uniqueId,
        name: column.title,
        locked: column.locked || false
      }))
      const columnsById = _.keyBy(columns, 'uniqueId')
      initColumns.forEach(column => {
        if (columnsById[column.uniqueId]) {
          $scope.dialogModel[column.uniqueId] = true
        } else {
          $scope.dialogModel[column.uniqueId] = false
        }
      })
    }

    $scope.getColumns = () => {
      return $scope.columnsOrder.filter(c => !c.locked)
    }

    $scope.filtersString = KendoFilterHelper.createPreviewHtml(
      $scope.filters,
      $scope.filterColumnsById
    )
  }

  initDialog()
}
module.exports = ViewsDialogController
