/* global _ */

/** @ngInject */
function CreateEditStockDialogController (
  $scope,
  $rootScope,
  $translate,
  $mdDialog,
  $mdToast,
  ResolvedStock,
  ResolvedStockOptions,
  locals,
  Stock
) {
  const mdToast = $mdToast.nextplus({
    position: $rootScope.toastLocation,
    parent: '.stock-management-create-edit-stock-dialog',
    theme: 'error-toast',
    hideDelay: 5000
  })

  $scope.cancel = () => {
    $mdDialog.cancel()
  }

  $scope.save = async () => {
    const editMode = ResolvedStock !== null
    const errorMessage = locals.isSerial
      ? $translate.instant(
          'PartAssembly.STOCK_DIALOG.STOCK_WITH_SKU_AND_SERIAL_EXISTS',
          {
            sku: locals.partNumber,
            serial: $scope.stock.serial
          }
        )
      : $translate.instant(
          'PartAssembly.STOCK_DIALOG.STOCK_WITH_SKU_AND_WORKORDERNUMBER_LOT_EXISTS',
          {
            sku: locals.partNumber,
            workorderNumber: $scope.stock.workorderNumber
          }
        )
    const where = locals.isSerial
      ? {
          sku: locals.partNumber,
          serial: $scope.stock.serial
        }
      : {
          sku: locals.partNumber,
          workorderNumber: $scope.stock.workorderNumber
        }
    $rootScope.loadingProgress = true
    if ($scope.stock.combinationParts?.length) {
      $scope.stock.combinationParts = $scope.stock.combinationParts.filter(
        part => part.lotNumber
      )
    }
    try {
      const stock = await Stock.find({
        filter: {
          where: where,
          fields: { id: true, sku: true, serial: true },
          limit: 1
        }
      }).$promise

      if (editMode) {
        if (
          !stock.length ||
          (stock.length && stock[0].id === $scope.stock.id)
        ) {
          await Stock.prototype$patchAttributes(
            { id: $scope.stock.id },
            _.omit($scope.stock, 'id')
          ).$promise
          if ($scope.stock.source !== 'manual') {
            await Stock.updateFormsRevision({
              stockId: $scope.stock.id,
              newRev: $scope.stock.skuRev
            })
          }
          $mdDialog.hide()
        } else {
          $mdToast.updateTextContent(errorMessage)
          $mdToast.show(mdToast)
        }
      } else {
        if (stock.length) {
          $mdToast.updateTextContent(errorMessage)
          $mdToast.show(mdToast)
        } else {
          await Stock.createManualStock({
            stockToCreate: $scope.stock
          }).$promise
          $mdDialog.hide()
        }
      }
    } catch (err) {
      if (err.data.error.code === 'CANNOT_EDIT_USED_STOCK') {
        $mdToast.updateTextContent(
          $translate.instant(
            'PartAssembly.STOCK_MANAGEMENT_PAGE.CANNOT_EDIT_USED_STOCK'
          )
        )
        $mdToast.show(mdToast)
      }
      console.error('Error', err)
    } finally {
      $rootScope.loadingProgress = false
    }
  }

  const initDialog = function initDialog () {
    const editMode = ResolvedStock !== null
    if (editMode) {
      $scope.stock = ResolvedStock
      $scope.stock.isSerial = locals.isSerial
      $scope.stock.hasExpirationDate = locals.hasExpirationDate
      $scope.dialogTitle = $translate.instant(
        'PartAssembly.STOCK_DIALOG.EDIT_TITLE',
        { partNumber: locals.partNumber }
      )
    } else {
      $scope.dialogTitle = $translate.instant(
        'PartAssembly.STOCK_DIALOG.CREATE_TITLE',
        { partNumber: locals.partNumber }
      )
      $scope.managedByRevision = locals.managedByRevision
      $scope.stock = {
        isSerial: locals.isSerial,
        isCombinated: locals.isCombinated,
        hasExpirationDate: locals.hasExpirationDate,
        sku: locals.partNumber,
        skuRev: $scope.managedByRevision ? locals.currentRevision : null,
        skuDesc: locals.partDesc,
        quantity: 1,
        availableQuantity: 1,
        productionQuantity: 1,
        serial: '',
        source: 'manual',
        combinationParts: []
      }
    }
    if (
      $scope.stock.skuRev &&
      !locals.revisions.find(rev => rev.revision === $scope.stock.skuRev)
    ) {
      locals.revisions.push({ revision: $scope.stock.skuRev })
    }
    const revOptions = locals.revisions.map(rev => ({
      id: rev.revision,
      name: rev.revision
    }))
    $scope.stockFields = [
      {
        key: 'workorderNumber',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          type: 'text',
          required: true,
          label: $translate.instant(
            'PartAssembly.STOCK_MANAGEMENT_PAGE.WO_LOT'
          ),
          onChange: (value, options) => {}
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return scope.model.source !== 'manual'
          }
        }
      },
      {
        key: 'quantity',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          type: 'number',
          required: true,
          disabled: locals.isSerial || editMode,
          min: 1,
          label: $translate.instant(
            'PartAssembly.STOCK_MANAGEMENT_PAGE.QUANTITY'
          ),
          onChange: (value, options) => {
            $scope.stock.availableQuantity = value
            $scope.stock.productionQuantity = value
          }
        }
      },
      {
        key: 'skuRev',
        type: 'selectWithSearch',
        className: 'layout-row',
        templateOptions: {
          options: revOptions,
          required: $scope.managedByRevision && !editMode,
          label: $translate.instant(
            'PartAssembly.STOCK_MANAGEMENT_PAGE.REVISION'
          ),
          onChange: (value, options) => {}
        }
      },
      {
        key: 'serial',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          type: 'text',
          required: true,
          label: $translate.instant(
            'PartAssembly.STOCK_MANAGEMENT_PAGE.SERIAL'
          ),
          onChange: (value, options) => {}
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return !scope.model.isSerial
        },
        expressionProperties: {
          'templateOptions.disabled': ($viewValue, $modelValue, scope) => {
            return scope.model.source !== 'manual'
          }
        }
      },
      {
        key: 'expirationDate',
        type: 'datePicker',
        className: 'layout-row',
        templateOptions: {
          required: true,
          label: $translate.instant(
            'PartAssembly.STOCK_MANAGEMENT_PAGE.EXPIRATION_DATE'
          )
        },
        hideExpression: function ($viewValue, $modelValue, scope) {
          return !scope.model.hasExpirationDate
        }
      }
    ]
    if (ResolvedStockOptions && ResolvedStockOptions.length) {
      $scope.stockFields.push({
        template: `<div layout="row" layout-align="start center">
                      <h3 translate="PartAssembly.COMBINATIONS_PARTS"></h3>:
                    </div>`
      })
      const combinationPartsLotByNumber = $scope.stock.combinationParts
        ? $scope.stock.combinationParts.reduce((acc, part) => {
            acc[part.number] = part.lotNumber
            return acc
          }, {})
        : {}
      const combinationParts = []
      for (let i = 0; i < ResolvedStockOptions.length; i++) {
        const combinationPart = ResolvedStockOptions[i]
        const { name, number, availableLotNumbers } = combinationPart
        const options = availableLotNumbers.map(lotNumber => ({
          id: lotNumber,
          name: lotNumber
        }))
        if (combinationPartsLotByNumber[number]) {
          combinationParts.push({
            number,
            lotNumber: combinationPartsLotByNumber[number]
          })
          if (
            !availableLotNumbers.includes(combinationPartsLotByNumber[number])
          ) {
            options.unshift({
              id: combinationPartsLotByNumber[number],
              name: combinationPartsLotByNumber[number],
              notAnOption: true
            })
          }
        } else {
          combinationParts.push({
            number,
            lotNumber: null
          })
        }
        $scope.stockFields.push({
          key: `combinationParts[${i}].lotNumber`,
          type: 'selectWithSearch',
          className: 'layout-row',
          templateOptions: {
            required: true,
            options: options,
            label: `(${number}) ${name}`
          }
        })
      }
      $scope.stock.combinationParts = combinationParts
    }
  }

  initDialog()
}

module.exports = CreateEditStockDialogController
