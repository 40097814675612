/* global angular _ */
/** @ngInject */
function customChipsController ($scope) {
  const onChange = function onChange () {
    if ($scope.to.onChange && typeof $scope.to.onChange === 'function') {
      $scope.to.onChange(
        _.get($scope.model, $scope.options.key, []),
        $scope.options
      )
    }
  }

  $scope.addBulkChips = function addBulkChips (chip) {
    const currentValue = _.get($scope.model, $scope.options.key, [])
    const separatedString = angular.copy(chip).toString()
    const chipsArray = separatedString.split(',')
    chipsArray.forEach(chipToAdd => {
      currentValue.push(chipToAdd.toString().trim())
    })
    _.set($scope.model, $scope.options.key, currentValue)
    onChange()
    return null
  }
}

module.exports = customChipsController
