/* global */
require('./license-serial.scss')
/** @ngInject */
function licenseSerial () {
  return {
    restrict: 'E',
    template: require('./license-serial.html'),
    replace: true,
    scope: {
      licenseSettings: '=',
      usersData: '=',
      callback: '&'
    },
    controller: [
      '$rootScope',
      '$scope',
      '$translate',
      '$mdToast',
      'Setting',
      'PermissionUtils',
      ($rootScope, $scope, $translate, $mdToast, Setting, PermissionUtils) => {
        $scope.hasPermission = PermissionUtils.isPermit(
          'Setting',
          'updateSystemSettings'
        )

        const methodTypes = [
          {
            id: 'perUser',
            name: 'Per User'
          },
          {
            id: 'perUserRole',
            name: 'Per User Role'
          }
        ]

        const enforcementMethodTypes = [
          {
            id: 'hard',
            name: 'Hard'
          },
          {
            id: 'soft',
            name: 'Soft'
          }
        ]

        const enforceConcurrentTypes = [
          {
            id: true,
            name: 'Yes (All users)'
          },
          {
            id: 'operators',
            name: 'Only Operators'
          },
          {
            id: 'editors',
            name: 'Only Editors'
          },
          {
            id: false,
            name: 'No'
          }
        ]

        const initDirective = function initDirective () {
          const usersStats = $scope.usersData
          const licenseSettings = $scope.licenseSettings
          $scope.license = {
            licenseData: licenseSettings,
            version: $rootScope.appSettings.manifest.version,
            deploymentId: $rootScope.appSettings.deploymentId,
            serial: ''
          }
          $scope.licensesFields = [
            {
              template: `<div layout="center" layout-align="center" flex style="margin: 1em 0em;">
                        <span translate="SETTINGS.LICENSE.EXPIRED_ENTER_NEW_SERIAL"></span>
                    </div>`,
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !$scope.hasPermission ||
                  !$rootScope.appSettings.activationRequired
                )
              }
            },
            {
              template: `<div layout="center" layout-align="center" flex style="margin: 1em 0em;">
                        <span translate="SETTINGS.LICENSE.ENTER_NEW_SERIAL"></span>
                    </div>`,
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !$scope.hasPermission ||
                  $rootScope.appSettings.activationRequired
                )
              }
            },
            {
              key: 'version',
              type: 'input',
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !$scope.hasPermission
              },
              templateOptions: {
                type: 'text',
                disabled: true,
                label: $translate.instant('SETTINGS.LICENSE.VERSION')
              }
            },
            {
              key: 'deploymentId',
              type: 'input',
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !$scope.hasPermission
              },
              templateOptions: {
                type: 'number',
                disabled: true,
                label: $translate.instant('SETTINGS.LICENSE.DEPLOYMENT_ID')
              }
            },
            {
              key: 'serial',
              type: 'input',
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !$scope.hasPermission
              },
              templateOptions: {
                label: $translate.instant('SETTINGS.LICENSE.SERIAL'),
                minlength: 41,
                maxlength: 42
              }
            },
            {
              template: `
                    <div layout="center" layout-align="center" flex>
                        <span translate="SETTINGS.LICENSE.EXPIRED_MESSAGE"></span>
                    </div>
                `,
              hideExpression: function ($viewValue, $modelValue, scope) {
                return (
                  !$rootScope.appSettings.activationRequired ||
                  $scope.hasPermission
                )
              }
            },
            {
              wrapper: 'collapsibleGroup',
              templateOptions: {
                class: 'multiple-open'
              },
              fieldGroup: [
                // GENERAL
                {
                  wrapper: 'collapsibleItem',
                  templateOptions: {
                    label: $translate.instant('SETTINGS.LICENSE.SETTINGS')
                  },
                  fieldGroup: [
                    {
                      template: `<table class="simple">
                                  <tr>
                                    <td>
                                      <b><span translate="SETTINGS.LICENSE.TYPE"></span></b>
                                    </td>
                                    <td>
                                      <b><span translate="SETTINGS.LICENSE.COUNT"></span></b>
                                    </td>
                                    <td>
                                      <b>
                                      <span translate="SETTINGS.LICENSE.AVAILABLE_IN_LICENSE"></span>
                                      </b>
                                    </td>
                                  </tr>
                                  <tr ng-if="licenseSettings.method !== 'perUser'">
                                    <td><span translate="SETTINGS.LICENSE.EDITOR_COUNT"></span></td>
                                    <td ng-bind="usersStats.editorCount"></td>
                                    <td ng-bind="licenseSettings.maxEditorCount"></td>
                                  </tr>
                                  <tr ng-if="licenseSettings.method !== 'perUser'">
                                    <td><span translate="SETTINGS.LICENSE.VIEWER_COUNT"></span></td>
                                    <td ng-bind="usersStats.viewerCount"></td>
                                    <td ng-bind="licenseSettings.maxViewerCount"></td>
                                  </tr>
                                  <tr ng-if="licenseSettings.method === 'perUser'">
                                    <td><span translate="SETTINGS.LICENSE.USER_COUNT"></span></td>
                                    <td ng-bind="usersStats.userCount"></td>
                                    <td ng-bind="licenseSettings.maxUserCount"></td>
                                  </tr>
                                </table>`,
                      controller: /* @ngInject */ function ($scope) {
                        $scope.usersStats = usersStats
                        $scope.licenseSettings = licenseSettings
                      }
                    },
                    {
                      key: 'licenseData.method',
                      type: 'selectWithSearch',
                      className: 'layout-column',
                      templateOptions: {
                        disabled: true,
                        label: $translate.instant('SETTINGS.LICENSE.method'),
                        options: methodTypes
                      }
                    },
                    {
                      key: 'licenseData.enforcementMethod',
                      type: 'selectWithSearch',
                      className: 'layout-column',
                      templateOptions: {
                        disabled: true,
                        label: $translate.instant(
                          'SETTINGS.LICENSE.enforcementMethod'
                        ),
                        options: enforcementMethodTypes
                      }
                    },
                    {
                      key: 'licenseData.enforceConcurrent',
                      type: 'selectWithSearch',
                      className: 'layout-column',
                      templateOptions: {
                        disabled: true,
                        label: $translate.instant(
                          'SETTINGS.LICENSE.enforceConcurrent'
                        ),
                        options: enforceConcurrentTypes
                      }
                    },
                    {
                      key: 'licenseData.enforceExpirationDate',
                      type: 'switch',
                      className: 'layout-column',
                      templateOptions: {
                        disabled: true,
                        label: $translate.instant(
                          'SETTINGS.LICENSE.enforceExpirationDate'
                        )
                      }
                    },
                    {
                      key: 'licenseData.enforceExpirationDateOnSlaves',
                      type: 'switch',
                      className: 'layout-column',
                      templateOptions: {
                        disabled: true,
                        label: $translate.instant(
                          'SETTINGS.LICENSE.enforceExpirationDateOnSlaves'
                        )
                      }
                    },
                    {
                      key: 'licenseData.expirationDate',
                      type: 'datepicker',
                      hideExpression: function (
                        $viewValue,
                        $modelValue,
                        scope
                      ) {
                        return !scope.model.licenseData.enforceExpirationDate
                      },
                      templateOptions: {
                        disabled: true,
                        label: $translate.instant(
                          'SETTINGS.LICENSE.expirationDate'
                        )
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }

        $scope.sendSerial = async function sendSerial () {
          try {
            $scope.isLoading = true
            const { serial } = $scope.license
            const res = await Setting.decodeSerial({ encodeString: serial })
              .$promise
            $scope.isLoading = false
            if (res) {
              if ($scope.callback) {
                $scope.callback()
              }
            } else {
              // toast
            }
          } catch (e) {
            console.log(e)
            const {
              data: {
                error: { code }
              }
            } = e
            $scope.isLoading = false
            $mdToast.show(
              $mdToast.nextplus({
                position: $rootScope.toastLocation,
                parent: 'document.body',
                theme: 'error-toast',
                hideDelay: 2500
              })
            )
            $mdToast.updateTextContent($translate.instant(code))
          }
        }

        initDirective()
      }
    ]
  }
}

module.exports = licenseSerial
