import newEditPostDialogController from './posts-wrapper-new-edit-post'
/* global _ angular event alert moment $ */
/** @ngInject */

function PostsWrapperDirective () {
  return {
    restrict: 'EA',
    scope: {
      where: '=',
      update: '=',
      embedded: '=embedded',
      goToPostFn: '='
    },
    link: async function (scope, element, attrs) {
      scope.fetchPosts(element)
    },
    /** @ngInject */
    controller: (
      $scope,
      $rootScope,
      Post,
      Comment,
      $mdDialog,
      VoteMapping,
      $translate,
      getUrlFromObj,
      PermissionUtils,
      LoginService,
      $compile
    ) => {
      $scope.getUrlFromObj = getUrlFromObj
      $scope.PermissionUtils = PermissionUtils
      console.log($scope)

      $scope.voteIs = (post, value) => {
        const index = _.findIndex(post.votes, {
          UserId: $rootScope.currentUser.id
        })
        if (index === -1) return false
        return post.votes[index].value === value
      }

      $scope.vote = (post, value) => {
        event.cancelBubble = true
        event.preventDefault()
        event.stopPropagation()
        const index = _.findIndex(post.votes, {
          UserId: $rootScope.currentUser.id
        })

        if (index === -1) {
          VoteMapping.create({
            commentId: null,
            postId: post.id,
            value: value
          }).$promise.then(data => {
            post.votes.push(data)
            post.score = post.score + value
          })
        } else {
          if (post.votes[index].value === value) {
            const msg = $translate.instant('FORUM.ERRORS.TRY_TO_VOTE_TWICE')
            alert(msg)
          } else if (post.votes[index].value !== value) {
            VoteMapping.prototype$patchAttributes(
              { id: post.votes[index].id },
              { value: value }
            ).$promise.then(() => {
              post.votes[index].value = value
              post.score = post.score + 2 * value
            })
          }
        }
      }

      $scope.fetchPosts = async element => {
        const where = $scope.where

        $scope.posts = await Post.find({
          filter: {
            where,
            include: [
              { relation: 'comments', scope: { fields: { id: true } } },
              'user'
            ]
          }
        }).$promise

        $scope.posts = $scope.posts
          .map(post => {
            post.createdAgo = moment(post.created).fromNow()
            post.userName = post.user
              ? post.user.firstName + ' ' + post.user.lastName
              : 'unknown'
            return post
          })
          .sort((a, b) => moment(b.created) - moment(a.created))

        $scope.afterDeleteFn = postId => {
          const index = _.findIndex($scope.posts, { id: postId })
          $scope.posts.splice(index, 1)
          $scope.showPosts()
        }

        $scope.showPost = post => {
          if ($scope.embedded) {
            $scope.postWhere = { id: post.id }
            $scope.currentPostId = post.id
            $scope.mode = 'post'
          } else {
            $scope.goToPostFn(post)
          }
        }

        $scope.mode = 'posts'

        $scope.addPost = () => {
          $mdDialog
            .show({
              controller: newEditPostDialogController,
              template: require('./posts-wrapper-new-edit-post.html'),
              parent: angular.element(document.body),
              targetEvent: '',
              locals: {},
              multiple: true,
              clickOutsideToClose: true
            })
            .then(post => {
              if (post) {
                _.map(Object.keys($scope.where), key => {
                  post[key] = $scope.where[key]
                })
                Post.create(post)
                  .$promise.then(addedPost => {
                    addedPost.comments = []
                    addedPost.votes = []
                    addedPost.score = 0
                    addedPost.user = $rootScope.currentUser
                    addedPost.createdAgo = moment(post.created).fromNow()
                    addedPost.userName = addedPost.user
                      ? addedPost.user.firstName + ' ' + addedPost.user.lastName
                      : 'unknown'
                    $scope.posts.unshift(addedPost)
                  })
                  .then(() => {
                    if ($scope.update) {
                      $scope.update()
                    }
                  })
                  .catch(err => {
                    console.log(err)
                    alert(err)
                  })
              }
            })
        }

        $scope.showPosts = () => {
          $('#' + $scope.currentPostId).remove()
          $scope.mode = 'posts'
        }

        const html = require('./posts-wrapper.html')
        element.replaceWith($compile(html)($scope))
      }
    }
  }
}

module.exports = PostsWrapperDirective
