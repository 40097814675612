/* global _ */
/** @ngInject */
function SelectToRelinkController($scope, $rootScope, $translate, locals, $mdDialog, $element, ContentPackage, Catalog, $mdToast) {
  $scope.contentPackages = []
  $scope.catalogs = []

  $scope.selected = {
    targetPackageIds: [],
    sourceCatalogIds: [],
    sourcePackageIds: [],
    clearTargetLinks: true
  }

  $scope.linkTable = []

  $scope.selectedPage = 0

  // TODO: Need to check what is the logic here
  $scope.tabClicked = () => {
    if (((!$scope.selected.targetPackageIds || $scope.selected.targetPackageIds.length === 0) ||
      (!$scope.selected.sourceCatalogIds || $scope.selected.sourceCatalogIds.length === 0)) &&
      (!$scope.selected.sourcePackageIds || $scope.selected.sourcePackageIds.length === 0)) {
      $scope.selectedPage = 0
    }
  }

  $scope.isFirstStep = () => $scope.selectedPage === 0
  $scope.isLastStep = () => $scope.selectedPage === 2

  $scope.previousStep = () => {
    $scope.selectedPage--
  }
  $scope.settingsError = false
  $scope.nextStep = () => {
    $scope.settingsError = false
    switch ($scope.selectedPage) {
      case 0:
        $scope.settingsTargetError = ((!$scope.selected.targetPackageIds || $scope.selected.targetPackageIds.length === 0))

        let noSourceCatalogIds = !$scope.selected.sourceCatalogIds || $scope.selected.sourceCatalogIds.length === 0
        let noSourcePackageIds = !$scope.selected.sourcePackageIds || $scope.selected.sourcePackageIds.length === 0

        $scope.settingsSourceError = noSourceCatalogIds && noSourcePackageIds

        if ($scope.settingsSourceError || $scope.settingsTargetError) return

        $rootScope.shadowEnable = true
        ContentPackage.getRelinkVariants({ obj: $scope.selected }).$promise
          .then((data) => {
            console.log(data)
            $scope.linkTable = data.result.map(x => {
              x.checked = true
              return x
            })
            $rootScope.shadowEnable = false
            $scope.selectedPage++
          })
          .catch((e) => {
            console.log(e)
            $rootScope.shadowEnable = false
            $mdToast.show({
              template:
                '<md-toast layout="column" layout-align="center start"><div class="md-toast-content">' +
                $translate.instant('CONTENTPACKAGE.RELINK_ERROR') +
                '</div></md-toast>',
              hideDelay: 2000,
              position: $rootScope.toastLocation,
              parent: '#content'
            })
          })

        break
      case 1:
        $scope.tableError = false

        $scope.linkTable = $scope.linkTable.map((x) => {
          if ((!x.selectedLink || x.selectedLink === '') && x.checked) {
            x.error = true
            $scope.tableError = true
          } else {
            x.error = false
          }
          return x
        })

        if ($scope.tableError) return

        let tmp = $scope.linkTable.filter((x) => x.checked)
        console.log(tmp)

        $rootScope.shadowEnable = true
        ContentPackage.replaceToLink({ obj: { arr: tmp, clearTargetLinks: $scope.selected.clearTargetLinks } }).$promise
          .then((data) => {
            $rootScope.shadowEnable = false

            $scope.statistic = data.result.map((x) => {
              let totalCreated = _.sum(_.map(x.words, 'createdLinks'))
              x.totalCreated = totalCreated
              return x
            })
            console.log($scope.statistic)
            $scope.selectedPage++
          })
          .catch((e) => {
            console.log(e)
            $rootScope.shadowEnable = false
            $mdToast.show({
              template:
                '<md-toast layout="column" layout-align="center start"><div class="md-toast-content">' +
                $translate.instant('CONTENTPACKAGE.RELINK_ERROR') +
                '</div></md-toast>',
              hideDelay: 2000,
              position: $rootScope.toastLocation,
              parent: '#content'
            })
          })

        break

      default:
        $scope.selectedPage++
        break
    }
  }

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.done = function () {
    $mdDialog.cancel()
  }

  $scope.closeDialog = function () {
    $mdDialog.hide()
  }

  $scope.getAllContentPackages = () =>
    ContentPackage.find({}).$promise
      .then((data) => {
        $scope.contentPackages = data
      })

  $scope.getAllCatalogs = () =>
    Catalog.find({}).$promise
      .then((data) => {
        $scope.catalogs = data
      })

  // ENTER POINT
  $scope.getAllContentPackages()
  $scope.getAllCatalogs()
}

module.exports = SelectToRelinkController
