/* global $,angular,_ alert */

const processArray = async (array, func) => {
  for (const item of array) {
    await func(item)
  }
}

/** @ngInject */

function customStoryline () {
  return {
    restrict: 'C',
    link: async function (scope, element, attrs) {
      const attributes = element.prop('attributes')
      const storylineId = attributes.id.value
      await scope.openStoryLine(storylineId)
      scope.createIframe(element, attributes, storylineId)
    },
    /** @ngInject */
    controller: (
      $scope,
      $rootScope,
      $window,
      nextplusSocket,
      ScromSession,
      ContentPackageSession,
      WorkflowSessionItem
    ) => {
      let debouncedUpdateSize //eslint-disable-line
      let iframe = angular.element('<iframe></iframe>')
      const iframeWrapper = angular.element('<div/>')
      // const fullscreenBtn = angular.element('<div/>')
      const elementId = +new Date() + ''

      if ($scope.contentPackage) {
        $scope.model = 'ContentPackage'
        $scope.modelId = $scope.contentPackage.id
      } else if ($scope.workflowSessions) {
        $scope.model = 'WorkflowSessionItem'
        $scope.modelId = $scope.workflow.id
      } else {
        $scope.model = 'Workflow'
        $scope.modelId = $scope.workflow ? $scope.workflow.id : ''
      }

      const onMessage = e => {
        if (e.data.iframeId !== elementId) {
          return false
        } else {
          if (e.data.msg === 'storyline-size') {
            console.log(e.data.data)
          }
          if (e.data.msg === 'storyline') {
            if (e.data.data.command === 'LMSSetValue') {
              $scope.collectData(
                e.data.data.key,
                e.data.data.value,
                e.data.data.sessionId
              )
            }
            if (e.data.data.command === 'LMSCommit') {
              $scope.sendData()
            }
          }
        }
      }
      $window.addEventListener('message', onMessage)
      $scope.getUserStorylineHistory = async storylineId => {
        await ScromSession.find({
          filter: {
            where: {
              model: $scope.model,
              modelId: $scope.modelId,
              resourceId: $scope.storylineId,
              UserId: $rootScope.currentUser.id
            }
          }
        }).$promise

        // TODO develop only
        // return userStorylineHistory
        return []
      }
      $scope.collectedData = []
      $scope.sendData = () => {
        const currentDataLength = $scope.collectedData.length
        if (currentDataLength > 0) {
          const data = $scope.collectedData.splice(0, currentDataLength)
          nextplusSocket.angularSocket.emit('LMSSetValue', data)
        }
      }
      $scope.$on('$destroy', () => {
        $window.removeEventListener('message', onMessage)
        $window.removeEventListener('resize', debouncedUpdateSize)
      })
      $scope.openStoryLine = storylineId =>
        new Promise(async (resolve, reject) => {
          const storylineHistory = await $scope.getUserStorylineHistory(
            storylineId
          )

          if (storylineHistory.length === 0) {
            // create new sessionData
            $scope.scromSession = await ScromSession.create({
              model: $scope.model,
              modelId: $scope.modelId,
              resourceId: storylineId,
              data: {}
            }).$promise

            if ($scope.model === 'ContentPackage') {
              await ContentPackageSession.createOrUpdateScromSessionIds({
                contentPackageSessionId: $scope.contentPackageSession.id,
                scromId: $scope.scromSession.id
              }).$promise
            } else if ($scope.model === 'WorkflowSessionItem') {
              await processArray($scope.workflowSessions, async session => {
                await WorkflowSessionItem.createOrUpdateScromSessionIds({
                  sessionId: session.id,
                  scromId: $scope.scromSession.id
                }).$promise
              })
            }
          } else {
            // ask what to do
            // user already answer to this story line
            // it may be in this ContentPackageSession or in other
          }
          resolve()
        })
      $scope.collectData = (key, value, sessionId) => {
        const data = {
          key: key,
          value: value,
          scromSessionId: sessionId
        }
        $scope.collectedData.push(data)
      }
      // let goFullScreen = false
      const updateSize = () => {
        return
        // if (goFullScreen) {
        // goFullScreen = false
        // return
        // }
        console.log('Resize')
        let documentHeight
        let documentWidth
        if ($scope.model === 'ContentPackage') {
          documentHeight = $window.innerHeight - 67 - 64
          documentWidth = iframe.parentNode.offsetWidth
        } else {
          if ($('#middle').length > 0) {
            // Workflow
            documentHeight = $('#middle').innerHeight()
            documentWidth = $('#middle').innerWidth()
          } else {
            // Custom Page
            documentHeight = $(
              'md-content#content md-content.content'
            ).innerHeight()
            documentWidth = $(
              'md-content#content md-content.content'
            ).innerWidth()
          }
        }
        iframe.style.width = `${documentWidth}px`
        iframe.style.height = `${documentHeight}px`

        iframe.contentWindow.postMessage(
          {
            command: 'windowSize',
            documentHeight,
            documentWidth
          },
          '*'
        )
      }
      debouncedUpdateSize = _.debounce(updateSize, 200)
      $scope.createIframe = (element, attributes, storylineId) => {
        $scope.element = element
        iframeWrapper.attr('class', 'storyline-wrapper')

        angular.forEach(attributes, function (attr) {
          iframe.attr(attr.name, attr.value)
        })

        iframe.attr('id', elementId)
        iframe.attr(
          'storyline',
          iframe.attr('ta-insert-video') + 'index_lms_html5.html'
        )
        iframe.attr('sessionid', $scope.scromSession.id)
        iframe.attr('src', 'assets/vendor/storyline/scrom_inner.html')
        iframe.appendTo(iframeWrapper)
        element.replaceWith(iframeWrapper)
        iframe = document.getElementById(elementId)

        updateSize()

        $window.addEventListener('resize', debouncedUpdateSize)
        iframe.onload = function () {
          updateSize()
        }
      }
    }
  }
}

module.exports = customStoryline
