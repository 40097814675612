/* global _ */
const debug = require('debug')('nextplus:lookup-select-helper')

module.exports = class LookUpSelectHelper {
  constructor (tableModel, model, key, tableId, fieldId) {
    this.tableModel = tableModel
    this.model = model
    this.key = key
    this.tableId = tableId
    this.fieldId = fieldId
    this.isInitialized = false
    this.skip = 0
    this.limit = 20
    this.filterObject = {
      skip: this.skip,
      limit: this.limit,
      where: { deletedAt: null }
    }
  }

  alreadyInit () {
    return this.isInitialized
  }

  async getFirstOptions () {
    const filterObject = _.cloneDeep(this.filterObject)
    const options = await this.tableModel.getLookupFieldOptions({
      tableId: this.tableId,
      fieldId: this.fieldId,
      filter: filterObject
    }).$promise
    this.isInitialized = true
    this.firstOptions = options
    this.optionsToDisplay = options
  }

  async initOptions () {
    debug('initOptions')
    const filterObject = _.cloneDeep(this.filterObject)
    let selectedValues = _.get(this.model, this.key)
    if (selectedValues && !Array.isArray(selectedValues)) {
      selectedValues = [selectedValues]
    }
    if (selectedValues && selectedValues.length) {
      filterObject.where[this.fieldId] = { inq: selectedValues }
      delete filterObject.where.deletedAt
    }
    const options = await this.tableModel.getLookupFieldOptions({
      tableId: this.tableId,
      fieldId: this.fieldId,
      filter: filterObject
    }).$promise
    if (!selectedValues || selectedValues.length === 0) {
      this.isInitialized = true
      this.firstOptions = options
    } else if (selectedValues.length > 0) {
      // If the selected values are not in the first 20 options (or not exist at all), we need to add them to the options to display
      if (Array.isArray(this.model[this.key])) {
        this.model[this.key].forEach(value => {
          if (typeof value !== 'undefined' && value !== null) {
            options.push({ id: value, name: value })
          }
        })
      } else if (
        typeof this.model[this.key] !== 'undefined' &&
        this.model[this.key] !== null
      ) {
        options.push({ id: this.model[this.key], name: this.model[this.key] })
      }
    }
    this.optionsToDisplay = options
  }

  getOptionsToDisplay () {
    debug('getOptionsToDisplay', this.optionsToDisplay)
    return this.optionsToDisplay
  }

  async loadMore () {
    debug('loadMore')
    this.skip = this.optionsToDisplay.length
    const filterObject = _.cloneDeep(this.filterObject)
    filterObject.skip = this.skip
    const options = await this.tableModel.getLookupFieldOptions({
      tableId: this.tableId,
      fieldId: this.fieldId,
      filter: filterObject
    }).$promise
    this.optionsToDisplay = this.optionsToDisplay.concat(options)
    return options
  }

  async makeSearch (query, imFeelingLuckey = false) {
    debug('makeSearch')
    if (!query || query === '') {
      this.skip = 0
      delete this.filterObject.query
      this.filterObject.skip = 0
      this.filterObject.limit = 20
      this.optionsToDisplay = this.firstOptions
      return this.optionsToDisplay
    } else {
      this.filterObject.query = query
      const filterObject = _.cloneDeep(this.filterObject)
      const options = await this.tableModel.getLookupFieldOptions({
        tableId: this.tableId,
        fieldId: this.fieldId,
        filter: filterObject
      }).$promise
      this.optionsToDisplay = options
      return this.optionsToDisplay
    }
  }

  findCandidates (word) {
    debug('findCandidates')
    let results = this.fuse.search(word)
    let k = 5
    if (results.length > 5) {
      for (; k < results.length; k++) {
        if (results[k].score > 0.4) {
          break
        }
      }
    }
    results = results.splice(0, k)
    return results.filter(r => r.score < 0.00001)
  }

  ocrCallback (words, eventObject, callback) {
    debug('ocrCallback')
    const that = this
    console.log('words', words)
    if (!_.isArray(words) && !_.isUndefined(words.text)) {
      // In case of barcode scan
      const candidates = that.findCandidates(words.text)
      if (candidates.length === 1) {
        _.set(that.model, that.key, candidates[0].item.id)
        return true
      } else {
        return words.text
      }
    } else {
      if (that.visionService.androidWithNewVersion) {
        const resultsFound = false
        for (let w = 0; w < words.length; w++) {
          const candidates = that.findCandidates(words[w])
          if (candidates.length === 1) {
            _.set(that.model, that.key, candidates[0].item.id)
            return true
          }
        }
        if (!resultsFound) {
          eventObject.returnWords = false
          eventObject.callback = callback
          that.visionService.openOCRSelectDialog(
            words,
            eventObject,
            `${that.type}_${that.key}`
          )
        }
      } else {
        return words
      }
    }
  }
}
