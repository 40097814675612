/* global _ */
require('./editableCard.scss')
/** @ngInject */
function editableCard ($scope) {
  $scope.locals = $scope.to.locals || {}
  $scope.getComplexValue = () => _.get($scope.model, $scope.options.key)
  $scope.edit = () => {
    if ($scope.to.editCB) {
      $scope.to.editCB($scope.model)
    }
  }
}

module.exports = editableCard
