/** @ngInject */
function CatalogListController (
  $scope,
  $log,
  $rootScope,
  $translate,
  Page,
  PermissionUtils,
  $timeout,
  Resolved,
  $state
) {
  const resolvedEvents = Resolved.resolvedEvents
  const training = Resolved.training
  const timeline = Resolved.timeline
  const contentPackageViews = Resolved.contentPackageViews

  $scope.contentPackages = contentPackageViews
  $scope.recentlyViews = resolvedEvents
  $scope.trainings = training
  $scope.timelines = timeline
  $scope.PermissionUtils = PermissionUtils
  $scope.page = {
    title: $translate.instant('CATALOG.HOME')
  }
  Page.setTitleText($scope.page.title)

  $rootScope.title = $scope.page.title

  $scope.search = ''

  $scope.loadMoreTodo = () => {
    $state.go('app.reports.content-package-views-list')
  }
}

module.exports = CatalogListController
