/* global _ */
/** @ngInject */
const ConflictResolverDialogController = function ConflictResolverDialogController (
  locals,
  $scope,
  $translate,
  $mdDialog,
  conflicts,
  partToImport
) {
  const getAllChildParts = part => {
    const childs = []

    const getChildNumber = children => {
      childs.push(children.number)
      if (children.childrens && children.childrens.length > 0) {
        children.childrens.forEach(children => {
          getChildNumber(children)
        })
      }
    }
    getChildNumber(part)

    return childs
  }

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.closeDialog = function () {
    $mdDialog.hide($scope.partNumbersWithConflicts)
  }

  $scope.switchPartSource = (number, value) => {
    const allChildPartNumbers = getAllChildParts(
      _.find(partToImport, { number: number })
    )
    allChildPartNumbers.forEach(num => {
      $scope.partNumbersWithConflicts[num] = {
        fromImport: value,
        locked: value === false ? false : num !== number
      }
    })
  }

  $scope.partNumbersWithConflicts = {}
  conflicts.forEach(conflict => {
    $scope.partNumbersWithConflicts[conflict.number] = {
      fromImport: false,
      locked: false
    }
  })

  $scope.conflicts = _.reverse(conflicts)
  $scope.partToImport = partToImport
}

module.exports = ConflictResolverDialogController
