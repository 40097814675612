/* global angular */
import catalogApp from './modules/catalog/catalog.module'
import partsApp from './modules/parts/parts.module'
import wiringApp from './modules/wiring/wiring.module'
const MODULE_NAME = 'app.catalogModule'

angular.module(MODULE_NAME, [catalogApp, partsApp, wiringApp]).config(config)

/** @ngInject */
function config () {}

export default MODULE_NAME
