/* global _ */
module.exports = /** @ngInject */ function config (
  $stateProvider,
  msApiProvider,
  msNavigationServiceProvider
) {
  // State
  $stateProvider
    .state('app.catalog', {
      abstract: true,
      url: '/catalog'
    })
    .state('app.catalog.list', {
      url: '/show',
      views: {
        'content@app': {
          template: require('app/modules/components/tree-category-grid/tree-category-grid.html'),
          controller: 'CatalogListController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCategoryList: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          ),
        ResolvedCatalogs: function (Catalog) {
          return Catalog.find({
            filter: {
              include: [
                {
                  relation: 'assembly'
                }
              ]
            }
          }).$promise
        }
      }
    })
    .state('app.catalog.workflow-list', {
      url: '/workflow/show',
      views: {
        'content@app': {
          template: require('./pages/catalog-workflow-list/catalog-workflow-list.html'),
          controller: 'CatalogWorkflowListController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCategoryList: Category => Category.find({}).$promise,
        Items: function (Catalog, Workflow, getUrlFromObj) {
          return new Promise(async resolve => {
            const catalogs = await Catalog.find({
              filter: {
                fields: {
                  id: true,
                  categories: true,
                  name: true,
                  partNumber: true,
                  tags: true
                }
              }
            }).$promise

            const workflows = await Workflow.find({
              filter: {
                where: {
                  currentReleasedVersion: true
                },
                fields: {
                  id: true,
                  categories: true,
                  name: true,
                  tags: true,
                  ResourceId: true,
                  recordId: true
                },
                include: [
                  {
                    relation: 'resource',
                    scope: {
                      fields: {
                        name: true,
                        container: true
                      }
                    }
                  }
                ]
              }
            }).$promise
            catalogs.map(catalog => {
              catalog.image = `./api/Resources/assemblyPicture/${encodeURIComponent(
                catalog.partNumber
              )}`
              catalog.type = 'Catalog'
              return catalog
            })
            workflows.map(workflow => {
              if (workflow.resource) {
                workflow.image = getUrlFromObj(workflow.resource)
              }
              workflow.type = 'Workflow'
              return workflow
            })
            resolve(
              _.orderBy(workflows.concat(catalogs), ['created'], ['desc'])
            )
          })
        }
      }
    })
    .state('app.catalog.learning', {
      url: '/learning/show/?:selectedCategories',
      views: {
        'content@app': {
          template: require('app/modules/components/tree-category-grid/tree-category-grid.html'),
          controller: 'CatalogLearningController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCategoryList: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          ),
        Items: function ($rootScope, ContentPackage, MultiTranslateService) {
          return new Promise(async resolve => {
            const contentPackages = await ContentPackage.find({
              filter: {
                where: {
                  or: [{ template: 'noSidebar' }, { template: 'Feed' }]
                },
                order: 'created desc',
                fields: {
                  id: true,
                  image: true,
                  categories: true,
                  name: true,
                  tags: true
                }
              }
            }).$promise
            contentPackages.map(contentPackage =>
              MultiTranslateService.getForView(
                ContentPackage,
                $rootScope.currentLang,
                contentPackage
              )
            )
            resolve(_.orderBy(contentPackages, ['created'], ['desc']))
          })
        }
      }
    })
    .state('app.catalog.learning1', {
      url: '/learning1/show',
      views: {
        'content@app': {
          template: require('app/modules/components/tree-category-grid/tree-category-grid.html'),
          controller: 'CatalogLearningController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCategoryList: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          ),
        Items: function ($rootScope, ContentPackage, MultiTranslateService) {
          return new Promise(async resolve => {
            const contentPackages = await ContentPackage.find({
              filter: {
                where: {
                  C_type: 'עץ הידע'
                },
                order: 'created desc',
                fields: {
                  image: true,
                  id: true,
                  categories: true,
                  name: true,
                  tags: true
                }
              }
            }).$promise
            contentPackages.map(contentPackage =>
              MultiTranslateService.getForView(
                ContentPackage,
                $rootScope.currentLang,
                contentPackage
              )
            )
            resolve(_.orderBy(contentPackages, ['created'], ['desc']))
          })
        }
      }
    })
    .state('app.catalog.training', {
      url: '/training/show',
      views: {
        'content@app': {
          template: require('app/modules/components/tree-category-grid/tree-category-grid.html'),
          controller: 'CatalogTrainingController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCategoryList: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          ),
        Items: function (Timeline) {
          return new Promise(async resolve => {
            const timelines = await Timeline.find({
              filter: {
                order: 'created desc',
                fields: {
                  id: true,
                  ResourceId: true,
                  categories: true,
                  name: true,
                  groups: true
                }
              }
            }).$promise
            resolve(_.orderBy(timelines, ['created'], ['desc']))
          })
        }
      }
    })
    .state('app.catalog.training1', {
      url: '/training1/show',
      views: {
        'content@app': {
          template: require('app/modules/components/tree-category-grid/tree-category-grid.html'),
          controller: 'CatalogTrainingController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCategoryList: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          ),
        Items: function ($rootScope, ContentPackage, MultiTranslateService) {
          return new Promise(async resolve => {
            const contentPackages = await ContentPackage.find({
              filter: {
                where: { C_type: 'הדרכה' },
                order: 'created desc',
                fields: {
                  image: true,
                  id: true,
                  categories: true,
                  name: true,
                  tags: true
                }
              }
            }).$promise
            contentPackages.map(contentPackage => {
              contentPackage.type = 'ContentPackage'
              return MultiTranslateService.getForView(
                ContentPackage,
                $rootScope.currentLang,
                contentPackage
              )
            })
            resolve(contentPackages)
          })
        }
      }
    })
    .state('app.catalog.home', {
      url: '/home/show',
      views: {
        'content@app': {
          template: require('./pages/catalog-workflow-list/home.html'),
          controller: 'CatalogHomeController'
        }
      },
      bodyClass: 'catalog home',
      resolve: {
        Resolved: Catalog =>
          Catalog.getCatalogHome().$promise.then(res => res.result)
      }
    })

    .state('app.catalog.home1', {
      url: '/home1/show',
      views: {
        'content@app': {
          template: require('./pages/catalog-workflow-list/home.1.html'),
          controller: 'CatalogHome1Controller'
        }
      },
      bodyClass: 'catalog home',
      resolve: {
        training: function (ContentPackage) {
          return ContentPackage.find({
            filter: {
              where: {
                C_type: 'עץ הידע'
              },
              limit: 5,
              order: 'created desc'
            }
          }).$promise
        },
        timeline: function (ContentPackage) {
          return ContentPackage.find({
            filter: {
              where: {
                C_type: 'הדרכה'
              },
              limit: 5,
              order: 'created desc'
            }
          }).$promise
        },
        workflow: function (Workflow) {
          return Workflow.find({
            filter: {
              where: {
                currentReleasedVersion: true,
                C_type: 'רשימת תיוג'
              },
              limit: 5,
              order: 'created desc'
            }
          }).$promise
        },
        workflowFlatus: function (Workflow) {
          return Workflow.find({
            filter: {
              where: {
                currentReleasedVersion: true,
                C_type: 'איתור תקלות'
              },
              limit: 5,
              order: 'created desc'
            }
          }).$promise
        }
      }
    })
    .state('app.catalog.home-tpss', {
      url: '/home-tpss/show',
      views: {
        'content@app': {
          template: require('./pages/catalog-workflow-list/home-tpss.html'),
          controller: 'CatalogHomeTpssController'
        }
      },
      bodyClass: 'catalog home tpss',
      resolve: {
        Resolved: function catalogs (Catalog, Workflow, PartAssembly) {
          return new Promise(async resolve => {
            const items = []
            const catalogs = await Catalog.find({
              filter: {
                include: [
                  {
                    relation: 'assembly',
                    scope: {
                      include: ['picture_object']
                    }
                  }
                ]
              }
            }).$promise
            const partNumbers = catalogs
              .filter(catalog => catalog.partNumber)
              .map(catalog => catalog.partNumber)
            const workflows = await Workflow.find({
              filter: {
                where: {
                  C_type: 'troubleshooting',
                  'parts.sku': { inq: partNumbers }
                },
                fields: {
                  recordId: true,
                  C_type: true,
                  id: true,
                  parts: true
                }
              }
            }).$promise
            catalogs.forEach(catalog => {
              const item = {
                imgSrc: `./api/Resources/assemblyPicture/${encodeURIComponent(
                  catalog.partNumber
                )}`,
                name: catalog.name,
                items: []
              }

              const workflow = _.find(workflows, workflow => {
                return _.find(workflow.parts, {
                  sku: catalog.partNumber
                })
              })
              if (workflow) {
                item.items.push({
                  title: 'Troubleshooting',
                  state: `app.workflow.session.show({recordId:'${workflow.recordId}',preview:false})`
                })
              }
              item.items.push({
                title: 'Assembly',
                state: `app.catalog.workflows({where:'${JSON.stringify({
                  'parts.sku': {
                    inq: catalog.partNumber
                  },
                  C_type: 'assembly'
                })}'})`
              })
              item.items.push({
                title: 'Disassembly',
                state: `app.catalog.workflows({where:'${JSON.stringify({
                  'parts.sku': {
                    inq: catalog.partNumber
                  },
                  C_type: 'disassembly'
                })}'})`
              })
              item.items.push({
                title: 'Catalog',
                state: `app.catalog.show({id:'${catalog.id}'})`
              })
              items.push(item)
            })
            resolve(items)
          })
        }
      }
    })
    .state('app.catalog.home-tpss2', {
      url: '/home-tpss2/show',
      views: {
        'content@app': {
          template: require('./pages/catalog-workflow-list/home-tpss.html'),
          controller: 'CatalogHomeTpssController'
        }
      },
      bodyClass: 'catalog home tpss',
      resolve: {
        Resolved: function catalogs (Category, Catalog, Workflow, PartAssembly) {
          return new Promise(async resolve => {
            const items = []
            const catalogs = await Catalog.find({
              filter: {
                include: [
                  {
                    relation: 'assembly',
                    scope: {
                      include: ['picture_object']
                    }
                  }
                ]
              }
            }).$promise
            const categories = await Category.find({}).$promise
            const partNumbers = catalogs
              .filter(catalog => catalog.partNumber)
              .map(catalog => catalog.partNumber)
            const workflows = await Workflow.find({
              filter: {
                where: {
                  C_type: 'troubleshooting',
                  'parts.sku': { inq: partNumbers }
                },
                fields: {
                  recordId: true,
                  C_type: true,
                  id: true,
                  parts: true
                }
              }
            }).$promise
            catalogs.forEach(catalog => {
              const item = {
                imgSrc: `./api/Resources/assemblyPicture/${encodeURIComponent(
                  catalog.partNumber
                )}`,
                name: catalog.name,
                items: []
              }

              const workflow = _.find(workflows, workflow => {
                return _.find(workflow.parts, {
                  sku: catalog.partNumber
                })
              })
              if (workflow) {
                item.items.push({
                  title: 'Troubleshooting',
                  state: `app.workflow.session.show({recordId:'${workflow.recordId}',preview:false})`
                })
              }
              item.items.push({
                title: 'Assembly',
                state: `app.catalog.workflows({where:'${JSON.stringify({
                  'parts.sku': {
                    inq: catalog.partNumber
                  },
                  C_type: 'assembly'
                })}'})`
              })
              item.items.push({
                title: 'Disassembly',
                state: `app.catalog.workflows({where:'${JSON.stringify({
                  'parts.sku': {
                    inq: catalog.partNumber
                  },
                  C_type: 'disassembly'
                })}'})`
              })
              const category = _.find(categories, { name: item.name })
              if (category) {
                item.items.push({
                  title: 'Documentation',
                  state: `app.content-package.list({selectedCategories:'${JSON.stringify(
                    [category.id]
                  )}'})`
                })
              }

              items.push(item)
            })
            resolve(items)
          })
        }
      }
    })
    .state('app.catalog.workflows', {
      url: '/workflow/list/:where',
      views: {
        'content@app': {
          template: require('./pages/catalog-workflow-list/workflows.html'),
          controller: 'WorkflowsListController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCategoryList: Category => Category.find({}).$promise,
        Items: function (Workflow, getUrlFromObj, $stateParams) {
          return new Promise(async resolve => {
            const workflowFilter = {
              where: {
                currentReleasedVersion: true
              },
              fields: {
                id: true,
                categories: true,
                name: true,
                tags: true,
                ResourceId: true,
                recordId: true
              },
              include: [
                {
                  relation: 'resource',
                  scope: {
                    fields: {
                      name: true,
                      container: true
                    }
                  }
                }
              ]
            }
            workflowFilter.where = _.merge(
              workflowFilter.where,
              JSON.parse($stateParams.where)
            )
            console.log(JSON.parse($stateParams.where), workflowFilter.where)
            const workflows = await Workflow.find({ filter: workflowFilter })
              .$promise

            workflows.map(workflow => {
              if (workflow.resource) {
                workflow.image = getUrlFromObj(workflow.resource)
              }
              workflow.type = 'Workflow'
              return workflow
            })
            resolve(_.orderBy(workflows, ['created'], ['desc']))
          })
        }
      }
    })
    .state('app.catalog.new', {
      url: '/new/?:{initialValues:json}',
      params: {
        initialValues: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./pages/catalog-new-edit/catalog-new-edit.html'),
          controller: 'CatalogNewEditController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCatalog: () => null,
        ResolvedCategories: Category => Category.find({}).$promise,
        ResolvedGroups: Group => Group.find({}).$promise
      }
    })
    .state('app.catalog.edit', {
      url: '/edit/:id',
      views: {
        'content@app': {
          template: require('./pages/catalog-new-edit/catalog-new-edit.html'),
          controller: 'CatalogNewEditController'
        }
      },
      bodyClass: 'catalog catalog-list',
      resolve: {
        ResolvedCategories: Category => Category.find({}).$promise,
        ResolvedGroups: Group => Group.find({}).$promise,
        ResolvedCatalog: function (PartAssembly, Catalog, $stateParams) {
          return Catalog.findOne({
            filter: { where: { id: $stateParams.id } }
          }).$promise.then(catalog => {
            return PartAssembly.findOne({
              filter: {
                where: {
                  number: catalog.partNumber
                },
                include: ['image_object', 'picture_object'],
                deleted: true
              }
            }).$promise.then(part => {
              catalog.assembly = part
              return catalog
            })
          })
        }
      }
    })
    .state('app.catalog.show', {
      url: '/show/:id/?:selectedPartNumber/?:selectedSubPartNumber',

      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.splitter" */ '@progress/kendo-ui/js/kendo.splitter.min'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      params: {
        selectedPartNumber: {
          dynamic: true
        },
        selectedSubPartNumber: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./pages/catalog-show/catalog-show.html'),
          controller: 'CatalogShowController'
        }
      },
      bodyClass: 'catalog catalog-show',
      resolve: {
        ResolvedCatalog: function (Catalog, PartAssembly, $stateParams) {
          return Catalog.get({ id: $stateParams.id }).$promise.then(catalog => {
            return PartAssembly.getAssebliesByPartNumber({
              partNumber: catalog.partNumber
            }).$promise.then(data => ({ assembly: data.result, catalog }))
          })
        }
      }
    })

    .state('app.catalog.show-old', {
      url: '/show/released/:id/?:selectedPartNumber/?:selectedSubPartNumber',

      params: {
        selectedPartNumber: {
          dynamic: true
        },
        selectedSubPartNumber: {
          dynamic: true
        }
      },

      resolve: {
        RedirectToNewEndpoint: function ($state, $stateParams) {
          return $state.go('app.catalog.show', {
            id: $stateParams.id,
            selectedPartNumber: $stateParams.selectedPartNumber,
            selectedSubPartNumber: $stateParams.selectedSubPartNumber
          })
        }
      }
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.knowledgeBase.catalog', {
    title: 'CATALOG.CATALOGS',
    state: 'app.catalog.list',
    icon: 'icon-file-cog',
    weight: 30,
    acls: [{ model: 'Catalog', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.catalog-workflow', {
    title: 'CATALOG.CATALOG_WORKFLOW_LIST_TITLE',
    state: 'app.catalog.workflow-list',
    icon: 'icon-tag-outline',
    hidden: true,
    weight: 4,
    acls: [
      { model: 'Catalog', method: 'find' },
      { model: 'Workflow', method: 'find' }
    ]
  })
  msNavigationServiceProvider.saveItem('apps.learning', {
    title: 'CATALOG.LEARNING',
    state: 'app.catalog.learning',
    icon: 'icon-folder-outline',
    hidden: true,
    weight: 2,
    acls: [{ model: 'ContentPackage', method: 'find' }]
  })
  msNavigationServiceProvider.saveItem('apps.learning1', {
    title: 'עץ הידע',
    state: 'app.catalog.learning1',
    icon: 'icon-folder-outline',
    hidden: true,
    weight: 2,
    acls: [{ model: 'ContentPackage', method: 'find' }]
  })
  msNavigationServiceProvider.saveItem('apps.training', {
    title: 'CATALOG.TRAINING',
    state: 'app.catalog.training',
    icon: 'icon-arrange-send-backward',
    hidden: true,
    weight: 3,
    acls: [{ model: 'ContentPackage', method: 'find' }]
  })

  msNavigationServiceProvider.saveItem('apps.training1', {
    title: 'הדרכה',
    state: 'app.catalog.training1',
    icon: 'icon-arrange-send-backward',
    hidden: true,
    weight: 3,
    acls: [{ model: 'ContentPackage', method: 'find' }]
  })
  msNavigationServiceProvider.saveItem('apps.home', {
    title: 'CATALOG.HOME',
    state: 'app.catalog.home',
    icon: 'icon-home',
    hidden: true,
    weight: 1
  })
  msNavigationServiceProvider.saveItem('apps.home1', {
    title: 'CATALOG.HOME',
    state: 'app.catalog.home1',
    icon: 'icon-home',
    hidden: true,
    weight: 1
  })
}
