/* global Audio */
/** @ngInject */
function starwars () {
  require('./starwars.scss')
  return {
    template: require('./starwars.html'),
    bindings: {
      title: '<',
      content: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function Starwars ($element) {
        this.$onInit = function () {
          document.body.appendChild($element[0])
          const audio = new Audio('starwars.mp3')
          audio.play()
          setTimeout(() => {
            $element[0].remove()
            audio.pause()
          }, 45 * 1000)
        }
      }
  }
}

module.exports = starwars
