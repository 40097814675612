/* global _ */
/** @ngInject */
function mapEditorContextMenuController (
  $scope,
  mdPanelRef,
  ev,
  layerId,
  layer,
  selectLayer,
  unselectLayer,
  enableEditMode,
  selectedLayer
) {
  $scope._mdPanelRef = mdPanelRef
  $scope.layerSelected = !_.isNil(selectedLayer)
  $scope.layerId = layerId
  $scope.selectedLayer = selectedLayer

  $scope.unselect = () => {
    unselectLayer(layer)
    $scope.layerSelected = false
  }
  $scope.select = () => {
    selectLayer(layer)
    $scope.layerSelected = true
  }
  $scope.edit = () => {
    $scope.layerSelected = true
    unselectLayer(layer)
    enableEditMode(layerId, 'fromMap', ev.originalEvent)
  }
  $scope.close = () => {
    if ($scope._mdPanelRef) {
      $scope._mdPanelRef.close()
    }
  }
}

module.exports = mapEditorContextMenuController
