/* global $, angular,_, alert */
import svgPanZoom from 'root/src/vendors/svg-pan-zoom.min'
import svgselection from 'root/src/vendors/svgselection'
/** @ngInject */
function WiringNewEditController (
  $scope,
  $interval,
  $http,
  $window,
  $translate,
  getUrlFromObj,
  ResolvedWiring,
  Wire,
  $state,
  Wiringlist,
  $mdDialog
) {
  let CANVAS_SIZE_H
  let CANVAS_SIZE_W

  const TIME_TO_REMOVE_ACTIVE = 1000
  const TIME_TO_CHECK_FOR_DELETE = 1000

  $scope.getUrlFromObj = getUrlFromObj
  $scope.wiring = ResolvedWiring || {
    resource_id: null,
    name: '',
    wires: []
  }
  $scope.wiring.mode = 'view'
  $scope.imageObject = ResolvedWiring ? ResolvedWiring.resource_object : {}

  $scope.clearAdditionalLines = force => {
    const currentTime = new Date()
    const objToDelete = force
      ? $scope.selectedObjects
      : $scope.selectedObjects.filter(
          object => currentTime - object.time > TIME_TO_REMOVE_ACTIVE
        )
    objToDelete.forEach(object => {
      const index = _.findIndex($scope.selectedObjects, { id: object.id })
      $('#' + object.id).removeClass('nearby')
      $scope.selectedObjects.splice(index, 1)
    })
  }

  $scope.loadSVG = async url => {
    const svg = await $http.get(url)
    document.getElementById('svgfile').innerHTML = $window.DOMPurify.sanitize(
      svg.data,
      { USE_PROFILES: { svg: true } }
    )
    $scope.selectedObjects = []

    $('#svgfile svg').attr('id', 'main-svg')
    $('#svgfile svg').css('height', CANVAS_SIZE_H + 'px')
    $('#svgfile svg').css('width', CANVAS_SIZE_W + 'px')

    $('#svgfile marker').attr('markerUnits', 'userSpaceOnUse')

    if (!$scope.intervalClear) {
      $scope.intervalClear = $interval(
        $scope.clearAdditionalLines,
        TIME_TO_CHECK_FOR_DELETE
      )
    }
    // eslint-disable-next-line new-cap
    const svgSelectionInstance = new svgselection(
      document.getElementById('main-svg')
    )
    svgSelectionInstance.init()
    let flag = 0
    const element = document.getElementById('main-svg')
    element.addEventListener(
      'mousedown',
      function () {
        flag = 0
      },
      false
    )
    element.addEventListener(
      'mousemove',
      function () {
        flag = 1
      },
      false
    )
    element.addEventListener(
      'mouseup',
      async e => {
        if (flag === 0) {
          if ($scope.wiring.mode === 'view') {
            return
          }
          const manipulatedEvent = {
            target: document.querySelector(
              '.wiring-new-edit #main-svg .svg-pan-zoom_viewport'
            ),
            clientX: e.clientX,
            clientY: e.clientY
          }
          const result = await svgSelectionInstance.find(manipulatedEvent)
          if (result !== null) {
            console.log(result)
            $(result.element).toggleClass('selected')
            $scope.addOrRemoveWire(result.element)
          }
        } else if (flag === 1) {
          console.log('drag')
        }
      },
      false
    )

    /* $('.wiring-new-edit #main-svg').on('mousemove', function (evt) {
         if ($scope.wiring.mode === 'view') {
           return
         }
         let root = $('#main-svg')[0]
         let rpos = root.createSVGRect()
         rpos.x = evt.clientX - $('#vertical-navigation').width() - 16 - 8
         rpos.y = evt.clientY - 64 - 100 - 138.5 - 92 - 20
         rpos.width = rpos.height = 1
         let list = root.getIntersectionList(rpos, null)
         for (let i = 0; i < list.length; i++) {
           if (list[i] !== evt.target) {
             $(list[i]).parent().addClass('nearby')
             let id = $(list[i]).parent().attr('id')
             let index = _.findIndex($scope.selectedObjects, { id: id })
             if (index === -1) {
               $scope.selectedObjects.push({ id: id, time: new Date() })
             } else {
               $scope.selectedObjects[index].time = new Date()
             }
           }
         }
       })
*/
    $('.wiring-new-edit #main-svg rect,path,line').on('click', function (evt) {
      if ($scope.wiring.mode === 'view') {
        return
      }
      evt.stopPropagation()
      evt.cancelBubble = true
      $(this).toggleClass('selected')
      $scope.addOrRemoveWire(this)
    })

    $scope.zoomInstance = svgPanZoom($('.wiring-new-edit #main-svg')[0], {
      zoomEnabled: true,
      controlIconsEnabled: true
    })
    $('#svg-pan-zoom-controls').hide()
  }

  $scope.run = () => {
    if (ResolvedWiring) {
      const url = getUrlFromObj(ResolvedWiring.resource_object)
      $scope.loadSVG(url)
    }
  }

  $('document').ready(() => {
    CANVAS_SIZE_H = $('#main-view').height() - 190 - 100
    CANVAS_SIZE_W = $('.wiring-schema-container').width()
    $scope.run()
  })

  $scope.selectByIds = ids => {
    $('.wiring-new-edit #main-svg .selected').removeClass('selected')
    ids.forEach(id => {
      $(`.wiring-new-edit #main-svg *[nextplus="${id}"]`).addClass('selected')
    })
  }

  $scope.addOrRemoveWire = element => {
    if ($scope.wiring.mode === 'view') {
      return
    }
    const id = $(element).attr('nextplus')

    if ($scope.currentGroup.ids.includes(id)) {
      const index = $scope.currentGroup.ids.indexOf(id)
      $scope.currentGroup.ids.splice(index, 1)
    } else {
      $scope.currentGroup.ids.push(id)
    }

    $scope.selectByIds($scope.currentGroup.ids)
    $scope.$apply()
  }

  $scope.setEditMode = group => {
    if (group) {
      $scope.selectGroup(group)
    }
    $scope.wiring.mode = 'select'
  }

  $scope.clearAll = () => {
    if ($scope.currentGroup) {
      const confirm = $mdDialog
        .confirm()
        .title('Would you like to clear this group?')
        .targetEvent()
        .clickOutsideToClose(true)
        .parent(angular.element(document.body))
        .ok('Yes')
        .cancel('Cancel')

      $mdDialog.show(confirm).then(
        function () {
          $scope.currentGroup.ids = []
          $scope.selectByIds($scope.currentGroup.ids)
        },
        function () {}
      )
    }
  }

  $scope.resetZoom = () => {
    $scope.zoomInstance.reset()
  }

  $scope.zoomOut = () => {
    $scope.zoomInstance.zoomOut()
  }

  $scope.zoomIn = () => {
    $scope.zoomInstance.zoomIn()
  }

  $scope.applyGroup = () => {
    $scope.currentGroup = null
    $scope.wiring.mode = 'view'
    $scope.selectByIds([])
  }

  $scope.selectGroup = group => {
    if ($scope.wiring.mode === 'view') {
      $scope.selectByIds(group.ids)
      $scope.currentGroup = group
    }
  }

  $scope.addGroup = () => {
    $scope.applyGroup()
    $scope.wiring.wires.push({
      name: $translate.instant('Wiring.NEW_WIRE_GROUP'),
      ids: []
    })
    $scope.selectGroup($scope.wiring.wires[$scope.wiring.wires.length - 1])
    $scope.setEditMode()
  }

  $scope.deleteGroup = index => {
    const confirm = $mdDialog
      .confirm()
      .title($translate.instant('Wiring.DELETE_GROUP_CONFIRM'))
      .targetEvent()
      .clickOutsideToClose(true)
      .parent(angular.element(document.body))
      .ok($translate.instant('Wiring.YES'))
      .cancel($translate.instant('Wiring.CANCEL'))

    $mdDialog.show(confirm).then(
      function () {
        $scope.wiring.wires.splice(index, 1)
        $scope.applyGroup()
      },
      function () {}
    )
  }

  $scope.deleteWiring = index => {
    /* var confirm = $mdDialog.confirm()
      .title($translate.instant('Wiring.DELETE_WIRING'))
      .targetEvent()
      .clickOutsideToClose(true)
      .parent(angular.element(document.body))
      .ok($translate.instant('Wiring.YES'))
      .cancel($translate.instant('Wiring.CANCEL'))

    $mdDialog.show(confirm).then(function () {
      // delete wiring
    }, function () {
    }) */
  }

  $scope.addWiring = () => {
    if ($scope.wiring.name === '') {
      alert('No name')
      return
    }

    if (!$scope.wiring.resource_id || $scope.wiring.resource_id === '') {
      alert('No name')
      return
    }
    let wiresStructure = []

    Wiringlist.findById({ id: $scope.state.params.id })
      .$promise.then(data => (wiresStructure = data.wiresStructure))
      .then(
        () =>
          Wiringlist.wires.create({ id: $scope.state.params.id }, $scope.wiring)
            .$promise
      )
      .then(data =>
        wiresStructure.push({
          id: data.id,
          nodes: []
        })
      )
      .then(
        () =>
          Wiringlist.updateAll(
            { where: { id: $scope.state.params.id } },
            { wiresStructure }
          ).$promise
      )
      .then(data => {
        console.log(data)
        $state.go('app.wiring-list.show', { id: $scope.state.params.id })
      })
      .catch(e => {
        console.log(e)
      })
  }

  $scope.saveWiring = () => {
    if ($scope.wiring.name === '') {
      alert('No name')
      return
    }

    if (!$scope.wiring.resource_id || $scope.wiring.resource_id === '') {
      alert('No name')
      return
    }

    const tmp = _.omit(angular.copy($scope.wiring), 'mode')

    Wire.updateAll({ where: { id: tmp.id } }, tmp)
      .$promise.then(data => {
        $state.go('app.wiring-list.show', { id: $scope.state.params.id })
      })
      .catch(e => {
        console.log(e)
      })
  }

  $scope.cancel = () =>
    $state.go('app.wiring-list.show', { id: $scope.state.params.id })

  $scope.fileSelected = data => {
    if (data && data[0]) {
      $scope.imageObject = data[0]
      $scope.wiring.resource_id = data[0].id
      $scope.loadSVG(getUrlFromObj(data[0]))
    }
    if (data.length === 0) {
      $scope.imageObject = {}
      $('.wiring-new-edit #main-svg').remove()
    }
  }

  $scope.headerOptions = {
    icon: 'icon-cable-data',
    template: require('app/templates/headers/simple.html'),
    title: $scope.wiring.id
      ? $translate.instant('Wiring.EDIT_WIRING')
      : $translate.instant('Wiring.NEW_WIRING'),
    fabButton: {}
  }
}

module.exports = WiringNewEditController
