/* global _ kendo */
'use strict'

/**
 * options structure should look like that:
 * [{
 *    id: ''
 *    name: ''
 *    parent: ''// id
 * }]
 */

/** @ngInject */
function selectTreeController ($scope, $timeout) {
  $scope.onChange = function onChange () {
    if ($scope.to.onChange && typeof $scope.to.onChange === 'function') {
      $scope.to.onChange(
        _.get($scope.model, $scope.options.key),
        $scope.options
      )
    }
  }

  const buildTree = function buildTree (data, parent) {
    const result = []
    parent = typeof parent !== 'undefined' ? parent : { id: null }
    const children = _.filter(data, value => {
      return value.parent === parent.id
    })
    if (!_.isEmpty(children)) {
      _.each(children, function (child) {
        if (child != null) {
          result.push(child)
          const ownChildren = buildTree(data, child)
          if (!_.isEmpty(ownChildren)) {
            child.items = ownChildren
          }
        }
      })
    }
    return result
  }

  const values = $scope.to.options
  const data = values.map(option => ({ ...option, items: [], expanded: true }))
  const options = buildTree(data)

  $scope.selectOptions = {
    placeholder: $scope.to.label,
    filter: 'contains',
    dataTextField: 'name',
    dataValueField: 'id',
    checkboxes: $scope.to.multiple || false,
    autoClose: !$scope.to.multiple,
    dataSource: options
  }

  $scope.showLabel = () => {
    const value = _.get($scope.model, $scope.options.key)
    return !_.isNil(value) && !_.isEmpty(value)
  }

  $scope.$watch('to.options', function (newValue, oldValue, scope) {
    if ($scope.dropDownTree && !_.isEqual(newValue, oldValue)) {
      const values = $scope.to.options
      const options = buildTree(
        values.map(option => ({ ...option, items: [], expanded: true }))
      )
      const newDataSource = new kendo.data.HierarchicalDataSource({
        data: options
      })

      $scope.dropDownTree.setDataSource(newDataSource)
    }
    if ($scope.dropDownTree) {
      $scope.dropDownTree.value(_.get($scope.model, $scope.options.key) || [])
    }
    $scope.$applyAsync()
  })
}

module.exports = selectTreeController
