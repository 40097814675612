/** @ngInject */
function DocumentHistoryReleaseController (
  $scope,
  $rootScope,
  $mdDialog,
  $translate,
  $mdToast,
  $state
) {
  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.releaseDocument = {
    releaseType: 'minor',
    releaseName: '',
    releaseMessage: ''
  }

  $scope.releaseDocumentFields = [
    // {
    //   template: '<p translate="WF.RELEASE_LOG_DIALOG.PARAGRAPH_TEXT"></p>'
    // },
    {
      key: 'releaseName',
      type: 'input',
      templateOptions: {
        type: 'text',
        required: true,
        label: $translate.instant('CONTENTPACKAGE.RELEASE_NAME'),
        placeholder: $translate.instant('CONTENTPACKAGE.RELEASE_NAME_PH')
      }
    },
    {
      key: 'releaseMessage',
      type: 'textarea',
      className: 'layout-row',
      templateOptions: {
        rows: 3,
        grow: true,
        label: $translate.instant('CONTENTPACKAGE.RELEASE_DESCRIPTION'),
        placeholder: $translate.instant('CONTENTPACKAGE.RELEASE_DESCRIPTION_PH')
      }
    },
    {
      key: 'releaseType',
      type: 'selectWithSearch',
      defaultValue: 'minor',
      templateOptions: {
        required: true,
        label: $translate.instant('CONTENTPACKAGE.RELEASE_TYPE'),
        options: [
          {
            id: 'minor',
            name: $translate.instant('CONTENTPACKAGE.RELEASE_TYPES.MINOR')
          },
          {
            id: 'major',
            name: $translate.instant('CONTENTPACKAGE.RELEASE_TYPES.MAJOR')
          }
        ]
      }
    }
  ]

  $scope.release = function () {
    if (!$scope.releaseDocument.releaseName) {
      $mdToast.show(
        $mdToast.nextplus({
          position: $rootScope.toastLocation,
          parent: '.document-dialog-release',
          theme: 'error-toast',
          hideDelay: 3500
        })
      )
      $mdToast.updateTextContent(
        $translate.instant('CONTENTPACKAGE.ERRORS.RELEASE_NAME_IS_REQUIRED')
      )
    } else {
      $mdDialog.hide($scope.releaseDocument)
    }
  }

  $scope.skip = function () {
    $mdDialog.hide(null)
  }
}

module.exports = DocumentHistoryReleaseController
