/* global moment */
require('./timePicker.scss')
/** @ngInject */
function timePickerController ($scope, DateTimeFormatService) {
  $scope.isDisabled = $scope.to.disabled || false
  const dateTimeFormats = DateTimeFormatService.getDateTimeFormatsForField()
  $scope.dateOpts = {
    enableTime: true,
    noCalendar: true,
    placeholder: $scope.to.placeholder || null,
    dateFormat: dateTimeFormats.time,
    time_24hr: !dateTimeFormats.time?.endsWith('K'),
    minuteIncrement: 1,
    minTime: $scope.to.minTime || null,
    maxTime: $scope.to.maxTime || null,
    defaultDate:
      $scope.model[$scope.options.key] || moment().format(dateTimeFormats.time),
    onChange: function (selectedDates, dateStr, instance) {
      $scope.options.instance = instance
      $scope.$applyAsync(() => {
        console.log('selectedDates', selectedDates)
        console.log('dateStr', dateStr)
        if ($scope.to.onChange) {
          $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
        }
      })
    }
  }
}

module.exports = timePickerController
