/* global angular */
/** @ngInject */
function httpResponseInterceptor (
  $q,
  $rootScope,
  $injector,
  $window,
  AuthenticationTimeoutService
) {
  const showErrorToast = function showErrorToast (rejection) {
    const mdToast = $injector.get('$mdToast')
    const err = `${rejection.status} - ${rejection.statusText}`
    mdToast.show(
      mdToast.nextplus({
        position: $rootScope.toastLocation,
        content: err,
        parent: angular.element(document.body),
        theme: 'error-toast',
        hideDelay: 6000
      })
    )
    mdToast.updateTextContent(err)
  }

  function modifyResponse (response) {
    const payload = `'"><img src=x onerror="console.log('%c 999999', 'background: red; color: white;')" >'"`

    function containsUUID (str) {
      // UUID regular expression pattern
      const uuidPattern =
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/g

      // Test if the given string includes a UUID
      return uuidPattern.test(str)
    }
    function injectPayload (obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          injectPayload(obj[key])
        } else if (
          typeof obj[key] === 'string' &&
          !containsUUID(obj[key]) &&
          !['template'].includes(key)
        ) {
          obj[key] = payload.replace(
            '999999',
            `url: ${response.config.url}, key: ${key}`
          )
        }
      }
    }

    injectPayload(response.data)
    return response
  }
  return {
    responseError: function (rejection) {
      if (rejection.status === 401) {
        const unauthorizedRoutesBlacklist = [
          '/api/UserModels/login',
          '/api/UserModels/current',
          '/api/UserModels/logout',
          '/api/UserModels/logoutResolver'
        ]
        if (!unauthorizedRoutesBlacklist.includes(rejection.config.url)) {
          // Check if user is logged in (api/UserModels/current works)
          $rootScope.getCurrentUser(true).then(user => {
            if (user === null) {
              return $rootScope.logout()
            } else {
              showErrorToast(rejection)
            }
          })
        }
      } else if (rejection.status === 413) {
        showErrorToast(rejection)
      }

      return $q.reject(rejection)
    },
    response: function (response) {
      if (response.status.toString().startsWith('2')) {
        AuthenticationTimeoutService.updateActivity()
      }
      if (
        $window.localStorage.getItem('xssTest') === 'true' &&
        !response.config.url.includes('getTranslations') &&
        !response.config.url.includes('Settings') &&
        !response.config.url.includes('UserModels/current') &&
        typeof response.data === 'object' &&
        response.status === 200
      ) {
        response = modifyResponse(response)
      }
      return response
    }
  }
}

module.exports = httpResponseInterceptor
