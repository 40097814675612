/* global angular */
/** @ngInject */
function MdCollapsibleHeader () {
  return {
    restrict: 'E',
    scope: { text: '@?', mdOpen: '=?', clickCallback: '=?' },
    transclude: true,
    template: `<div layout="row" layout-align="space-between center" class="md-collapsible-tools" data-testid="collapsible_{{::text.split(' ').join('').toLowerCase()}}">
                  <span flex="100" ng-if="text" ng-bind-html="text"></span>
                  <div ng-if="!text" layout="column" style="width:100%;" ng-transclude></div>
                  <div layout="row" layout-align="end center">
                    <md-icon md-font-icon="icon-chevron-right" class="toggleOff icon s20 rtlIcon"></md-icon>
                    <md-icon md-font-icon="icon-chevron-down" class="toggleOn icon s20"></md-icon>
                  </div>
              </div>`,
    link: function (scope, element, attrs) {
      const CLASS_ACTIVE = 'active'
      if (!scope.clickCallback) {
        scope.clickCallback = () => {}
      }
      const onClick = event => {
        event.stopPropagation()
        const currentTarget = angular.element(event.currentTarget.parentNode)

        if (currentTarget.hasClass(CLASS_ACTIVE)) {
          currentTarget.removeClass(CLASS_ACTIVE)
          scope.IS_ACTIVE = false
          scope.mdOpen = false
          scope.clickCallback(false)
        } else {
          currentTarget.addClass(CLASS_ACTIVE)
          scope.clickCallback(true)
          scope.mdOpen = true
        }
      }
      element.on('click', onClick)
      scope.$on('$destroy', function () {
        element.off('click')
      })
    }
  }
}

module.exports = MdCollapsibleHeader
