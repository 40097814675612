/* global CodeMirror */
/** @ngInject */
function cssEditorDirective () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      ngModel: '='
    },
    template: `
          <textarea style="border: 1px solid;" class="flex-100" flex="100"></textarea>
      `,
    link: async function (scope, element, attrs) {},
    /** @ngInject */
    controller: ($scope, $element) => {
      import(
        /* webpackChunkName: "CodeMirror" */ 'app/chunks/CodeMirror.js'
      ).then(mod => {
        const editorCss = CodeMirror.fromTextArea($element[0], {
          value: $scope.ngModel || '',
          lineNumbers: true,
          indentUnit: 2,
          lineWrapping: true,
          lintOnChange: false,
          mode: 'css',
          gutters: ['CodeMirror-lint-markers'],
          lint: true
        })
        editorCss.on('change', function () {
          $scope.ngModel = editorCss.getValue()
        })
        editorCss.getDoc().setValue($scope.ngModel || '')
      })
    }
  }
}

module.exports = cssEditorDirective
