/* global _ */
import { BrowserStream } from './browser-stream'
require('./camera-capture.dialog.scss')
/** @ngInject */
function CameraCaptureDialogController (
  $scope,
  $rootScope,
  $translate,
  $element,
  $mdDialog,
  $window,
  $timeout
) {
  $scope.isLandscape = false

  const browserStream = new BrowserStream()

  let canvas = document.querySelector('#canvas')

  let video = document.querySelector('#video')

  let defaultDevice

  const cancelStream = function cancelStream () {
    try {
      browserStream.stopStreams()
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Get all video devices
   */
  const getAllVideoDevices = function getAllVideoDevices () {
    return new Promise((resolve, reject) => {
      browserStream
        .getVideoDevices()
        .then(videoInputDevices => {
          console.log('videoInputDevices', JSON.stringify(videoInputDevices))
          if (
            _.isNil(videoInputDevices) ||
            !Array.isArray(videoInputDevices) ||
            videoInputDevices.length === 0
          ) {
            $scope.optionsModel.errorMessage = $translate.instant(
              'VISION.DIALOG.NO_DEVICES_FOUND'
            )
          } else {
            defaultDevice = videoInputDevices[0].deviceId
            videoInputDevices.forEach((device, index) => {
              const option = {}
              option.id = device.deviceId
              option.name =
                device.label || `Camera ${index + 1}` || device.deviceId
              $scope.cameraOptions.push(option)
            })
            resolve()
          }
        })
        .catch(err => {
          console.error(err)
          $scope.optionsModel.errorMessage = JSON.stringify(err)
          reject(err)
        })
    })
  }
  /**
   * Start video stream
   */
  const startStream = async function startStream () {
    console.log(
      '%c Camera capture dialog - Start stream ',
      'background: #43a047; color: #ffffff'
    )
    if (navigator.mediaDevices.getUserMedia) {
      video = document.querySelector('#video')
      let selectedDeviceId = defaultDevice
      console.log('defaultDevice', defaultDevice)
      if (
        !_.isUndefined($scope.selectedDeviceId) &&
        $scope.selectedDeviceId !== null
      ) {
        const deviceExists = $scope.cameraOptions.find(
          op => op.id === $scope.selectedDeviceId
        )
        if (deviceExists) {
          selectedDeviceId = $scope.selectedDeviceId
        } else {
          $scope.selectedDeviceId = defaultDevice
        }
      }
      try {
        await browserStream.startStreamProcess(selectedDeviceId, 'video')
      } catch (err) {
        if (err.name === 'NotAllowedError') {
          $scope.optionsModel.errorMessage = $translate.instant(
            'CAMERA_CAPTURE.DIALOG.ERRORS.PERMISSIONS_ERROR'
          )
        } else {
          $scope.optionsModel.errorMessage = $translate.instant(
            'CAMERA_CAPTURE.DIALOG.ERRORS.GENERAL_ERROR'
          )
        }
        console.error(err)
      }
    }
  }
  /**
   * Stop video stream
   */
  const stopStream = function stopStream () {
    browserStream.stopStreams()
  }
  /**
   * Capture image from stream and stop the stream
   */
  $scope.captureImage = () => {
    if (!canvas) {
      canvas = document.querySelector('#canvas')
    }
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    const ctx = canvas.getContext('2d')
    ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
    $scope.imageCaptured = true
    stopStream()
  }
  /**
   * Restart video stream
   */
  $scope.retry = () => {
    $scope.imageCaptured = false
    $timeout(
      () => {
        startStream()
      },
      0,
      false
    )
  }
  /**
   * Send image for procssing according to current option
   */
  $scope.save = () => {
    switch ($scope.captureOption) {
      case 'camera':
        {
          const dataURI = canvas.toDataURL('image/jpeg')
          $mdDialog.hide({ data: dataURI })
          cancelStream()
        }
        break
      case 'video':
        $scope.cancel()
        break
      default:
        $scope.cancel()
    }
  }
  /**
   * Close dialog without return any value
   */
  $scope.cancel = function () {
    cancelStream()
    $mdDialog.cancel()
  }

  /**
   * Switch camera according to user select
   * @param {string} deviceId
   * @param {number} idx
   */
  $scope.cameraSelect = (deviceId, idx) => {
    $scope.selectedDeviceId = deviceId
    $scope.selectedDeviceIndex = idx
    $scope.imageCaptured = false
    $window.localStorage.removeItem('camera-capture-position')
    $window.localStorage.setItem('camera-capture-position', JSON.stringify(idx))
    startStream()
  }
  /**
   * Switch camera mode
   * @param {string} mode
   */
  $scope.switchMode = mode => {
    $scope.captureOption = mode
    $scope.imageCaptured = false
    try {
      browserStream.stopStreams()
    } catch (err) {
      console.error(err)
    } finally {
      startStream()
    }
  }
  /**
   * Initialization dialog
   */
  const initDialog = async function initDialog () {
    console.log(
      '%c Camera capture dialog - Initialization ',
      'background: #003851; color: #fcbb44'
    )
    $element[0].style.zIndex = 90
    $scope.captureOption = 'camera'
    $scope.optionsModel = { errorMessage: false }
    $scope.cameraOptions = []
    $scope.selectedDeviceIndex =
      JSON.parse($window.localStorage.getItem('camera-capture-position')) || 0
    $scope.imageCaptured = false
    try {
      await getAllVideoDevices()
      if (
        _.isNil($scope.cameraOptions[$scope.selectedDeviceIndex]) ||
        _.isNil($scope.cameraOptions[$scope.selectedDeviceIndex].id)
      ) {
        console.log(
          '%c Camera capture dialog - device not found from local storage ',
          'background: #f44336; color: #ffffff'
        )
        $scope.selectedDeviceIndex = 0
        $scope.selectedDeviceId = $scope.cameraOptions[0].id
      } else {
        console.log(
          '%c Camera capture dialog - device restore from local storage ',
          'background: #43a047; color: #ffffff'
        )
        $scope.selectedDeviceId =
          $scope.cameraOptions[$scope.selectedDeviceIndex].id
      }
      startStream()
    } catch (err) {
      console.error(err)
    }
  }

  initDialog()

  $scope.$on('$destroy', () => {
    try {
      browserStream.stopStreams()
    } catch (err) {
      console.error(err)
    }
  })
}
module.exports = CameraCaptureDialogController
