/* global _ */
/** @ngInject */
function WiringReorderController (
  $scope,
  $rootScope,
  $translate,
  $state,
  PermissionUtils,
  KendoTreeViewHelper,
  ResolvedWiring,
  Wiringlist,
  Page
) {
  const wiringById = _.keyBy(ResolvedWiring.wiresData, 'id')

  const prepareWiresStructureToSave = function prepareWiresStructureToSave (
    data
  ) {
    return data.map(obj => {
      const wireStructure = obj.toJSON()
      const nodes =
        Array.isArray(wireStructure.items) && wireStructure.items.length > 0
          ? prepareWiresStructureToSave(wireStructure.items)
          : []
      return {
        id: wireStructure.id,
        nodes: nodes
      }
    })
  }

  $scope.save = async function save () {
    $rootScope.loadingProgress = true
    const treeData = $scope.kendoTree.getData()
    const wiresStructure = prepareWiresStructureToSave(treeData[0].items)
    try {
      await Wiringlist.prototype$patchAttributes(
        { id: $scope.wiringList.id },
        { wiresStructure }
      ).$promise
      $rootScope.loadingProgress = false
      $state.go('app.wiring-list.show', { id: $scope.wiringList.id })
    } catch (err) {
      $rootScope.loadingProgress = false
      console.error(err)
      $rootScope.showErrorToast('NP-4120')
    }
  }

  const formatItems = function formatItems (nodes) {
    const items = []
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i]
      const wiring = wiringById[node.id]
      items.push({
        id: node.id,
        isRoot: false,
        name: wiring.name,
        items:
          Array.isArray(node.nodes) && node.nodes.length
            ? formatItems(node.nodes)
            : []
      })
    }
    return items
  }

  const initScreen = function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('Wiring.REORDER')
    Page.setTitleText($scope.title)
    $scope.wiringList = ResolvedWiring
    $scope.PermissionUtils = PermissionUtils
    $scope.kendoTree = KendoTreeViewHelper.treeViewInstance()
    const treeData = [
      {
        id: ResolvedWiring.id,
        name: ResolvedWiring.name,
        isRoot: true,
        expanded: true,
        items: formatItems(ResolvedWiring.wiresStructure)
      }
    ]
    $scope.kendoTree.setup(
      {
        dragAndDrop: true,
        dataSource: treeData,
        dragstart: function (e) {
          const { isRoot } = this.dataItem(e.sourceNode)
          if (isRoot) {
            e.preventDefault()
          }
        },
        drop: function (e) {
          const { valid, dropTarget, dropPosition } = e
          if (!valid) return
          const { isRoot } = this.dataItem(dropTarget)
          if (isRoot && dropPosition !== 'over') {
            e.setValid(false)
          }
          const targetLevel = this.dataItem(dropTarget).level()
          if (dropPosition === 'over' && targetLevel > 0) {
            e.setValid(false)
          }
        }
      },
      $scope
    )
  }

  initScreen()

  $scope.headerOptions = {
    icon: 'icon-cable-data',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {}
  }
}

module.exports = WiringReorderController
