function loadVariant () {
  require('./load-variant.scss')
  return {
    template: require('./load-variant.html'),
    bindings: {
      model: '<',
      page: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function LoadVariant ($scope, Variant, $translate, $rootScope) {
        const initComponent = async page => {
          this.model.isLoading = true
          this.options = []
          this.options = await Variant.find({
            filter: { where: { page: page } }
          }).$promise
          const myVariants = this.options.filter(
            option => option.createdBy === $rootScope.currentUser.id
          )
          const publicVariants = this.options.filter(
            option => option.createdBy !== $rootScope.currentUser.id
          )
          this.options = myVariants
            .map(variant => ({
              ...variant,
              group: $translate.instant('VARIANT.MY')
            }))
            .concat(
              publicVariants.map(variant => ({
                ...variant,
                group: $translate.instant('VARIANT.PUBLIC')
              }))
            )
          this.fields = [
            {
              template: `<div flex layout="row" layout-align="center center">
                          <md-progress-circular class="md-accent" md-diameter="40"></md-progress-circular>
                        <div>`,
              hideExpression: function ($viewValue, $modelValue, scope) {
                return !scope.model.isLoading
              }
            },
            {
              key: 'variantId',
              type: 'selectWithSearch',
              templateOptions: {
                label: $translate.instant('VARIANT.OPTIONS'),
                options: this.options,
                hasGroupedOptions: true,
                onChange: (value, options) => {
                  this.model.variant = this.options.find(
                    variant => variant.id === value
                  )
                }
              },
              hideExpression: function ($viewValue, $modelValue, scope) {
                return scope.model.isLoading
              }
            }
          ]
          this.model.isLoading = false
          $scope.$applyAsync()
        }
        this.$onInit = async function () {
          this.loading = true
          await initComponent(this.page)
          this.loading = false
        }
      }
  }
}

module.exports = loadVariant
