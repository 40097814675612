'use strict'
/* global  _ */
require('./segmentedButtons.scss')
/** @ngInject */
function segmentedButtons ($scope) {
  $scope.selectButton = selectedId => {
    if ($scope.to.disabled) return
    let value = _.get($scope.model, $scope.options.key)
    if ($scope.to.multiple) {
      if (!value) {
        value = []
      }
      if (value && !value.includes(selectedId)) {
        value.push(selectedId)
      } else if (value && value.includes(selectedId)) {
        value.splice(value.indexOf(selectedId), 1)
      }
    } else {
      value = selectedId
    }
    _.set($scope.model, $scope.options.key, value)
  }

  $scope.getSelectedValue = () => _.get($scope.model, $scope.options.key)
}

module.exports = segmentedButtons
