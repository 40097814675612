/* global _ */
/** @ngInject */
function WorkflowsListController (
  $scope,
  $translate,
  LoginService,
  $state,
  Page,
  getUrlFromObj,
  PermissionUtils,
  ResolvedCategoryList,
  Items
) {
  $scope.PermissionUtils = PermissionUtils
  $scope.page = {
    title: $translate.instant('CATALOG.TRAINING')
  }
  Page.setTitleText($scope.page.title)

  $scope.obj = {
    search: ''
  }

  $scope.allItems = Items
  $scope.filteredItems = _.cloneDeep($scope.allItems)
  $scope.inlineSearch = () => {
    const search = $scope.obj.search.toLowerCase()
    let items = $scope.filteredItems
    items = items.filter(item => {
      const name = item.name.toLowerCase()
      if (name.includes(search)) {
        return true
      }
      const tag = _.some(item.tags, tag => tag.indexOf(search) > -1)
      if (tag) return true

      return false
    })
    return items
  }
  $scope.goToItem = item => {
    if (item.type === 'Workflow') {
      $state.go('app.workflow.session.show', {
        preview: false,
        recordId: item.recordId
      })
    }
  }

  $scope.selectedCategories = {}

  $scope.categoryFromCatalogs = _.cloneDeep(
    _.flatten(_.map(_.filter(Items, { type: 'ContentPackage' }), 'categories'))
  )

  $scope.getItemCatagories = (allCategories, neededCategories) => {
    const allCategoryWithParents = []
    const getParents = categoryId => {
      allCategoryWithParents.push(categoryId)
      const category = _.find(allCategories, { id: categoryId })
      if (category && category.parentId) getParents(category.parentId)
    }
    neededCategories.map(categoryId => getParents(categoryId))
    return _.uniq(allCategoryWithParents).map(categoryId =>
      _.find(allCategories, { id: categoryId })
    )
  }

  $scope.filterCatalogCategoryList = _.cloneDeep(
    $scope.getItemCatagories(ResolvedCategoryList, $scope.categoryFromCatalogs)
  )
  const refreshFilteredItems = () => {
    const isCategoriesSelected = _.some(
      _.values($scope.selectedCategories),
      x => x === true
    )
    if (isCategoriesSelected) {
      $scope.filteredItems = $scope.allItems.filter(item => {
        return _.some(
          item.categories,
          id => $scope.selectedCategories[`${item.type}-${id}`]
        )
      })
    } else {
      $scope.filteredItems = _.cloneDeep($scope.allItems)
    }
  }

  const buildHierarchy = (arr, type) => {
    const roots = []

    const nodes = {}

    // find the top level nodes and hash the nodes based on parent
    for (let i = 0, len = arr.length; i < len; ++i) {
      try {
        const item = arr[i]

        const p = item.parentId

        const target = !p ? roots : nodes[p] || (nodes[p] = [])
        item.type = type
        target.push(item)
      } catch (e) {
        console.log(e)
      }
    }

    // function to recursively build the tree
    const findChildren = function (parent) {
      if (nodes[parent.id]) {
        parent.nodes = nodes[parent.id]
        for (let i = 0, len = parent.nodes.length; i < len; ++i) {
          findChildren(parent.nodes[i])
        }
      }
    }

    // enumerate through to handle the case where there are multiple roots
    for (let i = 0, len = roots.length; i < len; ++i) {
      findChildren(roots[i])
    }

    return roots
  }

  $scope.categoryCatalogData = buildHierarchy(
    $scope.filterCatalogCategoryList,
    'ContentPackage'
  )
  // $scope.categoryWorkflowData = workflowRootCategory

  $scope.visible = item => true

  $scope.toggle1 = (x, event) => {
    event.stopPropagation()
    x.toggle()
  }

  $scope.isCollapsed = node => !$scope.selectedCategories[node.id]

  const getAllChildIds = category => {
    const ids = []
    const getIds = cat => {
      if (cat.nodes && cat.nodes.length > 0) {
        cat.nodes.forEach(node => {
          ids.push(`${node.type}-${node.id}`)
          getIds(node)
        })
      }
    }
    getIds(category)
    return ids
  }

  $scope.isNodeSelected = node =>
    !!$scope.selectedCategories[`${node.type}-${node.id}`]

  $scope.selectNode = (node, $event) => {
    $event.stopPropagation()
    const value = !$scope.selectedCategories[`${node.type}-${node.id}`]
    $scope.selectedCategories = {}
    const ids = [`${node.type}-${node.id}`].concat(getAllChildIds(node))
    ids.map(childId => ($scope.selectedCategories[childId] = value))
    refreshFilteredItems()
  }

  $scope.headerOptions = {
    icon: 'icon-folder-outline',
    preTitleHtml: require('app/templates/headers/sidebar.html'),
    postTitleHtml: require('app/templates/headers/filter.html'),
    template: require('app/templates/headers/simple.html'),
    title: 'Work Procedures',
    fabButton: {}
  }
}

module.exports = WorkflowsListController
