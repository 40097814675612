/* global angular */
/** @ngInject */
import 'root/src/vendors/leaflet/leaflet'
import 'root/src/vendors/leaflet.dom-markers'

import CatalogController from './catalog.controller'
import CatalogListController from './pages/catalog-list/catalog-list'
import CatalogWorkflowListController from './pages/catalog-workflow-list/catalog-workflow-list'
import WorkflowsListController from './pages/catalog-workflow-list/workflows'
import CatalogLearningController from './pages/catalog-workflow-list/catalog-learning'
import CatalogTrainingController from './pages/catalog-workflow-list/catalog-training'
import CatalogHomeController from './pages/catalog-workflow-list/home'
import CatalogHome1Controller from './pages/catalog-workflow-list/home.1'
import CatalogHomeTpssController from './pages/catalog-workflow-list/home-tpss'
import {
  CatalogNewEditController
  // SelectAssemblyDialogController
} from './pages/catalog-new-edit/catalog-new-edit'
import CatalogConfig from './catalog.config'
import {
  PictureViewController,
  CatalogShowController
} from './pages/catalog-show/catalog-show'
const moduleName = 'app.catalog'
const CATALOG_MODULE = angular.module(moduleName, ['ngSanitize'])
CATALOG_MODULE.config(CatalogConfig)
  .controller('CatalogController', CatalogController)
  .controller('CatalogListController', CatalogListController)
  .controller('CatalogNewEditController', CatalogNewEditController)
  .controller('CatalogWorkflowListController', CatalogWorkflowListController)
  .controller('WorkflowsListController', WorkflowsListController)
  // .controller('SelectAssemblyDialogController', SelectAssemblyDialogController)
  .controller('PictureViewController', PictureViewController)
  .controller('CatalogShowController', CatalogShowController)
  .controller('CatalogLearningController', CatalogLearningController)
  .controller('CatalogTrainingController', CatalogTrainingController)
  .controller('CatalogHomeTpssController', CatalogHomeTpssController)
  .controller('CatalogHome1Controller', CatalogHome1Controller)
  .controller('CatalogHomeController', CatalogHomeController)

export default moduleName
