/* global _ $ L */

/** @ngInject */
function MapHelper () {
  const MapInstance = function (options, scope) {
    return new Promise(resolve => {
      const that = this
      this.map = null
      this.scope = scope
      this.options = options
      resolve({
        convertToLeafleatCoords: function ({ blat, blng }) {
          const bounds = this.getBounds()
          const lat = Math.round(
            (that.options.img.height * blat) / bounds._southWest.lat
          )
          const lng = Math.round(
            (that.options.img.width * blng) / bounds._northEast.lng
          )
          return { lat, lng }
        },
        createGenericCircle: function (layerId, coords, radius, style, events) {
          const tempCircle = _.cloneDeep(style)
          tempCircle.radius = radius
          const { lat, lng } = this.convertToLeafleatCoords({
            blat: coords[0],
            blng: coords[1]
          })
          const shape = L.circle([lat, lng], tempCircle)
          shape.layerId = layerId
          shape.layerType = 'circle'
          events.map(event => shape.on(event.name, event.fn))
          return shape
        },
        createGenericMarker: function (
          layerId,
          coords,
          markerData,
          events,
          inlineStyle
        ) {
          const { lat, lng } = this.convertToLeafleatCoords({
            blat: coords[0],
            blng: coords[1]
          })
          if (!inlineStyle) {
            inlineStyle = ''
          }
          const icon = L.divIcon({
            popupAnchor: [10, 20],
            iconSize: ['fit-content', 'fit-content'],
            type: 'dom',
            html: `<div layer-id="${layerId}" style="${inlineStyle}" class="minimap-marker ${markerData.icon}" id="${markerData.id}">${markerData.name}</div>`
          })
          const shape = L.marker([lat, lng], { icon })
          shape.layerId = layerId
          shape.layerType = 'marker'
          events.map(event => shape.on(event.name, event.fn))
          return shape
        },
        createGenericReactangle: function (layerId, coords, style, events) {
          coords = coords.map(lll => {
            return lll.map(ll => {
              const { lat, lng } = this.convertToLeafleatCoords({
                blat: ll.lat,
                blng: ll.lng
              })
              return { lat, lng }
            })
          })
          const shape = L.rectangle(coords, style)
          shape.layerId = layerId
          events.map(event => shape.on(event.name, event.fn))
          shape.layerType = 'rectangle'
          return shape
        },
        createGenericPolygon: function (layerId, coords, style, events) {
          coords = coords.map(lll => {
            return lll.map(ll => {
              const { lat, lng } = this.convertToLeafleatCoords({
                blat: ll.lat,
                blng: ll.lng
              })
              return { lat, lng }
            })
          })
          const shape = L.polygon(coords, style)
          shape.layerId = layerId
          shape.layerType = 'polygon'
          events.map(event => shape.on(event.name, event.fn))
          return shape
        },
        fixZoomLevels: function () {
          if (that.map) {
            that.map.options.minZoom = -9999999999999
            that.map.options.maxZoom = 9999999999999
            that.map.once('zoomend', function (e) {
              that.map.options.minZoom = that.map.getZoom()
              that.map.options.maxZoom = that.map.options.minZoom + 5
              that.map.options.zoomSnap =
                (that.map.options.maxZoom - that.map.options.minZoom) / 5 - 1
            })
            that.map.fitBounds(that.bounds)
          }
        },
        resizeLeafLeat: function (width, height) {
          $('#' + that.mapId).width(width)
          $('#' + that.mapId).height(height)
          if (that.map) that.map.invalidateSize({ debounceMoveend: false })
          this.fixZoomLevels()
        },
        convertToImageCoords: function ({ blat, blng }) {
          const lat = Math.round(
            (that.options.img.height * blat) / that.bounds._southWest.lat
          )
          const lng = Math.round(
            (that.options.img.width * blng) / that.bounds._northEast.lng
          )
          return { lat, lng }
        },
        createLayerControl: function (baseMaps, overlayMaps) {
          return new Promise(resolve => {
            const control = L.control
              .layers(baseMaps, overlayMaps)
              .addTo(that.map)
            resolve(control)
          })
        },
        destroyMap: function () {
          if (that.map) that.map.remove()
        },
        buildMap: function (mapId) {
          return new Promise(resolve => {
            that.mapId = mapId
            that.options.minimap = !!that.options.minimap
            that.options.zoomControl =
              that.options.zoomControl === undefined
                ? true
                : that.options.zoomControl
            const { img } = that.options
            that.options.mapContainer = that.options.mapContainer || 'map'
            that.options.miniMapContainer =
              that.options.miniMapContainer || 'mini-map-container'
            that.options.miniMapOptions = that.options.miniMapOptions || {
              zoomLevelFixed: 0,
              class: 'minimapclass',
              type: 'minimap',
              position: 'bottomright'
            }
            this.destroyMap()
            that.map = L.map(mapId, {
              minZoom: 0,
              zoomSnap: 0.01,
              zoomDelta: 1,
              maxZoom: 18,
              attributionControl: false,
              center: [0, 0],
              zoom: 0,
              zoomControl: that.options.zoomControl,
              crs: L.CRS.Simple
            })
            const originalImage = {
              w: img.width,
              h: img.height,
              url: img.src
            }
            const leafletSize = {
              w: document.getElementById(that.options.mapContainer).clientWidth,
              h: document.getElementById(that.options.mapContainer).clientHeight
            }
            const southWest = that.map.unproject([0, originalImage.h], 0)
            const northEast = that.map.unproject([originalImage.w, 0], 0)
            that.bounds = new L.LatLngBounds(southWest, northEast)
            const overlay = L.imageOverlay(
              originalImage.url,
              that.bounds
            ).addTo(that.map)
            this.resizeLeafLeat(leafletSize.w, leafletSize.h)
            that.map.doubleClickZoom.disable()
            that.map.whenReady(() => {
              console.log('when ready in factory')
              setTimeout(function () {
                that.map.setMaxBounds(that.bounds)
              }, 500) // FIXME: change me to elegant handle
            })
            resolve({ map: that.map, overlay })
          })
        },
        getBounds: function () {
          return that.bounds
        },
        getMapId: function () {
          return that.mapId
        },
        bounds: that.bounds,
        map: that.map
      })
    })
  }
  return {
    MapInstance
  }
}

module.exports = MapHelper
