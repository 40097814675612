/* global $,_,angular */
import SelectToRelinkController from './relink'

/** @ngInject */
function ContentPackageShowController (
  $scope,
  $rootScope,
  $window,
  DocumentUtils,
  Page,
  $translate,
  $state,
  $mdDialog,
  Resolved,
  PermissionUtils,
  EventService,
  htmlWork
) {
  const ResolvedContentPackage = Resolved.ResolvedContentPackage
  const ResolvedContentPackageSession = Resolved.ResolvedContentPackageSession
  let currentTemplate =
    !$rootScope.contentPackagTemplate ||
    $rootScope.contentPackagTemplate === 'Default'
      ? $rootScope.appSettings.contentPackageDefaultTemplate || 'TreeBased'
      : $rootScope.contentPackagTemplate
  if (currentTemplate === 'Default') currentTemplate = 'TreeBased'
  const loadCss = () => {
    const css = ResolvedContentPackage.css
    if (css) {
      const style = document.createElement('style')
      style.setAttribute('id', ResolvedContentPackage.id)
      style.setAttribute('type', 'text/css')
      style.innerHTML = css
      document.querySelector('head').appendChild(style)
    }
  }
  loadCss()
  const addLightboxToImages = html => {
    const $html = $('<div />', { html })

    $html.find('img').each((i, elm) => {
      elm = $(elm)
      elm.attr('md-lightbox', true)
      elm.attr('lazy-load', elm.attr('src'))
      elm.attr('src', '')
    })
    return $html.html()
  }
  ResolvedContentPackage.documents.map(document => {
    document.content = htmlWork.textAngularSanitize(document.content)
    document.originalName = htmlWork.textAngularSanitize(document.originalName)
    document.content = addLightboxToImages(document.content)
    return document
  })
  $scope.where = {
    contentPackageId: ResolvedContentPackage.id
  }

  EventService.saveEvent({
    type: 'view',
    model: 'ContentPackage',
    modelId: ResolvedContentPackage.id
  })

  $scope.updateTimeline = () => {
    const topicWidth = $('.timeline_topics>div').width()
    const topicCount = $('.timeline_topics>div').length
    $('.timeline_rootline').css('width', topicWidth * topicCount)

    $('.topic_name').each(function (index, value) {
      // $(value).css('padding-left', topicWidth / 2 - $(value).width() / 2)
    })

    $('.timeline_topics').css(
      'min-height',
      $('#timeline_container').height() - 11
    )
  }

  $scope.contentPackageSession = ResolvedContentPackageSession
  $scope.packageContentType = $state.params.contentType
  $scope.PermissionUtils = PermissionUtils

  $scope.page = {}
  Page.setTitleText(ResolvedContentPackage.name)

  $scope.searchPlaceholder = $translate.instant('CONTENTPACKAGE.FILTER')

  $scope.increaseFontSize = function ($event) {
    $event.stopImmediatePropagation()
    let fontSize = parseInt($('.document_header *').css('font-size'), 10) + 1
    // now you have a proper float for the font size (yes, it can be a float, not just an integer)
    $('.document_header *').css('font-size', fontSize)
    fontSize = parseInt($('.document').css('font-size'), 10) + 1
    // now you have a proper float for the font size (yes, it can be a float, not just an integer)
    $('.document').css('font-size', fontSize)
  }
  $scope.decrieseFontSize = function ($event) {
    $event.stopImmediatePropagation()
    let fontSize = parseInt($('.document_header *').css('font-size'), 10) - 1
    // now you have a proper float for the font size (yes, it can be a float, not just an integer)
    $('.document_header *').css('font-size', fontSize)
    fontSize = parseInt($('.document').css('font-size'), 10) - 1
    // now you have a proper float for the font size (yes, it can be a float, not just an integer)
    $('.document').css('font-size', fontSize)
  }

  $scope.contentPackage = ResolvedContentPackage

  $scope.close = () => {
    $state.go('app.content-package.list')
  }

  $scope.openRelinkDialog = () =>
    $mdDialog
      .show({
        controller: SelectToRelinkController,
        template: require('./relink.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {},
        clickOutsideToClose: true
      })
      .then(
        function () {},
        function () {}
      )

  $scope.search = {
    query: ''
  }
  $scope.visible = item => {
    if (!$scope.search.query || $scope.search.query.length === 0) {
      return true
    } else if (
      item.search.toLowerCase().indexOf($scope.search.query.toLowerCase()) ===
      -1
    ) {
      return false
    } else {
      item.collapsed = false
      return true
    }
  }

  $scope.toggle1 = (x, event) => {
    event.stopPropagation()
    event.preventDefault()
    x.collapsed = !x.collapsed
  }

  $scope.getArrayOfDocs = array => {
    let result = []
    array.forEach(function (a) {
      DocumentUtils.getDocument(a.id, ResolvedContentPackage.documents)
      result.push(a)
      if (Array.isArray(a.nodes)) {
        result = result.concat($scope.getArrayOfDocs(a.nodes))
      }
    })
    return result
  }

  $scope.data = [
    DocumentUtils.makeIdTree({
      id: ResolvedContentPackage.id,
      name: ResolvedContentPackage.name,
      search:
        ResolvedContentPackage.name +
        ' ' +
        DocumentUtils.getValueForSearch(
          ResolvedContentPackage.documentStructure,
          ResolvedContentPackage.documents
        ),
      nodes: DocumentUtils.formatNodes(
        ResolvedContentPackage.documentStructure,
        ResolvedContentPackage.documents
      )
    })
  ]

  $scope.docsOrder = $scope.getArrayOfDocs(
    ResolvedContentPackage.documentStructure
  )

  $scope.docs = $scope.docsOrder.map(x => {
    return DocumentUtils.getDocument(x.id, ResolvedContentPackage.documents)
  })
  $scope.docsById = {}
  _.each($scope.docs, doc => {
    $scope.docsById[doc.id] = doc
  })
  $scope.rootIdInPath = rootId => {
    if (!$scope.currentDocument || !$scope.currentDocument.rootId) return false
    if ($scope.currentDocument.rootId === rootId) return false
    return $scope.currentDocument.rootId.includes(rootId)
  }

  $scope.currentDocument = {}
  /* function getScrollParent (node) {
    if (node == null) {
      return null
    }

    if (node.scrollHeight > node.clientHeight) {
      return node
    } else {
      return getScrollParent(node.parentNode)
    }
  } */
  const goToDocWithId = elementId => {
    /* console.log(ResolvedContentPackage)
    let $container = $('#doc_container')
      .parent()
      .parent()
      .parent()
      .parent()
*/

    let $scrollTo = $(`a[name=${elementId.substr(1)}]`)
    if ($scrollTo.length === 0) {
      if (elementId[0] === '#') {
        $scrollTo = $(`${elementId}`)
      } else {
        $scrollTo = $(`#${elementId}`)
      }
    }
    if ($scrollTo) {
      if ($scrollTo.length > 1) $scrollTo = $($scrollTo[0])
      console.log($scrollTo)
      let $container = $('#right-side')
      if (currentTemplate === 'Feed') {
        $container = $('md-content#content')
      }
      if ($container === null) return
      $container = $($container)
      const top = $scrollTo && $scrollTo.offset() ? $scrollTo.offset().top : 0
      const val = top - $container.offset().top + $container.scrollTop()
      $container.animate({
        scrollTop: val
      })
    }
  }

  $scope.loadDocument = recordId => {
    const doc = DocumentUtils.getDocument(
      recordId,
      ResolvedContentPackage.documents
    )
    if (doc && doc.name) {
      $scope.currentDocument = angular.copy(doc)
      setTimeout(
        () => goToDocWithId(`#document_${$scope.currentDocument.recordId}`),
        700
      )
    } else {
      if ($scope.docs && $scope.docs.length > 0) {
        $scope.currentDocument = $scope.docs[0]
      }
    }
  }

  const sharp = $state.params['#']
  if (sharp) {
    $scope.loadDocument(sharp.replace('document_', ''))
  } else {
    $scope.loadDocument($state.params.recordId)
  }

  $scope.nodeClick = (node, event) => {
    event.stopPropagation()
    $scope.loadDocument(node.recordId)
  }
  $scope.setCollapseStatus = (node, value) => {
    node.collapsed = value
    if (node.nodes && node.nodes.length !== 0) {
      node.nodes = node.nodes.map(x => $scope.setCollapseStatus(x, value))
    }
    return node
  }

  $scope.collapseAll = function () {
    $scope.data = $scope.data.map(node => $scope.setCollapseStatus(node, true))
  }
  $scope.expandAll = function () {
    $scope.data = $scope.data.map(node => $scope.setCollapseStatus(node, false))
  }

  $scope.reloadContentType = contentType => {
    $state.params.contentType = $scope.packageContentType = contentType
    $state.go(
      'app.content-package.show',
      {
        contentType: $scope.packageContentType,
        id: $state.params.id
      },
      { reload: true }
    )
  }
  const handleInnerClick = event => {
    let element = event.target
    while (element.tagName !== 'A' && element.parentNode) {
      element = element.parentNode
    }
    /* if (element.tagName !== 'A') {
      element = event.target.parentNode
    } */
    if (
      element.hash &&
      (element.hash.startsWith('#imported-doc-') || !element.hash.includes('/'))
    ) {
      // Don't follow the link
      event.preventDefault()
      event.stopPropagation()
      goToDocWithId(element.hash)
      return false
    }
  }
  document.addEventListener('click', handleInnerClick, true)
  $scope.$on('$destroy', () => {
    document.removeEventListener('click', handleInnerClick)
    if (document.getElementById(ResolvedContentPackage.id)) {
      document.getElementById(ResolvedContentPackage.id).remove()
    }
  })

  const panesOptions = () => {
    const panes = $window.localStorage.getItem('content-package-panes')
    if (panes) return JSON.parse(panes)
    return [{ size: '25%', min: '15%', collapsible: true }, { min: '50%' }]
  }

  $(document).ready(() => {
    const initSplitter = function initSplitter () {
      const initPanes = panesOptions()
      if ($rootScope.dir === 'rtl') {
        const index = initPanes.findIndex(pane => pane.collapsible)
        if (index === 0) {
          initPanes.reverse()
        }
      }
      $('#content-package-splitter').kendoSplitter({
        panes: initPanes,
        layoutChange: function (e) {
          const {
            sender: {
              options: { panes }
            }
          } = e
          if (panes) {
            if ($rootScope.dir === 'rtl') {
              const index = panes.findIndex(pane => pane.collapsible)
              if (index !== 0) {
                panes.reverse()
              }
            }
            $window.localStorage.removeItem('content-package-panes')
            $window.localStorage.setItem(
              'content-package-panes',
              JSON.stringify(panes)
            )
          }
        }
      })
    }
    if (
      ResolvedContentPackage.template === 'Default' ||
      ResolvedContentPackage.template === 'TreeBased'
    ) {
      if ($rootScope.dir === 'rtl') {
        $('#left-side').before($('#right-side')) // swap panes
      }
      initSplitter()
      $(window).on(
        'resize.doResize',
        _.debounce(function () {
          const splitter = $('#content-package-splitter').data('kendoSplitter')
          if (splitter) {
            splitter.destroy()
            $('.k-splitbar').remove()
          }
          initSplitter()
        }, 500)
      )
    }
  })

  const customHeaderBlock = `<div class="right-side" layout="row">
  <div
    data-testid="content-type"
    ng-show="::PermissionUtils.isPermit('Document','addDocument')"
    layout="row"
    layout-align="space-between center"
    style="font-size: 18px">
    <span translate="CONTENTPACKAGE.CONTENT_TYPE"></span>
    <md-select
      style="margin: 0px 10px"
      ng-change="reloadContentType(packageContentType)"
      ng-model="packageContentType"
      placeholder=""
      class="md-no-underline"
    >
      <md-option value="released">
        <span translate="CONTENTPACKAGE.RELEASED"></span>
      </md-option>
      <md-option value="last">
        <span translate="CONTENTPACKAGE.LAST"></span>
      </md-option>
    </md-select>
  </div>
  <md-menu
    ng-show="::PermissionUtils.isPermit('Document','patchAttributes')"
    md-position-mode="target-right target"
  >
    <md-button
      data-testid="content-package-menu"
      aria-label="Menu"
      class="md-icon-button"
      ng-click="$mdOpenMenu($event)"
    >
      <md-icon
        md-font-icon="icon-dots-vertical"
        class="icon"
      ></md-icon>
    </md-button>

    <md-menu-content width="4">
      <md-menu-item ng-show="::PermissionUtils.isPermit('ContentPackage','patchAttributes', null, contentPackage.UserId)">
        <md-button>
          <div layout="row" ui-sref="app.content-package.edit({id: contentPackage.id})" data-testid="edit-content-package">
            <md-icon md-font-icon="icon-pencil" class="icon"></md-icon>
            <p flex>${$translate.instant('CONTENTPACKAGE.EDIT_PACKAGE')}</p>
          </div>
        </md-button>
      </md-menu-item>
      <md-menu-item ng-show="::PermissionUtils.isPermit('ContentPackage','patchAttributes', null, contentPackage.UserId) && PermissionUtils.isPermit('Document','patchAttributes')">
        <md-button>
          <div
            layout="row"
            data-testid="add-new-document"
            ui-sref="app.content-package.new-document({id: contentPackage.id})"
          >
            <md-icon
              md-font-icon="icon-plus"
              class="icon"
            ></md-icon>
            <p flex>${$translate.instant('CONTENTPACKAGE.ADD_DOCUMENT')}</p>
          </div>
        </md-button>
      </md-menu-item>
      <md-menu-item
        ng-show="PermissionUtils.isPermit('ContentPackage','patchAttributes', null, contentPackage.UserId) && PermissionUtils.isPermit('Document','patchAttributes')"
      >
        <md-button>
          <div
            layout="row"
            data-testid="import-document"
            ui-sref="app.content-package.import-document({id: contentPackage.id})"
          >
            <md-icon
              md-font-icon="icon-upload"
              class="icon"
            ></md-icon>
            <p flex>
            ${$translate.instant('CONTENTPACKAGE.IMPORT_FROM_WORD_OR_ZIP')}
            </p>
          </div>
        </md-button>
      </md-menu-item>

      <md-menu-item
        ng-show="PermissionUtils.isPermit('Document','patchAttributes')"
      >
        <md-button
        data-testid="auto-linking"
          ng-click="openRelinkDialog()"
        >
          <div layout="row">
            <md-icon
              md-menu-align-target
              md-font-icon="icon-link-variant"
              class="icon"
            ></md-icon>
            <p flex>
            ${$translate.instant('CONTENTPACKAGE.AUTO_LINKING')}
            </p>
          </div>
        </md-button>
      </md-menu-item>
      <md-menu-item
        ng-show="PermissionUtils.isPermit('ContentPackage','patchAttributes', null, contentPackage.UserId)"
      >
        <md-button>
          <div
            layout="row"
            data-testid="reorder-documents"
            ui-sref="app.content-package.reorder({id: contentPackage.id})"
          >
            <md-icon
              md-font-icon="icon-sort"
              class="icon"
            ></md-icon>
            <p flex>
            ${$translate.instant('CONTENTPACKAGE.REORDER')}
            </p>
          </div>
        </md-button>
      </md-menu-item>
    </md-menu-content>
  </md-menu>
</div>`

  $scope.headerOptions = {
    icon: 'icon-document',
    postTitleHtml: customHeaderBlock,
    template: require('app/templates/headers/simple.html'),
    title: htmlWork.htmlEncode($scope.contentPackage.name),
    fabButton: {}
  }
}

module.exports = ContentPackageShowController
