import UUID from 'uuid'

/* global _  */

/** @ngInject */
function signsSettings ($scope, $mdSelect) {
  $scope.addSign = () => {
    if ($scope.to.onChange) {
      // if (_.isArray($scope.options.formControl)) {
      //   $scope.options.formControl = $scope.options.formControl[0]
      // }
      $scope.model[$scope.options.key].signs.push({
        id: UUID(),
        name: 'no name',
        certificateIds: []
      })
      if ($scope.to.onChange) {
        $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
      }
    }
  }
  $scope.deleteSign = id => {
    const index = _.findIndex($scope.model[$scope.options.key].signs, {
      id: id
    })
    $scope.model[$scope.options.key].signs.splice(index, 1)
    if ($scope.to.onChange) {
      if (_.isArray($scope.options.formControl)) {
        $scope.options.formControl = $scope.options.formControl[0]
      }
      if ($scope.to.onChange) {
        $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
      }
    }
  }

  let input
  $scope.optionsToDisplay = $scope.to.options
  $scope.searchTerm = ''
  $scope.search = $event => {
    input = $event.target
    $scope.searchTerm = input.value
    $scope.debouncedFn(input.value)
  }
  $scope.$watch('to.options', function (newValue, oldValue, theScope) {
    $scope.optionsToDisplay = $scope.to.options
  })
  $scope.onSelect = () => {
    if (input) {
      input.value = ''
    }
    if (!$scope.to.multiple) {
      $scope.optionsToDisplay = $scope.to.options
    }
    if ($scope.to.onChange) {
      if (_.isArray($scope.options.formControl)) {
        $scope.options.formControl = $scope.options.formControl[0]
      }
      if ($scope.to.onChange) {
        $scope.to.onChange($scope.model[$scope.options.key], $scope.options)
      }
    }
  }
  $scope.clearSearchTerm = () => {
    if (input) {
      $scope.searchTerm = input.value
      input.value = ''
      $scope.makeSearch(input.value)
    }
  }
  $scope.makeSearch = word => {
    if (!word) {
      $scope.optionsToDisplay = $scope.to.options
    } else {
      $scope.optionsToDisplay = $scope.to.options.filter(option =>
        option.name.toLowerCase().includes(word.toLowerCase())
      )
    }
  }
  $scope.debouncedFn = _.debounce($scope.makeSearch, 200)
}

module.exports = signsSettings
