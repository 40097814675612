/* global _, angular */

/** @ngInject */
function WiringListNewEditController (
  $scope,
  $rootScope,
  $stateParams,
  $mdDialog,
  $translate,
  FormlyHelper,
  ResolvedWiring,
  getUrlFromObj,
  Wiringlist,
  ResolvedCategories,
  ResolvedGroups,
  PermissionUtils,
  $state
) {
  const initScreen = function initScreen () {
    $scope.getUrlFromObj = getUrlFromObj
    $scope.PermissionUtils = PermissionUtils
    $scope.categories = ResolvedCategories
    const categoriesForSelect = ResolvedCategories.map(category => ({
      id: category.id,
      name: category.name,
      parent: category.parentId
    }))
    let groupsForSelect = []
    if (
      $rootScope.currentUser &&
      $rootScope.currentUser.groups &&
      $rootScope.currentUser.groups.length > 0
    ) {
      groupsForSelect = ResolvedGroups.filter(
        group => $rootScope.currentUser.groups.indexOf(group.id) !== -1
      )
    } else {
      groupsForSelect = ResolvedGroups
    }

    if (ResolvedWiring) {
      $scope.wiringList = ResolvedWiring
      $scope.imageObject = ResolvedWiring.resource_object
    } else {
      $scope.wiringList = {
        name: '',
        resource_id: null,
        categories: [],
        groups:
          $rootScope.currentUser && $rootScope.currentUser.groups
            ? $rootScope.currentUser.groups
            : []
      }
      $scope.imageObject = {}
      if ($stateParams && $stateParams.initialValues) {
        Object.keys($stateParams.initialValues).forEach(key => {
          $scope.wiringList[key] = $stateParams.initialValues[key]
        })
      }
    }

    const wiringListStaticFields = [
      {
        key: 'name',
        type: 'input',
        className: 'layout-row',
        templateOptions: {
          focus: true,
          type: 'text',
          required: true,
          label: $translate.instant('Wiring.NAME'),
          onChange: function (value, options) {}
        }
      },
      {
        key: 'resource_id',
        type: 'upload',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('Wiring.IMAGE'),
          filetype: 'image',
          caption: $translate.instant('Wiring.IMAGE'),
          mode: 'full',
          keyIs: 'id',
          object: 'resource_object',
          onClick: function (value, options) {
            options.validation.errorExistsAndShouldBeVisible = null
            options.formControl.$setValidity('serverError', true)
          },
          onFileSelect: function (result) {
            $scope.fileSelected(result)
          }
        }
      },
      {
        key: 'categories',
        type: 'selectTree',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('CATALOG.CATEGORIES'),
          options: categoriesForSelect,
          multiple: true,
          onChange: function (value, options) {}
        }
      },
      {
        key: 'groups',
        type: 'selectWithSearch',
        className: 'layout-column',
        templateOptions: {
          label: $translate.instant('CATALOG.GROUPS'),
          multiple: true,
          searchPlaceholder: 'Search...',
          options: groupsForSelect,
          onChange: function (value, options) {
            options.validation.errorExistsAndShouldBeVisible = null
            options.formControl.$setValidity('serverError', true)
          }
        }
      }
    ]

    $scope.wiringListFields = FormlyHelper.buildFields(
      wiringListStaticFields,
      Wiringlist
    )
  }

  initScreen()

  $scope.cancel = () => $state.go('app.wiring-list.list')

  $scope.fileSelected = data => {
    if (data && data[0]) {
      $scope.imageObject = data[0]
      $scope.wiringList.resource_id = data[0].id
    }
    if (data.length === 0) {
      $scope.imageObject = {}
    }
  }

  $scope.save = () => {
    if (!$scope.wiringList.categories) $scope.wiringList.categories = []
    const tmp = _.omit(angular.copy($scope.wiringList), [
      'resource_object',
      'wireIds'
    ])
    if ($scope.wiringList.id) {
      // edit
      Wiringlist.prototype$patchAttributes({ id: tmp.id }, tmp)
        .$promise.then(() => {
          $state.go('app.wiring-list.show', { id: tmp.id })
        })
        .catch(e => {
          console.log(e)
        })
    } else {
      // create
      Wiringlist.create(tmp)
        .$promise.then(data => {
          $state.go('app.wiring-list.show', { id: data.id })
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  $scope.deleteWiring = function deleteWiring (event) {
    event.stopPropagation()
    event.preventDefault()
    const confirm = $mdDialog
      .confirm()
      .title(
        $translate.instant('Wiring.REAL_DELETE') + ` ${$scope.wiringList.name}?`
      )
      .targetEvent()
      .clickOutsideToClose(true)
      .parent(angular.element(document.body))
      .ok($translate.instant('Wiring.YES'))
      .cancel($translate.instant('Wiring.CANCEL'))

    $mdDialog.show(confirm).then(
      function () {
        Wiringlist.destroyById({ id: $scope.wiringList.id })
          .$promise.then(data =>
            $state.go('app.wiring-list.list', {}, { reload: true })
          )
          .catch(e => console.log(e))
      },
      function () {}
    )
  }

  $scope.headerOptions = {
    icon: 'icon-cable-data',
    template: require('app/templates/headers/simple.html'),
    title: $scope.wiringList.id
      ? $translate.instant('Wiring.EDIT_WIRING_LIST')
      : $translate.instant('Wiring.NEW_WIRING_LIST'),
    fabButton: {}
  }
}

module.exports = WiringListNewEditController
