/* global $ */
/** @ngInject */
function goToTopDirective () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
    },
    template:
      `
             <div ng-show="scrollVisible"> 
                        <button class = "md-fab md-button md-primary-bg docs-scroll-fab goToTopDirective" 
                        type="button" ng-click="goToTop()"> 
                          <md-icon class="s36" md-font-icon="icon-chevron-up"></md-icon>
                       </button> 
                       </div>
      `,
    link: async function (scope, element, attrs) {
      scope.element = $(element).parent()
    },
    /** @ngInject */
    controller: (
      $scope,
      $timeout,
      $element
    ) => {
      $scope.scrollVisible = false
      $scope.contentHeight = 0
      $timeout(() => {
        $($scope.element).scroll(() => {
          if ($scope.contentHeight === 0) {
            for (let i = 0; i < $scope.element[0].children.length; i++) {
              $scope.contentHeight = $scope.contentHeight + $($scope.element[0].children[i]).innerHeight()
            }
            if ($scope.contentHeight > $($scope.element).height()) {
              $scope.scrollVisible = true
            }
          }
          if ($($scope.element).scrollTop() === 0) {
            $scope.contentHeight = 0
            $scope.scrollVisible = false
          }
        })
      }, 0)

      $scope.goToTop = () => {
        $($scope.element).animate({ scrollTop: 0 }, 1000)
      }
    }
  }
}

module.exports = goToTopDirective
