/** @ngInject */
function MdCollapsibleBody () {
  return {
    restrict: 'E',
    transclude: true,
    template: '<div layout="column" ng-transclude></div>'
  }
}

module.exports = MdCollapsibleBody
