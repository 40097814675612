'use strict'

/** @ngInject */
function inputTextAngular ($scope, $timeout) {
  $scope.assembly = $scope.model[$scope.options.key]
  if ($scope.assembly) {
    $scope.partNumber = $scope.model[$scope.options.key].number
  } else {
    $scope.partNumber = null
  }

  $scope.assemblySelected = assembly => {
    if (assembly) {
      $scope.model[$scope.options.key] = assembly
      $scope.model.partNumber = assembly.number
    }
  }
}

module.exports = inputTextAngular
