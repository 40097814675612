/* global angular */
/** @ngInject */

import 'root/src/vendors/leaflet/leaflet'
import 'root/src/../node_modules/leaflet-draw/dist/leaflet.draw'
import 'root/src/vendors/leaflet.dom-markers'

import { PartsListController } from './pages/parts-list/parts-list'
import { PartUnitsController } from './pages/part-units/part-units.controller'
import {
  PartsNewEditController,
  SelectSubpartDialogController
} from './pages/parts-new-edit/parts-new-edit'
import { PartViewController } from './pages/parts-view/part-view.controller'
import { PartViewDialogController } from './pages/parts-view/dialog/part-view.dialog.controller'
import { PartImageCacheController } from './pages/part-view-cache/part-view-cache'
import { PartsImportController } from './pages/parts-import/parts-import'
import { PartsBulkChangeController } from './pages/parts-bulk-change/parts-bulk-change'
import { StockManagementController } from './pages/stock-management/stock-management.controller'
import PartsConfig from './parts.config'
import PartViewHelper from './pages/parts-view/part-view-helper.service'
import PartPriorityPullDialog from './pages/parts-list/pull-part-dialog/part-priority-pull-dialog.controller'

const moduleName = 'app.parts'
const PARTS_MODULE = angular.module(moduleName, ['ngSanitize'])
PARTS_MODULE.config(PartsConfig)
  .controller('PartsListController', PartsListController)
  .controller('PartsNewEditController', PartsNewEditController)
  .controller('SelectSubpartDialogController', SelectSubpartDialogController)
  .controller('PartImageCacheController', PartImageCacheController)
  .controller('PartsImportController', PartsImportController)
  .controller('PartsBulkChangeController', PartsBulkChangeController)
  .controller('StockManagementController', StockManagementController)
  .controller('PartUnitsController', PartUnitsController)
  .controller('PartViewController', PartViewController)
  .controller('PartViewDialogController', PartViewDialogController)
  .controller('PartPriorityPullDialog', PartPriorityPullDialog)
  .service('PartViewHelper', PartViewHelper)

export default moduleName
