/* global _ angular */
'use strict'

/** @ngInject */
function alertDialog ($mdDialog, $rootScope, $location) {
  return {
    show: function (title, message, data, closeText) {
      const viewport = document.getElementById('smgViewport')
      $mdDialog
        .show({
          /** @ngInject */

          controller: function ($scope) {
            $scope.title = title
            console.log(message)
            $scope.message = message
            $scope.data = data
            $scope.closeText = closeText
            $scope.cancel = function () {
              try {
                $mdDialog.cancel()
              } catch (ex) {
                console.log(ex)
              }
            }
          },
          template: require('./alertDialog.html'),
          parent: angular.element(document.body),
          escapeToClose: true,
          clickOutsideToClose: true
        })
        .then(
          function () {
            if (!_.isNull(viewport)) {
              viewport.style.visibility = 'visible'
            }
          },
          function () {
            if (!_.isNull(viewport)) {
              viewport.style.visibility = 'visible'
            }
          }
        )
    }
  }
}

module.exports = alertDialog
