/** @ngInject */
function MdCollapsibleItem () {
  return {
    restrict: 'E',
    scope: {
      isFixed: '=?mdFixed',
      isOpen: '=?mdOpen'
    },
    link: function (scope, element) {
      if (scope.isFixed) {
        element.addClass('fixed')
      }
      if (scope.isOpen) {
        element.addClass('active')
      }
    }
  }
}

module.exports = MdCollapsibleItem
