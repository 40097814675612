/* global _ angular kendo */
/** @ngInject */
function PartsListController (
  $scope,
  $rootScope,
  $translate,
  $state,
  $stateParams,
  Page,
  PartAssembly,
  KendoGridHelper,
  ViewsService,
  getUrlFromObj,
  PermissionUtils,
  htmlWork,
  $mdDialog
) {
  $scope.getUrlFromObj = getUrlFromObj
  $scope.priorityEnabled =
    $rootScope?.appSettings?.prioritySettings?.enabled || false

  // Define autoCreateOptions
  const autoCreateOptions = [
    {
      value: 'ACTIVE',
      name: $translate.instant('PartAssembly.AUTO_CREATE.ACTIVE')
    },
    {
      value: 'NOT_ACTIVE',
      name: $translate.instant('PartAssembly.AUTO_CREATE.NOT_ACTIVE')
    },
    {
      value: 'REQUIRE_APPROVAL',
      name: $translate.instant('PartAssembly.AUTO_CREATE.REQUIRE_APPROVAL')
    }
  ]

  const initScreen = async function initScreen () {
    $scope.title = $rootScope.title = $translate.instant('PartAssembly.Parts')
    Page.setTitleText($scope.title)
    $scope.PermissionUtils = PermissionUtils
    const tableColumns = [
      // IMAGE
      {
        uniqueId: 'e325c4d4-33d5-4c0c-b0fd-8bd2044a1d53',
        field: 'picture_object',
        translateCode: 'PartAssembly.picture_object',
        type: 'object',
        filterable: false,
        sortable: false,
        width: '100px',
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          if (!data.picture_object) {
            return '<img height="45" src="assets/placeholder.png">'
          }
          const url = getUrlFromObj(data.picture_object)
          if (!url || url === '') return ''
          return `<img height="45" md-lightbox lazy-load="${htmlWork.escapeHTMLQuotes(
            url
          )}" class="part-list-item-image" src="">`
        }
      },
      // NAME
      {
        uniqueId: 'ea6f4fd8-c942-4eaa-8b49-8ee7608e86a1',
        field: 'name',
        translateCode: 'PartAssembly.name'
        // media: '(min-width: 768px)',
      },
      // NUMBER
      {
        uniqueId: '69991c34-1816-44a0-8855-dcb81817da97',
        field: 'number',
        translateCode: 'PartAssembly.number'
        // media: '(min-width: 768px)',
      },
      // NUMBER
      {
        uniqueId: 'ace21daf-85a1-4c98-9da6-131328156c17',
        field: 'currentRevision',
        translateCode: 'PartAssembly.REVISION',
        type: 'string'
        // media: '(min-width: 768px)',
      },
      // WORKORDER_NUMBERS
      {
        uniqueId: 'fda87ef7-1098-47bc-9be8-8ad7b4e637c9',
        field: 'workorderNumbers',
        translateCode: 'PartAssembly.WORKORDER_NUMBERS',
        type: 'array',
        // media: '(min-width: 768px)',
        template: data => {
          if (
            _.isNil(data.workorderNumbers) ||
            data.workorderNumbers.length === 0
          ) {
            return '--'
          }
          return data.workorderNumbers.join(', ')
        }
      },
      // SERIALS
      {
        uniqueId: '405ee56f-0a63-46ce-8b24-0ff3ee8b5a60',
        field: 'serials',
        translateCode: 'PartAssembly.SERIALS',
        type: 'array',
        // media: '(min-width: 768px)',
        template: data => {
          if (_.isNil(data.serials) || data.serials.length === 0) return '--'
          return data.serials.join(', ')
        }
      },
      // isSerial COLUMN
      {
        uniqueId: '2a67512c-c70e-47df-a421-dd6a61fb3928',
        field: 'isSerial',
        translateCode: 'PartAssembly.IS_SERIAL',
        type: 'boolean',
        filterable: true,
        sortable: true
      },
      // autoCreate COLUMN
      {
        uniqueId: 'e9d4c0bf-7f28-4eeb-8830-85abe4a267a4',
        field: 'autoCreate',
        translateCode: 'PartAssembly.AUTO_CREATION',
        type: 'string',
        values: autoCreateOptions.map(o => ({ text: o.name, value: o.value })),
        filterable: {
          mode: 'row',
          cell: {
            showOperators: false,
            operator: 'eq',
            suggestionOperator: 'eq',
            template: function (args) {
              args.element.kendoDropDownList({
                filter: 'contains',
                autoBind: false,
                dataTextField: 'name',
                dataValueField: 'value',
                dataSource: new kendo.data.DataSource({
                  data: autoCreateOptions
                }),
                valuePrimitive: true
              })
            }
          }
        },
        sortable: true,
        template: data => {
          const option = autoCreateOptions.find(
            opt => opt.value === data.autoCreate
          )
          return option ? option.name : '--'
        }
      },
      // ACTIONS
      {
        uniqueId: '876dbb79-d8aa-4fc2-8259-2567905a74d9',
        field: 'recordId',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        width: '100px',
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          const editLink = $state.href('app.parts.edit', {
            number: data.number
          })
          const stockLink = $state.href('app.parts.stock', {
            number: data.number
          })
          return `<div layout="row" layout-align="start center" style="direction: ${$rootScope.dir}" ng-show="PermissionUtils.isPermit('PartAssembly','create')">
                      <md-button class="md-icon-button" href="${editLink}">
                        <md-tooltip><span translate="PartAssembly.EDIT"></span></md-tooltip>
                        <md-icon md-font-icon="icon-pencil" class="s18"></md-icon>
                      </md-button>
                      <md-button class="md-icon-button" href="${stockLink}">
                        <md-tooltip><span translate="PartAssembly.STOCK_MANAGEMENT"></span></md-tooltip>
                        <md-icon md-font-icon="icon-package-variant" class="s18"></md-icon>
                      </md-button>
                    </div>`
        }
      }
    ]

    const basicFields = {
      id: true,
      recordId: true,
      name: true,
      number: true,
      description: true,
      picture: true,
      image: true,
      isSerial: true,
      autoCreate: true,
      created: true,
      currentRevision: true,
      workorderNumbers: true,
      serials: true,
      modified: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('PartAssembly', tableColumns)

    const baseFilter = {
      where: {},
      include: ['picture_object'],
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const { columns, selectedViewId } =
      ViewsService.getTablesColumns(defaultTableColumns)

    const { newBaseFilter, filters } = ViewsService.getViewCustomFilters(
      selectedViewId,
      _.cloneDeep(baseFilter)
    )

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      // model
      find: PartAssembly.find,
      count: PartAssembly.count,
      cleanBaseFilter: baseFilter,
      baseFilter: newBaseFilter,
      selectedViewId,
      columns: defaultTableColumns
    }

    const defaultTableToolbarSetup = {
      // stateName
      columns: defaultTableColumns,
      currentColumnIds: columns.map(c => c.uniqueId),
      filters,
      selectedViewId,
      title: $scope.title
    }

    $scope.downloadFunction = () => {}

    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      columns
    )

    await $scope.kendoGrid.isBound()

    $scope.tableToolbar = await ViewsService.GridToolBarInstance(
      defaultTableToolbarSetup,
      $scope.kendoGrid,
      $scope
    )

    $scope.$apply()
  }

  $scope.pullPart = function pullPart () {
    $mdDialog.show({
      controller: 'PartPriorityPullDialog',
      controllerAs: 'vm',
      template: require('./pull-part-dialog/part-priority-pull-dialog.html'),
      parent: angular.element(document.body),
      escapeToClose: true,
      clickOutsideToClose: true
    })
  }

  initScreen()

  const hasPullPartPermission = PermissionUtils.isPermit(
    'PartAssembly',
    'fetchPartFromPriority'
  )
  $scope.headerOptions = {
    icon: 'icon-codepen',
    template: require('app/templates/headers/simple.html'),
    title: $scope.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      icon: 'icon-plus',
      action: '',
      href: '',
      state: 'app.parts.new',
      showExpression: () =>
        $scope.PermissionUtils.isPermit('PartAssembly', 'create')
    },
    menuHideExpression: () => !$scope.priorityEnabled || !hasPullPartPermission,
    menu: [
      {
        content: `<md-button ng-click="pullPart()" class="md-default-theme md-ink-ripple" data-testid="pull-part">
                    <md-icon md-font-icon="icon-download"></md-icon>
                    <span translate="PartAssembly.PULL_PART"></span>
                  </md-button>`,
        hideExpression: () => !$scope.priorityEnabled || !hasPullPartPermission
      }
    ]
  }
}

module.exports = {
  PartsListController
}
