/* global angular */
import CatalogSelectAssemblyDialogController from './catalogSelectAssemblyDialogController'

/** @ngInject */
function selectAssemblyDirective () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      assembly: '=?',
      assemblyid: '=?',
      assemblySelected: '=?'
    },
    template: `
    <div style="max-width: 30rem;">
        <div>
          <md-card ng-if="assembly"  md-theme="{{ showDarkTheme ? 'dark-grey' : 'default' }}" md-theme-watch>
            <md-card-title style="padding: 5px;" layout="row"  layout-align="space-between center">
                <span flex></span>
                <md-button class="md-icon-button" ng-click="removeAssembly()">
                  <md-icon md-font-icon="icon-close"></md-icon>
                </md-button>
            </md-card-title>
            <md-card-content layout="column" style="padding-bottom: 5px;">
                <div style="text-align: center; padding-bottom: 10px;" class="md-media-lg card-media">
                  <img style="max-width: 200px;max-height: 150px;" ng-if="assembly.picture_object.ext === 'smg' || assembly.picture_object.ext === 'jpg' || assembly.picture_object.ext === 'png' || assembly.picture_object.ext === 'svg'"
                      ng-src="{{'api/Resources/'+assembly.picture_object.id+'/thumbnail/0'}}"
                  />
                </div>
                <div style="max-width: 200px;word-wrap: break-word;">
                  {{assembly.name}}
                </div>
              </md-card-content>
          </md-card>
        </div>
        <div ng-if="!assembly">
          <button ng-click="showSelectAssemblyDialog()"
              class="md-raised md-accent md-button md-default-theme md-ink-ripple">
            <span ng-if="!assembly" translate="CATALOG.SELECT"></span>
            <span ng-if="assembly"translate="CATALOG.CHANGE"></span>
          </button>
        </div>
      </div> `,
    link: async function (scope, element, attrs) {},
    /** @ngInject */
    controller: (
      $scope,
      $rootScope,
      $mdDialog,
      $translate,
      PermissionUtils,
      getUrlFromObj,
      $compile
    ) => {
      $scope.removeAssembly = () => {
        $scope.assembly = null
        $scope.assemblyid = null
        $scope.assemblySelected(null)
      }

      $scope.getUrlFromObj = getUrlFromObj
      $scope.showSelectAssemblyDialog = ev => {
        $mdDialog
          .show({
            controller: CatalogSelectAssemblyDialogController,
            controllerAs: 'vm',
            template: require('./catalogSelectAssemblyDialogTemplate.html'),
            parent: angular.element(document.body),
            targetEvent: ev,
            locals: {},
            clickOutsideToClose: true
          })
          .then(
            function (assembly) {
              if (assembly && assembly.recordId) {
                $scope.assembly = assembly
                $scope.assemblyid = assembly.recordId
                $scope.assemblySelected(assembly)
              }
            },
            function () {}
          )
      }
    }
  }
}

module.exports = selectAssemblyDirective
