/** @ngInject */

function DocumentImportController (
  $scope,
  $rootScope,
  $translate,
  $compile,
  $window,
  ResolvedContentPackage,
  ContentPackage
) {
  $scope.contentPackage = ResolvedContentPackage

  $scope.setNewUploadEnv = () => {
    $scope.selectedPage = 0
    $scope.tabStatus = [false, true]
    $scope.options = {
      minimumHeaderLevel:
        $window.localStorage.getItem('document-import-header-level') || 3,
      ignoreContentBeforeHeadings: true
    }
    $scope.selectedDocx = undefined
    $scope.selectedDocxObj = undefined
  }

  $scope.setNewUploadEnv()

  $scope.fileSelected = docx => {
    if (docx && docx[0]) {
      $scope.selectedDocx = docx[0]
      $scope.options.id = docx[0].id
    }
  }

  $scope.setHeaderInLocalStorage = value => {
    $window.localStorage.removeItem('document-import-header-level')
    $window.localStorage.setItem('document-import-header-level', value)
  }

  $scope.goToUploadPage = () => ($scope.selectedPage = 0)

  $scope.importDocx = () => {
    $scope.importWorking = true
    $scope.options.contentPackageId = $scope.contentPackage.id
    ContentPackage.parseDocx({ options: $scope.options })
      .$promise.then(data => {
        $scope.tabStatus[1] = false
        $scope.importWorking = false
        $scope.selectedPage = 1
        $scope.result = data.result
      })
      .catch(() => {
        $scope.importWorking = false
      })
  }

  $scope.tabClicked = () => {}

  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('CONTENTPACKAGE.IMPORT_FROM_WORD_OR_ZIP'),
    fabButton: {}
  }
}

module.exports = DocumentImportController
