/* global _ */
/** @ngInject */

function ContentPackageReorderController (
  $scope,
  $rootScope,
  PermissionUtils,
  $translate,
  $compile,
  $state,
  ResolvedContentPackage,
  ContentPackage,
  DocumentUtils
) {
  $scope.contentPackage = ResolvedContentPackage
  $scope.PermissionUtils = PermissionUtils

  $scope.treeOptions = {
    accept: function (sourceNodeScope, destNodesScope, destIndex) {
      return true
    },
    dropped: function (event) {},
    dragStart: event => {}
  }

  $scope.editDocument = (node, event) => {
    event.stopPropagation()
    event.preventDefault()
    event.cancelBubble = true

    if (node.notPublished) {
      $state.go('app.content-package.edit-document', {
        id: $state.params.id,
        documentId: node.recordId,
        contentType: 'last'
      })
    } else {
      $state.go('app.content-package.edit-document', {
        id: $state.params.id,
        documentId: node.recordId,
        contentType: 'released'
      })
    }
  }

  const prepareToSave = data =>
    data.map(x => {
      x = _.omit(x, ['$$hashKey'])
      x.id = x.recordId
      delete x.recordId
      delete x.search
      delete x.name
      x.nodes = x.nodes.length === 0 ? [] : prepareToSave(x.nodes)
      return x
    })

  $scope.saveOrder = () => {
    const documentStructure = prepareToSave($scope.data)
    ContentPackage.prototype$patchAttributes(
      { id: $scope.state.params.id },
      { documentStructure }
    )
      .$promise.then(() => {
        $scope.cancel()
      })
      .catch(e => console.log(e))
  }

  $scope.cancel = () => window.history.back()

  $scope.data = DocumentUtils.formatNodes(
    ResolvedContentPackage.documentStructure,
    ResolvedContentPackage.documents
  )

  $scope.headerOptions = {
    icon: 'icon-document',
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('CONTENTPACKAGE.REORDER'),
    fabButton: {}
  }
}

module.exports = ContentPackageReorderController
