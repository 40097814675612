/** @ngInject */
function MdCollapsible () {
  return {
    restrict: 'E',
    link: function (scope, element) {
      element.attr('layout', 'column')
      element.addClass('layout-column')
    }
  }
}

module.exports = MdCollapsible
