/* global */
require('./tree-menu.scss')
/** @ngInject */
function treeMenu () {
  return {
    restrict: 'E',
    template: require('./tree-menu.html'),
    replace: true,
    scope: {
      items: '=',
      selectedObject: '=',
      disabledObject: '=?',
      clickEvent: '&?',
      level: '=?'
    },
    controller: [
      '$rootScope',
      '$scope',
      ($rootScope, $scope) => {
        if (!$scope.level) {
          $scope.level = 1
        }
        $scope.nodesOpen = {}
        $scope.selectedItemId = null
        if (typeof $scope.clickEvent === 'function') {
          $scope.clickEvent = $scope.clickEvent()
        }
        $scope.toggleTree = function toggleTree (ev, item) {
          ev.stopPropagation()
          item.expanded = !item.expanded
        }
        $scope.selectItem = function selectItem (ev, item) {
          ev.stopPropagation()
          ev.preventDefault()
          if ($scope.disabledObject && $scope.disabledObject[item.id]) return
          if (typeof $scope.clickEvent === 'function') {
            $scope.clickEvent({ data: item })
          }
        }
      }
    ]
  }
}

module.exports = treeMenu
