/* global _ */

/** @ngInject */
function CatalogWorkflowListController (
  $scope,
  Page,
  $translate,
  $state,
  PermissionUtils,
  ResolvedCategoryList,
  Items
) {
  $scope.PermissionUtils = PermissionUtils
  $scope.page = {
    title: $translate.instant('CATALOG.CATALOG_WORKFLOW_LIST_TITLE'),
    icon: 'icon-tag-outline'
  }
  Page.setTitleText($scope.page.title)

  $scope.obj = {
    search: ''
  }

  $scope.allItems = Items
  $scope.filteredItems = _.cloneDeep($scope.allItems)
  $scope.inlineSearch = () => {
    const search = $scope.obj.search
    const items = $scope.filteredItems.filter(item => {
      if (item.name.toLowerCase().includes(search.toLowerCase())) {
        return true
      }
      const tag = _.some(
        item.tags,
        tag => tag.toLowerCase().indexOf(search.toLowerCase()) > -1
      )
      if (tag) return true

      return false
    })
    return items
  }
  $scope.goToItem = item => {
    if (item.type === 'Catalog') {
      $state.go('app.catalog.show', { id: item.id })
    } else {
      $state.go('app.workflow.session.show', {
        recordId: item.recordId,
        preview: false
      })
    }
  }

  $scope.selectedCategories = {}

  $scope.categoryFromCatalogs = _.cloneDeep(
    _.flatten(_.map(_.filter(Items, { type: 'Catalog' }), 'categories'))
  )
  $scope.categoryFromWorkflows = _.cloneDeep(
    _.flatten(_.map(_.filter(Items, { type: 'Workflow' }), 'categories'))
  )

  $scope.getItemCatagories = (allCategories, neededCategories) => {
    const allCategoryWithParents = []
    const getParents = categoryId => {
      allCategoryWithParents.push(categoryId)
      const category = _.find(allCategories, { id: categoryId })
      if (category && category.parentId) getParents(category.parentId)
    }
    neededCategories.map(categoryId => getParents(categoryId))
    return _.uniq(allCategoryWithParents).map(categoryId =>
      _.find(allCategories, { id: categoryId })
    )
  }

  $scope.filterCatalogCategoryList = _.compact(
    _.cloneDeep(
      $scope.getItemCatagories(
        ResolvedCategoryList,
        $scope.categoryFromCatalogs
      )
    )
  )
  $scope.filterWorkflowCategoryList = _.compact(
    _.cloneDeep(
      $scope.getItemCatagories(
        ResolvedCategoryList,
        $scope.categoryFromWorkflows
      )
    )
  )

  const refreshFilteredItems = () => {
    const isCategoriesSelected = _.some(
      _.values($scope.selectedCategories),
      x => x === true
    )
    if (isCategoriesSelected) {
      $scope.filteredItems = $scope.allItems.filter(item => {
        return _.some(
          item.categories,
          id => $scope.selectedCategories[`${item.type}-${id}`]
        )
      })
    } else {
      $scope.filteredItems = _.cloneDeep($scope.allItems)
    }
  }

  const buildHierarchy = (arr, type) => {
    const roots = []

    const nodes = {}

    // find the top level nodes and hash the nodes based on parent
    for (let i = 0, len = arr.length; i < len; ++i) {
      const item = arr[i]
      const p = item.parentId

      const target = !p ? roots : nodes[p] || (nodes[p] = [])
      item.type = type
      target.push(item)
    }

    // function to recursively build the tree
    const findChildren = function (parent) {
      if (nodes[parent.id]) {
        parent.nodes = nodes[parent.id]
        for (let i = 0, len = parent.nodes.length; i < len; ++i) {
          findChildren(parent.nodes[i])
        }
      }
    }

    // enumerate through to handle the case where there are multiple roots
    for (let i = 0, len = roots.length; i < len; ++i) {
      findChildren(roots[i])
    }

    return roots
  }

  const catalogRootCategory = [
    {
      id: '0',
      name: $translate.instant('CATALOG.CATALOGS'),
      parentId: null,
      type: 'Catalog',
      nodes: buildHierarchy($scope.filterCatalogCategoryList, 'Catalog').map(
        categoryElement => {
          categoryElement.parentId = '0'
          return categoryElement
        }
      )
    }
  ]

  const workflowRootCategory = [
    {
      id: '1',
      name: $translate.instant('WF.WORKFLOW'),
      parentId: null,
      type: 'Catalog',
      nodes: buildHierarchy($scope.filterWorkflowCategoryList, 'Workflow').map(
        categoryElement => {
          categoryElement.parentId = '1'
          return categoryElement
        }
      )
    }
  ]

  $scope.categoryCatalogData = catalogRootCategory.concat(workflowRootCategory)
  // $scope.categoryWorkflowData = workflowRootCategory

  $scope.visible = item => true

  $scope.toggle1 = (x, event) => {
    event.stopPropagation()
    x.toggle()
  }

  $scope.isCollapsed = node => !$scope.selectedCategories[node.id]

  const getAllChildIds = category => {
    const ids = []
    const getIds = cat => {
      if (cat.nodes && cat.nodes.length > 0) {
        cat.nodes.forEach(node => {
          ids.push(`${node.type}-${node.id}`)
          getIds(node)
        })
      }
    }
    getIds(category)
    return ids
  }

  $scope.isNodeSelected = node =>
    !!$scope.selectedCategories[`${node.type}-${node.id}`]

  $scope.selectNode = (node, $event) => {
    $event.stopPropagation()
    const value = !$scope.selectedCategories[`${node.type}-${node.id}`]
    $scope.selectedCategories = {}
    const ids = [`${node.type}-${node.id}`].concat(getAllChildIds(node))
    ids.map(childId => ($scope.selectedCategories[childId] = value))
    refreshFilteredItems()
  }

  $scope.headerOptions = {
    icon: 'icon-tag-outline',
    preTitleHtml: require('app/templates/headers/sidebar.html'),
    postTitleHtml: require('app/templates/headers/filter.html'),
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('CATALOG.CATALOG_WORKFLOW_LIST_TITLE'),
    fabButton: {}
  }
}

module.exports = CatalogWorkflowListController
