/* global _ */

/** @ngInject */
function grid () {
  require('./grid.scss')
  return {
    template: require('./grid.html'),
    bindings: {
      instance: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function grid ($scope) {
        this.$onChanges = async changeObj => {
          if (
            changeObj &&
            !_.isUndefined(changeObj.instance) &&
            changeObj.instance.currentValue
          ) {
            $scope.instance = changeObj.instance.currentValue
          }
        }

        this.$onInit = async function () {
          const that = this
          $scope.instance = that.instance
        }
      }
  }
}

module.exports = grid
