/** @ngInject */
function IconTextButton () {
  require('./icon-text-button.scss')
  return {
    template: require('./icon-text-button.html'),
    bindings: {
      text: '<',
      onClick: '<',
      icon: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function IconTextButton () {
        this.$onInit = function () {}
      }
  }
}

module.exports = IconTextButton
