/* global angular _ */
require('./timeline.scss')
/** @ngInject */
function timeline ($scope, $translate) {
  $scope.newButtonText = $scope.to.newButton || $translate.instant('ADD')
  $scope.repeatProperty = $scope.to.repeatProperty || 'items'

  $scope.copyFields = function copyFields (fields = [], headerFields = []) {
    const copyFields = angular.copy(fields)
    const copyHeaderFields = angular.copy(headerFields)
    return [
      {
        type: 'timelineItem',
        templateOptions: {
          fields: copyFields,
          headerFields: copyHeaderFields,
          showEdit: $scope.to.showItemEditHeader || false,
          editHeaderCB: $scope.to.itemEditHeaderCB,
          showDelete: $scope.to.showItemRemove || false,
          removeItemCB: $scope.to.itemRemoveCB,
          onChange: function (value, options) {}
        }
      }
    ]
  }

  $scope.addNew = function addNew () {
    if ($scope.to.onAdd) {
      $scope.to.onAdd()
    }
  }

  $scope.getItems = () =>
    _.get($scope.model[$scope.options.key], $scope.repeatProperty, [])
}

module.exports = timeline
