/* global */

/** @ngInject */
function gridWrapper () {
  require('./grid-wrapper.scss')
  return {
    transclude: true,
    template: require('./grid-wrapper.html'),
    bindings: {},
    controller:
      /**
       * @ngInject
       */
      function gridWrapper ($rootScope, $scope) {
        this.$onChanges = async changeObj => {}

        this.$onInit = async function () {}
      }
  }
}

module.exports = gridWrapper
