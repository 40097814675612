const Languages = {
  en: {
    title: 'English',
    translation: 'TOOLBAR.ENGLISH',
    code: 'en',
    flag: 'us'
  },
  ru: {
    title: 'Russian',
    translation: 'TOOLBAR.RUSSIAN',
    code: 'ru',
    flag: 'ru'
  },
  ch: {
    title: 'Switzerland',
    translation: 'TOOLBAR.SWITZERLAND',
    code: 'ch',
    flag: 'ch'
  },
  hi: {
    title: 'Hindi',
    translation: 'TOOLBAR.HINDI',
    code: 'hi',
    flag: 'in'
  },
  he: {
    title: 'Hebrew',
    translation: 'TOOLBAR.HEBREW',
    code: 'he',
    flag: 'il'
  },
  ko: {
    title: 'Korean',
    translation: 'TOOLBAR.KOREAN',
    code: 'ko',
    flag: 'kr'
  },
  es: {
    title: 'Spanish',
    translation: 'TOOLBAR.SPANISH',
    code: 'es',
    flag: 'es'
  },
  uz: {
    title: 'Uzbek',
    translation: 'TOOLBAR.UZBEK',
    code: 'uz',
    flag: 'uz'
  },
  de: {
    title: 'German',
    translation: 'TOOLBAR.GERMAN',
    code: 'de',
    flag: 'de'
  },
  fr: {
    title: 'French',
    translation: 'TOOLBAR.FRENCH',
    code: 'fr',
    flag: 'fr'
  },
  it: {
    title: 'Italian',
    translation: 'TOOLBAR.ITALIAN',
    code: 'it',
    flag: 'it'
  },
  zh: {
    title: 'Chinese (Simplified)',
    translation: 'TOOLBAR.CHINESE_SIMPLIFIED',
    code: 'zh',
    flag: 'cn'
  },
  ar: {
    title: 'Arabic',
    translation: 'TOOLBAR.ARABIC',
    code: 'ar',
    flag: 'sa'
  },
  pt: {
    title: 'Portuguese',
    translation: 'TOOLBAR.PORTUGUESE',
    code: 'pt',
    flag: 'pt'
  },
  ja: {
    title: 'Japanese',
    translation: 'TOOLBAR.JAPANESE',
    code: 'ja',
    flag: 'jp'
  },
  pl: {
    title: 'Polish',
    translation: 'TOOLBAR.POLISH',
    code: 'pl',
    flag: 'pl'
  },
  th: {
    title: 'Thai',
    translation: 'TOOLBAR.THAI',
    code: 'th',
    flag: 'th'
  }
}

module.exports = Languages
