/* global moment _ angular */
import { downloadXLSX } from 'app/helper.js'
import ReleaseLogsDialogController from '../../modules/main/workflow/session/templates/release-logs.dialog.controller'

/** @ngInject */
function VersionTable () {
  require('./versions-table.scss')
  return {
    template: require('./versions-table.html'),
    bindings: {
      versionsTitle: '=',
      versions: '<',
      previewFunction: '&?',
      publishFunction: '&?',
      editReleaseFunction: '&?',
      forkFunction: '&?',
      versionEdit: '&?',
      customization: '='
    },
    controller:
      /**
       * @ngInject
       */
      function VersionTable (
        $rootScope,
        $scope,
        $translate,
        $mdDialog,
        DateTimeFormatService
      ) {
        $scope.formatDate = function formatDate (date) {
          return DateTimeFormatService.formatDateTime(date, 'date')
        }

        $scope.showReleaseLog = async function showReleaseLog (version) {
          const newVersion = version
          newVersion.description = newVersion.description || ''
          newVersion.name = newVersion.name || ''
          newVersion.eco = newVersion.eco || ''
          newVersion.releasedByDisplayName = newVersion.releaseBy
          const releaseLogs = [newVersion]
          $mdDialog.show({
            controller: ReleaseLogsDialogController,
            template: require('../../modules/main/workflow/session/templates/release-logs.dialog.html'),
            parent: angular.element(document.body),
            locals: {
              workflow: version
            },
            resolve: {
              workflowReleaseLogs: function () {
                return new Promise(async (resolve, reject) => {
                  return resolve(releaseLogs)
                })
              }
            },
            multiple: true,
            fullscreen: true,
            escapeToClose: true,
            clickOutsideToClose: false
          })
        }

        $scope.downloadFunction = async function downloadFunction () {
          const that = this
          const exportColumns = [
            {
              label: $translate.instant('VERSIONS-TABLE.VERSION_ID'),
              value: 'version'
            },
            {
              label: $translate.instant('VERSIONS-TABLE.LOCKED'),
              value: 'lockId'
            },
            {
              label: $translate.instant('VERSIONS-TABLE.STATUS'),
              value: 'status'
            },
            {
              label: $translate.instant('VERSIONS-TABLE.ECO'),
              value: 'eco'
            },
            {
              label: $translate.instant('VERSIONS-TABLE.NAME'),
              value: 'name'
            },
            {
              label: $translate.instant('VERSIONS-TABLE.DESCRIPTION'),
              value: 'description'
            },
            {
              label: $translate.instant('VERSIONS-TABLE.CREATED'),
              value: 'created'
            },
            {
              label: $translate.instant('VERSIONS-TABLE.PUBLISHED'),
              value: 'releaseDate'
            },
            {
              label: $translate.instant('VERSIONS-TABLE.PUBLISHED_BY_TITLE'),
              value: 'releaseBy'
            }
          ]

          let data = _.cloneDeep(that.$ctrl.versions).map(version => {
            version.lockId = version.lockId ? 'True' : ''
            version.created = DateTimeFormatService.formatDateTime(
              version.created,
              'dateTime'
            )
            version.releaseDate = version.releaseDate
              ? DateTimeFormatService.formatDateTime(
                  version.releaseDate,
                  'dateTime'
                )
              : ''
            version.status = version.status
              ? $translate.instant(version.status)
              : ''

            return version
          })

          downloadXLSX(
            exportColumns,
            data,
            `${that.$ctrl.versionsTitle}-versions_export`
          )
        }
        this.$onChanges = function (changeObj) {
          if (
            changeObj &&
            !_.isUndefined(changeObj.versions) &&
            changeObj.versions.currentValue
          ) {
            $scope.liveVersion = changeObj.versions.currentValue.find(
              v => v.currentReleasedVersion
            )
          }
          this.versions = this.versions.map(version => {
            version.createdString = DateTimeFormatService.formatDateTime(
              version.created,
              'dateTime'
            )
            version.releaseDateString = version.releaseDate
              ? DateTimeFormatService.formatDateTime(
                  version.releaseDate,
                  'dateTime'
                )
              : ''
            return version
          })
        }
        this.$onInit = function () {
          console.log('Init versions table component')
          const that = this
          $scope.liveVersion = that.versions.find(v => v.currentReleasedVersion)
          $scope.previewFunction = that.previewFunction
            ? that.previewFunction()
            : null
          $scope.editReleaseFunction = that.editReleaseFunction
            ? that.editReleaseFunction()
            : null
          $scope.forkFunction = that.forkFunction ? that.forkFunction() : null
          $scope.publishFunction = that.publishFunction
            ? that.publishFunction()
            : null
          $scope.versionEdit = that.versionEdit ? that.versionEdit() : null
        }
      }
  }
}

module.exports = VersionTable
