/* global */
/** @ngInject */
function CatalogListController (
  $scope,
  $translate,
  $state,
  Page,
  ResolvedCatalogs,
  PermissionUtils,
  ResolvedCategoryList
) {
  const initScreen = function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.categories = ResolvedCategoryList
    const canEdit = PermissionUtils.isPermit('Catalog', 'patchAttributes')
    $scope.obj = {
      search: ''
    }
    $scope.selectedCategories = {}
    $scope.page = {
      title: $translate.instant('CATALOG.LIST_TITLE'),
      icon: 'icon-arrange-send-backward'
    }
    Page.setTitleText($scope.page.title)
    $scope.items = ResolvedCatalogs.map(catalog => {
      return {
        id: catalog.id,
        name: catalog.name,
        metadata: '',
        image: catalog.partNumber
          ? `/api/Resources/assemblyPicture/${encodeURIComponent(
              catalog.partNumber
            )}`
          : null,
        link: $state.href('app.catalog.show', {
          id: catalog.id
        }),
        editLink: canEdit
          ? $state.href('app.catalog.edit', {
              id: catalog.id
            })
          : null,
        categories: catalog.categories
      }
    })
  }

  initScreen()

  $scope.headerOptions = {
    icon: $scope.page.icon,
    preTitleHtml: require('app/templates/headers/sidebar.html'),
    postTitleHtml: require('app/templates/headers/filter.html'),
    template: require('app/templates/headers/simple.html'),
    title: $translate.instant('CATALOG.LIST_CATALOG(S)'),
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      icon: 'icon-plus',
      href: '#!/catalog/new',
      state: 'app.catalog.new',
      showExpression: () => $scope.PermissionUtils.isPermit('Catalog', 'create')
    }
  }
}

module.exports = CatalogListController
