/* global */
/** @ngInject */
function ContentPackageController (
  $scope,
  $translate,
  $state,
  ResolvedContentPackages,
  ResolvedCategoryList,
  PermissionUtils,
  Page
) {
  const canCreateDocument = PermissionUtils.isPermit('ContentPackage', 'create')

  const initScreen = function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.categories = ResolvedCategoryList
    const canEdit = PermissionUtils.isPermit(
      'ContentPackage',
      'patchAttributes'
    )
    $scope.obj = {
      search: ''
    }
    $scope.selectedCategories = {}
    $scope.page = {
      title: $translate.instant('CONTENTPACKAGE.CONTENTPACKAGE'),
      icon: 'icon-document'
    }
    Page.setTitleText($scope.page.title)
    $scope.items = ResolvedContentPackages.map(contentPackage => {
      return {
        id: contentPackage.id,
        name: contentPackage.name,
        metadata: '',
        image: contentPackage.image
          ? `./api/Resources/${contentPackage.image}/thumbnail/0`
          : null,
        link: $state.href('app.content-package.show', {
          id: contentPackage.id
        }),
        editLink: canEdit
          ? $state.href('app.content-package.edit', {
            id: contentPackage.id
          })
          : null,
        categories: contentPackage.categories
      }
    })
  }

  initScreen()

  $scope.headerOptions = {
    icon: $scope.page.icon,
    preTitleHtml: require('app/templates/headers/sidebar.html'),
    postTitleHtml: require('app/templates/headers/filter.html'),
    template: require('app/templates/headers/simple.html'),
    title: $scope.page.title,
    fabButton: {
      template: require('app/templates/headers/fab-button.html'),
      icon: 'icon-plus',
      state: 'app.content-package.new',
      showExpression: () => canCreateDocument
    }
  }
}

module.exports = ContentPackageController
