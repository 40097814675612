/* global _ */
/** @ngInject */
function CatalogSelectAssemblyDialogController (
  getUrlFromObj,
  $scope,
  PartAssembly,
  $mdDialog,
  KendoGridHelper,
  ViewsService,
  htmlWork
) {
  $scope.selectPart = function selectPart (recordId) {
    PartAssembly.find({
      filter: {
        where: { recordId: recordId },
        include: ['image_object', 'picture_object']
      }
    }).$promise.then(data => {
      $mdDialog.hide(data && data[0] ? data[0] : '')
    })
  }

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  const initScreen = async function initScreen () {
    const tableColumns = [
      // IMAGE
      {
        uniqueId: 'b05f97f8-06b1-4434-a63f-5d07b865dca8',
        field: 'picture_object',
        translateCode: 'PartAssembly.picture_object',
        type: 'object',
        filterable: false,
        sortable: false,
        // media: '(min-width: 768px)',
        trustedTemplate: data => {
          if (!data.picture_object) return ''
          const url = getUrlFromObj(data.picture_object)
          if (!url || url === '') return ''
          return `<img width="60" height="60" md-lightbox lazy-load="${htmlWork.escapeHTMLQuotes(
            url
          )}" class="part-list-item-image" src="">`
        }
      },
      // NAME
      {
        uniqueId: '9e69130d-e2c8-4fb8-a2d1-01dbb73e36a5',
        field: 'name',
        translateCode: 'PartAssembly.name'
        // media: '(min-width: 768px)',
      },
      // NUMBER
      {
        uniqueId: '7730b3c1-6b31-46af-9e93-03a0c599fc00',
        field: 'number',
        translateCode: 'PartAssembly.number'
        // media: '(min-width: 768px)',
      },
      // DESCRIPTION
      {
        uniqueId: 'd6984c95-2667-4612-8ace-a02fd2b47bad',
        field: 'description',
        translateCode: 'PartAssembly.description'
        // media: '(min-width: 768px)',
      },
      // ACTIONS
      {
        uniqueId: 'b9e26c0d-6640-4b49-bde9-79f16aae89a1',
        field: 'recordId',
        translateCode: 'COMMON.ACTIONS',
        headerAttributes: {
          class: 'actions-column-header'
        },
        // media: '(min-width: 768px)',
        filterable: false,
        sortable: false,
        trustedTemplate: data => {
          return `<div layout="row" layout-align="center center">
                      <md-button class="md-raised md-primary" ng-click="selectPart('${htmlWork.escapeHTMLQuotes(
                        data.recordId
                      )}')">
                        <span translate="CATALOG.SELECT"></span>
                      </md-button>
                    </div>`
        }
      }
    ]

    const basicFields = {
      id: true,
      recordId: true,
      name: true,
      number: true,
      description: true,
      picture: true,
      image: true
    }

    const { defaultTableColumns, customFieldsDisplay } =
      ViewsService.generateCustomFieldColumns('PartAssembly', tableColumns)

    const baseFilter = {
      where: { is_assembly: true },
      order: 'created DESC',
      include: ['picture_object'],
      fields: _.merge(basicFields, customFieldsDisplay)
    }

    const defaultTableSetup = {
      // stateName
      // ignoreParams
      find: PartAssembly.find,
      count: PartAssembly.count,
      baseFilter: baseFilter,
      selectedViewId: 'systemDefault',
      columns: defaultTableColumns
    }
    $scope.kendoGrid = await KendoGridHelper.GridInstance(
      defaultTableSetup,
      $scope,
      defaultTableColumns
    )
  }

  initScreen()
}

module.exports = CatalogSelectAssemblyDialogController
