/* global CodeMirror */
/** @ngInject */
function jsonEditorDirective () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      ngModel: '=',
      errorValue: '=error'
    },
    template: `
            <textarea style="border: 1px solid;" class="flex-100" flex="100"></textarea>
        `,
    link: async function (scope, element, attrs) {},
    /** @ngInject */
    controller: ($scope, $element, $window) => {
      import(/* webpackChunkName: "CodeMirror" */ 'app/chunks/CodeMirror').then(
        mod => {
          const editorJSON = CodeMirror.fromTextArea($element[0], {
            value: $scope.ngModel || '{}',
            lineNumbers: true,
            indentUnit: 2,
            autoRefresh: true,
            lineWrapping: true,
            lintOnChange: false,
            mode: 'application/json',
            gutters: ['CodeMirror-lint-markers'],
            lint: true
          })

          editorJSON.setSize('100%', 50)
          editorJSON.getDoc().setValue($scope.ngModel || '{}')
          editorJSON.refresh()

          try {
            $scope.errorValue = false
            $window.jsonlint.parse($scope.ngModel)
          } catch (e) {
            $scope.errorValue = true
          }

          editorJSON.on('change', function (instance) {
            const newValue = instance.getValue()
            try {
              $scope.errorValue = false
              JSON.parse(newValue)
            } catch (e) {
              $scope.errorValue = true
            }

            if (newValue !== $scope.ngModel) {
              $scope.ngModel = newValue
              $scope.$apply()
            }
          })
        }
      )
    }
  }
}

module.exports = jsonEditorDirective
