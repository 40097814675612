/* global angular */
/** @ngInject */

import WiringListNewEditController from './pages/wiring-list-new-edit/wiring-list-new-edit'
import WiringListController from './pages/wiring-list/wiring-list'
import WiringNewEditController from './pages/wiring-new-edit/wiring-new-edit'
import WiringShowController from './pages/wiring-show/wiring-show'
import WiringReorderController from './pages/wiring-reorder/wiring-reorder'
import lbServices from 'app/lb-services'
const MODULE_NAME = 'app.wiring'

angular
  .module(MODULE_NAME, ['ngSanitize', lbServices])
  .config(config)
  .controller('WiringListController', WiringListController)

/** @ngInject */
function config ($stateProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.wiring-list', {
      abstract: true,
      url: '/wiring-list'
    })
    .state('app.wiring-list.list', {
      url: '/show',
      views: {
        'content@app': {
          template: require('app/modules/components/tree-category-grid/tree-category-grid.html'),
          controller: WiringListController
        }
      },
      bodyClass: 'wiring wiring-list',
      resolve: {
        ResolvedWirings: function (Wiringlist) {
          return Wiringlist.find({ filter: { include: 'resource_object' } })
            .$promise
        },
        ResolvedCategoryList: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          )
      }
    })
    .state('app.wiring-list.new', {
      url: '/new/?:{initialValues:json}',
      params: {
        initialValues: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./pages/wiring-list-new-edit/wiring-list-new-edit.html'),
          controller: WiringListNewEditController
        }
      },
      bodyClass: 'wiring wiring-list-new',
      resolve: {
        ResolvedWiring: () => null,
        ResolvedCategories: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          ),
        ResolvedGroups: Group => Group.find({}).$promise
      }
    })
    .state('app.wiring-list.edit', {
      url: '/edit/:id',
      views: {
        'content@app': {
          template: require('./pages/wiring-list-new-edit/wiring-list-new-edit.html'),
          controller: WiringListNewEditController
        }
      },
      bodyClass: 'wiring wiring-list-edit',
      resolve: {
        ResolvedCategories: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          ),
        ResolvedGroups: Group => Group.find({}).$promise,
        ResolvedWiring: /** @ngInject */ function (Wiringlist, $stateParams) {
          return Wiringlist.findOne({
            filter: {
              where: { id: $stateParams.id },
              include: 'resource_object'
            }
          }).$promise
        }
      }
    })
    .state('app.wiring-list.new-wire', {
      url: '/show/:id/new-wire',
      views: {
        'content@app': {
          template: require('./pages/wiring-new-edit/wiring-new-edit.html'),
          controller: WiringNewEditController
        }
      },
      bodyClass: 'wiring wiring-new',
      resolve: {
        ResolvedWiring: () => null
      }
    })
    .state('app.wiring-list.edit-wire', {
      url: '/show/:id/edit-wire/:wireId',
      views: {
        'content@app': {
          template: require('./pages/wiring-new-edit/wiring-new-edit.html'),
          controller: WiringNewEditController
        }
      },
      bodyClass: 'wiring wiring-edit',
      resolve: {
        ResolvedWiring: function (Wire, $stateParams) {
          return Wire.findOne({
            filter: {
              where: { id: $stateParams.wireId },
              include: 'resource_object'
            }
          }).$promise
        }
      }
    })
    .state('app.wiring-list.show', {
      url: '/show/:id/?:wire',
      params: { wire: { dynamic: true } },
      views: {
        'content@app': {
          template: require('./pages/wiring-show/wiring-show.html'),
          controller: WiringShowController
        }
      },
      bodyClass: 'wiring wiring-show',
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.splitter" */ '@progress/kendo-ui/js/kendo.splitter'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      resolve: {
        ResolvedWiring: function (Wiringlist, $stateParams) {
          return Wiringlist.getWirings({
            id: $stateParams.id
          }).$promise
        }
      }
    })
    .state('app.wiring-list.reorder', {
      url: '/show/:id/reorder',
      views: {
        'content@app': {
          template: require('./pages/wiring-reorder/wiring-reorder.html'),
          controller: WiringReorderController
        }
      },
      bodyClass: 'content-package content-package-show',
      resolve: {
        ResolvedWiring: function (Wiringlist, $stateParams) {
          return Wiringlist.getWirings({
            id: $stateParams.id
          }).$promise
        }
      }
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.knowledgeBase.wiring', {
    title: 'Wiring.WIRING(S)',
    state: 'app.wiring-list.list',
    icon: 'icon-cable-data',
    weight: 40,
    acls: [{ model: 'Wiringlist', method: 'find' }]
  })
}

export default MODULE_NAME
