'use strict'
import { get } from 'lodash'

/** @ngInject */
function tinymce ($scope) {
  $scope.to.label = $scope.to.label ? $scope.to.label : null
  $scope.to.disabled = $scope.to.disabled ? $scope.to.disabled : false
  $scope.$watch(
    () => get($scope.model, $scope.options.key),
    (newValue, oldValue) => {
      if ($scope.to.onChange && newValue !== oldValue)
        $scope.to.onChange(newValue, $scope.options)
    }
  )
}

module.exports = tinymce
