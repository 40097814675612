/* global angular */
/** @ngInject */
const { getPdfViewerUrl } = require('app/helper.js')

function customPdf () {
  return {
    restrict: 'C',
    link: function (scope, element, attrs) {
      const attributes = element.prop('attributes')
      let url = attrs.taInsertVideo
      if (!attrs.taInsertVideo.startsWith('http')) {
        url = window.location.origin + attrs.taInsertVideo
      }
      const pdf = angular.element(
        `
        <object type="application/pdf" width="100%" height="100%">
        <iframe width="100%" height="100%" src="${getPdfViewerUrl(
          window.location.origin,
          url
        )}"></iframe>
      </object>
       
     `
      )

      angular.forEach(attributes, function (attr) {
        if (attr.name === 'ta-insert-video') {
          pdf.attr(attr.name, attrs.taInsertVideo)
        }
        pdf.attr(attr.name, attr.value)
      })
      pdf.attr('data', attrs.taInsertVideo)
      element.replaceWith(pdf)
    }
  }
}

module.exports = customPdf
