/** @ngInject */
function stockDetails () {
  require('./stock-details.scss')
  return {
    template: require('./stock-details.html'),
    bindings: {
      stock: '<',
      part: '<',
      mainStock: '<',
      mainStockPart: '<',
      loading: '<'
    },
    controller:
      /**
       * @ngInject
       */
      function StockDetails ($rootScope, $scope, PartAssembly, UploadService) {
        const vm = this

        $scope.validPatterns = ['.png', '.jpg', '.svg', '.gif', '.jpeg'].join()

        $scope.uploadImageForPart = async function uploadImageForPart (file) {
          $rootScope.loadingProgress = $scope.uploadImage = true
          try {
            const res = await UploadService.uploadFile({
              file,
              keepOriginal: true,
              folderId: null
            })
            if (res && Array.isArray(res.data) && res.data.length > 0) {
              const imageObject = res.data[0]
              await PartAssembly.uploadPartPicture({
                partNumber: vm.stock.sku,
                pictureId: imageObject.id
              }).$promise
              if (vm.part) vm.part.picture = imageObject.id
            }
          } catch (err) {
            console.error(err)
            $rootScope.showErrorToast('NP-8500')
          } finally {
            $rootScope.loadingProgress = $scope.uploadImage = false
          }
        }
        this.$onInit = function () {}
      }
  }
}

module.exports = stockDetails
