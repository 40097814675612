'use strict'
/* global $ window */

/** @ngInject */
function PartViewController ($scope, ResolvedPart) {
  $scope.resolvedPart = ResolvedPart
}

module.exports = {
  PartViewController
}
