/* global angular */

import contentPackageApp from './modules/content-package/content-package.module'
import searchResultsApp from './modules/search_results/search_results.module'

const MODULE_NAME = 'app.documentModule'

angular
  .module(MODULE_NAME, [contentPackageApp, searchResultsApp])
  .config(config)

/** @ngInject */
function config () {}

export default MODULE_NAME
