/** @ngInject */

function variable () {
  return {
    text: 'textVariable',
    number: 'numberVariable',
    date: 'dateVariable',
    boolean: 'booleanVariable',
    json: 'jsonVariable'
  }
}

module.exports = variable
