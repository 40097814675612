'use strict'
/* global */
require('./part-create.scss')
/** @ngInject */
function PartCreateDialogController ($scope, $mdDialog, ResolvedPart) {
  $scope.resolvedPart = ResolvedPart

  $scope.functionHelper = {
    afterSaveHandler: function (part) {
      $mdDialog.hide(part)
    },
    afterRevertHandler: function () {
      $mdDialog.hide(null)
    }
  }

  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }
}

module.exports = PartCreateDialogController
