/* global _ */
/** @ngInject */
function labeledSwitch ($scope) {
  const initValue = _.get($scope.model, $scope.options.key)
  if (_.isUndefined($scope.to.trueValue)) {
    $scope.to.trueValue = true
  } else {
    $scope.to.trueValue = `'${$scope.to.trueValue}'`
  }
  if (_.isUndefined($scope.to.falseValue)) {
    $scope.to.falseValue = false
  } else {
    $scope.to.falseValue = `'${$scope.to.falseValue}'`
  }

  $scope.onChange = function onChange () {}
  const value = _.get($scope.model, $scope.options.key)
  if (
    $scope.to.onChange &&
    typeof $scope.to.onChange === 'function' &&
    !_.isEqual(initValue, value)
  ) {
    $scope.to.onChange(value, $scope.options)
  }
}

module.exports = labeledSwitch
