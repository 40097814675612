/* global */
/** @ngInject */
function radioMaterialController (
  $scope,
  FileManagerService,
  AIService,
  $mdToast
) {
  $scope.loading = false

  $scope.openVisionAiDialog = async function openVisionAiDialog () {
    if ($scope.loading) {
      return
    }
    const base64 = await FileManagerService.openUploadMenu({}, true)
    if (!base64) {
      return
    }
    $scope.loading = true
    const settings = AIService.getPromptSettings('vision')
    const options = {
      max_tokens: settings.maxTokens,
      temperature: settings.temperature
    }
    const system = settings.plainPrompt.replace(
      '<AVAILABLE_OPTIONS>',
      `AVAILABLE_OPTIONS: ${$scope.to.options
        .map(option => option.name)
        .join(', ')}`
    )
    const completion = new AIService.Completion(
      [
        {
          role: 'system',
          content: [
            {
              type: 'text',
              text: system
            }
          ]
        },
        {
          role: 'user',
          content: [
            { type: 'text', text: $scope.to.aiPrompt },
            {
              type: 'image_url',
              image_url: {
                url: base64
              }
            }
          ]
        }
      ],
      options,
      handleAiResponse
    )
    completion.send().catch(error => {
      console.error(error)
      $scope.loading = false
    })
  }

  const handleAiResponse = (textDelta, end) => {
    if (textDelta) {
      if (!$scope.aiResponse) {
        $scope.aiResponse = textDelta
      } else {
        $scope.aiResponse += textDelta
      }
    }
    if (end) {
      const matchingOption = $scope.to.options.find(
        option =>
          option.name.toLowerCase() === $scope.aiResponse.toLowerCase().trim()
      )
      if (matchingOption) {
        $scope.model[$scope.options.key] = matchingOption.value
        if ($scope.to.onChange) {
          $scope.to.onChange(matchingOption.value, $scope.options, $scope.model)
        }
      } else {
        $mdToast.show(
          $mdToast
            .simple()
            .textContent('AI result does not match any available options')
            .position('top right')
            .hideDelay(3000)
        )
      }
      $scope.loading = false
      $scope.aiResponse = null
      $scope.$applyAsync()
    }
  }
}

module.exports = radioMaterialController
