/** @ngInject */
function searchResult () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      link: '=link',
      source: '=source',
      searchTitle: '=searchTitle',
      categories: '=categories',
      allCategories: '=allCategories',
      assembly: '=assembly',
      ext: '=ext',
      target: '=target',
      term: '=term',
      pathToDisplay: '=pathToDisplay',
      customTitleDetails: '=customTitleDetails'
    },
    template: `<div layout="column" layout-align="center start">
                <div class="search-header">
                  <a ng-if="!customTitleDetails" target="{{target}}" ng-href="{{ link }}" ng-disabled="$root.loadingProgress">
                      <h4 ng-if="searchTitle" style="display: inline-block">
                          <span>{{searchTitle}}</span>
                      </h4>
                      <div ng-if="assembly === false" style="display: inline-block" class="assembly-type">Part</div>
                      <div ng-if="assembly === true" style="display: inline-block" class="assembly-type">Assembly</div>
                      <div ng-if="ext" class="file-result-ext">[{{ext}}]</div>
                  </a>
                  <div ng-if="customTitleDetails" layout="column">
                    <a target="{{target}}" ng-href="{{ link }}" ng-disabled="$root.loadingProgress">
                      <h4 ng-if="customTitleDetails.title" style="display: inline-block">
                        <span>{{customTitleDetails.title}}</span>
                      </h4>
                    </a>
                    <h3 ng-if="customTitleDetails.subTitle1" style="display: inline-block; margin: 0px;">
                      <span>{{customTitleDetails.subTitle1}}</span>
                    </h3>
                    <h3 ng-if="customTitleDetails.subTitle2" style="display: inline-block; margin: 0px;">
                      <span>{{customTitleDetails.subTitle2}}</span>
                    </h3>
                    <h3 ng-if="customTitleDetails.subTitle3" style="display: inline-block; margin: 0px;">
                      <span>{{customTitleDetails.subTitle3}}</span>
                    </h3>
                  </div>
                  
                </div>
                <div class="search-path">
                  <div layout="row" layout-align="start center">
                    <md-icon md-font-icon="{{icon}}" style="margin-inline-end: 1rem;" class="s16"></md-icon>
                    <h6 ng-bind="pathToDisplay"></h6>
                  </div>
                </div>
                <div ng-if="categories" class="search-categories">
                  <ul ng-if="getCategoryPaths(categories || []).length > 2 && !showFull">
                    <li>{{getCategoryPaths(categories)[0]}}</li>
                    <li>{{getCategoryPaths(categories)[1]}}</li>
                    <li ng-click="showMore()" class="search-category-show-more" translate="SEARCH.SHOW_MORE"></li>
                  </ul>
                  <ul ng-if="getCategoryPaths(categories || []).length <=2 || showFull">
                    <li ng-repeat="path in getCategoryPaths(categories || []) track by $index">
                      {{ path }}
                    </li>
                    <li ng-if="showFull" ng-click="hideMore()" class="search-category-show-more" translate="SEARCH.HIDE_MORE"></li>
                  </ul>
                  <div class="clearfix"></div>
                </div>
            </div>`,
    /** @ngInject */
    controller: ($scope, $timeout, $element) => {
      $timeout(() => {
        const selectors = ['.search-header h4 span', '.search-categories li']
        selectors.forEach(selector => {
          const elm = $element[0].querySelector(selector)
          if (elm) {
            elm.innerHTML = elm.innerHTML.replace(
              new RegExp($scope.term, 'gi'),
              match => `<mark>${match}</mark>`
            )
          }
        })
      })
      $scope.showFull = false
      $scope.showMore = () => {
        $scope.showFull = true
      }
      $scope.hideMore = () => {
        $scope.showFull = false
      }
      switch ($scope.source) {
        case 2:
          $scope.icon = 'icon-file-document-multiple'
          break
        case 3:
          $scope.icon = 'icon-file-cog'
          break
        case 4:
          $scope.icon = 'icon-sitemap'
          break
        case 5:
          $scope.icon = 'icon-timeline-clock-outline'
          break
        case 6:
          $scope.icon = 'icon-file-document'
          break
        case 7:
          $scope.icon = 'icon-clipboard-text-play'
          break
        case 8:
          $scope.icon = 'icon-clipboard-list'
          break
      }
      const buildCategoryPath = function buildCategoryPath (categoryId) {
        const pathsArr = []
        const findParent = function findParent (id) {
          const category = $scope.allCategories.find(c => c.id === id)
          if (category) {
            pathsArr.push(category.name)
            if (category.parentId !== null) {
              findParent(category.parentId)
            }
          }
        }
        findParent(categoryId)
        return pathsArr.reverse().join(' / ')
      }

      $scope.getCategoryPaths = function getCategoryPaths (categoriesIds) {
        return categoriesIds.map(categoryId => buildCategoryPath(categoryId))
      }
    }
  }
}

module.exports = searchResult
