/* global */
require('./eula.dialog.scss')
/** @ngInject */
function EULADialogController ($scope, $rootScope, $mdDialog, UserModel) {
  $scope.accept = async function submit () {
    $rootScope.loadingProgress = true
    try {
      $rootScope.loadingProgress = true
      await UserModel.prototype$patchAttributes(
        { id: $rootScope.currentUser.id },
        { EULAv11: new Date() }
      ).$promise
      $rootScope.loadingProgress = false
      $mdDialog.hide()
    } catch (err) {
      $rootScope.loadingProgress = false
      $rootScope.showErrorToast(JSON.stringify(err))
    }
  }

  const initDialog = async function initDialog () {
    $scope.dialogData = {
      accept: false
    }
  }

  initDialog()
}
module.exports = EULADialogController
