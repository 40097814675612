/* global */
require('./duplicate.dialog.scss')
/** @ngInject */
function DuplicateDialogController ($scope, $mdDialog, $translate, locals) {
  $scope.cancel = function cancel () {
    $mdDialog.cancel()
  }

  $scope.clone = function clone () {
    $mdDialog.hide($scope.clonedName)
  }

  const initDialog = async function initDialog () {
    $scope.originalName = locals.name || ''
    $scope.clonedName = $scope.originalName
      ? `${$translate.instant('COMMON.CLONE')} - ${$scope.originalName}`
      : ''
  }

  initDialog()
}
module.exports = DuplicateDialogController
