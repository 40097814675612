/* global CodeMirror */
/** @ngInject */
function jsEditorDirective () {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      ngModel: '='
    },
    template: `
          <textarea style="border: 1px solid;" class="flex-100" flex="100"></textarea>
      `,
    link: async function (scope, element, attrs) {},
    /** @ngInject */
    controller: ($scope, $element) => {
      // let defaultConfig = {
      //   'env': {
      //     'node': true,
      //     'commonjs': true,
      //     'amd': true,
      //     'es6': true
      //   },
      //   'parserOptions': {
      //     'ecmaVersion': 9,
      //     'sourceType': 'script',
      //     'ecmaFeatures': {}
      //   },
      //   'rules': {
      //     'constructor-super': 2,
      //     'for-direction': 2,
      //     'getter-return': 2,
      //     'no-case-declarations': 2,
      //     'no-class-assign': 2,
      //     'no-compare-neg-zero': 2,
      //     'no-cond-assign': 2,
      //     'no-console': 2,
      //     'no-const-assign': 2,
      //     'no-constant-condition': 2,
      //     'no-control-regex': 2,
      //     'no-debugger': 2,
      //     'no-delete-var': 2,
      //     'no-dupe-args': 2,
      //     'no-dupe-class-members': 2,
      //     'no-dupe-keys': 2,
      //     'no-duplicate-case': 2,
      //     'no-empty-character-class': 2,
      //     'no-empty-pattern': 2,
      //     'no-empty': 2,
      //     'no-ex-assign': 2,
      //     'no-extra-boolean-cast': 2,
      //     'no-extra-semi': 2,
      //     'no-fallthrough': 2,
      //     'no-func-assign': 2,
      //     'no-global-assign': 2,
      //     'no-inner-declarations': 2,
      //     'no-invalid-regexp': 2,
      //     'no-irregular-whitespace': 2,
      //     'no-mixed-spaces-and-tabs': 2,
      //     'no-new-symbol': 2,
      //     'no-obj-calls': 2,
      //     'no-octal': 2,
      //     'no-redeclare': 2,
      //     'no-regex-spaces': 2,
      //     'no-self-assign': 2,
      //     'no-sparse-arrays': 2,
      //     'no-this-before-super': 2,
      //     'no-undef': 2,
      //     'no-unexpected-multiline': 2,
      //     'no-unreachable': 2,
      //     'no-unsafe-finally': 2,
      //     'no-unsafe-negation': 2,
      //     'no-unused-labels': 2,
      //     'no-unused-vars': 1,
      //     'no-useless-escape': 2,
      //     'require-yield': 2,
      //     'use-isnan': 2,
      //     'valid-typeof': 2
      //   }
      // }
      // const localESLint = new eslint() // eslint-disable-line
      // function validator(text) {
      //   var result = []
      //   var errors = localESLint.verify(text, defaultConfig)
      //   for (var i = 0; i < errors.length; i++) {
      //     var error = errors[i]
      //     result.push({
      //       message: error.message,
      //       severity: getSeverity(error),
      //       from: getPos(error, true),
      //       to: getPos(error, false)
      //     })
      //   }
      //   return result
      // }
      // CodeMirror.registerHelper('lint', 'javascript', validator)
      // function getPos(error, from) {
      //   var line = error.line - 1; var ch = from ? error.column : error.column + 1
      //   if (error.node && error.node.loc) {
      //     line = from ? error.node.loc.start.line - 1 : error.node.loc.end.line - 1
      //     ch = from ? error.node.loc.start.column : error.node.loc.end.column
      //   }
      //   return CodeMirror.Pos(line, ch)
      // }
      // function getSeverity(error) {
      //   switch (error.severity) {
      //     case 1:
      //       return 'warning'
      //     case 2:
      //       return 'error'
      //     default:
      //       return 'error'
      //   }
      // }
      import(/* webpackChunkName: "CodeMirror" */ 'app/chunks/CodeMirror').then(
        mod => {
          const editorJS = CodeMirror.fromTextArea($element[0], {
            value: $scope.ngModel || '',
            lineNumbers: true,
            indentUnit: 2,
            lineWrapping: true,
            lintOnChange: false,
            mode: 'javascript',
            gutters: ['CodeMirror-lint-markers'],
            lint: false
          })
          editorJS.on('change', function () {
            $scope.ngModel = editorJS.getValue()
          })
          editorJS.getDoc().setValue($scope.ngModel || '')
        }
      )
    }
  }
}

module.exports = jsEditorDirective
