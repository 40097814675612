/* global _ */
/** @ngInject */
function usersAvatar () {
  require('./users-avatar.scss')
  return {
    template: require('./users-avatar.html'),
    bindings: {
      users: '<',
      maxUsers: '<',
      maxWidth: '<',
      nameProperty: '=?',
      getUserDetails: '<?'
    },
    controller:
      /**
       * @ngInject
       */
      function UsersAvatar ($element, $scope, UserModel) {
        let styleElm
        this.$onDestroy = function () {
          styleElm.remove()
        }
        this.$onInit = async function () {
          $element[0].setAttribute('render-id', $scope.$id)
          $scope.maxWidth = this.maxWidth || '40'
          const css = `
          users-avatar user-avatar, users-avatar .more-users {
            margin-inline-start: ${(parseFloat($scope.maxWidth) / 3) * -1}px;
         }
          users-avatar user-avatar:hover, users-avatar .more-users:hover {
            margin-inline-start: 5px;
            margin-inline-end:  ${parseFloat($scope.maxWidth) / 2}px;;
         }
          users-avatar user-avatar.more-users, users-avatar .more-users.more-users {
            border-radius:  ${parseFloat($scope.maxWidth)}px;
            height:  ${parseFloat($scope.maxWidth)}px;
            width: ${parseFloat($scope.maxWidth)}px;
         
         }
          users-avatar user-avatar.more-users:hover, users-avatar .more-users.more-users:hover {
            margin-inline-start: ${(parseFloat($scope.maxWidth) / 3) * -1}px;
         }
       
          users-avatar user-avatar .img, users-avatar .more-users .img {
            background-size: auto  ${parseFloat($scope.maxWidth)}px;
         }`
          styleElm = document.createElement('style')
          styleElm.setAttribute('users-avatar-render-id', $scope.$id)
          styleElm.appendChild(document.createTextNode(css))

          document.getElementsByTagName('head')[0].appendChild(styleElm)
          $scope.name = 'name'
          if (this.nameProperty) {
            $scope.name = this.nameProperty
          }
          if (this.getUserDetails === true) {
            const users = await UserModel.find({
              filter: {
                where: {
                  id: { inq: this.users }
                },
                fields: { id: true, displayName: true }
              }
            }).$promise
            $scope.name = 'displayName'
            this.users = users
          }
          if (_.isNil(this.users)) this.users = []
          if (_.isNil(this.maxUsers)) this.maxUsers = 999999
          this.maxUsers = this.maxUsers--
          this.usersToDisplay = this.users.slice(0, this.maxUsers)
          this.moreUsersArray = this.users.slice(this.maxUsers)
          if (this.moreUsersArray.length === 1) {
            this.usersToDisplay = this.users
          } else {
            this.moreUsersArray = this.moreUsersArray.map(
              user => user[$scope.name]
            )
            this.moreUsersLength = this.moreUsersArray.length
            this.moreUsers = false
            if (this.moreUsersArray.length > 0) {
              this.moreUsers = this.moreUsersArray.join(', ')
            }
          }
        }
      }
  }
}

module.exports = usersAvatar
