/* global angular _ $ videojs */
/** @ngInject */

function customVideo () {
  return {
    restrict: 'C',
    link: function (scope, element, attrs) {
      import(/* webpackChunkName: "videojs" */ 'video.js/dist/video.min')
        .then(mod => {
          window.videojs = mod.default
          const video = angular.element('<video></video>')
          angular.forEach(
            [
              'taInsertVideo',
              'taOriginalVideo',
              'class',
              'customVideo',
              'poster',
              'width',
              'height'
            ],
            function (attr) {
              video.attr(attr, attrs[attr])
            }
          )
          if (typeof attrs.autoplay === 'undefined') {
            video.attr('autoplay', scope.autoplay)
          }
          video.attr('preload', 'metadata')
          video.attr('class', 'video-js')
          const userId = scope.userId

          let loop
          let volume
          let playbackRate
          let userCookie
          element.replaceWith(video)

          const getValuesFromCookies = () => {
            userCookie = scope.$cookies.get(`${userId}`)
            userCookie = userCookie ? JSON.parse(userCookie) : {}
            playbackRate =
              userCookie &&
              userCookie.videojs &&
              userCookie.videojs.playbackRate
                ? parseFloat(userCookie.videojs.playbackRate)
                : 1
            volume =
              userCookie && userCookie.videojs && userCookie.videojs.volume
                ? parseFloat(userCookie.videojs.volume)
                : 1
            loop =
              userCookie &&
              userCookie.videojs &&
              (userCookie.videojs.loop === 'true' ||
                userCookie.videojs.loop === true)
          }

          const setValuesFromCookies = () => {
            const expireDate = new Date()
            expireDate.setDate(expireDate.getDate() + 100)

            userCookie.videojs = {
              loop,
              volume,
              playbackRate
            }
            scope.$cookies.put(`${userId}`, JSON.stringify(userCookie), {
              expires: expireDate
            })
          }

          if (
            scope.state &&
            scope.state.$current.name === 'app.workflow.realwear-session-show'
          ) {
            const source = document.createElement('source')
            source.src = video.attr('taInsertVideo') + '#t=0.5'
            source.type = 'video/mp4'
            video[0].appendChild(source)
          } else {
            const src = video.attr('taInsertVideo')
            const ext = src.split('.').pop()
            const sources = [
              {
                src: src,
                type:
                  ext && ext === 'm3u8' ? 'application/x-mpegURL' : 'video/mp4'
              }
            ]
            if (ext !== 'mp4') {
              sources.push({
                src: video.attr('taOriginalVideo'),
                type: 'video/mp4'
              })
            }
            const videoJSObject = {
              preload: 'metadata',
              html5: {
                nativeAudioTracks: false,
                nativeVideoTracks: false
              },
              sources: sources,
              suppressNotSupportedError: true,
              controls: true,
              playbackRates: [0.5, 1, 1.5, 2]
            }
            if (attrs.width) {
              videoJSObject.width = attrs.width
            }
            if (attrs.height) {
              videoJSObject.height = attrs.height
            }

            if (!attrs.preview) {
              videoJSObject.autoplay = scope.autoplay
            }
            setTimeout(() => {
              getValuesFromCookies()
              const player = videojs(video[0], videoJSObject, function () {
                const lastVol = _.debounce(() => {
                  volume = player.volume()
                  setValuesFromCookies()
                }, 500)

                player.playbackRate(playbackRate)
                player.volume(volume)
                player.loop(loop)

                player.on('volumechange', function () {
                  lastVol()
                })

                player.on('ratechange', function () {
                  playbackRate = player.playbackRate()
                  setValuesFromCookies()
                })
              })
              const loopButton = player.controlBar.addChild('button')
              const loopButtonElm = loopButton.el()

              if (loop === 'true' || loop === true) {
                loopButtonElm.innerHTML =
                  '<span class="icon icon-replay state state-on" state="on"></span>'
              } else {
                loopButtonElm.innerHTML =
                  '<span class="icon icon-replay state " state="off"></span>'
              }

              loopButtonElm.onclick = function () {
                const state = $(this).find('span').attr('state')
                if (state === 'on') {
                  $(this).find('span').attr('state', 'off')
                  $(this).find('span').removeClass('state-on')
                  loop = false
                } else {
                  $(this).find('span').attr('state', 'on')
                  $(this).find('span').addClass('state-on')
                  loop = true
                }
                player.loop(loop)
                setValuesFromCookies()
              }
            }, 0)
          }
          // }
        })
        .catch(err => {
          throw new Error('Ooops, something went wrong, ' + err)
        })
    },
    /** @ngInject */
    controller: function ($scope, $cookies, $rootScope) {
      $scope.$cookies = $cookies
      $scope.autoplay = !!(
        $rootScope.appSettings && $rootScope.appSettings.videoAutoplay
      )
      $scope.userId = $rootScope.currentUser.id
    }
  }
}

module.exports = customVideo
