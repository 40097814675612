/* global */
/** @ngInject */
function ConditionsDialogController ($scope, $rootScope, $mdDialog, locals) {
  const ignoreFields = []
  $scope.fields = locals.fields.filter(
    field => !ignoreFields.includes(field.id)
  )
  $scope.categories = locals.categories
  $scope.model = locals.model
  $scope.save = conditions => {
    $mdDialog.hide($scope.model)
  }
  $scope.extras = {
    canSave: false,
    validOperators: locals.validOperators,
    columnCondition: locals.columnCondition || false,
    saveCallback: $scope.save,
    sourceNodeId: locals.sourceNodeId || null
  }
  $scope.cancel = () => $mdDialog.cancel()
}

module.exports = ConditionsDialogController
