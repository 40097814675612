'use strict'

/** @ngInject */
function inputTextAngular ($scope, $rootScope) {
  if (!$scope.to.taToolbar) {
    $scope.to.taToolbar = [
      [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'pre',
        'quote',
        'bold',
        'italics',
        'underline',
        'strikeThrough',
        'fontColor',
        'ul',
        'ol',
        'redo',
        'undo',
        'clear'
      ],
      [
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'html',
        'uploadImage',
        'linkToDocument',
        'insertLink',
        'insertIFrame',
        'insertStreamVideo',
        'wordcount',
        'charcount'
      ]
    ]
    if ($rootScope.appSettings.storylineEnabled === true) {
      $scope.to.taToolbar[1].push('insertStoryLine')
    }
  }
}

module.exports = inputTextAngular
