'use strict'
/* global angular, _ */
/** @ngInject */
function repeatingOptions ($scope) {
  $scope.formOptions = {
    formState: $scope.formState
  }
  $scope.index = 0
  $scope.copyFields = function copyFields (fields) {
    fields = angular.copy(fields)
    // addRandomIds(fields)
    fields = fields.map(field => {
      field.templateOptions.index = $scope.index
      return field
    })

    $scope.index++
    return fields
  }
  $scope.addNew = function addNew () {
    // const value = _.get($scope.model, $scope.options.key, [])
    // _.set($scope.model, $scope.options.key, value)
    // $scope.model[$scope.options.key] = $scope.model[$scope.options.key] || []
    const repeatsection = _.get($scope.model, $scope.options.key, [])
    const newsection = {
      id: Math.floor(Math.random() * (9999 - 0)) + ''
    }
    repeatsection.push(newsection)
    _.set($scope.model, $scope.options.key, repeatsection)
    $scope.options.templateOptions.onClick(newsection.id)
  }
  $scope.removeField = function removeField (index) {
    // const optionsArray = _.get($scope.model, $scope.options.key, [])
    // optionsArray.splice(index, 1)
    $scope.index--
    $scope.options.templateOptions.onRemove(index)
  }
  $scope.onMoveUp = function onMoveUp (index) {
    const optionsArray = _.get($scope.model, $scope.options.key, [])
    const toIndex = index - 1
    swapNames(optionsArray, index, toIndex)
    $scope.options.templateOptions.onMoveUp(index)
  }
  $scope.onMoveDown = function onMoveDown (index) {
    const optionsArray = _.get($scope.model, $scope.options.key, [])
    const toIndex = index + 1
    swapNames(optionsArray, index, toIndex)
    $scope.options.templateOptions.onMoveDown(index)
  }
  const swapNames = function swapNames (array, fromIndex, toIndex) {
    const tempName = array[fromIndex].name
    array[fromIndex].name = array[toIndex].name
    array[toIndex].name = tempName
  }
  // const addRandomIds = function addRandomIds (fields) {
  //   angular.forEach(fields, function (field, index) {
  //     if (field.fieldGroup) {
  //       addRandomIds(field.fieldGroup)
  //       return
  //     }
  //     if (field.templateOptions && field.templateOptions.fields) {
  //       addRandomIds(field.templateOptions.fields)
  //     }
  //     const optionsArray = _.get($scope.model, $scope.options.key, [])
  //     const generateId =
  //       optionsArray[$scope.index].id ||
  //       Math.floor(Math.random() * (999 - 0)) + ''
  //     field.id = field.id || field.key + '_' + generateId
  //     $scope.index++
  //   })
  // }
  $scope.getOptions = function getOptions () {
    return _.get($scope.model, $scope.options.key, [])
  }
}

module.exports = repeatingOptions
