/* global angular $ */
/** @ngInject */

function customIframe () {
  return {
    restrict: 'C',
    link: function (scope, element, attrs) {
      const iframe = angular.element('<iframe></iframe>')
      const attributes = element.prop('attributes')
      angular.forEach(attributes, function (attr) {
        iframe.attr(attr.name, attr.value)
      })
      iframe.attr('src', iframe.attr('ta-insert-video'))
      const id = scope.$id
      iframe.attr('id', id)
      const parent = $(element).parent()

      element.replaceWith(iframe)

      document.getElementById(id).onload = function () {
        try {
          const frameHeight = document.getElementById(id).contentWindow.document
            .clientHeight
          const frameWidth = document.getElementById(id).contentWindow.document
            .clientWidth

          // let parentHeight = $(parent).height()
          const parentWidth = $(parent).width()

          const newFrameWidth = parentWidth - 5
          const newFrameHeight = (frameHeight * newFrameWidth) / frameWidth - 5

          $('#' + id).width(newFrameWidth)
          $('#' + id).height(newFrameHeight)
        } catch (err) {
          $('#' + id).css('min-height', '50vh')
        }
      }
    }
  }
}

module.exports = customIframe
