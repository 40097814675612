/** @ngInject */
function CatalogController ($scope, $rootScope, $translate, $compile, $state, ResolvedCatalogs) {
  var vm = this
  vm.page = {}
  
  vm.contentPackages = ResolvedCatalogs
  vm.page.title = $translate.instant('Catalog.catalogs')

}

module.exports = CatalogController
