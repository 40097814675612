/* global  $ _ */
import svgPanZoom from 'root/src/vendors/svg-pan-zoom.min'
import svgselection from 'root/src/vendors/svgselection'

/** @ngInject */
function WiringMainController (
  $scope,
  $rootScope,
  $timeout,
  $window,
  $http,
  $state,
  $translate,
  $interval,
  $stateParams,
  ResolvedWiring,
  PermissionUtils,
  getUrlFromObj,
  DialogService,
  WireUtils,
  Wiringlist,
  EventService
) {
  const TIME_TO_REMOVE_ACTIVE = 1000
  const TIME_TO_CHECK_FOR_DELETE = 1000

  /**
   * Resize handler - fit SVG to middle splitter size
   */
  const onResizeHandler = function onResizeHandler () {
    $timeout(() => {
      $scope.loadSVG($scope.lastUrl)
      $scope.currentGroup = null
    }, 500)
  }

  /**
   * Get user's splitter size
   * @returns {array} splitter sizes
   */
  const getPanesOptions = function getPanesOptions () {
    const panes = $window.localStorage.getItem('wiring-panes')
    if (panes) return JSON.parse(panes)
    return [
      { size: '20%', collapsible: true },
      { min: '30%' },
      { min: '10%', size: '20%', collapsible: true }
    ]
  }

  const clearAdditionalLines = function clearAdditionalLines (force) {
    const currentTime = new Date()
    const objToDelete = force
      ? $scope.selectedObjects
      : $scope.selectedObjects.filter(
          object => currentTime - object.time > TIME_TO_REMOVE_ACTIVE
        )
    objToDelete.forEach(object => {
      const index = _.findIndex($scope.selectedObjects, { id: object.id })
      $('#' + object.id).removeClass('nearby')
      $scope.selectedObjects.splice(index, 1)
    })
  }

  const findObjects = function findObjects (
    obj,
    targetProp,
    targetValue,
    finalResults
  ) {
    const getObject = function getObject (theObject) {
      if (theObject instanceof Array) {
        for (let i = 0; i < theObject.length; i++) {
          getObject(theObject[i])
        }
      } else {
        for (const prop in theObject) {
          // eslint-disable-next-line
          if (theObject.hasOwnProperty(prop)) {
            if (prop === targetProp) {
              if (theObject[prop] === targetValue) {
                finalResults.push(theObject)
              }
            }
            if (
              theObject[prop] instanceof Object ||
              theObject[prop] instanceof Array
            ) {
              getObject(theObject[prop])
            }
          }
        }
      }
    }

    getObject(obj)
  }

  const loadWire = function loadWire (id) {
    if (WireUtils.getWire(id, ResolvedWiring.wiresData)) {
      $scope.currentWire = WireUtils.getWire(id, ResolvedWiring.wiresData)
      const finalResults = []
      findObjects($scope.data[0], 'id', id, finalResults)
      $scope.currentWire.rootId = finalResults[0].rootId
      $state.go(
        $state.current,
        _.merge($state.params, { wire: $scope.currentWire.id }),
        {
          inherit: false,
          reload: false,
          notify: false
        }
      )
      const url = getUrlFromObj($scope.currentWire.resource_object)
      $scope.loadSVG(url)
    }
  }

  const findGroupByItemIds = function findGroupByItemIds (itemIds) {
    let group = null
    $scope.currentWire.wires.forEach(x => {
      if (_.intersection(x.ids, itemIds).length > 0) group = x
    })
    return group
  }

  const selectByIds = function selectByIds (ids) {
    $('.selected').removeClass('selected')
    ids.forEach(id => {
      $(`*[nextplus="${id}"]`).addClass('selected')
    })
  }

  $scope.toggleNode = function toggleNode (node) {
    node.toggle()
  }

  $scope.rootIdInPath = function rootIdInPath (rootId) {
    if (!$scope.currentWire || !$scope.currentWire.rootId) return false
    if ($scope.currentWire.rootId === rootId) return false
    return $scope.currentWire.rootId.includes(rootId)
  }

  $scope.visible = function visible (item) {
    return !(
      $scope.search.query &&
      $scope.search.query.length > 0 &&
      item.search.toLowerCase().indexOf($scope.search.query.toLowerCase()) ===
        -1
    )
  }

  $scope.selectGroup = function selectGroup (group) {
    selectByIds(group.ids)
    $scope.currentGroup = group
  }

  $scope.nodeClick = function nodeClick (node, event) {
    event.stopPropagation()
    loadWire(node.id)
  }

  $scope.collapseAll = function collapseAll () {
    $scope.$broadcast('angular-ui-tree:collapse-all')
  }

  $scope.expandAll = function expandAll () {
    $scope.$broadcast('angular-ui-tree:expand-all')
  }

  $scope.loadSVG = async function loadSVG (url) {
    if (_.isNil(url)) return
    $scope.selectedObjects = []
    $scope.lastUrl = url
    const svg = await $http.get(url)
    document.getElementById('svgfile').innerHTML = $window.DOMPurify.sanitize(
      svg.data,
      { USE_PROFILES: { svg: true } }
    )
    const svgElm = document.querySelector('#svgfile > svg')
    const canvasHeight = $('#middle').height() - 100 - 64 - 80
    const canvasWidth = $('.wiring-schema-container').width()
    $(svgElm).attr('id', 'main-svg')
    $(svgElm).css('height', canvasHeight + 'px')
    $(svgElm).css('width', canvasWidth + 'px')
    $('marker').attr('markerUnits', 'userSpaceOnUse')
    if (!$scope.intervalClear) {
      $scope.intervalClear = $interval(
        clearAdditionalLines,
        TIME_TO_CHECK_FOR_DELETE
      )
    }
    const element = document.getElementById('main-svg')
    // eslint-disable-next-line new-cap
    const svgSelectionInstance = new svgselection(element)
    svgSelectionInstance.init()
    let flag = 0
    svgElm.addEventListener(
      'mousedown',
      function () {
        flag = 0
      },
      false
    )
    svgElm.addEventListener(
      'mousemove',
      function () {
        flag = 1
      },
      false
    )
    svgElm.addEventListener(
      'mouseup',
      async e => {
        if (flag === 0) {
          const manipulatedEvent = {
            target: document.querySelector('#main-svg .svg-pan-zoom_viewport'),
            clientX: e.clientX,
            clientY: e.clientY
          }
          const result = await svgSelectionInstance.find(manipulatedEvent)
          if (result !== null) {
            const id = $(result.element).attr('nextplus')
            const group = findGroupByItemIds([id])
            if (group) {
              $scope.selectGroup(group)
              clearAdditionalLines(true)
            }
          }
        } else if (flag === 1) {
          console.log('drag')
        }
      },
      false
    )
    $scope.zoomInstance = svgPanZoom(svgElm, {
      zoomEnabled: true,
      controlIconsEnabled: true
    })
    $('#svg-pan-zoom-controls').hide()
  }

  $scope.removeWiring = async function removeWiring (wireId) {
    DialogService.deleteDialog(
      $translate.instant(
        'SETTINGS.REAL_DELETE',
        {},
        null,
        null,
        'sceParameters'
      )
    ).then(
      function () {
        Wiringlist.deleteWiring({
          wireListId: ResolvedWiring.id,
          wireId
        })
          .$promise.then(() => {
            $window.location.reload()
          })
          .catch(err => {
            console.error(err)
            $rootScope.showErrorToast('NP-5030')
          })
      },
      function () {}
    )
  }

  const initScreen = function initScreen () {
    $scope.PermissionUtils = PermissionUtils
    $scope.wiringList = ResolvedWiring
    $scope.search = { query: '' }
    $scope.currentWire = {}

    EventService.saveEvent({
      type: 'view',
      model: 'Wiring',
      modelId: ResolvedWiring.id
    })

    $window.addEventListener('resize', onResizeHandler)

    $scope.data = [
      WireUtils.makeIdTree({
        id: ResolvedWiring.id,
        name: ResolvedWiring.name,
        search:
          ResolvedWiring.name +
          ' ' +
          WireUtils.getValueForSearch(
            ResolvedWiring.wiresStructure,
            ResolvedWiring.wiresData
          ),
        nodes: WireUtils.formatNodes(
          ResolvedWiring.wiresStructure,
          ResolvedWiring.wiresData
        )
      })
    ]
    if (!_.isNil($stateParams) && !_.isNil($stateParams.wire)) {
      loadWire($stateParams.wire)
    }
  }

  initScreen()

  $(document).ready(() => {
    const initSplitter = function initSplitter () {
      const panes = getPanesOptions()
      if ($rootScope.dir === 'rtl') {
        panes.reverse() // reverse the array
      }
      $('#splitter-wiring').kendoSplitter({
        panes: panes,
        layoutChange: function (e) {
          const {
            sender: {
              options: { panes }
            }
          } = e
          if (panes) {
            $window.localStorage.removeItem('wiring-panes')
            $window.localStorage.setItem('wiring-panes', JSON.stringify(panes))
          }
          onResizeHandler()
        }
      })
    }
    if ($rootScope.dir === 'rtl') {
      $('#left-side').before($('#middle')) // swap panes
      $('#middle').before($('#right-side'))
    }
    initSplitter()

    $(window).on(
      'resize.doResize',
      _.debounce(function () {
        const splitter = $('#splitter-wiring').data('kendoSplitter')
        if (splitter) {
          splitter.destroy()
          $('.k-splitbar').remove()
        }
        if ($rootScope.dir === 'rtl') {
          $('#left-side').before($('#middle')) // swap panes
          $('#middle').before($('#right-side'))
        }
        initSplitter()
      }, 500)
    )
  })

  $scope.$on('$destroy', function () {
    if ($scope.intervalClear) {
      $interval.cancel($scope.intervalClear)
    }
    $window.removeEventListener('resize', onResizeHandler)
  })

  const postTitleHtml = `<div class="toolbar wiring-show-toolbar" layout="row" layout-align="space-between">
                            <div class="right-side" layout="row">
                              <md-button  ng-if="::PermissionUtils.isPermit('Wire','create')" ui-sref="app.wiring-list.new-wire({id: wiringList.id})" class="md-icon-button">
                                <md-icon md-font-icon="icon-plus"></md-icon>
                              </md-button>
                              <md-button  ng-if="::PermissionUtils.isPermit('Wire','patchAttributes')" ui-sref="app.wiring-list.edit-wire({id: wiringList.id,wireId:currentWire.id})"
                                          ng-show="currentWire.id" class="md-icon-button">
                                <md-icon md-font-icon="icon-pencil"></md-icon>
                              </md-button>
                              <md-button  ng-if="::PermissionUtils.isPermit('Wiringlist','patchAttributes')" ui-sref="app.wiring-list.reorder({id: wiringList.id})"
                                          ng-show="currentWire.id" class="md-icon-button">
                                <md-icon md-font-icon="icon-sort"></md-icon>
                              </md-button>
                              <md-button  ng-if="::PermissionUtils.isPermit('Wiringlist','deleteWiring')" ng-click="removeWiring(currentWire.id)"
                                          ng-show="currentWire.id" class="md-icon-button">
                                <md-icon md-font-icon="icon-delete" class="md-warn"></md-icon>
                              </md-button>
                            </div>
                          </div>`

  $scope.headerOptions = {
    icon: 'icon-cable-data',
    postTitleHtml: postTitleHtml,
    template: require('app/templates/headers/simple.html'),
    title: $scope.wiringList.name,
    fabButton: {}
  }
}

module.exports = WiringMainController
