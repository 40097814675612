/* global angular */

import DocumentNewEditController from './pages/document-new-edit/document-new-edit'
import ContentPackageReorderController from './pages/document-reorder/document-reorder'
import ContentPackageController from './pages/package-list/package-list'
import NewEditPackageController from './pages/package-new-edit/package-new-edit'
import DocumentImportController from './pages/document-import/document-import'
import 'app/constants/file-types'

const templates = {
  TreeBased: {
    template: require('./pages/package-show/package-default-show.html'),
    controller: require('./pages/package-show/package-default-show')
  },
  noSidebar: {
    template: require('./pages/package-show/package-nosidebar-show.html'),
    controller: require('./pages/package-show/package-default-show')
  },
  Feed: {
    template: require('./pages/package-show/package-feed-show.html'),
    controller: require('./pages/package-show/package-default-show')
  },
  Timeline: {
    template: require('./pages/package-show/package-timeline-show.html'),
    controller: require('./pages/package-show/package-default-show')
  }
}

const MODULE_NAME = 'app.content-package'

angular
  .module(MODULE_NAME, [
    // 'ngSanitize',
    'ui.tree'
  ])
  .config(config)
  .controller('DocumentNewEditController', DocumentNewEditController)
  .controller('DocumentImportController', DocumentImportController)
  .controller(
    'ContentPackageReorderController',
    ContentPackageReorderController
  )
  .controller('ContentPackageController', ContentPackageController)
  .controller('NewEditPackageController', NewEditPackageController)
  .directive('navigation', [
    '$compile',
    function ($compile) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          menu: '='
        },
        template:
          '<ul><li ng-repeat="item in menu">{{item.name}}<span ng-if="item.children.length > 0"><navigation menu="item.children"></navigation></span></li></ul>',
        compile: function (el) {
          const contents = el.contents().remove()
          let compiled
          return function (scope, el) {
            if (!compiled) compiled = $compile(contents)

            compiled(scope, function (clone) {
              el.append(clone)
            })
          }
        }
      }
    }
  ])

/** @ngInject */
function config ($stateProvider, msApiProvider, msNavigationServiceProvider) {
  // State
  $stateProvider
    .state('app.content-package', {
      abstract: true,
      url: '/content-package'
    })
    .state('app.content-package.new', {
      url: '/new/?:{initialValues:json}',
      params: {
        initialValues: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./pages/package-new-edit/package-new-edit.html'),
          controller: 'NewEditPackageController'
        }
      },
      bodyClass: 'content-package content-package-new',
      resolve: {
        ResolvedContentPackage: () => null,
        ResolvedGroups: Group => Group.find({}).$promise,
        ResolvedCategories: Category => Category.find({}).$promise
      }
    })
    .state('app.content-package.edit', {
      url: '/edit/:id',
      views: {
        'content@app': {
          template: require('./pages/package-new-edit/package-new-edit.html'),
          controller: 'NewEditPackageController'
        }
      },
      bodyClass: 'content-package content-package-new',
      resolve: {
        ResolvedContentPackage: function (ContentPackage, $stateParams) {
          return ContentPackage.findOne({
            filter: {
              where: { id: $stateParams.id },
              include: ['documents', 'image_object']
            }
          }).$promise
        },
        ResolvedGroups: Group => Group.find({}).$promise,
        ResolvedCategories: Category => Category.find({}).$promise
      }
    })
    .state('app.content-package.list', {
      url: '/list/?:selectedCategories',
      views: {
        'content@app': {
          template: require('app/modules/components/tree-category-grid/tree-category-grid.html'),
          controller: 'ContentPackageController as vm'
        }
      },
      bodyClass: 'content-package content-package-list',
      resolve: {
        ResolvedContentPackages: function (
          ContentPackage,
          $rootScope,
          MultiTranslateService
        ) {
          return ContentPackage.find({
            filter: {
              fields: {
                image: true,
                id: true,
                categories: true,
                name: true,
                tags: true,
                translations: true,
                UserId: true
              }
            }
          }).$promise.then(res =>
            res.map(contentPackage =>
              MultiTranslateService.getForView(
                ContentPackage,
                $rootScope.currentLang,
                contentPackage
              )
            )
          )
        },
        ResolvedCategoryList: ($rootScope, Category, MultiTranslateService) =>
          Category.find({}).$promise.then(categories =>
            categories.map(category =>
              MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            )
          )
      }
    })
    .state('app.content-package.show', {
      url: '/show/:contentType/:id?:recordId',
      params: {
        contentType: 'released',
        recordId: {
          dynamic: true
        }
      },
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.splitter" */ '@progress/kendo-ui/js/kendo.splitter.min'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      views: {
        'content@app': {
          templateProvider: function ($rootScope, $stateParams) {
            let currentTemplate =
              !$rootScope.contentPackagTemplate ||
              $rootScope.contentPackagTemplate === 'Default'
                ? $rootScope.appSettings.contentPackageDefaultTemplate ||
                  'TreeBased'
                : $rootScope.contentPackagTemplate
            if (currentTemplate === 'Default') currentTemplate = 'TreeBased'
            return templates[currentTemplate].template
          },
          controllerProvider: function ($stateParams, $rootScope) {
            let currentTemplate =
              !$rootScope.contentPackagTemplate ||
              $rootScope.contentPackagTemplate === 'Default'
                ? $rootScope.appSettings.contentPackageDefaultTemplate ||
                  'TreeBased'
                : $rootScope.contentPackagTemplate
            if (currentTemplate === 'Default') currentTemplate = 'TreeBased'
            return templates[currentTemplate].controller
          },
          controllerAs: 'vm'
        }
      },
      bodyClass: 'content-package content-package-show',
      resolve: {
        Resolved: (
          $stateParams,
          ContentPackage,
          Timeline,
          $state,
          $rootScope,
          MultiTranslateService
        ) =>
          ContentPackage.getContentPackage({
            contentPackageId: $stateParams.id,
            contentType: $stateParams.contentType
          })
            .$promise.then(res => {
              res.result.ResolvedContentPackage =
                MultiTranslateService.getForView(
                  ContentPackage,
                  $rootScope.currentLang,
                  res.result.ResolvedContentPackage
                )
              $rootScope.contentPackagTemplate = res.result.template
              return res.result
            })
            .catch(err => {
              console.error(err)
              Timeline.findOne({
                filter: {
                  where: {
                    oldContentPackageId: $stateParams.id
                  }
                }
              })
                .$promise.then(res => {
                  $state.go('app.timeline.display', { id: res.id })
                })
                .catch(err => {
                  console.error('Timeline not found', err)
                })
            })
      }
    })
    .state('app.content-package.import-document', {
      url: '/import/:id',
      views: {
        'content@app': {
          template: require('./pages/document-import/document-import.html'),
          controller: 'DocumentImportController as vm'
        }
      },
      bodyClass: 'content-package content-package-show',
      resolve: {
        ResolvedContentPackage: function (ContentPackage, $stateParams) {
          return ContentPackage.findOne({
            filter: {
              where: { id: $stateParams.id },
              include: ['documents', 'image_object']
            }
          }).$promise
        }
      }
    })
    .state('app.content-package.new-document', {
      url: '/show/:id/new-document',
      lazyLoad: () => {
        return import(
          /* webpackChunkName: "icons" */ 'app/modules/core/assets/icons/selection.json'
        )
          .then(mod => {
            global.Icons = mod.default
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      views: {
        'content@app': {
          template: require('./pages/document-new-edit/document-new-edit.html'),
          controller: 'DocumentNewEditController'
        }
      },
      bodyClass: 'content-package content-package-show',
      resolve: {
        ResolvedDocument: () => null
      }
    })
    .state('app.content-package.edit-document', {
      url: '/show/:id/edit-document/:documentId/:contentType',
      views: {
        'content@app': {
          template: require('./pages/document-new-edit/document-new-edit.html'),
          controller: 'DocumentNewEditController'
        }
      },
      bodyClass: 'content-package content-package-show',
      resolve: {
        ResolvedIcons: function () {
          return import(
            /* webpackChunkName: "icons" */ 'app/modules/core/assets/icons/selection.json'
          )
            .then(mod => {
              global.Icons = mod.default
            })
            .catch(err => {
              throw new Error('Ooops, something went wrong, ' + err)
            })
        },
        ResolvedDocument: function (Document, $stateParams) {
          const where = { latestVersion: true }
          where.recordId = $stateParams.documentId
          return Document.findOne({ filter: { where: where } }).$promise
        }
      }
    })
    .state('app.content-package.reorder', {
      url: '/show/:id/reorder',
      views: {
        'content@app': {
          template: require('./pages/document-reorder/document-reorder.html'),
          controller: 'ContentPackageReorderController'
        }
      },
      bodyClass: 'content-package content-package-show',
      resolve: {
        ResolvedContentPackage: function (ContentPackage, $stateParams) {
          const where = {
            or: [{ currentReleasedVersion: true }, { latestVersion: true }]
          }
          return ContentPackage.findOne({
            filter: {
              where: { id: $stateParams.id },
              include: [
                {
                  relation: 'documents',
                  scope: {
                    where: where
                  }
                },
                {
                  relation: 'image_object'
                }
              ]
            }
          }).$promise
        }
      }
    })

  // Navigation
  msNavigationServiceProvider.saveItem('apps.knowledgeBase.content-package', {
    title: 'CONTENTPACKAGE.CONTENTPACKAGE',
    icon: 'icon-file-document-multiple',
    weight: 10,
    state: 'app.content-package.list',
    acls: [{ model: 'ContentPackage', method: 'find' }]
  })
}

export default MODULE_NAME
